.backPerson {
  background-image: url("../../Images/backPerson.jpg");
  background-position: center;
  background-size: cover;
  height: 650px;
}
.mainContent {
  padding: 50px;
  padding-top: 100px;
  background: -webkit-linear-gradient(to right, #cac7c7, #e2dfdf);
  background: linear-gradient(to right, #cac7c7, #e2dfdf);
}
.mainContentMobile {
  padding: 50px;
  padding-top: 100px;
  background: -webkit-linear-gradient(to right, #cac7c7, #e2dfdf);
  background: linear-gradient(to right, #cac7c7, #e2dfdf);
  display: none;
}
.mainContent1 {
  width: 100%;
  background: -webkit-linear-gradient(to right, #fff, #dcdcdc);
  background: linear-gradient(to right, #e2e3e5, #fff);
}
.two {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 1950;
  padding-left: 15px;
}
.twoDark {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 1950;
  padding-left: 15px;
  color: #fff;
}
.oneRes {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 1950;
  padding-left: 15px;
}
.oneResDark {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 1950;
  padding-left: 15px;
  color: #fff;
}
.three {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 1950;
  padding-left: 15px;
  color: #828282;
  line-height: 10px;
}
.span3 {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  line-height: 30px;
}
.mainContentText {
  color: #4f4f4f;
  text-align: justify;
  padding: 20px;
}
.mainContentTextDark {
  color: #fff;
  text-align: justify;
  padding: 20px;
}
.mainContentText1 {
  color: #4f4f4f;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: justify;
}
.mainContentText1Dark {
  color: #fff;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: justify;
}
.backImage01 {
  background-image: url("../../Images/backImage01.png");
  background-position: center;
  background-size: cover;
  height: 650px;
}
.backImage02 {
  background-image: url("../../Images/backImage02.png");
  background-position: center;
  background-size: cover;
  height: 650px;
}
.backImage00 {
  background-image: url("../../Images/backImage00.jpg");
  background-position: top;
  /* background-size: cover; */
  border-radius: 25px;
  height: 550px;
}

.homePortfolio {
  margin-top: 60px;
}
.profileImage1 {
  background-image: url("../../Images/face1.png");
  background-position: center;
  background-size: cover;
  height: 190px;
}
.profileImage2 {
  background-image: url("../../Images/face2.png");
  background-position: center;
  background-size: cover;
  height: 190px;
}
.profileImage3 {
  background-image: url("../../Images/face3.png");
  background-position: center;
  background-size: cover;
  height: 190px;
}
.profileContentHome {
  margin-top: 20px;
}
.roundUnit {
  background-color: #e4e1e1;
  color: #e84a4a;
  padding: 10px;
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 11px;
  border-radius: 10px;
}

.footer {
  background-color: #ffffff;
  /* height:550px; */
  max-width: 1440px;
  margin: auto;
}

.footer0 {
  border-top: 1px solid #f2f2f2c7;
  box-shadow: 0px 15px 15px rgb(242, 242, 242),
    0px -15px 15px rgb(241, 241, 241);
}
.mobile {
  display: none;
}
.spanRes {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  word-wrap: break-word;
  font-weight: 100;
  /* line-height: 12px ; */
}
.spanResDark {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  word-wrap: break-word;
  font-weight: 100;
  /* line-height: 12px ; */
  color: #fff;
}
.spanFooter {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  line-height: 13px;
  margin: 15px;
}
.textPri2 {
  color: #ff6a50;
}
.footerLogo {
  margin-top: -40px;
}
.semi {
  color: #ff6a50;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
}
.footerContent {
  min-width: 250px;

  align-content: center;
}
.companyName {
  display: flex;
}
.companyName-mobile {
  display: none;
}
.homeButtonAlign {
  margin-left: 20px;
}
.homeContent2 {
  padding: 50px;
}
.backImagePadding2 {
  padding: 50px;
  display: block;
}
.backImagePadding2Mobile {
  display: none;
  padding: 50px;
}
.homeButtonAlign2 {
  margin-left: 20px;
}
.homeContent2Mobile {
  display: none;
}
@media only screen and (min-width: 1830px) and (max-width: 3230px) {
  .backImagePadding {
    padding: 50px;
    padding-top: 80px;
  }
  .backPerson {
    height: 750px;
  }
  .two {
    font-size: 35px;
  }
  .twoDark {
    font-size: 35px;
  }
  .span3 {
    font-size: 21px;
    line-height: 40px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 11px;
    font-weight: bold;
    padding: 10px 35px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }
  .backImage00 {
    background-position: center;
    background-size: cover;
    height: 600px;
  }
  .homeContent2 {
    padding: 160px;
  }
  .backImagePadding {
    padding: 50px;
    padding-top: 100px;
  }
  .backImagePadding2 {
    padding: 30px;
  }
  .backImage02 {
    height: 900px;
  }
  .backImage01 {
    height: 900px;
  }
  .homeProImage {
    height: 450px;
    width: 450px;
  }
  .roundUnit {
    padding: 20px;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
  }
  .mainContentText1 {
    font-size: 25px;
    line-height: 30px;
    padding-right: 150px;
    padding-left: 150px;
  }
  .mainContentText1Dark {
    font-size: 25px;
    line-height: 30px;
    padding-right: 150px;
    padding-left: 150px;
  }
}
@media only screen and (min-width: 1230px) and (max-width: 1830px) {
  .backImagePadding {
    padding: 40px;
    padding-top: 80px;
  }
  .backPerson {
    height: 650px;
  }
  .two {
    font-size: 30px;
  }
  .twoDark {
    font-size: 30px;
  }
  .span3 {
    font-size: 17px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 11px;
    font-weight: bold;
    padding: 10px 35px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }
  .backImage02 {
    height: 600px;
  }
  .backImage01 {
    height: 600px;
  }
  .three {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: -18px;
    margin-top: -18px;
  }
  .homeProImage {
    height: 220px;
  }
  .homeProImage {
    height: 300px;
    width: 300px;
  }
  .mainContentText1 {
    font-size: 18px;
    line-height: 30px;
    padding-right: 50px;
    padding-left: 50px;
  }
  .mainContentText1Dark {
    font-size: 18px;
    line-height: 30px;
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media only screen and (min-width: 970px) and (max-width: 1230px) {
  .backImagePadding {
    padding: 30px;
    padding-top: 80px;
  }
  .backPerson {
    height: 650px;
  }
  .two {
    font-size: 30px;
  }
  .twoDark {
    font-size: 30px;
  }
  .span3 {
    font-size: 17px;
  }
  .mainContent {
    padding: 40px;
    padding-top: 60px;
  }
  .homeContent2 {
    padding: 40px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 11px;
    font-weight: bold;
    padding: 10px 35px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }
  .backImage02 {
    height: 500px;
  }
  .backImage01 {
    height: 600px;
  }
  .homeContent3 {
    margin-top: -30px;
  }
  .three {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: -18px;
    margin-top: -18px;
  }
  .homeProImage {
    height: 220px;
    width: 220px;
  }
}
@media only screen and (min-width: 715px) and (max-width: 970px) {
  .backImagePadding {
    padding: 30px;
    padding-top: 80px;
  }
  .backPerson {
    height: 550px;
  }
  .two {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    font-weight: 1950;
    padding-left: 8px;
  }
  .twoDark {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    font-weight: 1950;
    padding-left: 8px;
  }
  .span3 {
    font-size: 15px;
    line-height: 20px;
  }
  .mainContent {
    padding: 30px;
    padding-top: 60px;
  }

  .mainContentText {
    padding: 12px;
  }
  .mainContentTextDark {
    padding: 12px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 11px;
    font-weight: bold;
    padding: 10px 35px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }
  .backImage00 {
    height: 470px;
  }
  .backImagePadding2 {
    padding: 20px;
    padding-top: 80px;
  }
  .homeContent2 {
    padding: 20px;
  }
  .backImage02 {
    height: 500px;
  }
  .backImage01 {
    height: 500px;
  }
  .homeContent3 {
    margin-top: 60px;
  }
  .three {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: -12px;
    margin-top: -12px;
  }
  .homeProImage {
    height: 150px;
    width: 150px;
  }
  .roundUnit {
    padding: 10px;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 10px;
  }
  .homeButtonAlign {
    margin-left: 5px;
  }
}
@media only screen and (min-width: 575px) and (max-width: 715px) {
  .backPerson {
    height: 450px;
  }
  .two {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 1950;
    padding-left: 2px;
  }
  .twoDark {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 1950;
    padding-left: 2px;
  }
  .span3 {
    font-size: 12px;
    line-height: 17px;
  }
  .mainContent {
    padding: 30px;
    padding-top: 60px;
  }
  .mainContentText {
    padding: 6px;
  }
  .mainContentTextDark {
    padding: 6px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 9px;
    font-weight: bold;
    padding: 6px 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }
  .homeButtonAlign {
    margin-left: 5px;
  }
  .backImage00 {
    background-size: cover;
    height: 410px;
  }
  .homeContent2 {
    padding: 13px;
  }
  .backImagePadding2 {
    padding: 23px;
    padding-top: 63px;
  }
  .backImagePadding {
    padding: 2px;
    padding-top: 50px;
  }
  .backImage02 {
    height: 400px;
  }
  .backImage01 {
    margin-left: 10px;
    height: 400px;
  }
  .homeContent3 {
    margin-top: 40px;
  }
  .homeButtonAlign2 {
    margin-left: 10px;
  }
  .three {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: -16px;
    margin-top: -16px;
  }
  .homeProImage {
    height: 100px;
    width: 100px;
  }
  .roundUnit {
    padding: 5px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 9px;
  }
  .spanRes {
    font-size: 10px;
    line-height: 10px;
  }
  .spanResDark {
    font-size: 10px;
    line-height: 10px;
  }
  .oneRes {
    font-size: 12px;
  }
  .oneResDark {
    font-size: 12px;
  }
}
@media only screen and (min-width: 430px) and (max-width: 575px) {
  .backPerson {
    height: 380px;
  }
  .two {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 1950;
    padding-left: 0px;
  }
  .twoDark {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 1950;
    padding-left: 0px;
  }
  .span3 {
    font-size: 11px;
    line-height: 14px;
  }
  .mainContent {
    padding: 20px;
    padding-top: 50px;
  }
  .mainContentText {
    padding: 2px;
    margin-bottom: 5px;
  }
  .mainContentTextDark {
    padding: 2px;
    margin-bottom: 5px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 6px;
    font-weight: bold;
    padding: 3px 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }
  .homeButtonAlign {
    margin-left: 5px;
    margin-left: 5px;
  }
  .backImage00 {
    background-size: cover;
    height: 350px;
  }
  .homeContent2 {
    padding: 5px;
  }
  .backImagePadding {
    padding: 2px;
    padding-top: 30px;
  }
  .backImagePadding2 {
    padding: 30px;
  }
  .backImage02 {
    margin-top: 30px;
    height: 350px;
  }
  .backImage01 {
    margin-top: 30px;
    height: 350px;
    margin-left: 10px;
  }
  .homeContent3 {
    margin-top: 40px;
  }
  .homeContent4 {
    padding: 15px;
  }
  .homeButtonAlign2 {
    margin-left: 5px;
  }
  .three {
    font-size: 15px;
    line-height: 15px;
    margin-bottom: -16px;
    margin-top: -5px;
  }
  .homeProImage {
    height: 150px;
    width: 150px;
  }
  .roundUnit {
    padding: 5px;
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .spanRes {
    font-size: 15px;
    line-height: 10px;
  }
  .spanResDark {
    font-size: 15px;
    line-height: 10px;
  }
  .oneRes {
    font-size: 15px;
  }
  .oneResDark {
    font-size: 15px;
  }
  .profileContentPart {
    min-width: 400px;
  }
  .mainContentText1 {
    font-size: 23px;
    line-height: 25px;
    padding: 30px;
  }
  .mainContentText1Dark {
    font-size: 23px;
    line-height: 25px;
    padding: 30px;
  }
}
@media only screen and (min-width: 350px) and (max-width: 430px) {
  .backPerson {
    height: 280px;
    background-position: top;
    background-size: cover;
  }
  .two {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 1950;
    padding-left: 0px;
  }
  .twoDark {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 1950;
    padding-left: 0px;
  }
  .span3 {
    font-size: 14px;
    line-height: 18px;
  }
  .mainContent {
    padding: 20px;
    padding-top: 30px;
    display: none;
  }
  .backImagePadding2 {
    display: none;
  }
  .backImagePadding2Mobile {
    display: block;
    padding: 30px;
  }
  .homePortfolio {
    margin-top: -10px;
    padding: 10px;
  }
  .mainContentMobile {
    padding: 30px;
    padding-top: 30px;
    background: -webkit-linear-gradient(to right, #cac7c7, #e2dfdf);
    background: linear-gradient(to right, #cac7c7, #e2dfdf);
    display: block;
  }
  .mainContentText {
    padding: 2px;
  }
  .mainContentTextDark {
    padding: 2px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 11px;
    font-weight: bold;
    padding: 7px 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }
  .homeButtonAlign {
    margin-left: 3px;
    margin-top: -10px;
  }
  .companyName-mobile {
    display: block;
  }
  .companyName {
    display: none;
  }
  .backImage00 {
    background-position: center;
    background-size: cover;
    height: 260px;
  }
  .homeContent2 {
    padding: 0px;
  }
  .web {
    display: none;
  }
  .mobile {
    display: block;
  }
  .backImagePadding {
    padding: 0px;
    padding-top: 30px;
  }
  .backImagePadding2 {
    padding: 30px;
  }
  .backImage02 {
    margin-top: -10px;
    margin-bottom: -27px;
    height: 300px;
  }
  .backImage01 {
    margin-top: 0px;
    height: 300px;
    margin-left: 0px;
  }
  .homeContent3 {
    margin-top: 40px;
  }
  .homeContent4 {
    padding: 15px;
  }
  .homeButtonAlign2 {
    margin-left: 5px;
  }
  .three {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: -16px;
    margin-top: -16px;
  }
  .homeProImage {
    height: 120px;
    width: 120px;
  }
  .roundUnit {
    padding: 5px;
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .spanRes {
    font-size: 13px;
    line-height: 10px;
  }
  .spanResDark {
    font-size: 13px;
    line-height: 10px;
  }
  .oneRes {
    font-size: 13px;
  }
  .oneResDark {
    font-size: 13px;
  }
  .profileContentPart {
    min-width: 300px;
  }
  .mainContentText1 {
    font-size: 21px;
    line-height: 25px;
    padding: 20px;
  }
  .mainContentText1Dark {
    font-size: 21px;
    line-height: 25px;
    padding: 20px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 350px) {
  .backPerson {
    height: 320px;
  }
  .web {
    display: none;
  }
  .mobile {
    display: block;
  }
  .two {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 1950;
    padding-left: 0px;
  }
  .twoDark {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 1950;
    padding-left: 0px;
  }
  .span3 {
    font-size: 11px;
    line-height: 13px;
  }
  .mainContent {
    padding: 15px;
    padding-top: 30px;
    display: none;
  }

  .mainContentMobile {
    padding: 30px;
    padding-top: 30px;
    background: -webkit-linear-gradient(to right, #cac7c7, #e2dfdf);
    background: linear-gradient(to right, #cac7c7, #e2dfdf);
    display: block;
  }
  .mainContentText {
    padding: 2px;
  }
  .mainContentTextDark {
    padding: 2px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 9px;
    font-weight: bold;
    padding: 5px 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }
  .homeButtonAlign {
    margin-right: 3px;
    margin-top: -20px;
  }
  .backImagePadding2 {
    display: none;
  }
  .backImagePadding2Mobile {
    display: block;
    padding: 20px;
  }
  .homePortfolio {
    margin-top: -10px;
    padding: 10px;
  }
  .companyName-mobile {
    display: block;
  }
  .companyName {
    display: none;
  }
  .backImage00 {
    background-size: cover;
    height: 260px;
  }
  .homeContent2 {
    padding: 0px;
  }
  .backImagePadding {
    padding: 0px;
    padding-top: 20px;
  }
  .backImagePadding2 {
    padding: 20px;
  }
  .backImage02 {
    margin-top: -10px;
    height: 250px;
  }
  .backImage01 {
    margin-top: -20px;
    margin-left: 0px;
    height: 290px;
  }
  .homeContent3 {
    margin-top: 20px;
  }
  .homeButtonAlign2 {
    margin-left: 20px;
    margin-top: -500px;
  }
  .homeContent4 {
    padding: 10px;
  }
  .homeButtonAlign2 {
    margin-left: 5px;
  }
  .three {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: -16px;
    margin-top: -16px;
  }
  .homeProImage {
    height: 120px;
    width: 120px;
  }
  .roundUnit {
    padding: 5px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .spanRes {
    font-size: 13px;
    line-height: 10px;
  }
  .spanResDark {
    font-size: 13px;
    line-height: 10px;
  }
  .oneRes {
    font-size: 13px;
  }
  .oneResDark {
    font-size: 13px;
  }
  .profileContentPart {
    min-width: 250px;
  }
  .mainContentText1 {
    font-size: 18px;
    line-height: 25px;
    padding: 1px;
  }
  .mainContentText1Dark {
    font-size: 18px;
    line-height: 25px;
    padding: 1px;
  }
}
