.maintenanceBasicDetailsNameAlign{
     padding-top:1%;
     width:70%;
}
.maintenanceBasicDetailsExpandAlign{
     width:10%;
}
.maintenanceBasicDetailsImageAlign{
     width:20%;
     align-self: left;
     text-align: left;
}
.maintenanceBasicDetailsDateAlign{
     width:20%;
     align-self: left;
     text-align: left;
     margin-top:-5px !important;
}

@media only screen and (min-width: 614px) and (max-width: 730px) {
     .maintenanceBasicDetailsDateAlign{
          width:30%;
          align-self: left;
          text-align: left;
     }
}
@media only screen and (min-width: 0px) and (max-width: 890px) {
     .maintenanceBasicDetailsImageAlign{
          width:100%;
          
     }
     .maintenanceBasicDetailsNameAlign{
          padding-top:3%;
          width:90%;
     }
     .maintenanceBasicDetailsExpandAlign{
          width:10%;
     }
}