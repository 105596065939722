.torontoAgreementHeadLeft{
    padding:0px;
    margin:0px;
    margin-top:27px;
  }

  .torontoagreementTableBody{
    margin-right:30px;
    margin-left:30px;
  }

  .torontoagreementTitleBar{
    font-size: 16px ;
    font-weight: bold;
    background-color: rgb(211,211,211);
    color:#000;
    border-bottom: 1px solid rgba(000,000,000);
    border-top: 2px solid rgba(000,000,000);
  }

  .agreementBoder{
    border-bottom: 1px solid rgba(000,000,000);
  }

  .agreementRightBoder{
    border-right: 1px solid rgba(000,000,000);
  }

  hr.hrline {
    border: 1px solid rgba(000,000,000);
    border-width: 1px;
    background-color:black
  }

  .ApplicationNote{
	background-color: rgba(224, 220, 220, 0.603);
	border: none;
    font-family: "Montserrat", sans-serif;
	width: 100%;     
    /* padding-right:50px; */
    font-size: 12px;
  }

  .TrontoInput{
    border: none;
    border-bottom: 2px solid black;
      font-family: "Montserrat", sans-serif;
      padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
  }

  .bottomspanLeft{
    font-size: 10px ;
    text-align: left;
    padding-top:50px;
  }
  .bottomspanRight{
    font-size: 10px ;
    text-align: right;
    padding-top:50px;
  }
  .bottomspancenter{
    font-size: 10px ;
    text-align: center;
    padding-top:50px;
  }