.cardHeader{
  /* background-color: #4c7faf; */
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
  
}
.card-footer{
  background-color: #f1ac7d;
}
.footerButton{
  border-radius: 60px;
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
}
.footerButton:hover{
  background-color: #584f48;
  color: white;
  cursor: pointer;
}
.dashCover{
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* border-style: solid; */
  /* border-width: 0.5px; */
  /* border-color: rgb(5, 5, 56); */
}

@media only screen and (min-width: 992px) and (max-width: 1250px) {
  .cardHeader{
    font-size: 15px;
  }
  .cardTextDash{
    font-size: 40px !important;
  }
}
@media only screen and (min-width: 700px) and (max-width: 850px) {
  .cardHeader{
    font-size: 15px;
  }
  .cardTextDash{
    font-size: 40px !important;
  }
}
@media only screen and (min-width: 100px) and (max-width: 700px) {
  .cardHeader{
    font-size: 15px;
  }
  .cardTextDash{
    font-size: 40px !important;
  }
  .square1{
    margin-top:20px;
    min-width: 160px !important;
  }
}