@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,800);
@import url(https://fonts.googleapis.com/css2?family=Play&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: #fff;
  /* scrollbar-width: thin; */
}
.modal-backdrop {
  background-color: rgb(0, 0, 0);
  /* backdrop-filter: invert(80%) !important;
  background: rgb(255, 11, 11) !important; */
}
.appResponsive{
  max-width:1440px;
  /* background: #f4f4f498; */
  margin: auto;
}
.profileFillText {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  line-height: 23px;
  color: #7e7d7d;
  padding-left: 7%;
  padding-right: 7%;
}
.profileFillText2 {
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  line-height: 28px;
  color: #ff4949;
}
.progressProfile {
  width: 150px;
  height: 150px !important;
  float: left;
  line-height: 150px;
  background: none;
  margin: 20px;
  box-shadow: none;
  position: relative;
}

.progressProfile:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.progressProfile > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progressProfile .progressProfile-left {
  left: 0;
}

.progressProfile .progressProfile-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 8px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progressProfile .progressProfile-left .progressProfile-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progressProfile .progressProfile-right {
  right: 0;
}

.progressProfile .progressProfile-right .progressProfile-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  
}

.progressProfile.blue .progressProfile-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  /* background: #000; */
  font-size: 24px;
  color: #ffffff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-image: url(/static/media/profileFill.2ef4090c.jpeg);
  background-position: center;
  background-size: cover;
  text-shadow: 0 0 5px #000;
}
.progressProfile.red .progressProfile-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  /* background: #000; */
  font-size: 24px;
  color: #ffffff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-image: url(/static/media/profileFill.2ef4090c.jpeg);
  background-position: center;
  background-size: cover;
  text-shadow: 0 0 5px #000;
}

.progressProfile.blue .progressProfile-bar {
  border-color: #dfc4ff;
}

.progressProfile.red .progressProfile-bar {
  border-color: #ff5d5d;
}

.alertApp {
  position: fixed;
  top: 200px;
  right: 0;
  z-index: 500;
}
body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  cursor: pointer;
}
.profileFill {
  z-index: 5500;
  padding-top: 5%;

  background-color: rgba(0, 0, 0, 0.386);
}
.profileFill2 {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.bodyAvailable::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.bodyAvailable::-webkit-scrollbar-track {
  background: #c9c8c8;
}
.bodyAvailable::-webkit-scrollbar-thumb {
  background: #ff6d53;
}
.bodyAvailable2::-webkit-scrollbar {
  width: 2px;
  height: 0px;
}
.bodyAvailable2::-webkit-scrollbar-track {
  background: #c9c8c8;
}
.bodyAvailable2::-webkit-scrollbar-thumb {
  background: #ff6d53;
}
/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #ff4b2b;
}


/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #aca9a8;
}

.monthlyRentScroll{
  max-height: 300px;
  overflow-y: scroll;
}

.monthlyRentScroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.monthlyRentScroll::-webkit-scrollbar-track {
  background: #c9c8c8;
}
.monthlyRentScroll::-webkit-scrollbar-thumb {
  background: #ff6d53;
}



.AppMain {
  background: hsl(0, 0%, 100%);
}
.AppDark {
  background: #424241;
}
.darkBg {
  background: #424241;
}
.bgPri {
  background-color: #ff4b2b;
}

.bgPriRad {
  background: linear-gradient(to right, #ff4b2b, #ff416c);
}
.bgPriRad2 {
  background: linear-gradient(to right, #ff4b2b, #f76046);
}
.textPri {
  color: #ff4b2b !important;
}
.textSuccess {
  color: #2fcb56 !important;
}
.textWarning {
  color: #e2cc26 !important;

}
.textPri2 {
  color: #f86e56;
}
.textPriHover {
  color: #ff4b2b;
}
.textPriHover:hover {
  color: #9c2d1a;
}
.borderPri {
  border: 1px solid #ff4b2b;
}
.bgSec {
  background-color: rgba(224, 220, 220, 0.603);
}
.textSec {
  color: rgba(224, 220, 220, 0.603);
}
.textSec1 {
  color: rgba(152, 152, 152, 0.865);
}
.textDark {
  color: rgba(102, 95, 95, 0.734);
}
.borderSec {
  border: 1px solid rgba(224, 220, 220, 0.603);
}
.pointer {
  cursor: pointer;
}
.notAllow {
  cursor: not-allowed;
}
.noPointer {
  cursor: none !important;
}
.axText0 {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
}
.axText {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
}
.axTextBold {
  font-family: "Montserrat", sans-serif;
  font-weight: 650;
}
.axTextDark {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-size: 20px;
}

.axText1 {
  font-size: 28px;
   font-family: "Montserrat", sans-serif;
}
.loginModal {
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
  font-family: "Montserrat", sans-serif;
  
  /* height: 100vh; */
  /* margin: 0px 50px; */
}
.borderRightGray {
  border-right: 1px solid rgb(228, 227, 227);
}
.borderLeftGray {
  border-left: 1px solid rgb(239, 239, 239);
}
.borderTopGray {
  border-top: 1px solid rgb(239, 239, 239);
}
.borderBottomGray {
  border-bottom: 1px solid rgb(239, 239, 239);
}
.logo {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-weight: 1950;
}
h1 {
  font-weight: bold;
  margin: 0;
}
h1.one {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 1950;
  padding-left: 15px;
}
p.one {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 850;
  color: #414141;
}
p.two {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 150;
  color: #8e8e8e;
}
h2.one {
  font-family: "Montserrat", sans-serif;
  font-size: 29px;
  font-weight: 1950;
  font-weight: bold;
  line-height: 20px;
}
h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
p.nav {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
span {
  font-size: 12px;
}
.span1 {
  font-size: 20px;
  line-height: 30px;
}
.span2 {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  white-space: nowrap;
}
.spanThin{
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500  !important;
  white-space: nowrap;
}
.span5 {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  white-space: none;
}
.span6 {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  word-wrap: break-word;
}
.span4 {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  white-space: nowrap;
  position: absolute;
  margin-left: -120px;
  margin-top: 25px;
  color: #ff4b2b;
}
.spanBig {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 13px;
}
.span7 {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  line-height: 13px;
}
.span8 {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  line-height: 16px;
}
.spanvalidate {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  padding-right: 10px;
  /* position:fixed; */
  padding-top: 0px;
}
a {
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

button {
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}
.sample {
  border-radius: 20px;
  border: solid 1px #e2e2e2;
  background-color: #6772e5;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}
button:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.buttonPri {
  border: solid 1px #ff4b2b;
  background-color: #ff4b2b;
  color: #fff;
  cursor: pointer;
}
button.buttonPriHome {
  border-radius: 20px;
  border: solid 1px #ff4b2b;
  background-color: #ff4b2b;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}
.buttonSuccess {
  border: solid 1px #2bff87;
  background-color: #2bff87;
  color: #fff;
  cursor: pointer;
}
.textLowerCase{
  text-transform: lowercase !important;
}
.textCapitalizeCase{
  text-transform: capitalize !important;
}
.textNoneCase{
  text-transform: none !important;
}
.textUpperCase{
  text-transform: uppercase !important;
}
.textLeft{
  text-align: left;
}
.textRight{
  text-align: right;
}
button.buttonPriHome:hover {
  border: solid 1px #ff4b2b;
  background-color: transparent;
  color: #ff4b2b;
}
button.buttonPri:hover {
  border: solid 1px #ff4b2b;
  background-color: transparent;
  color: #ff4b2b;
}
.buttonSquarePri {
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;    
  border: solid 1px #ff4b2b;
  background-color: #ff4b2b;
  color: #fff;
  cursor: pointer;
}
.buttonSquareSec {
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;    
  border: solid 1px #a79e9d;
  background-color: #a79e9d;
  color: #fff;
  cursor: pointer;
}
.buttonSquareBlack {
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;    
  border: solid 1px #000000;
  background-color: #000000;
  color: #fff;
  cursor: pointer;
}
.buttonSquareSuccess {
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;    
  border: solid 1px #1aa782;
  background-color: #1aa782;
  color: #fff;
  cursor: pointer;
}
.buttonSquareLinePri {
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;    
  border: solid 1px #ff4b2b;
  background-color: transparent;
  color: #ff4b2b;
  cursor: pointer;
}
.buttonSquareLineSec{
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  border: solid 1px #a79e9d;
  background-color: transparent;
  color: #a79e9d;
  cursor: pointer;
}
.buttonSquareLineBlack{
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  border: solid 1px #000000;
  background-color: transparent;
  color: #000000;
  cursor: pointer;
}
.buttonSquareLineSuccess{
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  border: solid 1px #1aa782;
  background-color: transparent;
  color: #1aa782;
  cursor: pointer;
}
.badgeRed {
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 1px #ff4b2b;
  background-color: #ff4b2b;
  color: #fff;
}
.badgeRedLine {
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 1px #ff4b2b;
  background-color: transparent;
  color: #ff4b2b;
}
.badgeYellow {
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 1px #F89212;
  background-color: #F89212;
  color: #fff;
}

.badgeYellowLine {
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 1px #F89212;
  background-color: transparent;
  color: #F89212;
}
.badgeGreen {
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 1px #1aa782;
  background-color: #1aa782;
  color: #fff;
}
.sec {
  border: solid 1px #a79e9d;
  background-color: transparent;
  color: #a79e9d;
}
.secDisable {
  border: solid 1px #a79e9d;
  background-color: transparent;
  color: #a79e9d;
  font-weight: bold;
  cursor: not-allowed !important;
}
.sec:hover {
  border: solid 1px #a79e9d;
  background-color: #a79e9d;
  color: #fff;
}
.disable {
  cursor: not-allowed;
}
button.buttonPri:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
button.buttonPri:focus {
  outline: none;
}
.buttonTrans {
  border: solid 0px #fff;
  background: transparent;
  color: #000;
  padding: 10px;
  padding-right: 15px;
  padding-left: 15px;
}
.buttonTransBlack {
  border: solid 1px #000;
  background: transparent;
  color: #000;
  padding: 10px;
  padding-right: 15px;
  padding-left: 15px;
}
.buttonDark {
  border: solid 0px #fff;
  background: linear-gradient(to right, #928e8e52, rgba(170, 163, 163, 0.707));
  color: #fff;
}
.rad15 {
  border-radius: 15px;
}
.buttonDark:hover {
  /* border: solid 1px #fff; */
  /* background: linear-gradient(to right, #979696, rgb(228, 227, 227)); */
  box-shadow: 0 0px 40px #fff;
  /* color:#616161; */
}
.buttonDark1 {
  border: solid 1px #5f5a5a52;
  color: #5f5a5a52;
  /* background: linear-gradient(to right, #e6e4e4, rgb(235, 231, 231)); */
  background-color: transparent;
}
button.ghost {
  background-color: transparent;
}
button.pri {
}
button.pri:hover {
  background-color: transparent;
  border-color: #ff4b2b;
  color: #ff4b2b;
}
button.pri:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
button.pri2 {
  background-color: #eb6658;
  color: #fff;
}
button.pri2:hover {
  background-color: #fff;
  border-color: #ff4b2b;
  color: #ff4b2b;
}
button.pri2:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.formlogin {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}
input:checked {
  border: 1px #9c2d1a solid;
}
input.tag {
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
input.tag2 {
  font-family: "Montserrat", sans-serif;
  background-color: rgb(241, 241, 241);
  border: none;

  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
input.alert {
  font-family: "Montserrat", sans-serif;
}
input.alert1 {
  border: 1px solid #f76046 !important;
}
input {
  border: none;
  font-family: "Montserrat", sans-serif;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.spanRound {
  border-radius: 25px;
  background-color: #ff4b2b;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #fff;
}
.spanRoundDark {
  border-radius: 25px;
  background-color: #aaa9a9;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #fff;
}
.divider {
  /* minor cosmetics */
  display: table;
  font-size: 24px;
  text-align: center;
  width: 75%; /* divider width */
  margin: 30px auto; /* spacing above/below */
}
.divider span {
  display: table-cell;
  position: relative;
}
.divider span:first-child,
.divider span:last-child {
  width: 50%;
  top: 13px; /* adjust vertical align */ /* line width */
  background-size: 100% 2px; /* line width */
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
}
.divider span:first-child {
  /* color changes in here */
  background-image: linear-gradient(90deg, transparent, rgb(151, 147, 147));
}
.divider span:nth-child(2) {
  color: #ee674f;
  padding: 1px 20px;
  width: auto;
  white-space: nowrap;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 900;
}
.divider span:last-child {
  /* color changes in here */
  background-image: linear-gradient(90deg, rgb(155, 153, 153), transparent);
}


.blink_me {
  -webkit-animation: blinker 2s linear infinite;
          animation: blinker 2s linear infinite;
}

@-webkit-keyframes blinker {  
  50% { opacity: 0; }
}

@keyframes blinker {  
  50% { opacity: 0; }
}

.itemCenter{
  margin: auto;
}
.PDFWeb{
  display: block;
}
.PDFMobile{
  display: none;
}
.dropdown-item {
  display: block;
  width: 100%;
 padding: .3rem .7rem !important;
}
@media only screen and (min-width: 970px) and (max-width: 1230px) {
  button.buttonPriHome {
    font-size: 11px;
    padding: 10px 35px;
    letter-spacing: 1px;
  }
}

@media only screen and (min-width: 550px) and (max-width: 850px) {
  button.buttonPriHome {
    font-size: 10px;

    padding: 8px 30px;
  }
  .axText {
    font-size: 20px;
  }
  .axText0 {
    font-size: 22px;
  }
  .axTextDark {
    font-size: 28px;
  }
  .axText1 {
    font-size: 25px;
  }
}
@media only screen and (min-width:0px) and (max-width: 550px) {
  .PDFWeb{
    display: none;
  }
  .PDFMobile{
    display: block;
  }
  button.buttonPriHome {
    font-size: 10px;

    padding: 8px 30px;
  }
  .axText0 {
    font-size: 20px;
  }
  .axText {
    font-size: 20px;
  }
  .axTextDark {
    font-size: 20px;
  }
  .axText1 {
    font-size: 18px;
  }

}


.row.equal-cols:before,
.row.equal-cols:after {
  display: block;
}

.row.equal-cols > [class*='col-'] {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.row.equal-cols > [class*='col-'] > * {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto; 
}
.AppDashboard {
 
  padding: 2%;
  margin-top: 4%;
}
.App{
  padding:3%
}
.cardHead {
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
}
th {
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
}
td {
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Ubuntu', sans-serif; */
}
.submit {
  background: linear-gradient(to bottom right, #e2523d 0%, #ee9f77 100%);
  border: none;
  color: azure;
  padding: 8px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.submit:hover {
  background: linear-gradient(to bottom right, #e66754 0%, #ff9966 100%);
}
.submit1 {
  background: linear-gradient(to bottom right, #6d6867 0%, #c4c1bf 100%);
  border: none;
  color: #fff;
  padding: 8px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.submit1:hover {
  background: linear-gradient(to bottom right, #999696 0%, #cac6c5 100%);
}

.primary {
  background: linear-gradient(to bottom right, #3299c9 0%, #e74c3c 100%);
  border: none;
  color: #fff;
  padding: 7px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.primary:hover {
  background: linear-gradient(to bottom right, #51b1dd 0%, #e74c3c 100%);
}

.primaryIcon {
  background: linear-gradient(to bottom right, #3299c9 0%, #e74c3c 100%);
  border: none;
  color: #fff;
  padding: 10px;
  /* padding-right: 10px;
  padding-left: 10px; */
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.primaryIcon:hover {
  background: linear-gradient(to bottom right, #51b1dd 0%, #e74c3c 100%);
}
.success {
  background: linear-gradient(to bottom right, #2fa75b 0%, #bbecc5 100%);
  border: none;
  color: #fff;
  padding: 8px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.success:hover {
  background: linear-gradient(to bottom right, #40c06f 0%, #cdf5d5 100%);
}
.secondary {
  background: linear-gradient(to bottom right, #672fa7 0%, #e2cef8 100%);
  border: none;
  color: #fff;
  padding: 8px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.secondary:hover {
  background: linear-gradient(to bottom right, #955ad8 0%, #d2bbec 100%);
}
.BackApproval {
  background: linear-gradient(rgba(143, 24, 24, 0), rgba(255, 255, 255, 0)),
    url("http://localhost:3000/2.png");
}
.oneLine {
  white-space: nowrap;
}
.bullet {
  position: absolute;
}
.darkBlue {
  color: #244d74;
}
.bgDarkBlue {
  background-color: #eb6658;
}
.blue {
  color: #244d74;
}
.lightBlue {
  color: #4c7faf;
}
.tenant {
  background-color: #f3bd7b;
}
.tenant1 {
  background-color: #ffd29ca6;
}
.landLoad {
  background-color: #af7ac5;
}
.landLoad1 {
  background-color: #af7ac590;
}
.currency {
  background-color: #45b39d;
}
.currency1 {
  background-color: #45b39d88;
}
.application {
  background-color: #7fb3d5;
}
.pet {
  background-color: #f1948a;
}
.pet1 {
  background-color: #ff422dc1;
}
.mail {
  background-color: #aab7b8;
}
.fullHeight {
  height: 100%;
}
.fullWidth {
  width: 100%;
}
.width-1 {
  width: "1rem";
}
.width-1-5 {
  width: "1.5rem";
}
.occupied {
  font-size: 50px;
position: absolute;
right: 0px;
  width:120px;
  padding-top: 40px;
  padding-top: 40px;
  
  color: #e74c3c;
  font-weight: 800;
  z-index: 100;
  width: 100%;
}
@media only screen and (min-width: 224px) and (max-width: 991px) {
  .occupied {
    width: 100%;
    padding-top: 10px;
    padding-right: 50px;
  }
}
@media only screen and (min-device-width: 224px) and (max-device-width: 425px) {
  .occupied {
    width: 100%;
 
    min-width: auto;
    padding-top: 10px;
    padding-right: 50px;
  }
  .AppDashboard {
    margin-top: 12%;
  }
}
@media only screen and (min-device-width: 425px) and (max-device-width: 662px) {
  .AppDashboard {
    margin-top: 8%;
  }
  .occupied {
    width: 100%;
    margin-top: -40px;
    min-width: auto;

  }
}
@media only screen and (min-device-width: 662px) and (max-device-width: 992px) {
  .AppDashboard {
    margin-top: 5%;
  }
  .occupied {
    width: 100%;
    margin-top: -40px;
    min-width: auto;

  }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1245px) {
  .occupied {
    width: 100%;
    margin-top: -30px;

    min-width: auto;
  }
}
@media only screen and (min-width: 425px) and (max-width: 1245px) {
  .occupied {
    width: 100%;
    padding-top: 40px;
    padding-right: 50px;
    min-width: auto;
  }
}
@media only screen and (min-width: 1245px) and (max-width: 2000px) {
  .occupied {
    width: 100%;
    padding-top: 10px;
    padding-right: 50px;

    min-width: auto;
  }
}
@media only screen and (min-width: 2000px) and (max-width: 6045px) {
  .occupied {
    width: 100%;
    padding-top: 10px;
    padding-right: 50px;

    min-width: auto;
  }
}
.tableHead {
    white-space: nowrap;
  }
.rowTable{
    width: 100%;
    text-align: left;
    display: inline-table;
    /* white-space: nowrap; */
    table-layout: fixed;
  }
  
  .headName{
    text-align: left;
    color:rgb(243, 243, 243) !important;
    font-family: 'Montserrat', sans-serif;
  }
  .tableRental1 {
    min-height: 510px;
  }
  thead {
    overflow-y: scroll;
    width: 100%;
    background-color: #eb6658 ;
  }
 .tHeadType {
    background-color: #eb6658 ;
    border: none;
    border-radius: 15px;
    /* border-top-right-radius: 15px; */
    color:#fff;
  }
  .tHeadType1 {
    background-color: #fff ;
    border: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color:#eb6658;
  }
  .table{
    overflow-y: scroll; 
    width: 100%;     
    /* height: 1000px;             */
    /* position: absolute; */
  }
  .table::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .table::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .table::-webkit-scrollbar-thumb {
    background: #aca9a8;
  }
  
  /* Handle on hover */
  .table::-webkit-scrollbar-thumb:hover {
    background: #eb6658;
  }
  .tableYAxis {
  }
  /* width */
  .table::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .table::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .table::-webkit-scrollbar-thumb {
    background: #aca9a8;
  }
  
  /* Handle on hover */
  .table::-webkit-scrollbar-thumb:hover {
    background: #FF4B2B;
  }
  .dropDownSlow{
   
  }

  .rowTable{
   
    table-layout: auto;
  }
  @media only screen and (min-width: 15px) and (max-width: 1280px) {
    .rowTable{
      width: 100%;
      text-align: left;
      display: inline-table;
      /* white-space: nowrap; */
      table-layout: auto;
    }
  }
.page404{

	display: -webkit-flex;

	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	padding-top: 8%;
	margin: -20px 0 50px;
}
.page404Original{
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	padding-top: 8%;
	margin: -150px 0 50px;
}
.Image404{
	padding-top: 10%;
}
.PageText404{
    padding-top: 20%;
}
.head{
    font-size: 100px;
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1500px) {
	.page404Original{
		margin: -90px 0 50px;
	}

}
@media only screen and (min-device-width: 800px) and (max-device-width: 900px) {
	.page404Original{
		margin: -40px 0 50px;
	}
	.head{
		font-size: 60px;
	}
	.head2{
		font-size: 30px;
	}
	.page404{
		padding-top: 8%;
		margin: -20px 0 50px;
	}
	.Image404{
		padding-top: 10%;
	}
	.PageText404{
		padding-top: 16%;
	}
	.span1{
		font-size: 17px;
	}
}
@media only screen and (min-device-width: 650px) and (max-device-width: 800px) {
	.page404Original{
		margin: 0px 0 50px;
	}
	.head{
		font-size: 30px;
	}
	.head2{
		font-size: 23px;
	}
	.page404{
		padding-top: 15%;
		margin: -20px 0 80px;
	}
	.Image404{
		padding-top: 10%;
	}
	.PageText404{
		padding-top: 16%;
	}
	.span1{
		font-size: 12px;
		line-height: 20px;
	}
}
@media only screen and (min-device-width: 550px) and (max-device-width: 650px) {
	.page404Original{
		margin: 30px 0 50px;
	}
	.head{
		font-size: 24px;
	}
	.head2{
		font-size: 16px;
	}
	.page404{
		padding-top: 18%;
		margin: -20px 0 50px;
	}
	.Image404{
		padding-top: 10%;
	}
	.PageText404{
		padding-top: 1%;
	}
	.span1{
		font-size: 9px;
		line-height: 20px;
	}

}
@media only screen and (min-device-width: 100px) and (max-device-width: 550px) {
	.page404Original{
		margin: 60px 0 50px;
	}
	.head{
		font-size: 19px;
	}
	.head2{
		font-size: 10px;
	}
	.page404{
		padding-top: 20%;
		margin: -20px 0 50px;
	}
	.Image404{
		padding-top: 10%;
	}
	.PageText404{
		padding-top: 1%;
	}
	.span1{
		font-size: 8px;
		line-height: 15px;
	}
	.pri{
		/* margin-top: -20px !important; */
	}
}
header img {
    height: 80px;
    margin-left: 40px;
}

header {
    background-color: white;
   
    /* background-color: rgb(237, 237, 237); */
    position:static ;
    top:0;
    left: 0;
    right: 0;
    height:80px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    
    Z-index:210;
   
}
header.dark{
    background-color: #30302f;
    color:#fff;
}
.navItem {
    background-color: white;
    /* position: fixed; */
    top:0;
    /* left: 0; */
    right: 0;
    
    display: -webkit-flex;
    
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    box-shadow: 0 8px 6px -6px black;
    Z-index:200;
    display:none;
    height:0px;
}

.itemLogo{
    font-size: 50px !important;
    padding-top:100px !important;
}
.itemList{
    font-size: 20px !important;
    line-height: 35px !important;
}
.menuShow{
    -webkit-animation: show3 0.6s;
            animation: show3 0.6s;
    z-index: 2000;
    height: 350px;
    display:block;
 
}
@-webkit-keyframes show3 {
	0%{
        height: 0px;
        display: block; 
        opacity: 0;
        
	}
	80% {
        opacity: 0.3;
        height: 250px;
        display: block; 
        
	}
	100% {
        opacity: 1;
        height: 300px;
        display: block; 
        
	}
}
@keyframes show3 {
	0%{
        height: 0px;
        display: block; 
        opacity: 0;
        
	}
	80% {
        opacity: 0.3;
        height: 250px;
        display: block; 
        
	}
	100% {
        opacity: 1;
        height: 300px;
        display: block; 
        
	}
}
.navHead{
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    z-index:200;
 
}
.headerNav{   
    /* background: -webkit-linear-gradient(to right, #b6b5b5, #e2dfe0); */
	/* background: linear-gradient(to right, #b6b5b5, #e2dfe0); */
    -webkit-animation: show2 0.6s;
            animation: show2 0.6s;
    /* height:65px; */
    display: block; 
    
}
.headerNav1{
    
    -webkit-animation: show1 0.6s;
    
            animation: show1 0.6s;
    display: none; 
    opacity: 0;
}
@-webkit-keyframes show2 {
	0%{
        display: block; 
        opacity: 0;
       
	}
	
	100% {
        opacity: 1;
        display: block; 
        
	}
}
@keyframes show2 {
	0%{
        display: block; 
        opacity: 0;
       
	}
	
	100% {
        opacity: 1;
        display: block; 
        
	}
}
@-webkit-keyframes show1 {
	0%{
        opacity: 1;
        display: block; 
	}
	
	100% {
        display: none; 
        opacity: 0;
	}
}
@keyframes show1 {
	0%{
        opacity: 1;
        display: block; 
	}
	
	100% {
        display: none; 
        opacity: 0;
	}
}
header * {
    display: inline;
    Z-index:210;
}

header li {
    margin: 20px;
}

header li a {
    color: black;
    text-decoration: none;
}
.navNameTag{
    /* font-size: 20px !important; */
    color: rgb(0, 0, 0);
    /* font-weight: 900 !important; */
    font-family: "Montserrat", sans-serif;
}
.navNameTag1{
 
    color: rgb(0, 0, 0);
    font-weight: 600 !important;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
}
.navOne{
    font-family: "Montserrat", sans-serif;
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
    text-decoration: none;
    letter-spacing: 0;
    position:static;
    cursor: pointer;
    /* color:#000; */
}
/* .fa{
    color:#000;
}
.one{
    color:#000;
    
} */
.navOne:hover{
    text-decoration: none;
    color: #FF4B2B;
}
.activeNav{
    color: #FF4B2B;
    font-weight: 00;
    text-decoration: underline;
    text-underline-position: under;
    text-underline-offset: 5px;
}
.navOne:hover,
.navOne:active {
  letter-spacing: 0;
}
.navOne:after,
.navOne:before {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border: 1px solid rgba(#FF4B2B, 0);
  bottom: 0px;
  content: " ";
  /* display: block; */
  margin: 0 auto;
  position: relative;
  transition: all 280ms ease-in-out;
  width: 0;
}

.navOne:hover:after,
.navOne:hover:before {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-color: #FF4B2B;
  transition: width 350ms ease-in-out;
  width: 70%;
}

.navOne:hover:before {
  bottom: auto;
  top: 0;
  width: 70%;
}
.searchTap{
    background: linear-gradient(to right, #FF4B2B, #FF416C);
    /* position:absolute; */
    padding: 12px 15px;
    cursor: pointer;
	/* margin: 8px 3px; */
   height:50px;
   color:#fff;
}
.absolute{
    position:absolute;
}
.searchInput1{
    /* text-align:right; */
    font-family: "Montserrat", sans-serif;
    border:none;
    position:absolute!important;
    display: -webkit-flex;
    display: flex;
    right:190px;
    top:-18px;
    width:200px !important;
    z-index:220;
    padding-right:40px!important;
}
.searchInputMobile{
    /* text-align:right; */
    font-family: "Montserrat", sans-serif;
    border:none;
    position:absolute!important;
    display: -webkit-flex;
    display: flex;
    right:250px;
    top:-5px;
    width:200px !important;
    z-index:220;
    padding-right:40px!important;
}

.searchBack{
    z-index:3;
    background-color: #fff;
	background: linear-gradient(to right, #a09f9f, #d0cacb);
    height:0px;
     /* width:100%; */
    opacity: 0;
}
.ss{
    position: fixed;
}
.searchButton{
    cursor: pointer;
    z-index:230;
    margin-top:5px;
    position:absolute;
}
.menu2{
    display:none;
  }
  .menu3{
    display:none;
  }
.absolute{
    position:absolute;
}
.profileList{
    /* padding:0px; */
    
    /* padding:5px; */
    /* padding-left:15px; */
    margin:0px;
    font-family: "Montserrat", sans-serif;
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
   cursor: pointer;
}
.profileList:hover{
    background-color: #c9c0c077;
    
    /* padding:0px; */
}
.profileList:focus{
    background-color: #fd53539e;
    /* padding:0px; */
}

.profileList1{
    /* padding:0px; */
    /* padding:5px; */
    /* padding-left:15px; */
    margin:0px;
    font-family: "Montserrat", sans-serif;
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
    background-color: #4bbbeb4f;
}
.profileList1:hover{
    background-color: #d7edf7;
    /* padding:0px; */
}
.profileList1:focus{
    background-color: #fd53539e;
    /* padding:0px; */
}
.profileMenu{
    padding:5px;
    margin:0px;
    /* z-index:510; */
    background-color: rgba(250, 250, 250, 0.927);
}
.navProfile{
    z-index:510 ;
}
.notification{
    position:absolute;
    border-radius:50px;
    font-size:10px;
    margin-top:14px;
    padding-top:5px;
    margin-left:11px;
    min-height:20px;
    min-width:20px;
}
.menu1{
    /* position: fixed; */
    max-width:1440px;
    margin: auto;
    top:0;
}
.menuAlign{
    padding: 0px;
    margin:0px;
}
.menuAlignRight{
    position: absolute;
    right:0px;
    min-width: 300px;
}
.menuAlignLeft{
    position: absolute;
    left:0px;
    
    min-width: 1500px;
}
.DeleteNotification{
    color:#FF4B2B;
    background-color: #fff;
    font-size: 15px;
    border:1px solid #f8d5cf;
    z-index:1100;
    position: fixed,
}
.DeleteNotification:hover{
    color:#fff;
    background-color: #FF4B2B;
    font-size: 15px;
    border:1px solid #f8d5cf;
}
.DeleteNotificationDisable{
    color:#a5a5a5;
    background-color: #fff;
    font-size: 15px;
    border:1px solid #f8d5cf;
    z-index:1100;
    cursor:not-allowed;
    position: fixed,
    
}
.DeleteNotificationDisable:hover{
    color:#fff;
    background-color: #a5a5a5;
    font-size: 15px;
    border:1px solid #f8d5cf;
}
.appStatus{
    text-align: "center";
    padding: 7px;
    border-radius:5px;
    font-family: "Montserrat", sans-serif;
    color:#fff;
    margin-bottom:10px;
}
.proImage{
    border-radius: 45px;
    height:40px;
}
.notificationBox{
    right:0;
    top:80px;
    max-height:500px;
    overflow: scroll;
    margin-top: -10px;
    z-index:200 ;
    /* min-width:500px; */
    background-color: rgba(250, 250, 250, 0.857);
    padding:0px;
    border:1px solid rgb(231, 227, 227);
    /* border-radius: 10px; */
}
.notificationBox::-webkit-scrollbar {
    width: 6px;
	height: 6px;
  }
  
  /* Track */
  .notificationBox::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .notificationBox::-webkit-scrollbar-thumb {
    background: #ffe62b;
  }
  
  /* Handle on hover */
  .notificationBox::-webkit-scrollbar-thumb:hover {
    background: #918c8b;
  }

  .notificationMobile{
     display: none;   
}

input[type="radio"]:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
  }
  
  input[class="active"]:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;

    position: relative;
    background-color: #3eda0e;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid rgba(140, 255, 25, 0.987);
  }
  input[class="away"]:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;

    position: relative;
    background-color: #ff846e;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid rgba(244, 238, 58, 0.548);
  }
  input[class="inactive"]:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;

    position: relative;
    background-color: #5f5e5e;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid rgb(212, 212, 212);
  }
.mobileNav{
        margin-top:50px;
    }
    .navOneMobile{
        line-height: 30px;
    }
    @media only screen and (min-width: 1501px) and (max-width: 99170px) {
        .naHead{
            max-width:1440px !important;
            /* position: fixed; */
        }
        .menu1{
            /* position: fixed; */
            /* margin-top: unset; */
            margin-top:-30px !important;
        }
        .menuAlignRight{
            /* position:  unset;
            right:unset;
            min-width: unset; */
        }
        .menuAlignLeft{
            /* position: unset;
            left:unset;
            min-width: unset; */
        }
    }
    @media only screen and (min-width: 1170px) and (max-width: 1500px) {
        .menu1{
           /* top: -500 !important; */
           margin-top: -30px !important;
           
        } 
    }
  @media only screen and (min-width: 790px) and (max-width: 1170px) {
    .menu1{
        display:none;
    } 
    .menu2{
      display:block;
    }
    .menu3{
      display:none;
    }
    .headerNav{
        margin-top:500px;
    }
    .navOneMobile{
        line-height: 40px;
    }
    .mobileNav{
        margin-top:40px;
    }
    .searchInputMobile{
        right:300px;
        top:0px;
    }
  }
  @media only screen and (min-width: 650px) and (max-width: 790px) {
   
    .menu1{
        display:none;
    }
    .menu2{
      display:block;
    }
    .menu3{
      display:none;
    }
    .searchInputMobile{
        right:300px;
        top:3px;
    }
    .navOneMobile{
      line-height: 40px;
      }
      .mobileNav{
          margin-top:40px;
      }
  }
  @media only screen and (min-width: 550px) and (max-width: 650px) {
    .notificationWeb{
        display: none;   
    }
    .notificationMobile{
        display: block;   
    }
      .menu1{
          display:none;
      }
      .menu2{
        display:block;
      }
      .menu3{
        display:none;
      }
      .searchInputMobile{
          right:300px;
          top:5px;
      }
      .navOneMobile{
        line-height: 43px;
        }
        .mobileNav{
            margin-top:40px;
        }
    }
    
  
  @media only screen and (min-width: 370px) and (max-width: 550px) {
    .notificationWeb{
        display: none;   
    }
    .notificationMobile{
        display: block;   
    }
      .menu1{
          display:none;
      }
      .menu2{
          display:none;
      }
      .menuAlign{
          margin-left:-30px;
      }
      .menu3{
          display:block;
      }
      .searchInputMobile{
            right:64px;
            top:-15px;
            height:35px;
          width:200px !important;
          z-index:220;
          padding-right:40px!important;
      }
      .itemLogo{
          font-size: 30px !important;
          padding-top:80px !important;
      }
      .itemList{
          font-size: 13px !important;
          line-height: 20px !important;
      }
      .menuShow{
          -webkit-animation: show3 0.6s;
                  animation: show3 0.6s;
          height: 280px;
          display:block;
      }
      @-webkit-keyframes show3 {
          0%{
              height: 0px;
              display: block; 
              opacity: 0;
              
          }
          80% {
              opacity: 0.3;
              height: 220px;
              display: block; 
              
          }
          100% {
              opacity: 1;
              height: 280px;
              display: block; 
              
          }
      }
      @keyframes show3 {
          0%{
              height: 0px;
              display: block; 
              opacity: 0;
              
          }
          80% {
              opacity: 0.3;
              height: 220px;
              display: block; 
              
          }
          100% {
              opacity: 1;
              height: 280px;
              display: block; 
              
          }
      }
      .navOneMobile{
        line-height: 40px;
        }
        .mobileNav{
            margin-top:20px;
            text-align: center;
        }
        .menu4{
            display:none;
        }
    }

  @media only screen and (min-width: 000px) and (max-width: 370px) {
    .notificationWeb{
        display: none;   
    }
    .notificationMobile{
        display: block;   
    }
    .menu1{
        display:none;
    }
    .menu2{
        display:none;
    }
    .menu4{
        display:none;
      
    }
   
    .menuAlign{
        margin-left:-30px;
    }
    .menu3{
        display:block;
    }
    .searchInputMobile{
        right:64px;
        top:-15px;
        width:200px !important;
        z-index:220;
        padding-right:40px!important;
    }
    .itemLogo{
        font-size: 30px !important;
        padding-top:80px !important;
    }
    .itemList{
        font-size: 13px !important;
        line-height: 20px !important;
    }
    .menuShow{
        -webkit-animation: show3 0.6s;
                animation: show3 0.6s;
        
        height: 280px;
        display:block;
    }
    .navOne{
        font-size: 13px;
    }
    @-webkit-keyframes show3 {
        0%{
            height: 0px;
            display: block; 
            opacity: 0;
            
        }
        80% {
            opacity: 0.3;
            height: 220px;
            display: block; 
            
        }
        100% {
            opacity: 1;
            height: 280px;
            display: block; 
            
        }
    }
    @keyframes show3 {
        0%{
            height: 0px;
            display: block; 
            opacity: 0;
            
        }
        80% {
            opacity: 0.3;
            height: 220px;
            display: block; 
            
        }
        100% {
            opacity: 1;
            height: 280px;
            display: block; 
            
        }
    }
    .navOneMobile{
        line-height: 38px;
    }
    .mobileNav{
        margin-top:30px;
        text-align: center;
    }
    
  }
  @media only screen and (min-width: 000px) and (max-width: 340px) {
    .menuMobileRight{
     max-width:220px;
     position: absolute;
     right:0px;
    }
}
.RentalUnitsModal{
  /* background-color: black; */
  margin-top:0px;
  /* padding:50px; */
  padding-top:20px;
}
.RentalUnitsModalList{
  
}
.rentalCol{
/* max-width:405px; */

}
.propertyCard{
  border-radius: 15px;
  margin-bottom:30px;
  width:100%;
 min-width:275px;
 max-width:375px;
 min-height:275px;
 background-color: #fff;
 padding-bottom: 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  /* background-color: black; */
}
.hide{
display: none;
}
.property-avatar{
  width: 60px;
  height: 60px;
  border-radius: 100px;
}
.propsMenu{
  height: 40px;
  width: 40px;
  border-radius: 100px;
  padding:18px;
}
.propsMenu:hover{
  
  background-color: rgb(218, 210, 210);
}
.propsAddress{
  font-family: "Montserrat", sans-serif;
  color:#807e7e;
  letter-spacing: 0.5px;
  line-height: 25px;
  /* font-family: "Kanit", sans-serif; */
  /* font-family: "Titillium Web", sans-serif;  */
  /* font-family: 'Ubuntu', sans-serif; */
  font-size: 15px;
}

.propsAddressDark{
font-family: "Montserrat", sans-serif;
color:#e9e5e5;
letter-spacing: 0.5px;
line-height: 25px;
/* font-family: "Kanit", sans-serif; */
/* font-family: "Titillium Web", sans-serif;  */
/* font-family: 'Ubuntu', sans-serif; */
font-size: 15px;
}
.propsContent{
  font-size: 13px;
  font-family: "Montserrat", sans-serif; 
  color:rgb(134, 128, 128);
line-height: 20px;
  
}
.propsContent2{
  font-family: "Montserrat", sans-serif; 
  color:rgb(134, 128, 128);
  font-size: 13px;
  
}
.propCoverContent{
  
  border: 0px solid #fff;
  border-bottom: 1px solid rgb(225, 225, 225);
  -moz-box-shadow:    inset 0 0 20px #bcbfc0;
  /* -webkit-box-shadow: inset 0 0 20px #bcbfc0; */
  /* box-shadow:         inset 0 -1px 10px #bcbfc0; */
}
.prize{
  font-family: "Montserrat", sans-serif; 
  color:#313131;

}
.propertyCard:hover .viewListingButton2{
display: block;

}
.viewListingButton2 {
display: none;
position: absolute;
margin-top: 100px;
/* margin-left: 70px; */
border: solid 1px #fff;
background-color: #ff4b2b91;
color: #fff;
cursor:pointer;
border-radius: 25px;
font-size: 19px;
box-shadow: rgba(255, 255, 255, 0.438) 0px 0.0625em 0.0625em, rgba(255, 255, 255, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.344) 0px 0px 0px 1px inset;
}
.card-price {
display: inline-block;

width: auto;
height: 38px;

background-color: #FF4B2B;
border-radius: 3px 4px 4px 3px;

border-left: 1px solid #FF4B2B;

/* This makes room for the triangle */
margin-left: 19px;

position: relative;

color: white;
font-weight: 300;
font-size: 22px;
line-height: 38px;

padding: 0 10px 0 10px;
background: linear-gradient(to right, #FF4B2B, #f37650);
}

/* Makes the triangle */
.card-price:before {
content: "";
position: absolute;
display: block;
left: -19px;
width: 0;
height: 0;
border-top: 19px solid transparent;
border-bottom: 19px solid transparent;
border-right: 19px solid #FF4B2B;
}

/* Makes the circle */
.card-price:after {
content: "";
background-color: white;
border-radius: 50%;
width: 4px;
height: 4px;
display: block;
position: absolute;
left: -9px;
top: 17px;
}
.propCoverContent1{
  padding-Top :10px;
}
.click{
  cursor: pointer;
}
.propsImage{
  width: 100%;
  object-fit: cover;
  display: block;
margin-left: auto;
margin-right: auto;
height:270px;
border-top-left-radius: 15px;
border-top-right-radius: 15px;
}
.propertyCard:hover .propCoverContent{
  
  -webkit-animation: return 0.4s linear 1;
  
          animation: return 0.4s linear 1;
  opacity: 1;
  height:62px;
}
.propertyCard .propCoverContent1{
  
  /* display:none; */

}
.propertyHead{
 
}
.propertyCard:hover .propCoverContent1{
  
  /* display:block; */

}
.propertyCard .propCoverContent{
  
  -webkit-animation: turn 0.4s linear 1;
  
          animation: turn 0.4s linear 1;
  opacity: 0;
  height:0px;
 
}

@-webkit-keyframes turn {
  0% {
      height:62px;
      opacity: 1;
  }
  30%{
      opacity: 0.2;
     }
  100% {
      opacity: 0;
      height:0px;
  }
}

@keyframes turn {
  0% {
      height:62px;
      opacity: 1;
  }
  30%{
      opacity: 0.2;
     }
  100% {
      opacity: 0;
      height:0px;
  }
}
@-webkit-keyframes return {
  0% {
      height:0px;
      opacity: 0;
  }
 70%{
  opacity: 0.4;
 }
  100% {
      opacity: 1;
      height:62px;
  }
}
@keyframes return {
  0% {
      height:0px;
      opacity: 0;
  }
 70%{
  opacity: 0.4;
 }
  100% {
      opacity: 1;
      height:62px;
  }
}
.propertyCard:hover .propsImage{
  -webkit-animation: return1 0.4s linear 1;
          animation: return1 0.4s linear 1;
  height:270px;
}
.propertyCard .propsImage{
  -webkit-animation: turn1 0.4s linear 1;
          animation: turn1 0.4s linear 1;
  height:332px;
}
@-webkit-keyframes turn1 {
  0% {
      height:270px;
  }
  /* 50% {
      height:335px;
  } */
  100% {
      height:332px;
  }
}
@keyframes turn1 {
  0% {
      height:270px;
  }
  /* 50% {
      height:335px;
  } */
  100% {
      height:332px;
  }
}
@-webkit-keyframes return1 {
  0% {
      height:332px;
  }
  /* 50% {
      height:335px;
  } */
  100% {
      height:270px;
  }
}
@keyframes return1 {
  0% {
      height:332px;
  }
  /* 50% {
      height:335px;
  } */
  100% {
      height:270px;
  }
}
.RentalInput{
background-color: rgb(255, 255, 255);
border: none;
  font-family: "Montserrat", sans-serif;
padding: 12px 15px;
margin: 8px 0;
width: 100%;
  padding-right:50px;

}

.RentalSearch{
    
    padding:10px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    background-color: rgba(192, 192, 192, 0.314);
    margin-top: 80px;
    z-index:500;

    padding-right:15px;
     padding-left:15px;
    border-bottom: 1px solid #e0e0e096;
  
}
.RentalSearchDark{
  padding-bottom: 20px;
  padding:10px;
  background-color: #fff;
  margin-top: 80px;
  z-index:500;
  background: #424241;
    padding-right:15px;
    padding-left:15px;
    color:#fff;
}
.RentalSearch1{
  min-width:200px;
}
.searchListHeadButton1{
  cursor: pointer;
  position: relative;
  margin: auto;
  margin-right:1px;
  color:#ffffff;
  background-color: rgb(212, 212, 212);
  height:36px;
  width:36px;
  padding: 10px;
  padding-left: 11px;
  border-radius: 25px;
}
.searchListHeadButton{
  cursor: pointer;
  /* z-index:230; */
  margin-top:25px;
  margin-left:-35px;
  position:absolute;
  color:#FF4B2B;
}
.searchListHead{
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  
  /* margin-bottom: 0px; */
  /* position:absolute; */
}
.RentalInput:focus{
    border:none;
}
.heart{
  color:#fff;
  font-size: 25px;
  /* right:0; */
  padding-left:240px;
  padding-top:10px;

}
.heart:hover{
  cursor: pointer;
}

.RentalUnitsMapOff{
    -webkit-animation: slideRight 1s linear 1;
            animation: slideRight 1s linear 1;
    width:47%;
    display:block;
}
.RentalUnitsMapOn{
  -webkit-animation: slideRight1 1s linear 1;
          animation: slideRight1 1s linear 1;
    width:0%;
    /* display:none; */
  }   
  .RentalUnitsMapOffMobile{
      -webkit-animation: slideRightMobile 1s linear 1;
              animation: slideRightMobile 1s linear 1;
      height:400px;
      display:block;
  }
  .RentalUnitsMapOnMobile{
    -webkit-animation: slideRight1Mobile 1s linear 1;
            animation: slideRight1Mobile 1s linear 1;
      height:0px;
    }  
@-webkit-keyframes slideRight {
  0% {
      width:0%;
      /* display:block; */
  }
  /* 50% {
      height:335px;
  } */
  100% {
      width:47%;
      display:block;
  }
}  
@keyframes slideRight {
  0% {
      width:0%;
      /* display:block; */
  }
  /* 50% {
      height:335px;
  } */
  100% {
      width:47%;
      display:block;
  }
}
@-webkit-keyframes slideRight1 {
  0% {
      width:47%;
      display:block;
  }
  /* 50% {
      height:335px;
  } */
  100% {
      width:0%;
      display:none;
  }
}
@keyframes slideRight1 {
  0% {
      width:47%;
      display:block;
  }
  /* 50% {
      height:335px;
  } */
  100% {
      width:0%;
      display:none;
  }
}
@-webkit-keyframes slideRightMobile {
  0% {
      height:0px;
      /* display:block; */
  }
  /* 50% {
      height:335px;
  } */
  100% {
      height:400px;
      display:block;
  }
}
@keyframes slideRightMobile {
  0% {
      height:0px;
      /* display:block; */
  }
  /* 50% {
      height:335px;
  } */
  100% {
      height:400px;
      display:block;
  }
}
@-webkit-keyframes slideRight1Mobile {
  0% {
      height:400px;
      display:block;
  }
  /* 50% {
      height:335px;
  } */
  100% {
      height:0px;
      display:none;
  }
}
@keyframes slideRight1Mobile {
  0% {
      height:400px;
      display:block;
  }
  /* 50% {
      height:335px;
  } */
  100% {
      height:0px;
      display:none;
  }
}

.mapNavigatorRoot{
  width:1%;
  margin-left:1%;
  left:0;
  z-index:1;
  position: relative;
}
.mapNavigatorRootMobile{
  width:100%;
  position: relative;
}
.padNavMap{
  position:absolute;
    z-index:10;
    right:0;
    top:0;
    background-color: rgb(255, 255, 255);
    color:#FF4B2B;
    width:60px;
    padding-right:25px;
    padding-top:10px;
    padding-bottom:10px;
    margin-right:-31px;
    margin-top:16px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
.mapNavigator{
    position:absolute;
    z-index:10;
    right:0;
    top:0;
    background-color: rgb(255, 255, 255);
    
    color:#FF4B2B;
    width:60px;
    padding-right:10px;
    padding-top:10px;
    padding-bottom:10px;
    margin-right:30px;
    margin-top:16px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}
.padNavMapMobile{
    z-index:10;
    background-color: rgb(255, 255, 255);
    
    color:#FF4B2B;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
.mapNavigatorMobile{
z-index:10;
padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgb(255, 255, 255);
    color:#948f8f;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}
.mapNavigator:hover{
  color:#948f8f;
      cursor: pointer;
}
.padNavMap:hover{
  
  color:#948f8f;
  cursor: pointer;
}
.mapNavigatorRootMobile{
  display:none;
}.RentalUnitsMapOnMobile{
  display:none;
}.RentalUnitsMapOffMobile{
    display:none;
}
.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
.vert-move {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { -webkit-transform: translateY(0); transform: translateY(0); }
    100% { -webkit-transform: translateY(-5px); transform: translateY(-5px); }
}
@keyframes mover {
    0% { -webkit-transform: translateY(0); transform: translateY(0); }
    100% { -webkit-transform: translateY(-5px); transform: translateY(-5px); }
}
@media only screen and (min-width: 425px) and (max-width: 770px) {
  .RentalUnitsModal{
    padding:10px;
    margin-top:15px;
}
  .RentalUnitsMapOn{
    display:none;
}
.RentalUnitsMapOff{
  display:none;
}
.mapNavigatorRoot{
  display:none;
}
.mapNavigatorRootMobile{
  display:block;
}
.RentalUnitsMapOnMobile{
  display:block;
}
.RentalUnitsMapOffMobile{
  display:block;
}
}
@media only screen and (min-width: 150px) and (max-width: 425px) {
  .RentalUnitsModal{
      padding:10px;
      margin-top:15px;
  }
  .RentalUnitsMapOn{
      display:none;
  }
  .RentalUnitsMapOff{
    display:none;
  }
  .mapNavigatorRoot{
    display:none;
  }
  .mapNavigatorRootMobile{
    display:block;
  }
  .RentalUnitsMapOnMobile{
    display:block;
  }
  .RentalUnitsMapOffMobile{
    display:block;
  }
}
@media only screen and (min-width: 150px) and (max-width: 425px) {
  .RentalUnitsModal{
      padding:10px;
  }
  .RentalUnitsMapOn{
      display:none;
  }
  .RentalUnitsMapOff{
    display:none;
  }
  .mapNavigatorRoot{
    display:none;
  }
  .mapNavigatorRootMobile{
    display:block;
  }
  .RentalUnitsMapOnMobile{
    display:block;
  }
  .RentalUnitsMapOffMobile{
    display:block;
  }
}



.lds-roller {
display: inline-block;
position: relative;
width: 80px;
height: 80px;
top:200%;
Left:50%;
}
.lds-roller div {
-webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
-webkit-transform-origin: 40px 40px;
        transform-origin: 40px 40px;
}
.lds-roller div:after {
content: " ";
display: block;
position: absolute;
width: 7px;
height: 7px;
border-radius: 50%;
background: #f76c39;
margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
-webkit-animation-delay: -0.036s;
        animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
top: 63px;
left: 63px;
}
.lds-roller div:nth-child(2) {
-webkit-animation-delay: -0.072s;
        animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
top: 68px;
left: 56px;
}
.lds-roller div:nth-child(3) {
-webkit-animation-delay: -0.108s;
        animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
top: 71px;
left: 48px;
}
.lds-roller div:nth-child(4) {
-webkit-animation-delay: -0.144s;
        animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
top: 72px;
left: 40px;
}
.lds-roller div:nth-child(5) {
-webkit-animation-delay: -0.18s;
        animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
top: 71px;
left: 32px;
}
.lds-roller div:nth-child(6) {
-webkit-animation-delay: -0.216s;
        animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
top: 68px;
left: 24px;
}
.lds-roller div:nth-child(7) {
-webkit-animation-delay: -0.252s;
        animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
top: 63px;
left: 17px;
}
.lds-roller div:nth-child(8) {
-webkit-animation-delay: -0.288s;
        animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
top: 56px;
left: 12px;
}
@-webkit-keyframes lds-roller {
0% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
}
@keyframes lds-roller {
0% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
}
.closeModal{
font-size:20px;
padding:13px;
}
.textAvailableUnit {
font-family: "Montserrat", sans-serif;
font-size:40px !important;
font-weight: 1950;
padding-left:15px;
}
.detailsHead{
font-family: "Montserrat", sans-serif;
font-size: 20px;
font-weight: 600;
margin-top: 15px;
margin-bottom: 10px;
}
.detailContentAvailable{
font-family: "Montserrat", sans-serif;
font-size: 15px;
font-weight: 500;
}
.shadow1 {
 
box-shadow: 0px 0 25px rgba(0, 0, 0, 0.4);
}
.shadow2 {
 
box-shadow: 7px 7px 25px rgba(227, 225, 225, 0.871);
}
.box2 {
box-shadow:
0 2.8px 2.2px rgba(206, 203, 203, 0.034),
0 6.7px 5.3px rgba(212, 211, 211, 0.048),
0 12.5px 10px rgba(228, 227, 227, 0.06),
0 22.3px 17.9px rgba(194, 191, 191, 0.072),
0 41.8px 33.4px rgba(206, 205, 205, 0.086),
0 100px 80px rgba(230, 228, 228, 0.12)
;}
.modal{
/* width:1800px; */
}
.modal-content{


}
.img-wrap{

width: 100%;        

}
.imageAvailable{
max-width: 100%;
max-height: 100%;
display: block;
width:100%;
}
.detailAvailable{
overflow-y: scroll;
overflow-x: hidden;
max-height:30vmax;
width:100%;
}

.detailAvailable::-webkit-scrollbar {
width: 5px;
height: 1px;
}

/* Track */
.detailAvailable::-webkit-scrollbar-track {
background: #f1f1f1;
}

/* Handle */
.detailAvailable::-webkit-scrollbar-thumb {
background: #FF4B2B;
}

/* Handle on hover */
.detailAvailable::-webkit-scrollbar-thumb:hover {
background: #918c8b;
}



.detailAvailable::-moz-scrollbar {
width: 5px;
height: 1px;
}

/* Track */
.detailAvailable::-moz-scrollbar-track {
background: #f1f1f1;
}

/* Handle */
.detailAvailable::-moz-sc {
background: #FF4B2B;
}

/* Handle on hover */
.detailAvailable::-moz-scrollbar-thumb:hover {
background: #918c8b;
}
.modal{
height: -webkit-fit-content;
height: -moz-fit-content;
height: fit-content;
}
.bodyAvailable{

}
#exampleModal{
/* width:1500px; */
}
.availableTable {
border-top: 1px solid #FF4B2B;
border-bottom: 1px solid #FF4B2B;
padding-top: 3%;
}
.availableTableRow {
padding-bottom: 4%;
}
.availableUnitTableHead {
margin-bottom: 1%;
}
.availableTableContent {
font-weight: 690;
}
.availableTableHead {
margin-left: 4%;
font-size: 15px;
text-transform: uppercase;
color: #d15428;
font-family: sans-serif;
}
.availableUnitSection {
padding-top: 7%;
}
.availableUnitSection2 {
padding-top: 15%;
}
.availableUnitTable {
padding-left: 4%;
padding-right: 4%;
}
hr.line {
color: #d15428;
height: 9px;
}
.brownYellow {
color: #d15428;
}

.unitModalImageMobile{
display:none;
}
.listingModalLoader{
  display: block;
}
.listingModalLoader1{
  display: none;
}
@media only screen and (min-width: 1300px) and (max-width: 15000px) {
.detailAvailable{
  width:65%
}
.availableUnitSection2 {
  display: none;
}
.availableUnitSection {
  display: block;
}
}
@media only screen and (min-width: 1040px) and (max-width: 1300px) {
.detailAvailable{
  width:65%
}
.availableUnitSection2 {
  display: none;
}
.availableUnitSection {
  display: block;
}
.availableUnitButton {
  font-size: 10px;
  padding: 10px 15px;
}
.primaryAvailableUnitButton {
  font-size: 10px;
  padding: 10px 15px;
}
}
@media only screen and (min-width: 800px) and (max-width: 1040px) {
  .listingModalLoader{
    display: none;
  }
  .listingModalLoader1{
    display: block;
  }
.detailAvailable{
  width:55%
}
.unitModalImage{
  display:none;
}
.unitModalImageMobile{
  display:block;
}
.availableUnitSection2 {
  display: none;
}
.availableUnitSection {
  display: block;
}
.textAvailableUnit {
  font-size: 31px !important;
}
.unitDetailListTop{
  
  margin-bottom:-15px;
  margin-top:5px;
}
.detailsHead{
  font-size: 15px !important;
}
.detailContentAvailable{
  font-size: 13px;
}
.span2{
  padding-left:10px;
  font-size: 10px ;
}
.availableUnitTable {
  padding-left: 0%;
  padding-right: 0%;
}
.availableUnitSection {
  padding-top: 10%;
}
.availableUnitButton {
  font-size: 8px;
  padding: 8px 10px;
}
.primaryAvailableUnitButton {
  font-size: 8px;
  padding: 8px 10px;
}
}
@media only screen and (min-width: 500px) and (max-width: 800px) {
  .listingModalLoader{
    display: none;
  }
  .listingModalLoader1{
    display: block;
  }
.unitModalImage{
  display:none;
}
.unitModalImageMobile{
  display:block;
}
.availableUnitSection2 {
  display: block;
}
.availableUnitSection {
  display: none;
}
.textAvailableUnit {
  padding-top:7px;
  font-size: 28px !important;
}
.unitDetailListTop{
  
  margin-bottom:-15px;
  margin-top:5px;
}
.detailsHead{
  font-size: 15px !important;
}
.detailContentAvailable{
  font-size: 13px;
}
.span2{
  padding-left:10px;
  font-size: 12px ;
}
.span7{
  font-size: 10px ;
}
.span8{
  font-size: 11px ;
}
.availableUnitTable {
  padding-left: 0%;
  padding-right: 0%;
}
.availableTableContent {
  line-height: 10px;
}
.availableUnitButton {
  margin-top: 0.5%;
  display: block;
}
.primaryAvailableUnitButton {
  margin-top: 0.5%;
  display: block;
}
}
@media only screen and (min-width: 400px) and (max-width: 500px) {
  .listingModalLoader{
    display: none;
  }
  .listingModalLoader1{
    display: block;
  }
.unitModalImage{
  display:none;
}
.unitModalImageMobile{
  display:block;
}
.availableUnitSection2 {
  padding-top: 20%;
}
.availableUnitSection2 {
  display: block;
}
.availableUnitSection {
  display: none;
}
.textAvailableUnit {
  padding-top:10px;
  font-size: 25px !important;
}
.unitDetailListTop{
 
  margin-bottom:-15px;
  margin-top:5px;
}
.detailsHead{
  font-size: 15px !important;
}
.detailContentAvailable{
  font-size: 13px;
}
.span2{
  padding-left:10px;
  font-size: 15px ;
}
.availableUnitTable {
  padding-left: 0%;
  padding-right: 0%;
}
.availableTableContent {
  line-height: 10px;
}
.availableUnitButton {
  margin-top: 0.5%;
  display: block;
}
.primaryAvailableUnitButton {
  margin-top: 0.5%;
  display: block;
}
}
@media only screen and (min-width: 300px) and (max-width: 400px) {
  .listingModalLoader{
    display: none;
  }
  .listingModalLoader1{
    display: block;
  }
.unitModalImage{
  display:none;
}
.unitModalImageMobile{
  display:block;
}
.availableUnitSection2 {
  padding-top: 30%;
}
.availableUnitSection2 {
  display: block;
}
.availableUnitSection {
  display: none;
}
.textAvailableUnit {
  padding-top:15px;
  font-size: 18px !important;
}
.unitDetailListTop{
  
 
  
}
.detailsHead{
  font-size: 15px !important;
}
.detailContentAvailable{
  font-size: 13px;
}
.span2{
  padding-left:10px;
  font-size: 15px ;
}
.availableUnitTable {
  padding-left: 0%;
  padding-right: 0%;
}
.availableTableContent {
  line-height: 10px;
}
.availableUnitButton {
  margin-top: 0.5%;
  display: block;
}
.primaryAvailableUnitButton {
  margin-top: 0.5%;
  display: block;
}
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
  .listingModalLoader{
    display: none;
  }
  .listingModalLoader1{
    display: block;
  }
.unitModalImage{
  display:none;
}
.unitModalImageMobile{
  display:block;
}
.availableUnitSection2 {
  padding-top: 35%;
}
.availableUnitSection2 {
  display: block;
}
.availableUnitSection {
  display: none;
}
.textAvailableUnit {
  font-size: 20px !important;
}
.unitDetailListTop{
  
  margin-bottom:-15px;
  margin-top:5px;
}
.detailsHead{
  font-size: 15px !important;
}
.detailContentAvailable{
  font-size: 13px;
}
.span2{
  padding-left:10px;
  font-size: 10px ;
}
.availableUnitTable {
  padding-left: 0%;
  padding-right: 0%;
}
.availableTableContent {
  line-height: 10px;
}
.availableUnitButton {
  margin-top: 0.5%;
  display: block;
}
.primaryAvailableUnitButton {
  margin-top: 0.5%;
  display: block;
}
}@media only screen and (min-width: 200px) and (max-width: 600px) {
.modal-footer{
  height: 80px;
  padding-bottom: 0px;
}
.listingModalLoader{
  display: none;
}
.listingModalLoader1{
  display: block;
}

}
@media only screen and (min-width: 400px) and (max-width: 600px) {


  .listingModalLoader{
    display: none;
  }
  .listingModalLoader1{
    display: block;
  }
.heart{
position: fixed;

right: auto;
padding-left: 300px;
padding-top:10px;

}
}

#loader {
    /* Uncomment this to make it run! */
    /*
       animation: loader 5s linear infinite; 
    */
   
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    z-index:800;
    
  }
  @-webkit-keyframes loader {
    0% { left: -100px }
    100% { left: 110%; }
  }
  @keyframes loader {
    0% { left: -100px }
    100% { left: 110%; }
  }
  #box {
    width: 50px;
    height: 50px;
    /* background: #6997DB; */
    -webkit-animation: animate .5s linear infinite;
            animation: animate .5s linear infinite;
    position: absolute;
    top: 0;
    
    left: 0;
    border-radius: 3px;
  }
  @-webkit-keyframes animate {
    17% { border-bottom-right-radius: 3px; }
    25% { -webkit-transform: translateY(9px) rotate(22.5deg); transform: translateY(9px) rotate(22.5deg); }
    50% {
      -webkit-transform: translateY(18px) scale(1,.9) rotate(45deg) ;
              transform: translateY(18px) scale(1,.9) rotate(45deg) ;
      border-bottom-right-radius: 40px;
    }
    75% { -webkit-transform: translateY(9px) rotate(67.5deg); transform: translateY(9px) rotate(67.5deg); }
    100% { -webkit-transform: translateY(0) rotate(90deg); transform: translateY(0) rotate(90deg); }
  }
  @keyframes animate {
    17% { border-bottom-right-radius: 3px; }
    25% { -webkit-transform: translateY(9px) rotate(22.5deg); transform: translateY(9px) rotate(22.5deg); }
    50% {
      -webkit-transform: translateY(18px) scale(1,.9) rotate(45deg) ;
              transform: translateY(18px) scale(1,.9) rotate(45deg) ;
      border-bottom-right-radius: 40px;
    }
    75% { -webkit-transform: translateY(9px) rotate(67.5deg); transform: translateY(9px) rotate(67.5deg); }
    100% { -webkit-transform: translateY(0) rotate(90deg); transform: translateY(0) rotate(90deg); }
  } 
  #shadow { 
    width: 50px;
    height: 5px;
    background: #000;
    opacity: 0.1;
    position: absolute;
    top: 59px;
    left: 0;
    border-radius: 50%;
    -webkit-animation: shadow .5s linear infinite;
            animation: shadow .5s linear infinite;
  }
  @-webkit-keyframes shadow {
    50% {
      -webkit-transform: scale(1.2,1);
              transform: scale(1.2,1);
    }
  }
  @keyframes shadow {
    50% {
      -webkit-transform: scale(1.2,1);
              transform: scale(1.2,1);
    }
  }
  
  
 
.loader,
.loader:before,
.loader:after {
  background: #FF4B2B;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader2{
    padding-top:70px;
}
.loader {
  color: #FF4B2B;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
.loader3,
.loader3:before,
.loader3:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader3Head{
    padding-top:1px;
}
.loader3 {
  color: #FF4B2B;
  font-size: 4px;
  margin: 0px auto;
  margin-top:-45px;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader3:before,
.loader3:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader3:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader3:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.progressProfileApp {
  width: 150px;
  height: 150px !important;
  float: left;
  line-height: 150px;
  background: none;
  margin: 20px;
  box-shadow: none;
  position: relative;
}

.progressProfileApp:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.progressProfileApp > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progressProfileApp .progressProfileApp-left {
  left: 0;
}

.progressProfileApp .progressProfileApp-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 8px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progressProfileApp .progressProfileApp-left .progressProfileApp-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progressProfileApp .progressProfileApp-right {
  right: 0;
}

.progressProfileApp .progressProfileApp-right .progressProfileApp-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progressProfileApp.blue .progressProfileApp-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  /* background: #000; */
  font-size: 24px;
  color: #ffffff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-image: url(/static/media/profileFill.2ef4090c.jpeg);
  background-position: center;
  background-size: cover;
  text-shadow: 0 0 5px #000;
}
.progressProfileApp.red .progressProfileApp-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  /* background: #000; */
  font-size: 24px;
  color: #ffffff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-image: url(/static/media/profileFill.2ef4090c.jpeg);
  background-position: center;
  background-size: cover;
  text-shadow: 0 0 5px #000;
}

.progressProfileApp.blue .progressProfileApp-bar {
  border-color: #dfc4ff;
}

.progressProfileApp.red .progressProfileApp-bar {
  border-color: #ff5d5d;
}

.account-settings .user-profile {
  margin: 0 0 1rem 0;
  padding-bottom: 1rem;
  text-align: center;
}
.account-settings .user-profile .user-avatar {
  margin: 0 0 1rem 0;
}
.account-settings .user-profile .user-avatar img {
  width: 140px;
  height: 140px;
  border-radius: 100px;
}
.account-settings .user-profile h5.user-name {
  margin: 0 0 0.5rem 0;
}
.account-settings .user-profile h6.user-email {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 400;
}
.account-settings .about {
  margin: 1rem 0 0 0;
  font-size: 0.8rem;
  text-align: center;
}
.profileCard {
  background: #fff;
  border-radius: 15px;
  border: 0;
  margin-bottom: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.box {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.effect7 {
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.effect7:before,
.effect7:after {
  content: "";
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 10px;
  right: 10px;
  border-radius: 100px / 10px;
}
.effect7:after {
  right: 10px;
  left: auto;
  -webkit-transform: skew(8deg) rotate(3deg);
  transform: skew(8deg) rotate(3deg);
}
.effect8 {
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.1) inset;
}
.effect8:before,
.effect8:after {
  content: "";
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
  border-radius: 100px / 10px;
}
.effect8:after {
  right: 10px;
  left: auto;
  /* -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg); */
}
.inputProfile {
  border: 1px solid #596280;
  border-radius: 2px;
  font-size: 0.825rem;
  background: rgba(159, 183, 194, 0.22);
  color: #000;
  font-family: "Montserrat", sans-serif;
}
.contentProfile {
  color: #000;
  font-family: "Montserrat", sans-serif;
}
#shop {
  /* background-image: url("images/shop_bg.png"); */
  background-repeat: repeat-x;
  height: 121px;
  width: 984px;
  margin-left: 20px;
  margin-top: 13px;
}

#shop .content {
  width: 182px; /*328 co je 1/3 - 20margin left*/
  height: 121px;
  line-height: 20px;
  margin-top: 0px;
  margin-left: 9px;
  margin-right: 0px;
  display: inline-block;
}
.profileHead {
  color: #f86e56;
  font-family: "Montserrat", sans-serif;
  /* font-family: "Titillium Web", sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
}
.profileLabel {
  color: #000;
  font-family: "Montserrat", sans-serif;
  /* font-family: "Kanit", sans-serif; */
  /* font-family: "Titillium Web", sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
}

button {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  padding: 9px 30px;
  font-weight: bold;

  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

button:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}
button.pri {
  background-color: transparent;
  border-color: #ff4b2b;
  color: #ff4b2b;
}
button.pri:hover {
  background-color: #ff4b2b;
  color: #fff;
}
button.pri:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

input.tag {
  font-family: "Montserrat", sans-serif;
  background-color: rgb(241, 241, 241);
  border: none;

  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
input.alert {
  font-family: "Montserrat", sans-serif;
  border: 1px solid #ff4b2b;
}
textarea.alert {
  border: 1px solid #ff4b2b;
  font-family: "Montserrat", sans-serif;
}
div.alert {
  font-family: "Montserrat", sans-serif;
  border: 1px solid #ff4b2b;
}
div.divAlert {
  font-family: "Montserrat", sans-serif;
  border: 3px solid #ff4b2b73;
}
select.alert {
  font-family: "Montserrat", sans-serif;
  border: 1px solid #ff4b2b;
}

@media only screen and (min-device-width: 500px) and (max-device-width: 850px) {

  .profileHead {
    font-size: 18px;
  }
  .profileLabel {
    font-size: 15px;
  }
  .form-control {
    font-size: 15px !important;
  }
}
@media only screen and (min-device-width: 100px) and (max-device-width: 500px) {

  .profileHead {
    font-size: 17px;
  }
  .profileLabel {
    font-size: 15px;
  }
  .form-control {
    font-size: 15px !important;
  }
}

.tabSignature{
  border: solid 1px #b7b5b5 !important;
  border-bottom: solid 1px #eaeaea !important;
  background-color: #fff !important;
  color: #b7b5b5 !important;
  font-size: 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px  !important;
  border-bottom-left-radius: 0px !important;
  font-weight: 600;
  padding:12px 10px;
  letter-spacing: 1px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
.tabSignature2{
 border: solid 1px #fff !important;
      background-color: #b7b5b5 !important;
      color: #fff !important;
      font-size: 16px;
      border-radius: 5px;
      border-bottom-right-radius: 0px  !important;
  border-bottom-left-radius: 0px !important;
      font-weight: 600;
      padding:12px 10px;
      letter-spacing: 1px;
      text-align: center;
      font-family: "Montserrat", sans-serif;
      box-shadow: rgba(238, 238, 238, 0.2) 0px 7px 29px 0px !important;
}
.StepRound {

  background-color: blueviolet;
}
.cardApplication{
  background-color: #fff;
  padding: 0px;
  padding-top: 1%;
  margin-top: 2.0%;
  border-radius: 8px;
  margin-right: 3%;
  margin-left: 3%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.social-container {
  margin: 20px 0;
}
.titleStepOpp {
  display: none;
}

.buttonUpload {
  border-radius: 5px;
  border: solid 0px #fff;
  background: linear-gradient(to right, #949494,#ada9a9a4);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  cursor: pointer;
}

.buttonUploadAlert {
  border-radius: 5px;
  border: solid 1px rgb(228, 38, 38);
  background: linear-gradient(to right, #949494,#ada9a9a4);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  cursor: pointer;
}
.ax-success{
  color: rgb(97, 230, 9);
}
.buttonUpload:hover {
  /* border: solid 1px #fff; */
  color: #fff;
  /* background: linear-gradient(to right, #979696, rgb(228, 227, 227)); */
  box-shadow: 0 0px 40px rgba(255, 255, 255, 0.349);
  /* color:#616161; */
}
.social-container-step {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 0px;
  height: 40px;
  width: 40px;
}
.activeStep {
  background-color: #ff4b2b;
  border: 1px solid #ff4b2b;
  color: #fff !important;
}
.activeLine {
  border-bottom: 2px solid #ff4b2b !important;
}
.SideLine {
  /* width: 112px; */
  height: 47px;
  margin-top: -25px;
  border-bottom: 2px solid #e6dddab9;
  /* margin-left: -5%;
    margin-right: -5%; */
}
.stepFormNavTitle {
  padding-top: 25px;
}
.stepFormNav {
  /* padding:10%; */
  padding-top: 120px;
}
.stepSpan {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  white-space: nowrap;
}
.stepTitlePayment {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin-top: 3px;
  white-space: nowrap;
  position: absolute;
  color: #828282;
  /* align-items: center; */
  -webkit-align-self: center;
          align-self: center;
}
.stepTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 3px;
  white-space: nowrap;
  position: absolute;
  /* align-items: center; */
  -webkit-align-self: center;
          align-self: center;
}
.stepContentTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 3px;
  white-space: nowrap;
  position: absolute;
  /* align-items: center; */
  -webkit-align-self: center;
          align-self: center;
}
.ApplicationInputAgg {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;

  /* padding-right:50px; */
  font-size: 12px;
}
.ApplicationInput {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  min-width: 240px;
  max-width: 340px;

  /* padding-right:50px; */
  font-size: 12px;
}
.ApplicationInput2 {
  background-color: rgb(255, 255, 255);
  border: 1px solid #000;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  min-width: 240px;
  max-width: 340px;
  font-size: 12px;
}
.ApplicationInputAgg2 {
  background-color: rgb(255, 255, 255);
  border: 1px solid #000;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  font-size: 12px;
}
.ApplicationInput1 {

  font-family: "Montserrat", sans-serif;
  padding: 8px 15px;
  margin: 8px 0;
  font-size: 12px;
  width: 100%;
  min-width:290px;
  border-bottom: 1px solid #30302f;
  padding-bottom: 0px;
  padding-top: 15px;
  margin-bottom: 0px;
}
.ApplicationInputLabel{
  position: absolute;
  font-size: 9px;
  margin-top:-1px;
  z-index:500;
}
.ApplicationInputLabel1{
  position: absolute;
  font-size: 9px;
  width:100%;
  border-top: 1px solid #000;
  margin-top:-1px;
  z-index:500;
}
.ApplicationInput2 {
  min-width: 240px;
  max-width: 340px;
  font-family: "Montserrat", sans-serif;
  padding: 8px 15px;
  margin: 8px 0;
  font-size: 12px;

  border-bottom: 1px solid #30302f;
  padding-bottom: 0px;

  margin-bottom: 0px;
}
.alignInput {
  min-width: 250px;
  max-width: 350px;
}
.dateInputIcon{
  right:30px;
}
.w-5 {
  width: 5%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.ApplicationInputSelect {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  /* padding: 12px 15px; */
  height: 45px;
  margin: 8px 0;
  width: 100%;
  font-size: 12px;
  /* padding-right:50px; */
  min-width: 240px;
  max-width: 340px;
}
.ApplicationInputSelectAgg {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  height: 45px;
  margin: 8px 0;
  width: 100%;
  font-size: 12px;
}
.ApplicationInputSelectNormal {
  background-color: transparent;
  border: none;
  font-family: "Montserrat", sans-serif;
  height: 30px;
  min-width: 50px;
  width: auto;
  margin: 3px 5px;
  color: #ff7777;
  font-size: 15px;
  font-weight:700;
}
.ApplicationInputSelectNormal1 {
  background-color: transparent;
  border: none;
  font-family: "Montserrat", sans-serif;
  height: 30px;
  min-width: 50px;
  width: auto;
  
  color: #989898;
  font-size: 15px;
  font-weight:200;
}
.ApplicationInputOptionNormal {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  color: #636363;
}
.OfficeApplicationInputSelect {
  border: 1px solid #b8b6b69a;
  font-family: "Montserrat", sans-serif;
  height: 38px;
  width: 100%;
  font-size: 12px;
  box-shadow: rgba(99, 99, 99, 0.13) 0px 2px 8px 0px;
}
.searchListHeadApp {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  white-space: normal;
  word-wrap: break-word !important;
  /* white-space: nowrap !important; */
  display: inline-block;
}
.searchListHeadApp2 {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  white-space: normal;
  word-wrap: break-word !important;
  font-weight: 600;
  display: inline-block;
}
.searchListHeadAppDark {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  white-space: normal;
  word-wrap: break-word !important;
  /* white-space: nowrap !important; */
  display: inline-block;
  color: #fff;
}
.inputContainer {
  padding: 50px;
}
.inputContainer1 {
  padding-left: 50px;
  padding-right:50px;
}
.outSpan {
  /* display: inline-block; */
  /* white-space: nowrap !important; */
}
.noBreak {
  /* white-space: nowrap; */
  word-break: break-word !important;
}
.searchListContentApp {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  color: #615a5a;
}
.informationListApp {
  background-color: #dddddd;
  padding-top: 15px;
  border: 1px solid #cac4c4;
  padding-bottom: 15px;
  padding-right: 25px;
  padding-left: 25px;
  text-align: justify;
  box-shadow: 0 8px 16px -6px rgb(189, 183, 183);
}
.informationListAppAlign {
  padding: 50px;
}
.informationListAppNormal {
  background-color: transparent;
  padding-top: 15px;
  border: 1px solid #e4e4e4;
  padding-bottom: 15px;
  padding-right: 25px;
  padding-left: 25px;
  text-align: justify;
  box-shadow: 0 1px 16px -6px rgb(189, 183, 183);
}

.ashBack{
  background-color: #d3d3d31e !important;
}
.informationListAppInfo {
  background-color: #ddeff3;
  padding-top: 15px;
  border: 1px solid #ddeff3;
  padding-bottom: 15px;
  color: #29bddf;
  padding-right: 25px;
  padding-left: 25px;
  text-align: justify;
  box-shadow: 0 8px 16px -6px rgb(189, 183, 183);
}
.informationListAppWarning {
  background-color: #f1f1c9;
  padding-top: 15px;
  border: 1px solid #f1f1c9;
  padding-bottom: 15px;
  color: #999731;
  padding-right: 25px;
  padding-left: 25px;
  text-align: justify;
  box-shadow: 0 8px 16px -6px rgb(189, 183, 183);
}
.appFootButton {
  margin-top: 20px;
  padding-right: 50px;
  padding-bottom: 50px;
}
hr.line {
  border-bottom: 1px solid rgba(153, 153, 153, 0.308);
  border-top: transparent;
}
hr.line2 {
  border-bottom: 2px solid rgba(153, 153, 153, 0.308);
  border-top: transparent;
}
.spanValidateApp {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  /* padding-right: 10px; */
  /* position:fixed; */
  margin-top: -10px;
  margin-bottom: -3px;
}

.licenseButton {
  background-color: transparent;
  border: 1px solid #ff4b2b;
  color: #ff4b2b;
  cursor:pointer;
}
.licenseButton:hover {
  background-color: #ff4b2b;
  border: 1px solid #ff4b2b;
  color: #fff;
 
}
.licenseButtonDis {
  background-color: transparent;
  border: 1px solid #c7c7c7;
  color: #c7c7c7;
  cursor:not-allowed;
}
.licenseButtonDis:hover {
  background-color: #c7c7c7;
  border: 1px solid #c7c7c7;
  color: #fff;
}
.dragDrop {
  height: 250px;
  border: 9px dashed rgb(192, 174, 170);
  border-radius: 5px;
}
.licenseHead {
  font-family: "Montserrat", sans-serif;
}
.licenseHeadDark {
  font-family: "Montserrat", sans-serif;
  color: #fff;
}
.absolute {
  position: absolute;
}
.licenseImage {
  height: 250px;
  opacity: 0.5;
}

.pdf {
}

.pdf::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.pdf::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.pdf::-webkit-scrollbar-thumb {
  background: #ff4b2b;
}

/* Handle on hover */
.pdf::-webkit-scrollbar-thumb:hover {
  background: #918c8b;
}
.applicationContainer {
  margin: 4%;
  margin-right: 3%;
  margin-left: 3%;
  /* margin-top:120px; */
  /* margin-top:100px; */
}
.applicationCard {
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border:1px solid rgb(241, 241, 241);
}
.applicationCard2 {
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border:1px solid rgb(241, 241, 241);
}
.applicationCardDark {
  background-color: rgb(151, 150, 150);
  border-radius: 15px;
  margin-bottom: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.appImage {
  height: 50px;
  width: 50px;
}
.appImage1 {
  height: 50px;
}
.addApp {
  margin-top: -10px;
}
.applicationText {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #807e7e;
  letter-spacing: 0.5px;
  line-height: 25px;
  font-size: 15px;
}
.applicationTextDark {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #fafafa;
  letter-spacing: 0.5px;
  line-height: 25px;
  font-size: 15px;
}
.applicationText1 {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #807e7e;
  letter-spacing: 0.5px;
  line-height: 25px;
  font-size: 15px;
}
.appStatus {
  text-align: "center";
  padding: 7px;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  margin-bottom: 10px;
}

/* #Toggle
   */

.switchApplication {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 27px;
}
.switchApplication1 {
  position: relative;
  display: inline-block;
  width: 190px;
  height: 27px;
}
.switchApplication2 {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 27px;
}
.switchApplication input {
  display: none;
}
.switchApplication1 input {
  display: none;
}
.switchApplication2 input {
  display: none;
}
.sliderApplication {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b7bbba;
  transition: 0.4s;
}
.sliderApplication1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b7bbba;
  transition: 0.4s;
}
.sliderApplication2 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b7bbba;
  transition: 0.4s;
}
.sliderApplication:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 22px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}
.sliderApplication1:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 22px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}
.sliderApplication2:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 22px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}
input:checked + .sliderApplication {
  background-color: #ff4b2bc7;
}
input:checked + .sliderApplication1 {
  background-color: #ff4b2bc7;
}
input:checked + .sliderApplication2 {
  background-color: #ff4b2bc7;
}
input:focus + .sliderApplication {
  box-shadow: 0 0 1px #2196f3;
}
input:focus + .sliderApplication1 {
  box-shadow: 0 0 1px #2196f3;
}
input:focus + .sliderApplication2{
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .sliderApplication:before {
  -webkit-transform: translateX(54px);
  transform: translateX(54px);
}
input:checked + .sliderApplication1:before {
  -webkit-transform: translateX(164px);
  transform: translateX(164px);
}
input:checked + .sliderApplication2:before {
  -webkit-transform: translateX(84px);
  transform: translateX(84px);
}
/*------ ADDED CSS ---------*/
.onApp {
  display: none;
}

.onApp,
.offApp {
  color: rgb(235, 229, 229);
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 47%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .sliderApplication .onApp {
  display: block;
}
input:checked + .sliderApplication1 .onApp {
  display: block;
}
input:checked + .sliderApplication2 .onApp {
  display: block;
}
input:checked + .sliderApplication .offApp {
  display: none;
}
input:checked + .sliderApplication1 .offApp {
  display: none;
}
input:checked + .sliderApplication2 .offApp {
  display: none;
}
/*--------- END --------*/

/* Rounded sliders */
.sliderApplication.round {
  border-radius: 34px;
}
.sliderApplication1.round {
  border-radius: 34px;
}
.sliderApplication2.round {
  border-radius: 34px;
}
.sliderApplication.round:before {
  border-radius: 50%;
}
.sliderApplication1.round:before {
  border-radius: 50%;
}
.sliderApplication2.round:before {
  border-radius: 50%;
}

.stepTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 3px;

  white-space: nowrap;
  position: absolute;

  /* align-items: center; */
  -webkit-align-self: center;
          align-self: center;
}
.stepContentTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 3px;

  white-space: nowrap;
  position: absolute;

  /* align-items: center; */
  -webkit-align-self: center;
          align-self: center;
}
.infoIcon {
  margin-left: 15px;
  /* margin-top:5px; */
  font-size: 20px;
  cursor: help;
  color: #ff4b2b;
}
.infoIcon:hover {
  color: #968c8c;
}
.infoContent {
  display: none;
  position: absolute;
  z-index: 1000;
}
.infoHandel:hover .infoContent {
  display: block;
}
.infoContent2 {
  display: none;
  position: absolute;
  right:0;
  z-index: 1000;
}
.infoHandel:hover .infoContent2 {
  display: block;
}
.iconL {
  color: #999393;
}

.drop-list {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  height: 25px;
  padding-left: 5px;
  color: #4f5050;
}
.drop-list:hover {
  background-color: #e8e8e9;
  cursor: pointer;
  color: #000;
}
.act {
  background-color: #cccdce;
}

.searchList {
  max-height: 150px;

  width: 93%;
  z-index: 100;
  overflow-y: scroll;
  position: absolute;
  /* margin-right: 8px; */
}
.searchList::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.searchList::-webkit-scrollbar-track {
  background: #dad9d9;
}

/* Handle */
.searchList::-webkit-scrollbar-thumb {
  background: #ff4b2b;
}

/* Handle on hover */
.searchList::-webkit-scrollbar-thumb:hover {
  background: #aca9a8;
}

.checkedContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  white-space: pre;
}

/* Hide the browser's default checkbox */
.checkedContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ccc;
}

/* On mouse-over, add a grey background color */
.checkedContainer:hover input ~ .checkmark {
  background-color: #ff9380;
}

/* When the checkbox is checked, add a blue background */
.checkedContainer input:checked ~ .checkmark {
  background-color: #ff4b2b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkedContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkedContainer .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.optionContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
}
.spanFont {
  font-size: 13px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #4d4d4d;
}
/* Hide the browser's default radio button */
.optionContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ccc;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.optionContainer:hover input ~ .checkmark1 {
  background-color: #ff9380;
}

/* When the radio button is checked, add a blue background */
.optionContainer input:checked ~ .checkmark1 {
  background-color: #ff4b2b;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark1:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.optionContainer input:checked ~ .checkmark1:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.optionContainer .checkmark1:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.optionTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  white-space: nowrap;

  /* align-items: center; */
  -webkit-align-self: center;
          align-self: center;
}
.SideLine {
  display: block;
}
.SideLineHide {
  display: block;
}
.applicationAgreement {
  padding-right: 30px;
  padding-left: 30px;
  
}
.applicationAgreementCard{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.applicationAgreementMobile {
  min-width: 1100px;
  max-width: 1100px;
  padding-right: 30px;
  padding-left: 30px;
  padding: 0px;
  margin: 0px;
}
.applicationAgreementHead {
  padding-top: 50px;
  padding-left: 80px;
}
.applicationAgreementHead4 {
  padding-top: 30px;
  padding-left: 60px;
  padding-right: 60px;
}
.applicationAgreementHeadBar {
  background-color: rgba(224, 220, 220, 0.603);
  border: 1px solid rgb(228, 226, 226);
  height: 20px;
  margin: 30px;
  margin-bottom: 0px;
}
.applicationAgreementHeadLeftBox {
  border: 1px solid #000;
  padding: 0px;
  margin: 0px;
  margin-top: 27px;
}
.applicationAgreementHeadLeft {
  border: 1px solid #000;
  padding: 0px;
  margin: 0px;
  margin-top: 27px;
}
.applicationAgreementHeadLeftBoxMobile {
  border: 1px solid #000;
  padding: 0px;
  margin: 0px;
  margin-top: 27px;
  display: none;
}
.agreementLogo {
  width: 190px;
  margin-bottom: 20px;
}
.agreementSign {
  width: 90px;
  height: 34px;
  margin-bottom: 20px;
}
.applicationAgreementHeadRight {
  padding-right: 55px;
  padding-left: 25px;
  margin: 0px;
}
.applicationAgreementHead2 {
  padding-top: 30px;
  padding-left: 80px;
  padding-left: 80px;
}
.applicationAgreementHead3 {
  padding-top: 0px;
  padding-right: 80px;
  padding-left: 80px;
}
.agreementTableBody {
  border: 1px solid #000;
  margin-right: 30px;
  margin-left: 30px;
}
.agreementTableBody1 {
  margin-right: 40px;
  margin-left: 40px;
}
.borderBlack {
  /* border:1px solid #000; */
  border-style: ridge;
}
.borderBlack1 {
  /* border:1px solid #000; */
  border: ridge 1.7px #fff;
}
.borderRightBlack {
  border-right: 1px solid #000;
}
.borderLeftBlack {
  border-left: 1px solid #000;
}
.borderTopBlack {
  border-top: 1px solid #000;
}
.borderBottomBlack {
  border-bottom: 1px solid #000;
}
.ApplicationInputAgreement {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  /* width: 100%; */

  /* padding-right:50px; */
  font-size: 12px;
}
.ApplicationInputAgreement2 {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  height: 70%;
  /* padding-right:50px; */
  font-size: 12px;
}
.ApplicationInputAgreement4 {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  height: 70%;
  /* padding-right:50px; */
  top:15px;
  font-size: 12px;
  margin-right:10px;
  margin-left:10px;

}
.bgGray {
  background-color: rgba(224, 220, 220, 0.603);
}
.ApplicationInputAgreement3 {
  background-color: rgba(255, 255, 255, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;

  width: 100%;
  height: 100%;
  /* padding-right:50px; */
  font-size: 12px;
}
.agreementTitleBar {
  background-color: #000;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
.threeQuarterBreak {
  width: 75%;
}

.headApplicationReview {
  font-size: 19px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.7px;
}
.headApplicationReview1 {
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 25px;
  font-family: "Montserrat", sans-serif;
}
.fixedInputSize {
  min-width: 150px;
}
.tableLeftPad {
  padding-left: 15px;
}
.quarterBreak {
  min-width: 130px;
}
.tableResNo {
  display: none;
  position: absolute;
  padding-left: 5px;
  padding-top: 5px;
}
.tableResNo2 {
  display: none;
  position: absolute;
  padding-left: 5px;
  padding-top: 5px;
}
.pageNumberAlign {
  text-align: right;
}
.preEmployeeHide1 {

  display: block;
}
.preEmployeeHide2 {
  display: none;

}
.contractAlign{
  padding-right:10%;
  padding-left:10%;
}
.buttonTabSec {
  border-radius: 0px;
  font-size: 10px;
  font-weight: bold;
  padding: 7px 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;    
  border: solid 1px #efefef;
  background-color: #efefef;
  color: #939292;
  cursor: pointer;
  border-bottom: none;

}
.buttonTabLineSec {
  /* border-radius: 5px; */
  font-size: 10px;
  font-weight: bold;
  padding: 7px 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;    
  border: solid 1px #efefef;
  border-bottom: none;
  background-color:  #ffffff;
  color: #939292;
  cursor: pointer;
}
.buttonTabSec:hover{
  background-color:  #c7c6c6;
  color: #fff;
}
.tabAppList{
  padding-bottom: 3px;
  background-color:  #efefef;
}
.badgeApplicationStatusRed {
  position: absolute;
  right:43px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  padding: 0px 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 5px #ffffff;
  background-color: #fd846f;
  color:#fff;
  margin-top: -7px;
}
.badgeApplicationStatusYellow {
  position: absolute;
  right:43px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  padding: 0px 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 5px #ffffff;
  background-color: #f4da2e;
  color:#fff;
  margin-top: -7px;
}
.badgeApplicationStatusGreen {
  position: absolute;
  right:43px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  padding: 0px 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 5px #ffffff;
  background-color: #66c533;
  color:#fff;
  margin-top: -7px;
}
.applicationCardAlign{
  min-width: 500px !important;
}
@media only screen and (min-width: 1900px) and (max-width: 5900px) {
  .dateInputIcon{
    right:30px;
    top:22px;
  }
  .applicationCard {
    height: 320px !important;
  }
  .applicationCard2 {
    height: 320px !important;
  }
  .optionInfo{
    min-width: 1600px;
    max-width: 1400px;
  }
  .alignInput {
    min-width: 280px;
    max-width: 380px;
  }
  .dateInputIcon{
  
  }
  .inputContainer {
    padding: 60px;
    padding-right: 80px;
    padding-left: 80px;
    
  }
  .inputContainer1 {
    padding-left: 80px;
    padding-right:80px;
  }
  .subTitle {
    padding-left: 80px;
  }
  .stepTitle {
    font-size: 18px;
  }
  .stepContentTitle {
    font-size: 18px;
  }
  .applicationAgreement {

    padding-top: 20px;

    margin-right: 50px;
  margin-left: 50px;
  }
  .managerLandlord {
    min-width: 400px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 60px;
  }
}

@media only screen and (min-width: 1450px) and (max-width: 1900px) {
  .applicationCard {
    height: 320px !important;
  }
  .applicationCard2 {
    height: 320px !important;
  }
  .dateInputIcon{
    right:30px;
    top:22px;
  }

  .alignInput {
    min-width: 280px;
    max-width: 380px;
  }
  .dateInputIcon{
  
  }
  .optionInfo{
    min-width: 1200px;
    max-width: 1000px;
  }
  .inputContainer {
    padding: 60px;
    padding-right: 80px;
    padding-left: 80px;
  }
  .inputContainer1 {
    padding-left: 80px;
    padding-right:80px;
  }
  .subTitle {
    padding-left: 80px;
  }
  .stepTitle {
    font-size: 18px;
  }
  .stepContentTitle {
    font-size: 18px;
  }
  .applicationAgreement {
    margin-top: 50px;
    padding-top: 20px;
    margin-right: 50px;
  margin-left: 50px;
  }
  .managerLandlord {
    min-width: 350px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 1270px) and (max-width: 1450px) {
  .applicationCard {
    height: 340px !important;
  }
  .applicationCard2 {
    height: 340px !important;
  }
  .dateInputIcon{
    right:25px;
    top:22px;
  }

  .headApplicationReview {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.7px;
  }
  .optionInfo{
    min-width: 1000px;
    max-width: 800px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 24px;
  }
  .inputContainer {
    padding: 55px;
    padding-right: 80px;
    padding-left: 80px;
  }
  .inputContainer1 {
    padding-left: 80px;
    padding-right:80px;
  }
  .subTitle {
    padding-left: 60px;
  }
  .stepTitle {
    font-size: 16px;
  }
  .stepContentTitle {
    font-size: 18px;
  }
  .managerLandlord {
    min-width: 350px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
  .applicationAgreement {
    padding-right: 50px;
    padding-left: 50px;
    padding-top: 20px;

    margin-right: 50px;
  margin-left: 50px;
  }
}


@media only screen and (min-width: 1150px) and (max-width: 1270px) {
  .applicationCard {
    height: 360px !important;
  }
  .applicationCard2 {
    height: 360px !important;
  }
  .dateInputIcon{
    right:25px;
    top:22px;
  }

  .headApplicationReview {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.7px;
  }
  .optionInfo{
    min-width: 900px;
    max-width: 700px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 23px;
  }
  .inputContainer {
    padding: 60px;
  }
  .inputContainer1 {
    padding-left: 60px;
    padding-right:60px;
  }
  .subTitle {
    padding-left: 60px;
  }
  .stepTitle {
    font-size: 16px;
  }
  .stepContentTitle {
    font-size: 17px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
  .managerLandlord {
    min-width: 450px;
  }
  .QuarterText {
    min-width: 200px;
  }
  .agreementLogoDiv {
    min-width: 550px;
  }
  .applicationAgreement {
    padding-right: 50px;
    padding-left: 50px;
    padding-top: 20px;

    margin-right: 50px;
  margin-left: 50px;
  }
}

@media only screen and (min-width: 850px) and (max-width: 1150px) {
  .dateInputIcon{
    right:25px;
    top:22px;
  }
  .applicationCard {
    height: 580px !important;
  }
  .applicationCard2 {
    height: 550px !important;
  }
  .headApplicationReview {
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.7px;
  }
  .optionInfo{
    min-width: 700px;
    max-width: 500px;
  }
  .tableLeftPad {
    padding-left: 13px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 21px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }
  .inputContainer {
    padding: 40px;
  }
  .inputContainer1 {
    padding-left: 40px;
    padding-right:40px;
  }
  .subTitle {
    padding-left: 40px;
  }

  .stepContentTitle {
    font-size: 15px;
  }

  .stepContentTitle {
    font-size: 17px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
  .applicationAgreement {
    padding-right: 50px;
    padding-left: 50px;
    padding-top: 20px;

    margin-right: 50px;
  margin-left: 50px;
  }
  .managerLandlord {
    min-width: 420px;
  }
  .span7 {
    font-size: 12px;
  }

  .span8 {
    font-size: 12px;
  }
  .applicationAgreementHeadRight {
    /* padding:0px; */
    min-width: 400px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .QuarterText {
    min-width: 200px;
  }
  .agreementLogoDiv {
    min-width: 550px;
  }
}
@media only screen and (min-width: 770px) and (max-width: 850px) {
  .applicationCard {
    height: 600px !important;
   
  }
  .applicationCard2 {
    height: 550px !important;
  }
  .tableLeftPad {
    padding-left: 13px;
  }
  .optionInfo{
    min-width: 550px;
    max-width: 400px;
  }
  .headApplicationReview {
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 21px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }

  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }

  .inputContainer {
    padding: 30px;
  }
  .inputContainer1 {
    padding-left: 30px;
    padding-right:30px;
  }
  .subTitle {
    padding-left: 30px;
  }
  .stepTitle {
    font-size: 15px;
  }
  .stepContentTitle {
    font-size: 17px;
  }

  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
  .applicationAgreement {
    margin-top: 30px;
    padding-right: 50px;
    padding-left: 50px;
    padding-top: 20px;
    margin-left: 50px;
  }
  .managerLandlord {
    min-width: 320px;
  }
  .span7 {
    font-size: 12px;
  }
  .span8 {
    font-size: 12px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .halfBreak {
    min-width: 500px;
  }
  .threeQuarterBreak {
    min-width: 600px;
  }

  .QuarterText {
    min-width: 600px;
  }
  .agreementLogoDiv {
    min-width: 550px;
  }
}
@media only screen and (min-width: 671px) and (max-width: 770px) {

  .applicationCard {
    height: 600px !important;
  }
  .applicationCard2 {
    height: 630px !important;
  }
  .tableResNo2 {
    display: block;
  }
  .tableLeftPad {
    padding-left: 11px;
  }
  .optionInfo{
    min-width: 550px;
    max-width: 400px;
  }
  .headApplicationReview {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 20px;
  }
  .addApp {
    margin-top: -30px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }
  .stepTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 10px !important;
    -webkit-align-self: center;
            align-self: center;
    line-height: 10px;
  }
  .stepContentTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 16px !important;
    -webkit-align-self: center;
            align-self: center;
    line-height: 10px;
  }
  .stepFormNavTitle {
    padding-top: 25px;
  }
  .inputContainer {
    padding: 20px;
  }
  .inputContainer1 {
    padding-left: 20px;
    padding-right:20px;
  }
  .subTitle {
    padding-left: 20px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 120px;
  }
  .applicationAgreement {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;

  }
  .managerLandlord {
    min-width: 220px;
  }
  .span7 {
    font-size: 12px;
  }
  .span8 {
    font-size: 12px;
  }
  .applicationAgreementHead {
    padding-top: 30px;
    padding-left: 60px;
    padding-right: 20px;
  }
  .applicationAgreementHead2 {
    padding-top: 20px;
    padding-left: 80px;
  }
  .checkedContainer {
    font-size: 11px;
  }
  .agreementCheck {
    min-width: 150px;
  }
  .agreementCheckService {
    min-width: 150px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .applicationAgreementHead3 {
    padding-top: 0px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .halfBreak {
    min-width: 450px;
  }
  .QuarterText {
    min-width: 600px;
  }
  .agreementLogoDiv {
    min-width: 400px;
  }

  .searchListContentApp {
    font-size: 13px;
  }
}

@media only screen and (min-width: 580px) and (max-width: 671px) {
  .dateInputIcon{
    right:20px;
    top:22px;
  }
  .applicationCard {
    height: 600px !important;
  }
  .applicationCard2 {
    height: 630px !important;
  }
  .searchInput{
    font-size: 12px;
  }
  .optionInfo{
    min-width: 450px;
    max-width: 350px;
  }
  .tableResNo2 {
    display: block;
  }
  .pageNumberAlign {
    text-align: center;
  }
  .tableResNo {
    display: block;
  }
  .tableLeftPad {
    padding-left: 11px;
  }
  .headApplicationReview {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 20px;
  }
  .addApp {
    margin-top: -30px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }
  .stepTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 10px !important;
    -webkit-align-self: center;
            align-self: center;
    line-height: 10px;
  }
  .stepContentTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 16px !important;
    -webkit-align-self: center;
            align-self: center;
    line-height: 10px;
  }
  .stepFormNavTitle {
    padding-top: 25px;
  }
  .inputContainer {
    padding: 20px;
  }
  .inputContainer1 {
    padding-left: 20px;
    padding-right:20px;
  }
  .subTitle {
    padding-left: 20px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 120px;
  }
  .applicationAgreement {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;

  }
  .managerLandlord {
    min-width: 220px;
  }
  .span7 {
    font-size: 11px;
  }
  .span8 {
    font-size: 12px;
  }
  .applicationAgreementHead {
    padding-top: 30px;
    padding-left: 60px;
    padding-right: 20px;
  }
  .applicationAgreementHead2 {
    padding-top: 20px;
    padding-left: 80px;
  }
  .checkedContainer {
    font-size: 11px;
  }
  .agreementCheck {
    min-width: 150px;
  }
  .agreementCheckService {
    min-width: 150px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .applicationAgreementHead3 {
    padding-top: 0px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .halfBreak {
    min-width: 450px;
  }
  .QuarterText {
    min-width: 600px;
  }
  .agreementLogoDiv {
    min-width: 400px;
  }

  .searchListContentApp {
    font-size: 13px;
  }
}
@media only screen and (min-width: 430px) and (max-width: 580px) {

  .dateInputIcon{
    right:28px;
    top:23px;
  }
  .applicationCard {
    height: 600px !important;
  }
  .applicationCard2 {
    height: 660px !important;
  }
  .applicationCardTop{
    margin-top: 40px;
  }
  .mobileStepView{
    padding:0px;
  }
  .optionInfo{
    min-width: 350px;
    max-width: 300px;
  }
  .searchInput{
    font-size: 14px;
  }
  .titleStep {
    display: none;
  }
  .titleStepOpp {
    display: block;
  }
  .pageNumberAlign {
    text-align: center;
  }
  .tableResNo2 {
    display: block;
  }
  .tableResNo {
    display: block;
  }
  .tableLeftPad {
    padding-left: 11px;
  }
  .headApplicationReview {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 19px;
  }
  .informationListAppAlign {
    padding: 30px;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .addApp {
    margin-top: -30px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 11px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }
  .stepTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 12px !important;
    -webkit-align-self: center;
            align-self: center;
    line-height: 10px;
  }
  .stepContentTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 16px !important;
    -webkit-align-self: center;
            align-self: center;
    line-height: 10px;
  }
  .stepFormNavTitle {
    padding-top: 25px;
  }
  .inputContainer {
    padding: 20px;
  }
  .inputContainer1 {
    padding-left: 20px;
    padding-right:20px;
  }
  .subTitle {
    padding-left: 20px;
    font-size: 15px !important;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
  .applicationAgreement {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
  }

  .span7 {
    font-size: 11px;
  }
  .span8 {
    font-size: 12px;
  }
  .applicationAgreementHeadLeftBox {
    display: none;
  }
  .applicationAgreementHeadLeftBoxMobile {
    display: block;
    min-width: 300px;
  }
  .applicationAgreementHeadRight {
    padding: 0px;
    min-width: 300px;
  }
  .managerLandlord {
    min-width: 220px;
  }
  .applicationAgreementHead {
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 45px;
  }
  .checkedContainer {
    font-size: 11px;
  }
  .agreementCheck {
    min-width: 150px;
  }
  .agreementCheckService {
    min-width: 150px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .applicationAgreementHead3 {
    padding-top: 0px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .halfBreak {
    min-width: 300px;
  }
  .Quarter {
    min-width: 300px;
  }
  .QuarterText {
    min-width: 500px;
  }
  .agreementTableBody {
    margin-right: 15px;
    margin-left: 15px;
  }
  .agreementTableBody1 {
    margin-right: 15px;
    margin-left: 15px;
  }
  .agreementLogoDiv {
    min-width: 300px;
  }
  .applicationAgreementHead4 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .ApplicationInput {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInputAgg {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInput1 {
    height: 45px;
    font-size: 16px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    
  }
  .searchListContentApp {
    font-size: 11px;
  }
}
@media only screen and (min-width: 354px) and (max-width: 430px) {
  .cardApplication{

    margin-right: 1%;
    margin-left: 1%;
    margin-top: 5%;

  }
  .dateInputIcon{
    right:30px;
  }
  .applicationCard {
    height: 600px !important;
  }
  .applicationCard2 {
    height: 660px !important;
  }
  .applicationCardTop{
    margin-top: 40px;
  }
  .mobileStepView{
    padding:0px;
  }
  .optionInfo{
    min-width: 300px;
    max-width: 300px;
  }
  .searchInput{
    font-size: 14px;
  }
  .preEmployeeHide2 {
    display: block;
  }
  .preEmployeeHide1 {
    display: none;
  }
  .titleStep {
    display: none;
  }
  .titleStepOpp {
    display: block;
  }
  .pageNumberAlign {
    text-align: center;
  }
  .tableResNo2 {
    display: block;
  }
  .tableResNo {
    display: block;
  }
  .tableLeftPad {
    padding-left: 8px;
  }
  .headApplicationReview {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 17px;
  }
  .informationListAppAlign {
    padding: 15px;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 11px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .addApp {
    margin-top: -30px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }

  .stepTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    /* align-items: center; */
    font-size: 12px !important;
    -webkit-align-self: center;
            align-self: center;
    line-height: 17px;
  }
  .stepContentTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    /* align-items: center; */
    font-size: 16px !important;
    -webkit-align-self: center;
            align-self: center;
    line-height: 17px;
  }
  .stepFormNavTitle {
    padding-top: 25px;
  }
  .social-container {
    margin: 20px 0;
  }

  .social-container-step {
    border: 1px solid #dddddd;
    border-radius: 50%;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 0 0px;
    height: 30px;
    width: 30px;
  }
  .social {
    font-size: 10px;
  }
  .stepSpan {
    font-family: "Montserrat", sans-serif;
    font-size: 8px;
    white-space: nowrap;
  }
  .inputContainer {
    padding: 10px;
    padding-top: 25px;
  }
  .inputContainer1 {
    padding-left: 10px;
    padding-right:10px;
  }
  .subTitle {
    padding-left: 10px;
    font-size: 15px !important;
  }

  .span7 {
    font-size: 10px;
  }
  .span8 {
    font-size: 11px;
  }
  .applicationAgreementHeadLeftBox {
    display: none;
  }
  .applicationAgreementHeadLeftBoxMobile {
    display: block;
    min-width: 200px;
  }
  .applicationAgreementHeadRight {
    padding: 0px;

    min-width: 200px;
  }
  .managerLandlord {
    min-width: 220px;
  }
  .applicationAgreementHead {
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 35px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 30px;
  }
  .applicationAgreement {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 20px;
  }
  .agreementLogo {
    width: 150px;
  }
  .checkedContainer {
    font-size: 11px;
  }
  .agreementCheck {
    min-width: 150px;
  }
  .agreementCheckService {
    min-width: 150px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .applicationAgreementHead3 {
    padding-top: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .halfBreak {
    min-width: 200px;
  }
  .Quarter {
    min-width: 200px;
  }
  .QuarterText {
    min-width: 200px;
  }
  .agreementTableBody {
    margin-right: 5px;
    margin-left: 5px;
  }
  .agreementTableBody1 {
    margin-right: 5px;
    margin-left: 5px;
  }
  .agreementLogoDiv {
    min-width: 200px;
  }
  .applicationAgreementHead4 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .ApplicationInput {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInputAgg {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInput1 {
    height: 35px;
    font-size: 14px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    min-width:220px;
  }
  .searchListContentApp {
    font-size: 11px;
  }
  .applicationCardAlign{
    min-width: 420px !important;
  }
}
@media only screen and (min-width: 00px) and (max-width: 353px) {
  .cardApplication{

    margin-right: 1%;
    margin-left: 1%;
    margin-top: 5%;
  }
  .dateInputIcon{
    right:30px;
  }
  .applicationCard {
    height: 600px !important;
  }
  .applicationCard2 {
    height: 720px !important;
  }
  .applicationCardTop{
    margin-top: 40px;
  }
  .mobileStepView{
    padding:0px;
  }
  .optionInfo{
    min-width: 300px;
    max-width: 300px;
  }
  .searchInput{
    font-size: 14px;
  }
  .preEmployeeHide2 {
    display: block;
  }
  .preEmployeeHide1 {
    display: none;
  }
  .titleStep {
    display: none;
  }
  .titleStepOpp {
    display: block;
  }
  .pageNumberAlign {
    text-align: center;
  }
  .tableResNo2 {
    display: block;
  }
  .tableResNo {
    display: block;
  }
  .tableLeftPad {
    padding-left: 8px;
  }
  .headApplicationReview {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 17px;
  }
  .informationListAppAlign {
    padding: 15px;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 11px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .addApp {
    margin-top: -30px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }

  .stepTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    /* align-items: center; */
    font-size: 12px !important;
    -webkit-align-self: center;
            align-self: center;
    line-height: 17px;
  }
  .stepContentTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    /* align-items: center; */
    font-size: 14px !important;
    -webkit-align-self: center;
            align-self: center;
    line-height: 17px;
  }
  .stepFormNavTitle {
    padding-top: 25px;
  }
  .social-container {
    margin: 20px 0;
  }

  .social-container-step {
    border: 1px solid #dddddd;
    border-radius: 50%;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 0 0px;
    height: 30px;
    width: 30px;
  }
  .social {
    font-size: 10px;
  }
  .stepSpan {
    font-family: "Montserrat", sans-serif;
    font-size: 8px;
    white-space: nowrap;
  }
  .inputContainer {
    padding: 10px;
    padding-top: 25px;
  }
  .inputContainer1 {
    padding-left: 10px;
    padding-right:10px;
  }
  .subTitle {
    padding-left: 10px;
    font-size: 15px !important;
  }

  .span7 {
    font-size: 10px;
  }
  .span8 {
    font-size: 11px;
  }
  .applicationAgreementHeadLeftBox {
    display: none;
  }
  .applicationAgreementHeadLeftBoxMobile {
    display: block;
    min-width: 200px;
  }
  .applicationAgreementHeadRight {
    padding: 0px;

    min-width: 200px;
  }
  .managerLandlord {
    min-width: 220px;
  }
  .applicationAgreementHead {
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 35px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 30px;
  }
  .applicationAgreement {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 20px;
  }
  .agreementLogo {
    width: 150px;
  }
  .checkedContainer {
    font-size: 11px;
  }
  .agreementCheck {
    min-width: 150px;
  }
  .agreementCheckService {
    min-width: 150px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .applicationAgreementHead3 {
    padding-top: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .halfBreak {
    min-width: 200px;
  }
  .Quarter {
    min-width: 200px;
  }
  .QuarterText {
    min-width: 200px;
  }
  .agreementTableBody {
    margin-right: 5px;
    margin-left: 5px;
  }
  .agreementTableBody1 {
    margin-right: 5px;
    margin-left: 5px;
  }
  .agreementLogoDiv {
    min-width: 200px;
  }
  .applicationAgreementHead4 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .ApplicationInput {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInputAgg {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInput1 {
    height: 35px;
    font-size: 14px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    min-width:220px;
  }
  .searchListContentApp {
    font-size: 11px;
  }
  .applicationCardAlign{
    min-width: 420px !important;
  }
}

.phoneInput{
     position: absolute;
     margin-top: 13px;
     margin-left: 8px;
}
.countryList{
     margin:0px;
     font-family: "Montserrat", sans-serif;
     font-size: 14px;
     font-weight: 100;
     line-height: 20px;
    cursor: pointer;
 }
 .countryList:hover{
     background-color: #c9c0c077;
 }
 .countryList:focus{
     background-color: #fd53539e;
 }
 .phoneInputCode{
     position: absolute;
     margin-top: 4px;
     margin-left: 30px;
     font-family: "Montserrat", sans-serif;
     font-size: 16px;
     font-weight: 100;
     color:#495057;
     line-height: 20px;
 }
 @media only screen and (min-device-width: 500px) and (max-device-width: 850px) {
     .phoneInput{
          margin-top: 10px;
          margin-left: 8px;
     }
   }
   @media only screen and (min-device-width: 100px) and (max-device-width: 500px) {
     .phoneInput{
          margin-top: 5px;
          margin-left: 8px;
     }
   }

.show {
  display: block;
}
.hide {
  display: none;
}
.modalStyle {
  border-radius: 25px;
}
.modalContent {
  font-size: 19px;
  font-weight: 100;
  margin-top: -40px;
  margin-bottom: -20px;
}

.alertContain {
  position: fixed;
  right: 15px;
  z-index: 1000000 !important;
}

.NotificationWindow {
  z-index: 1000000000000000 !important;
  padding: 10px;
  min-width: 300px;
  max-width: 600px;
  margin-bottom: 5px;
  border-radius: 10px;
  /* animation: AlertRight 1s linear ; */
  opacity: 1;
}
.topMargin10 {
  top: 10px !important;
}
.topMargin40 {
  top: 100px !important;
}
.successAlert {
  /* background-color: rgba(49, 165, 76, 0.81); */
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(45, 43, 43, 0.721) 0px 3px 8px;
  color: rgb(30, 103, 23);
}
.successAlert .fa {
  color: rgb(61, 190, 61);
}

.successAlert .bar {
  background: rgb(48, 237, 48);
}
.warningAlert {
  /* background-color: rgba(252, 255, 85, 0.842); */
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(45, 43, 43, 0.721) 0px 3px 8px;
  color: rgb(142, 143, 51);
}
.warningAlert .fa {
  color: rgb(227, 213, 68);
}

.warningAlert .bar {
  background: rgb(219, 232, 42);
}

.infoAlert {
  /* background-color: rgba(96, 155, 218, 0.842); */
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(45, 43, 43, 0.721) 0px 3px 8px;
  color: rgba(22, 59, 99, 0.842);
}
.infoAlert .fa {
  color: rgba(86, 168, 255, 0.842);
}

.infoAlert .bar {
  background: rgba(105, 177, 255, 0.842);
}
.lightAlert .bar {
  background: rgba(171, 171, 171, 0.842);
}

.dangerAlert {
  /* background-color: rgba(245, 76, 84, 0.822); */
  background-color: rgb(255, 255, 255);

  box-shadow: rgba(45, 43, 43, 0.721) 0px 3px 8px;
  color: rgba(97, 7, 13, 0.822);
}
.dangerAlert .fa {
  color: rgb(255, 71, 71);
}

.dangerAlert .bar {
  background: rgba(255, 65, 84, 0.842);
}

.lightAlert {
  background-color: rgba(238, 238, 238, 0.904);
  box-shadow: rgba(255, 255, 255, 0.982) 0px 3px 8px;
}
@-webkit-keyframes AlertRight {
  0% {
    opacity: 0.2;

    /* display:block; */
  }

  50% {
    opacity: 1;
    display: block;
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  55% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  65% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  75% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  85% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  95% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}
@keyframes AlertRight {
  0% {
    opacity: 0.2;

    /* display:block; */
  }

  50% {
    opacity: 1;
    display: block;
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  55% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  65% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  75% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  85% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  95% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}

.notificationText {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
}
.notificationClose {
  margin-top: 10px;
  margin-right: 100px;
  z-index: 501;
  position: absolute;
  color: rgb(159, 159, 159) !important;
}
.notificationClose:hover {
  color: rgb(108, 108, 108) !important;
}
.notificationText {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
}

@media only screen and (min-width: 425px) and (max-width: 600px) {
  .notificationText {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 425px) {
  .notificationText {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
  }
}

.timeInputDropDown{
     width:300px;
     z-index: 1600 !important;
     position: absolute;
     background-color: #ffffff;
     box-shadow: rgba(126, 126, 126, 0.809) 0px 7px 29px 0px;
}
.timeInputList{
     max-height:300px;
     overflow-y: scroll;
}
.inputClose{
     font-size: 16px;
     background-color: #ff6d53;
     color: #fff;
     cursor: pointer;
}
.inputClose1{
     font-size: 11px;
     background-color: #ff6d5374;
     color: #fff;
     cursor: pointer;
}

.inputClose1:hover{
     background-color: #ff6d53;
}
.timeInputList::-webkit-scrollbar {
     width: 4px;
     height: 2px;
   }
   .timeInputList::-webkit-scrollbar-track {
     background: #f2f2f2;
   }
   .timeInputList::-webkit-scrollbar-thumb {
     background: #8f8f8f;
   }
.timeInputListPart{
     padding:10px;
     cursor:pointer;
     
}
.timeInputListPart:hover{
     background-color: rgb(255, 187, 158);
     color: aliceblue;
}

.timeInputListPart.active{
     background-color: #ff6d53;
     color: aliceblue;
}





.wrapper {
  /* margin: 1px auto; */
  max-width: 1100px;
}

.container-calendar {
  padding: 14px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 10px;
  min-width: 320px;
  max-width: 520px;

  /* display:none; */
  /* margin: 0 auto; */
  /* overflow: auto; */
  box-shadow: rgba(126, 126, 126, 0.809) 0px 7px 29px 0px;
}
.calenderLight {
  background: #ffffff;
}
.calenderDark {
  background: #3e62798a;
}
.button-container-calendar button {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #bbbbbb2f;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 2px 10px;
}

.table-calendar {
  border-collapse: collapse;
  width: 100%;
}
.table-calendar thead{
  background-color: #ffffff;
}
.table-calendar td,
.table-calendar th {
  padding: 5px;

  /* border: 1px solid #e2e2e2; */
  text-align: center;
  vertical-align: top;
}
.dropdownMenuEvent {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.eventList {
  /* width:250px; */
  background-color: #fff;
}
.eventList:hover {
  /* width:250px; */
  color: #f3f3f3;
  background-color: #14293def;
}
.line-20 {
  line-height: 20px;
}
.calenderSpan{
  font-family: "Montserrat", sans-serif;
  color: #b44c39;
  background-color: #fb8b5f51;
  padding:5px;
  padding-right:8px;
  padding-left:8px;
  cursor: pointer;
}
.date-picker {
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  
}
.date-picker-disable{
  font-family: "Montserrat", sans-serif;
  cursor: auto;
  color: rgb(221, 221, 221) !important;
}
.date-picker1 {
  height: 40px;
}

.date-picker.active{
  border-radius: 6px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #fff !important;
  font-weight: bold;
  background-color: #ff6d53 !important;
}
.date-picker.disableStyle{
  border-radius: 6px;
  color: rgb(221, 221, 221) !important;
}

.date-picker:hover {
  border-radius: 6px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #fff !important;
  font-weight: bold;
  background-color: #fb8b5f51;
}
.date-picker.selected {
  font-weight: bold;
  /* outline: 1px dashed #00BCD4; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  color: #fff !important;
  background-color: #b3b3b385;
}
.date-picker.selected:hover {
  border-radius: 6px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  color: #fff !important;
  background-color: #fb8b5f51;
}
.calenderLight .date-picker.event {
  border-radius: 10px;
  cursor: pointer;
  color: rgb(212, 156, 72) !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff0c1b2;
}
.calenderDark .date-picker.event {
  border-radius: 10px;
  cursor: pointer;
  color: rgb(107, 93, 73) !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #eee56af8;
}
.date-picker.selected span {
  /* border-bottom: 2px solid currentColor; */
}
.removePaddings {
  padding: 0px;
  line-height: 1px;
  height: 1px;
  font-size: 8px;
}

.calenderLight .date-picker.selectedEvent {
  /* font-weight: bold; */
  cursor: pointer;
  outline: 3px solid #ffe283b2 !important;
  border-radius: 10px;
  background-color: #14293def;
  color: #ffe283b2;
}
.calenderDark .date-picker.selectedEvent {
  /* font-weight: bold; */
  cursor: pointer;
  outline: 3px solid #eee56af8 !important;
  border-radius: 10px;
  background-color: #fff;
  color: rgb(107, 93, 73);
}
.calenderLight .date-picker.selectedEvent:hover {
  outline: 1px solid #fff !important;
  cursor: pointer;
  border-radius: 10px;
  background-color: #ffe283b2;
  color: rgb(212, 156, 72) !important;
}
.calenderDark .date-picker.selectedEvent:hover {
  cursor: pointer;
  /* outline: 3px solid #71b1f5 !important; */
  border-radius: 10px;
  background-color: #14293def;
  color: rgb(107, 93, 73) !important;
}
/* sunday */
.date-picker:nth-child(1) {
  color: rgb(204, 84, 84);
}

/* friday */
.date-picker:nth-child(6) {
  color: rgb(80, 182, 80);
}

.monthAndYear {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  text-align: center;
  color: #fff;
  margin-top: 0;
}
.weeks {
  color: rgb(182, 182, 186);
  font-weight: 400;
}
.badge-throo {
  background-color: #3be8c8;
  color: #fff;
}
.badge-throo2 {
  background-color: #53535373;
  color: #fff;
}
.calenderEventMessage {
  font-family: "Montserrat", sans-serif;
  line-height: 5px !important;
  color: #000 !important;
  letter-spacing: 0.5px;
  padding-top: 1px !important;
  padding-bottom: 4px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;

  margin: 0px 0 0px !important;
}
.calenderEventMessage:hover {
  color: #fff !important;
}
.button-container-calendar {
  background-color: #ff6d53;
  position: relative;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin-bottom: 0.3em;
  overflow: hidden;
  clear: both;
}

.previous {
  float: left;
  font-size: 16px !important;
}

.next {
  float: right;
  font-size: 16px !important;
}

.footer-container-calendar {
  font-family: "Montserrat", sans-serif;
  margin-top: 1em;
  border-top: 1px solid #dadada;
  padding: 10px 0;
}
.footer-container-calendar-dark {
  font-family: "Montserrat", sans-serif;
  margin-top: 1em;
  border-top: 1px solid #dadada;
  padding: 10px 0;
}
.footer-container-calendar select {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #ffffff;
  color: #585858;
  border: 1px solid #bfc5c5;
  border-radius: 3px;
  padding: 5px 1em;
}

.footer-container-calendar-dark select {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #5a748563;
  color: #f3f3f3;
  border: 1px solid #bfc5c5;
  border-radius: 3px;
  padding: 5px 1em;
}

.footer-container-calendar-dark select option {
  color: rgb(70, 67, 67);
}

.sig-canvas {
     border: 2px dotted #CCCCCC;
     border-radius: 15px;
     cursor: crosshair;
     margin-bottom: 3%;
     touch-action: none;
}
/* @media only screen and (min-width: 650px) and (max-width: 850px) {
  .signatureParent {

    overflow:hidden;
    max-width:600px;
}
}
@media only screen and (min-width: 450px) and (max-width: 650px) {
  .signatureParent {

    overflow:hidden;
    max-width:500px;
}
}
@media only screen and (min-width: 0px) and (max-width: 450px) {
  .signatureParent {

    overflow:hidden;
    max-width:400px;
}
} */
.addApp1 {
  /* padding-top:5px; */
}
.roundNumberBadge {
  border-radius: 20px;
  border: solid 3px #fff;
  background-color: #ff4b2b;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  padding-top: 4px;
  text-transform: uppercase;
  width: 40px;
  height: 40px;
}
.applicationTenantBudge {
  width: 100%;
  cursor: pointer;
  /* background-color: rgb(231, 20, 20); */
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-right: 10px;
  padding-left: 10px;
  /* border-radius: 10px; */
}
.applicationTenantBudge:hover {
  background-color: #e7e4e479 !important;
  color: #fff;
}
.contractCard1 {
  background-color: #fff;
  /* border-top: .00001rem solid #f7ada0; */
  /* border-bottom: .00001rem solid #f7ada0; */
  /* border-right: .3rem solid #f7ada0; */
  /* border-left: .3rem solid #f7ada0; */
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
  /* box-shadow:
         inset 0 -3em 3em rgba(206, 223, 240, 0.131),
          0 0  0 2px rgb(255,255,255),
          0.3em 0.3em 1em rgba(192, 187, 187, 0.3); */
  /* margin-top: -20px;       */
  /* height:80px; */
  margin-top: 15px;
  padding-top: 5px;
  cursor: pointer;
}
.bottomRad {
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
}
.contractImg {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding: 7px;
  /* margin-top: 5px; */
}

.zoom {
  -webkit-animation: heart 1s infinite alternate;
          animation: heart 1s infinite alternate;
}
.appNotification {
  background-color: #ff7d6a;
  margin-top: 20px;
}
@-webkit-keyframes heart {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes heart {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.zoom1 {
  -webkit-animation: heart1 2.5s infinite alternate;
          animation: heart1 2.5s infinite alternate;
}

@-webkit-keyframes heart1 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes heart1 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.contractCardDetail {
  /* margin-top: -10px; */
  background-color: rgb(248, 244, 244);
  /* border-right: .3rem solid #f7ada0; */
  /* border-left: .3rem solid #f7ada0; */
  border-color: #fcc1b7;
  padding-right: 50px;
  padding-left: 50px;
}
.tenantDetailBox {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  /* background: -webkit-linear-gradient(to right, #cfcccc, #ffffff);
    background: linear-gradient(to right, #dcdddd, #ffffff); */
  background-color: #ffffff;
}
.appTenantImage {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: -7px;
  border-radius: 19px;
  height: 50px;
}

.appStatus1 {
  text-align: "center";
  padding: 9px;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  margin-bottom: 10px;
}
.vert-move-x {
  -webkit-animation: moverX 2s infinite alternate;
  animation: moverX 2s infinite alternate;
}
.tenantShow {
  font-size: 14px;
}
@-webkit-keyframes moverX {
  0% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  100% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}
@keyframes moverX {
  0% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  100% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}
.contractCardDetailPaddingBottom {
  /* border-top: .00001rem solid #f7ddd8; */
  /* border-bottom: .2rem solid #f7ada0; */
  background-color: #ffffff;
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;

  margin-bottom: 3%;
  padding-bottom: 20px;
  padding-top: 10px;
}
.contractCardDetailPaddingTop {
  border-top: 0.00001rem solid #f7ada0;
  background-color: #ffffff;
  border-top: 0.2rem solid #dee0e2d9;
  box-shadow: inset 0 -3em 3em rgba(206, 223, 240, 0.131),
    0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(192, 187, 187, 0.3);
}
.contractMainCard {
  background-color: #ffffff;
  /* border-top-left-radius:50px ; */
  /* border-bottom-left-radius:50px ; */
  border-top-left-radius: 0.5rem;
  /* margin-top: -20px; */
  /* height:60px; */
}

.showMenu {
  height: auto;
  overflow: hidden;
  transition: height ease-out;
}
@-webkit-keyframes height {
  0% {
    height: 0;
  }

  100% {
    height: auto;
  }
}
@keyframes height {
  0% {
    height: 0;
  }

  100% {
    height: auto;
  }
}
.hideMenu {
  height: 0px;
  overflow: hidden;
  transition: height 300ms;
}
.contractName {
  font-size: 21px;
  font-family: "Montserrat", sans-serif;
}
.unitName {
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
}
.status {
  font-family: "Montserrat", sans-serif;
  font-size: 5px;
}
.contractButton {
  font-family: "Montserrat", sans-serif;
  padding-left: 8%;
  padding-right: 8%;
}
.successText {
  background-color: #d5f5e3;
  color: #7dcea0;
}
.primaryText {
  background-color: #367199;
}
.primaryText:hover {
  background-color: #6999b9;
  cursor: pointer;
}
.warningText {
  background-color: #fdebd0;
  color: #e59866;
}
.dangerText {
  background-color: #fdedec;
  color: #ec7063;
}
.infoText {
  background-color: #bb8fce;
}
.secoundaryText {
  background-color: #bdc3c7;
}
.editButton {
}
.editButton:hover {
  background-color: #9b59b6;
  cursor: pointer;
}
.detailsText {
  font-size: 16px;

  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.detailsText1 {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  font-weight: 400;
}
.listApp {
  padding-top: 30px;
  padding-right: 1.5%;
  padding-left: 1.5%;
}
.bodyApplication {
  height: 400px;
  overflow: scroll;
}
.applicationCard {
  background-color: #fff;
  border-radius: 15px;
  margin-right: 5px !important;
  margin-left: 5px !important;
  height: 100%;

}
.applicationCardDark {
  background-color: rgb(100, 100, 100);
  border-radius: 15px;
  margin-right: 5px !important;
  margin-left: 5px !important;
  height: 100%;
}
.tenantListAlign {
  min-height: 155px;
}
.bodyApplication::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.bodyApplication::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.bodyApplication::-webkit-scrollbar-thumb {
  background: #ff4b2b;
}

/* Handle on hover */
.bodyApplication::-webkit-scrollbar-thumb:hover {
  background: #918c8b;
}

.noDataImage{
    height:750px;
    width:750px;
}
.noData1{
    height:250px;
    width:250px;
}
.nodataSection{
    
}
.noDataHead{
    color: #333c7e;
    font-size: 100px;
    font-family: 'Exo 2', sans-serif;
    text-shadow: 5px 5px #e2e9eb;
    white-space: nowrap;
    /* font-family: 'Kanit', sans-serif; */
    /* font-family: 'Titillium Web', sans-serif; */
    /* font-family: 'Ubuntu', sans-serif; */
}
@media only screen and (min-width: 780px) and (max-width: 1050px) {
    .noDataImage {
        height:750px;
        width:750px;
    } 
    .noDataImage {
        height:750px;
        width:650px;
    }
    .noDataHead{
        font-size: 80px;
    }
}
@media only screen and (min-width: 550px) and (max-width: 780px) {
    .noDataImage {
        height:650px;
        width:250px;
    }
    .noDataImage {
        height:850px;
        width:350px;
    }
    .noDataHead{
        font-size: 70px;
    }
}
@media only screen and (min-width: 390px) and (max-width: 550px) {
    .noDataImage {
        height:650px;
        width:250px;
    }
    .noDataImage {
        height:850px;
        width:350px;
    }
    .noDataHead{
        font-size: 70px;
        margin-left: 80px;
    }
}
@media only screen and (min-width: 224px) and (max-width: 390px) {
    .noDataImage {
        height:650px;
        width:250px;
    }
    .noDataImage {
        height:850px;
        width:350px;
    }
    .noDataHead{
        font-size: 70px;
        margin-left: 120px;
    }
}
.page404 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: "Montserrat", sans-serif;
  padding-top: 15%;
  margin: -20px 0 50px;
}
.PageText404 {
  padding-top: 20%;
}
.head {
  font-size: 100px;
}
@media only screen and (min-device-width: 800px) and (max-device-width: 900px) {
  .head {
    font-size: 60px;
  }
  .head2 {
    font-size: 30px;
  }
  .page404 {
    padding-top: 8%;
    margin: -20px 0 50px;
  }
  .PageText404 {
    padding-top: 16%;
  }
  .span1 {
    font-size: 17px;
  }
}
@media only screen and (min-device-width: 650px) and (max-device-width: 800px) {
  .head {
    font-size: 30px;
  }
  .head2 {
    font-size: 23px;
  }
  .page404 {
    padding-top: 8%;
    margin: -20px 0 50px;
  }
  .PageText404 {
    padding-top: 16%;
  }
  .span1 {
    font-size: 12px;
    line-height: 20px;
  }
}
@media only screen and (min-device-width: 550px) and (max-device-width: 650px) {
  .head {
    font-size: 24px;
  }
  .head2 {
    font-size: 16px;
    padding-top: 10% !important;
  }
  .page404 {
    padding-top: 8%;
    margin: -20px 0 50px;
  }
  .PageText404 {
    padding-top: 1%;
  }
  .span1 {
    font-size: 9px;
    line-height: 20px;
  }

}
@media only screen and (min-device-width: 100px) and (max-device-width: 550px) {
  .head {
    font-size: 19px;
  }
  .head2 {
    padding-top: 15% !important;
    font-size: 20px !important;
  }
  .page404 {
    padding-top: 8%;
    margin: -20px 0 50px;
  }
  .PageText404 {
    padding-top: 1%;
  }
  .span1 {
    font-size: 8px;
    line-height: 15px;
  }
  .pri {
    /* margin-top: -20px !important; */
  }
}

.badgeFileType {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding:3px;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: -7px;
  margin-top: 10px;
  font-weight: 100;
  font-size: 13px;
  border-radius:6px;
  text-transform: capitalize !important;
  background-color: rgb(233, 233, 233);
  color: #828282;
  
}
.badgeFileType1 {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding:3px;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: -7px;
  margin-top: 10px;
  font-weight: 100;
  font-size: 13px;
  border-radius: 6px;
  text-transform: capitalize !important;
  background-color: rgb(140, 140, 140);
  color: #ffffff;
  
}
.fileTypeMenuPro{
  /* background-color: rgba(246, 246, 246, 0.692); */

 margin-top: -10px;
 /* margin-top: 10px; */
  border-radius: 5px;
  padding:5px;
  padding-top:15px;
  padding-left:15px;

  width: 10px;
  height: 10px;
  right:-20px;
  position: static;
  background-color: rgba(231, 228, 228, 0.692);
 
}
.fileTypeMenuPro1{
  
  margin-left:-10px;
  margin-top:-35px;
}
.fileTypeMenuPro:hover{
  background-color: rgba(193, 193, 193, 0.692);
 
}
.fileType {
  position: relative;
  display: block;
  width: 120px;
  margin-right:9%;
  margin-left:9%;
  height: 140px;
  border-radius: 10px;
  background: #ccc;
  color: #888;
  font: bold 30px/160px Verdana;
  text-align: center;
  text-transform: uppercase;
}
.fileType:before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 40px;
  height: 40px;
  border-bottom-left-radius: 10px;
  background: #aaa;
}
.fileType:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 40px 40px 0;
  /* change #fff to color of your background. This doesn't work on non-solid backgrounds, but it's a trade-off for compatibility's sake. If you don't care about that, then you can get rid of this psuedo-element and use angled background gradients fading to transparent instead. */
  border-color: transparent #fff transparent transparent;
}

a.fileType {
  text-indent: 180px;
  text-decoration: none;
  overflow: visible;
  white-space: nowrap;
}

a.fileType:before {
  content: attr(data-extension);
  text-indent: -90px;
  /* IE8 hack, I really suggest using Paul Irish's conditional HTML classes instead: http://www.paulirish.com/2008/conditional-stylesheets-vs-css-hacks-answer-neither */
  text-indent: -55px\9;
}
.cardContract{
  background-color: #fff;
  padding-top: 3%;
  padding-bottom: 3%;
  margin-top: 2%;
  margin-bottom: 5%;
  border-radius: 8px;
  margin-right: 3%;
  margin-left: 3%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contractContainer {
  margin-right: 4%;
  margin-left: 4%;
  margin-top: 0px;
}
.contractCard {
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
}
.paymentModalColum {
  width: 100%;
  background-color: #f7f7f796;
  border: solid 1px #e1e1e1;
  padding: 10px;
}
.contractCard2 {
  background-color: rgb(244, 244, 244);
  border-radius: 15px;
  margin-bottom: 30px;
}
.contractCardDark {
  background-color: rgb(75, 75, 75);
  border-radius: 15px;
  margin-bottom: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
}
.contractImage {
  height: 50px;
}
.contractDivider {
  border-bottom: 1px solid rgba(214, 214, 214, 0.418);
}
.contractDivider2 {
  border-top: 1px solid rgba(153, 153, 153, 0.336);
}
.contractDivider3 {
  border-bottom: 2px solid rgba(95, 95, 95, 0.336);
}
.bg-gray {
  background-color: rgb(231, 231, 231);
}
.agreePayment {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}
.badge02 {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 500;
  background-color: rgb(231, 231, 231);
  color: #ff4d2d;
}
.badge03 {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 800;
  background-color: rgb(231, 231, 231);
  color: #ff4d2d;
}
.badge04 {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  padding-right: 15px;
  padding-left: 15px;
  font-weight: 800;
  background-color: rgb(255, 255, 255);
  color: #ff4d2d;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.badge05 {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 800;
  background-color: rgba(241, 239, 239, 0.514);
  border:1px solid rgba(222, 221, 221, 0.496);
  color: #323232;
  box-shadow: rgba(100, 100, 111, 0.052) 0px 7px 29px 0px;

}
.badge06 {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 800;
  background-color: rgba(210, 210, 210, 0.604);
  border:1px solid rgba(192, 190, 190, 0.492);
  color: rgb(32, 32, 32);
  box-shadow: rgba(100, 100, 111, 0.052) 0px 7px 29px 0px;

}
.badge07 {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 800;
  background-color: rgba(255, 255, 255, 0.604);
  border:1px solid rgba(192, 190, 190, 0.492);
  color: rgb(32, 32, 32);
  box-shadow: rgba(100, 100, 111, 0.112) 0px 7px 29px 0px;
}
.contractText {
  font-family: "Montserrat", sans-serif;
  color: #807e7e;
  letter-spacing: 0.5px;
  /* line-height: 5px; */
  font-size: 15px;
  padding-top: 3px;
  margin: 0px;
}
.contractTextDark {
  font-family: "Montserrat", sans-serif;
  color: #fafafa;
  letter-spacing: 0.5px;
  /* line-height: 15px; */
  font-size: 15px;
  padding-top: 3px;
  margin: 0px;
}
.contractStatus {
  text-align: "center";
  padding: 7px;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  margin-bottom: 10px;
}

.agreementDark {
  border-radius: 20px;
  border: solid 1px #ffffff;
  background-color: transparent;
  color: #ffffff;

  font-size: 17px;
  font-weight: 500;
  padding: 5px 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}
.agreementDark:hover {
  color: #7b7b7b;
  border: solid 1px #fff;
  background-color: #fff;
}
.listButtonIcon {
  border-radius: 29px;
  border: solid 3px #f2f2f2;
  background-color: #eb6658;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  padding: 3px 5px;
  width: 45px;
  height: 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}
.listButtonIcon:hover {
  border: solid 3px #ffffff;
  background-color: #eb6658;
  color: #fff;
}

.listButtonIconDisable {
  border-radius: 29px;
  border: solid 3px #ffffff;
  background-color: #c0c0c0;
  color: #ffffff;
  font-size: 17px;
  font-weight: 500;
  padding: 3px 5px;
  width: 45px;
  height: 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}
.listButtonIconDisable:hover {
  cursor: not-allowed;
}
.agreement {
  border-radius: 20px;
  border: solid 1px #eb6658;
  background-color: #eb6658;
  color: #fff;

  font-size: 17px;
  font-weight: 500;
  padding: 5px 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}
.agreementDisable {
  border: solid 1px #a79e9d;
  background-color: transparent;
  color: #a79e9d;
  cursor: not-allowed !important;
}
.roundButton {
  border-radius: 20px;
  border: solid 1px #ff4b2b;
  background-color: transparent;
  color: #ff4b2b;
  font-size: 17px;
  font-weight: 500;
  padding: 3px 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  width: 35px;
  height: 35px;
}
.roundButton:hover {
  color: #fff;
  border: solid 1px #ff4b2b;
  background-color: #ff4b2b;
}
.roundButtonActive {
  border-radius: 20px;
  color: #fff;
  border: solid 1px #ff4b2b;
  background-color: #ff4b2b;
  font-size: 17px;
  font-weight: 500;
  padding: 3px 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  width: 35px;
  height: 35px;
}

.roundButtonDisable {
  border-radius: 20px;
  border: solid 1px #b8b8b8;
  background-color: transparent;
  color: #b8b8b8;
  font-size: 17px;
  font-weight: 500;
  padding: 3px 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  width: 35px;
  height: 35px;
}

.roundButtonDark {
  border-radius: 20px;
  border: solid 1px #ffffff;
  background-color: transparent;
  color: #ffffff;

  font-size: 17px;
  font-weight: 500;
  padding: 3px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}
.roundButtonDarkDisable {
  border-radius: 20px;
  border: solid 1px #b8b8b841;
  background-color: transparent;
  color: #b8b8b841;

  font-size: 17px;
  font-weight: 500;
  padding: 3px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}
.roundButtonDark:hover {
  color: #7b7b7b;
  border: solid 1px #fff;
  background-color: #fff;
}
.payImage {
  height: 70px;
  width: 70px;
  border-radius: 15px;
  border: solid 1px #7b7b7b;
}
.payImageAlign {
  width: 100px;
  margin-left: 30px;
  margin-left: 30px;
}
.contractListAlign {
  min-width: 180px;
}
.applicationListAlign {
  min-width: 130px;
}
.applicationListAlign2 {
  min-width: 120px;
}
.addApp {
  margin-top: -10px;
}
.appUnit {
  margin-top: 20px;
}
.applicationTenantText {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;

  font-weight: 100;
  line-height: 20px;
  letter-spacing: 1.5px;
}

.contractListImage2 {
  display: none;
}
.applicationListImage1 {
  display: -webkit-flex;
  display: flex;
}
.applicationListImage2 {
  display: none;
}
.applicationListImage3 {
  height: 250px;
  display: none;
}
@media only screen and (min-width: 850px) and (max-width: 5550px) {
.alignSmokeDetect{
  padding-right:30px;
  padding-left:30px;
}
}
@media only screen and (min-width: 850px) and (max-width:1150px) {
  .applicationListImage1 {
    display: none;
  }
  .applicationListImage2 {
    display: -webkit-flex;
    display: flex;
  }
  .applicationListImage3 {
    display: -webkit-flex;
    display: flex;
  }
}
@media only screen and (min-width: 770px) and (max-width: 850px) {
  .alignSmokeDetect{
    padding-right:20px;
    padding-left:20px;
  }
  .agreement {
    font-size: 16px;
    padding: 5px 18px;
  }
  .contractListImage1 {
    display: none;
  }
  .contractListImage2 {
    display: block;
  }
  .applicationListImage1 {
    display: none;
  }
  .applicationListImage2 {
    display: -webkit-flex;
    display: flex;
  }
  .applicationListImage3 {
    display: -webkit-flex;
    display: flex;
  }
  .contractText {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .agreement {
    margin-top: 10px;
  }
  .applicationTenantText {
    font-size: 17px;
  }
}
@media only screen and (min-width: 580px) and (max-width: 770px) {
  .paymentPlanToggleAlign {
    margin-left: -50px;
  }
  .agreement {
    font-size: 15px;
    padding: 5px 16px;
  }
  .contractListImage1 {
    display: none;
  }
  .contractListImage2 {
    display: block;
  }
  .applicationListImage1 {
    display: none;
  }
  .applicationListImage2 {
    display: -webkit-flex;
    display: flex;
  }
  .applicationListImage3 {
    display: -webkit-flex;
    display: flex;
  }
  .addApp {
    margin-top: -30px;
  }
  .contractText {
    font-size: 13px;
  }

  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .agreement {
    margin-top: 10px;
  }
  .appUnit {
    margin-top: -18px;
  }
  .appNot {
    margin-top: -13px;
  }
  .applicationTenantText {
    font-size: 15px;
    word-break: normal;
  }
  .payImageAlign2 {
    margin-left: 35px;
    margin-right: 35px;
  }
}

@media only screen and (min-width: 430px) and (max-width: 580px) {
  .paymentPlanToggleAlign {
    margin-left: -150px;
  }
  .agreement {
    font-size: 14px;
    padding: 4px 14px;
  }
  .contractListImage1 {
    display: none;
  }
  .contractListImage2 {
    display: block;
  }
  .applicationListImage1 {
    display: none;
  }
  .applicationListImage2 {
    display: -webkit-flex;
    display: flex;
  }
  .applicationListImage3 {
    display: -webkit-flex;
    display: flex;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .addApp {
    margin-top: -30px;
  }
  .contractText {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .agreement {
    margin-top: 10px;
  }
  .appUnit {
    margin-top: -18px;
  }
  .appNot {
    margin-top: -13px;
  }
  .applicationTenantText {
    font-size: 13px;
    word-break: normal;
  }
  .contractCardDetail {
    padding-right: 30px;
    padding-left: 30px;
  }
  .payImageAlign2 {
    margin-left: 35px;
    margin-right: 35px;
  }
}

@media only screen and (min-width: 100px) and (max-width: 430px) {
  .paymentPlanToggleAlign {
    margin-left: -150px;
  }
  .agreement {
    font-size: 13px;
    padding: 3px 12px;
  }
  .contractListImage1 {
    display: none;
  }
  .contractListImage2 {
    display: block;
  }
  .applicationListImage1 {
    display: none;
  }
  .applicationListImage2 {
    display: -webkit-flex;
    display: flex;
   
  }
  .applicationListImage3 {
    display: -webkit-flex;
    display: flex;
    height: 250px !important;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .contractText {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
    border: 2px solid rgb(219, 219, 219);
  }
  .addApp {
    margin-top: -30px;
  }
  .agreement {
    margin-top: 10px;
  }
  .appUnit {
    margin-top: -18px;
  }
  .appNot {
    margin-top: -13px;
  }
  .applicationTenantText {
    font-size: 11px;
    word-break: normal;
  }
  .contractCardDetail {
    padding-right: 20px;
    padding-left: 20px;
  }
  .payImageAlign2 {
    margin-left: 35px;
    margin-right: 35px;
  }
}

.Bar{
    width: 100%;
    height: 260px;
    position: relative;
    
    /* margin: 20px; */
}

.occupied{
    font-family: "Montserrat", sans-serif;
    /* font-family: 'Kanit', sans-serif; */
    /* font-family: 'Titillium Web', sans-serif; */
    /* font-family: 'Ubuntu', sans-serif; */
    font-size:40px;
    padding-left:30px;
}
.colorLine{
    border-bottom: 3px solid #E74C3C;
    opacity: 1;
   
    width:100%;   
    z-index: 6;
    position: absolute;
}
.colorBox{
    width: 100%;
    height: 100%;            
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    z-index: 1;
    border-bottom-left-radius: 15px;
    background: linear-gradient(to bottom, #cab4a9 0%, #E74C3C 100%);
    -webkit-animation: upColor 1s;
            animation: upColor 1s;
}
@-webkit-keyframes upColor{
	from{-webkit-transform: scaleY(0.5);transform: scaleY(0.5);opacity: 0.1;}
	to{-webkit-transform: scaleY(1);transform: scaleY(1);opacity: 1;}
}
@keyframes upColor{
	from{-webkit-transform: scaleY(0.5);transform: scaleY(0.5);opacity: 0.1;}
	to{-webkit-transform: scaleY(1);transform: scaleY(1);opacity: 1;}
}
@-webkit-keyframes up{
	from{-webkit-transform: scaleY(0.5);transform: scaleY(0.5);}
	to{-webkit-transform: scaleY(1);transform: scaleY(1);}
}
@keyframes up{
	from{-webkit-transform: scaleY(0.5);transform: scaleY(0.5);}
	to{-webkit-transform: scaleY(1);transform: scaleY(1);}
}
.colorChanger{
    z-index: 5;
    height: 50%;
    bottom:0px;
    border-bottom-left-radius: 0px;
    border-bottom: 3px solid rgb(221, 55, 55);
    background: white;
}
.textSize{
    font-size: 10px;
    height:4%;
    line-height: 16px;
}
.imageBox{
    width: 100%;
    height: 265px;
    border-bottom-left-radius: 15px;
    -webkit-animation: up 1s;
            animation: up 1s;
}.Box{
    z-index: 2;
    opacity: 1;
    border-radius: 25;
}
.height00{
    height: 98%;
}
.height05{
    height: 94%;
}
.height10{
    height: 90%;
}
.height15{
    height: 87%;
}
.height20{
    height: 84%;
}
.height25{
    height: 80.5%;
}
.height30{
    height: 77%;
}
.height35{
    height: 73.5%;
}
.height40{
    height: 70%;
}
.height45{
    height: 66.5%;
}
.height50{
    height: 63%;
}
.height55{
    height: 59%;
}
.height60{
    height:56%;
}
.height65{
    height: 51%;
}
.height70{
    height: 48%;
}
.height75{
    height: 44%;
}
.height80{
    height: 40%;
}
.height85{
    height: 36%;
}
.height90{
    height: 32%;
}
.height95{
    height: 28%;
}
.height100{
    height: 24%;
}
@media only screen and (min-width: 491px) and (max-width: 991px) {
    .occ{
        margin-top: 30px;
    }
    .occupied{
        font-size:40px;
    }
}
@media only screen and (min-width: 100px) and (max-width: 491px) {
    .occ{
        margin-top: 30px;
    }
    .occupied{
        font-size:30px;
    }
}
.cardHeader{
  /* background-color: #4c7faf; */
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
  
}
.card-footer{
  background-color: #f1ac7d;
}
.footerButton{
  border-radius: 60px;
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
}
.footerButton:hover{
  background-color: #584f48;
  color: white;
  cursor: pointer;
}
.dashCover{
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* border-style: solid; */
  /* border-width: 0.5px; */
  /* border-color: rgb(5, 5, 56); */
}

@media only screen and (min-width: 992px) and (max-width: 1250px) {
  .cardHeader{
    font-size: 15px;
  }
  .cardTextDash{
    font-size: 40px !important;
  }
}
@media only screen and (min-width: 700px) and (max-width: 850px) {
  .cardHeader{
    font-size: 15px;
  }
  .cardTextDash{
    font-size: 40px !important;
  }
}
@media only screen and (min-width: 100px) and (max-width: 700px) {
  .cardHeader{
    font-size: 15px;
  }
  .cardTextDash{
    font-size: 40px !important;
  }
  .square1{
    margin-top:20px;
    min-width: 160px !important;
  }
}


.cardIcon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.cartPart {
  margin-right: 1rem;
  width: 100%;
  margin-left: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-width: 21rem;
  /* max-width: 30rem; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.cartPart2 {
  /* padding-right: 0.2rem; */
  /* padding-left: 0.2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem; */
  background-color: aqua;
}
.received {
  font-size: 13px;
}
.cardSub {
  color: #888;
  font-size: 16px;
  white-space: nowrap;
  width: 100%;
  clear: both;
  display: inline-block;
  float: left;
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
 
}

.cardMain {
  color: #484f58;
  font-weight: 950;
  clear: both;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 33px;
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
}
i.round {
  display: inline-block;
  border-radius: 60px;
  padding: 1em 1em;
  color: white;
  /* background: linear-gradient(to bottom right, #5f90bb 0%, rgb(226,82,61) 100%); */
  /* background-color: #1e5185; */
  background: linear-gradient(to bottom right, #e74c3c 0%, #e27a6f 100%);
  box-shadow: 1px 1px 5px #888888;
  transition: 0.5s ease-in-out;
}
i.round:hover {
  background: linear-gradient(to bottom right, #e74c3c 0%, #e27a6f 100%);
  border-radius: 15px;
}


@media only screen and (min-width: 1148px) and (max-width: 1320px) {
  .cardSub {
    font-size: 14px;
  }
  .cardMain {
    font-size: 26px;
    font-weight: 950;

  }
}
@media only screen and (min-width:  880px) and (max-width: 1148px) {
  .cartPart{
    margin-top: 20px;
  }
}
@media only screen and (min-width:  758px) and (max-width: 880px) {
  .cartPart{
    margin-top: 20px;
  }
  .cardSub {
    font-size: 14px;
  }
  .cardMain {
    font-size: 26px;
    font-weight: 950;

  }
}
@media only screen and (min-width:  500px) and (max-width: 758px) {
  .cartPart{
    margin-top: 20px;
    
  }
 
}
@media only screen and (min-width:  300px) and (max-width: 500px) {
  .cartPart{
    margin-top: 20px;
    min-width: 18rem;
  }
 
}
.octagon {
  width: 150px;
  height: 150px;
  background: #273746;
  /* background: linear-gradient(rgb(206, 197, 197), rgb(2, 7, 15)); */
  position: relative;
}
.octagon:before {
  content: "";

  width: 150px;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 60px solid#273746;
  border-left: 60px solid #fff;
  border-right: 60px solid #fff;
}
.octagon:after {
  content: "";
  width: 150px;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 60px solid #273746;
  border-left: 60px solid #fff;
  border-right: 60px solid #fff;
}

.square {
  /* z-index: 0; */
  /* border: 5px outset #273746; */
  /* border-style: inset; */
  /* background: url("http://localhost:3000/53.png"); */
  /* border-radius: 50px; */
  /* border-color: #d2d7db; */
  /* background-color: #EBEDEF ; */
  /* background-position: 5ch -10ch; */
  /* background-attachment: fixed; */
  /* transform: perspective(150px) rotateY(10deg); */

  /* background: linear-gradient(rgba(232, 236, 245, 1), rgba(180, 187, 192, 1)), url("http://localhost:3000/3.png"); */
}
.square1 {
}
.squareStyle {
  /* background: url("http://localhost:3000/build.svg"),
linear-gradient(rgb(255, 255, 255,1), rgba(255, 255, 255, 1)); */
  /* background-position: center; */
  background-size: cover;
}
.BackStatus {
  /* background: url("http://localhost:3000/53.png"),
  linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)); */
  background:#fff;
  background-position: -27ch -4ch;
}
.square:hover {
  /* opacity: 1; */
  /* background: linear-gradient(to bottom, #b6bece 0%, #4d78a0 100%); */
  /* background: url("http://localhost:3000/7.png"); */
  /* background-image: none; */
  /* background-color: #273746; */
  /* transform-origin: 0px 0px; */
  /* animation: boro 0.001s both linear; */
  /* z-index: 5; */
}
.round1 {
  background-color: #82e0aa;
  border-radius: 20px;
  background: linear-gradient(to bottom right, #f7f7f7 0%, #f7f7f7 100%);
}
.round1:hover {
  /* padding-top: 5px;
  background: #d16356;
  background-color: #e74d3c38;
  border: 1px solid #f39f96; */
}
.square1 .textGreen {
  background-color: #82e0aa;
  color: #fff;
}
.square1:hover .textGreen {
  background-color: #fff;
  color: #82e0aa;
}
.square1 .textBlue {
  background-color: #5dade2;
  color: #fff;
}
.square1:hover .textBlue {
  background-color: #fff;
  color: #5dade2;
}
.square1 .textRed {
  background-color: #df978c;
  color: #fff;
}
.square1:hover .textRed {
  background-color: #fff;
  color: #ec7063;
}
.square1 .textPurple {
  background-color: #c39bd3;
  color: #fff;
}
.square1:hover .textPurple {
  background-color: #fff;
  color: #c39bd3;
}
.square1 .textGray {
  background-color: #abb2b9;
  color: #fff;
}
.square1:hover .textGray {
  background-color: #fff;

  color: #abb2b9;
}
.textGreen:hover {
  color: #82e0aa;
  color: #ff9082f5;
  cursor: pointer;
}
.textBlue:hover {
  color: #5dade2;
  color: #ff9082f5;
  cursor: pointer;
  /* -webkit-text-stroke: 0.5px white; */
}
.textRed:hover {
  color: #ff9082f5;
  cursor: pointer;
}
.textPurple:hover {
  /* color: #C39BD3; */
  color: #ff9082f5;
  cursor: pointer;
}
.textGray:hover {
  color: #abb2b9;
  color: #ff9082f5;
  cursor: pointer;
}

.bgGreen {
  border-color: #82e0aa;
}
.bgBl {
  border-color: #5dade2;
}

.bgRed {
  border-color: #ec7063;
}
.bgPurple {
  border-color: #c39bd3;
}
.bgGray {
  border-color: #808b96;
}
.cardIconDash {
  font-size: 50px;
  z-index: 5;
  position: relative;

  /* color: #fff; */
}
.cardTextDash {
  /* color: #fff; */
  z-index: 5;
  font-size: 50px;
  position: relative;
}
.innerSquare {
  /* background-color: #fff; */
  width: 100%;
}
@-webkit-keyframes boro {
  to {
    -webkit-transform: perspective(0px) rotateY(-20deg);
            transform: perspective(0px) rotateY(-20deg);
  }
  from {
    -webkit-transform: perspective(200px) rotateY(20deg);
            transform: perspective(200px) rotateY(20deg);
  }
}
@keyframes boro {
  to {
    -webkit-transform: perspective(0px) rotateY(-20deg);
            transform: perspective(0px) rotateY(-20deg);
  }
  from {
    -webkit-transform: perspective(200px) rotateY(20deg);
            transform: perspective(200px) rotateY(20deg);
  }
}
.scaled-square {
  -webkit-transform: scaleX(20deg) /* flip "up" */ scaleY(-20deg) /* flip "right" */
    scaleZ(20deg);
          transform: scaleX(20deg) /* flip "up" */ scaleY(-20deg) /* flip "right" */
    scaleZ(20deg); /* rotate counter-clockwise */
}

.scaled-square-shorthand {
  -webkit-transform: scale3d(20deg, -20deg, 20deg);
          transform: scale3d(20deg, -20deg, 20deg);
}
@media only screen and (min-device-width: 250px) and (max-device-width: 319px) {
}
@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .square {
    margin-top: 30px;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 480px) {
  .square {
    margin-top: 30px;
  }
}
@media only screen and (min-device-width: 479px) and (max-device-width: 767px) {
  .square {
    margin-top: 80px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .square {
    margin-right: 30px;
    margin-top: 30px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .square {
    margin-right: 30px;
    margin-top: 30px;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1320px) {
  .square {
    margin-right: 0px;
    margin-top: 30px;
  }
}
@media only screen and (max-device-width: 3000px) and (min-device-width: 1024px) {
  .square {
    margin-left: 10px;
  }
  .firstSquare {
  }
}

@media only screen and (max-width: 1024px) and (min-width: 980px) {
  .square {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 980px) and (min-width: 800px) {
  .square {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 800px) and (min-width: 650px) {
  .square {
    margin-right: 50px;
  }
}
@media only screen and (max-width: 650px) and (min-width: 500px) {
  .square {
    margin-left: 80px;
    margin-top: 90px;
  }
}
@media only screen and (max-width: 500px) and (min-width: 360px) {
  .square {
    margin-left: 80px;
    margin-top: 60px;
  }
}
@media only screen and (max-width: 360px) and (min-width: 200px) {
  .square {
    margin-left: 40px;
    margin-top: 50px;
  }
}

.navItemSearch1 {
  height: 3rem;
  width: 3rem;
  margin-left: 30px;
  padding-top: 5px;
}
.searchInsideButton1 {
  border-radius: 25px;
  float: left;
  background-color: #eb6658;
  height: 100%;
  color: #ffffff;
  /* position:absolute; */
  margin-left: -15px;
}
.show {
  display: block;
}
.hide {
  display: none;
}
.searchBoxOpen {
  width: 19rem;
  border: none;
  border-radius: 25px;
  /* padding-left: 2rem; */
  padding-right: 0px;
  -webkit-flex-grow: 2;
          flex-grow: 2;
}
.searchForm1 {
  width: 16rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  border: 1px solid grey;
  border-radius: 25px;
  border-width: 40;
  color: #000;
  padding: 1px;
  margin-right: 20px;
}

.searchInput2 {
  border: none;
  border-radius: 25px;
  border: 20px;
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
}


@media only screen and (min-width: 425px) and (max-width: 745px) {
  .searchBoxOpen {
    width: 15rem;
  }
  .searchInput2 {
    font-size: 10px;
  }
  .searchBoxOpen {
    width: 15rem;
  }
  .navItemSearch1 {

    margin-left: 30px;

  }
  .searchInsideButton1 {
    margin-left: 42px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 425px) {
  .searchBoxOpen {
    width: 12rem;
  }
  .searchInput2 {
    font-size: 10px;
  }
  .searchBoxOpen {
    width: 12rem;
  }
  .navItemSearch1 {

    margin-left: 30px;

  }
  .searchInsideButton1 {
    margin-left: -22px;
  }
  .searchForm1 {
 
    margin-left: 14px;
  
  }
  .searchInput2{
    padding-left: 20px;
  }
  
}
.card__image {
    height: 160px;
    width: 160px;
    border-radius: 50%;
    border: 5px solid #244d74;
    box-shadow: 0 3px 15px rgb(83, 130, 172);
   
  }
  .detailsCard{
    border-radius: 15px;
    width:500px;
    /* background: linear-gradient(to bottom right, #feffff 0%, #aabdcf 100%); */
    background-color: #fff;
  } 
  .detailsCard1{
    border-radius: 15px;
    width:250px;
    /* background: linear-gradient(to bottom right, #feffff 0%, #aabdcf 100%); */
    background-color: #fff;
  } 
  .detailsText{
    white-space: nowrap;
    color:#26445f;
    font-weight: 1500;
  }
.tableHead {
    white-space: nowrap;
  }
  .rowTable {
    width: 100%;
    text-align: left;
    display: inline-table;
    /* white-space: nowrap; */
    /* table-layout: fixed; */
  }
  
  .tableMaintain {
    text-align: left;
    /* height: 380px; */
  }
  thead {
    overflow-y: scroll;
    width: 100%;
  }
  tbody {
    /* overflow-y: scroll; 
    width: 100%;     
    height: 500px;            
    position: absolute; */
  }
  .tableYAxis {
  }
  /* width */
  .table::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .table::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .table::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .table::-webkit-scrollbar-thumb:hover {
    background: rgb(85, 85, 85);
  }
.tableHead {
    white-space: nowrap;
  }
.rowTable{
    width: 100%;
    text-align: left;
    display: inline-table;
    /* white-space: nowrap; */
    /* table-layout: fixed; */
  }
  
  .headName{
    text-align: left;
    color:rgb(243, 243, 243) !important;
    font-family: 'Montserrat', sans-serif;
  }
  .tableRental {
    height: 510px;
  }
  thead {
    overflow-y: scroll;
    width: 100%;
    background-color: #eb6658 ;
  }
  tbody {
    /* overflow-y: scroll; 
    width: 100%;     
    height: 500px;            
    position: absolute; */
  }
  .tableYAxis {
  }
  /* width */

  .ratingName{
    min-width:180px !important;
    /* display: ; */
    /* overflow: scroll !important; */
  }
  .table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  .table::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .table::-webkit-scrollbar-thumb {
    background: #aca9a8;
  }
  
  /* Handle on hover */
  .table::-webkit-scrollbar-thumb:hover {
    background: #FF4B2B;
  }
.headName {
  color: black;
  /* transform: rotate(-90deg); */
}
thead {
  background-color: #eb6658;
}
td {
  margin: 0ch !important;
}
.tableRating {
  height: 350px;
}
.ratingName {
  width: 20%;
}
.ratingFinal {
  width: 2%;
}

.boxx {
  height: 20px;
  width: 20px;
}

.excellent {
  /* background-color: #f5da6f; */
  background-color: #1a793a;
  /* background-color: #0e2652; */
}

.good {
  /* background-color: #ecb041; */
  background-color: #43dd43;
  /* background-color: #11589e; */
}

.ok {
  background-color: #f5f83f;
  /* background-color: #2180e0; */
}
.bad {
  /* background-color: #fca242; */
  background-color: #ff0000;
}
.veryBad {
  background-color: #ff0000;
  /* background-color: #cce0ff; */
}
.non {
  background-color: #fff;
  /* border: 1px solid black; */
}
.non1 {
  background-color: rgb(255, 254, 254);
  border: 1px solid rgb(199, 195, 195);
}
.vertical {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}


@media only screen and (min-width: 655px) and (max-width: 885px) {
  td {
   font-size: 13px;
  }
  .headName{
    font-size: 13px;
  }
}
@media only screen and (min-width: 425px) and (max-width: 655px) {
  td {
   font-size: 11px;
  }
  .headName{
    font-size: 12px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 425px) {
  td {
   font-size: 10px;
  }
  .headName{
    font-size: 10px;
  }
}
.card_unit__image {
    /* height: 160px;
    width: 160px; */
    width:100%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    /* border: 1px solid #eb6658; */
    /* box-shadow: 0 3px 15px #dd6e62; */
    max-height:200px;
    max-width:200px;
  }
  .detailsCard{
    border-radius: 15px;
    width:500px;
    /* background: linear-gradient(to bottom right, #feffff 0%, #aabdcf 100%); */
    background-color: #fff;
  } 
  .detailsCard1{
    border-radius: 15px;
    /* width:250px; */
    /* background: linear-gradient(to bottom right, #feffff 0%, #aabdcf 100%); */
    background-color: #fff;
  } 
  .detailsCard2{
   
    box-shadow: 0 3px 15px #dd6e62;
    border: 1px solid #eb6658;
    
  } 
  .detailsText{
    white-space: nowrap;
    color:#26445f;
    font-weight: 1500;
  }
  .textPri {
    color: #ff4b2b;
  }
  .availableTableHead2{
    font-family: "Montserrat", sans-serif;
    text-decoration: underline;
    color:#000;
    font-size: 12px;
  }

  @media only screen and (min-width: 225px) and (max-width: 445px) {
    .detailsCard2{
      max-width: 280px;
    }
    .availableTableHead2{
      font-size: 12px;
    }
    .detailsText2{
      font-size: 12px;
    }
  }
 
.propertyCardPro{
    border-radius: 15px;
    height:100%;
    width:100%;
    min-width:305px;
   max-width:375px;
  
   
  
   background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    /* background-color: black; */
}
.propertyCardProAlign{
    max-width:375px;
}
.viewListingButton {
    display: none;
    position: absolute;
    margin-top: 140px;
    border: solid 1px #fff;
    background-color: #ff4b2b91;
   
    color: #fff;
    cursor:pointer;
    border-radius: 25px;
    box-shadow: rgba(255, 255, 255, 0.438) 0px 0.0625em 0.0625em, rgba(255, 255, 255, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.344) 0px 0px 0px 1px inset;
}
 .propertyCardPro:hover .viewListingButton{
    display: -webkit-flex;
    display: flex;
    
}
.property-avatar{
    width: 60px;
    height: 60px;
    border-radius: 100px;
}
.propsMenuPro{
    /* background-color: rgba(246, 246, 246, 0.692); */

   margin-top: -5px;
   /* margin-top: 10px; */
    border-radius: 10px;
    padding:18px;
    width: 20px;
    height: 20px;
    right:-20px;
    position: absolute;
}
.propsMenuPro1{
    position: absolute;
    margin-left:-10px;
    margin-top:-10px;
}
.propsMenuPro:hover{
    
    background-color: rgba(231, 228, 228, 0.692);
}
.propsAddress{
    font-family: 'Montserrat', sans-serif;
    color:#555555;
    font-size: 13px;
    line-height: 13px;
}
.propsAddressHead{
    font-family: 'Montserrat', sans-serif;
    color:#555555;
    font-size: 13px;
    font-weight:800;
    line-height: 13px;
}
.propsContent{
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    color:rgb(134, 128, 128);
}
.propsContent2{
    font-family: 'Montserrat', sans-serif;
    color:rgb(134, 128, 128);
    font-size: 12px;
}
.propCoverContentPro{
    
    border: 0px solid #fff;
     border-bottom: 1px solid rgb(225, 225, 225);
    -moz-box-shadow:    inset 0 0 20px #bcbfc0;
    /* -webkit-box-shadow: inset 0 0 20px #bcbfc0; */
    /* box-shadow:         inset 0 -1px 10px #bcbfc0; */
}
.propCoverContentPro1{
    padding-Top :10px;
    font-size: 14px;
    color:#555555;
}
.click{
    cursor: pointer;
}
.propsImagePro{
    width: 100%;
    object-fit: cover;
    display: block;
  margin-left: auto;
  margin-right: auto;
  height:300px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.propertyCardPro:hover .propCoverContentPro{
    
    -webkit-animation: return2 0.4s linear 1;
    
            animation: return2 0.4s linear 1;
    opacity: 1;
    height:85px;
}
.propertyCardPro .propCoverContentPro1{
    
    /* display:none; */

}
.propertyHead{
   
}
.propFooterButton{
   
    
}
.propertyCardPro:hover .propCoverContentPro1{
    
    /* display:block; */

}
.propertyCardPro .propCoverContentPro{
    
    -webkit-animation: turn3 0.4s linear 1;
    
            animation: turn3 0.4s linear 1;
    opacity: 0;
    height:0px;
   
}

@-webkit-keyframes turn3 {
    0% {
        height:85px;
        opacity: 1;
    }
    30%{
        opacity: 0.2;
       }
    100% {
        opacity: 0;
        height:0px;
    }
  }

@keyframes turn3 {
    0% {
        height:85px;
        opacity: 1;
    }
    30%{
        opacity: 0.2;
       }
    100% {
        opacity: 0;
        height:0px;
    }
  }
  @-webkit-keyframes return2 {
    0% {
        height:0px;
        opacity: 0;
    }
   70%{
    opacity: 0.4;
   }
    100% {
        opacity: 1;
        height:85px;
    }
  }
  @keyframes return2 {
    0% {
        height:0px;
        opacity: 0;
    }
   70%{
    opacity: 0.4;
   }
    100% {
        opacity: 1;
        height:85px;
    }
  }
.propertyCardPro:hover .propsImagePro{
    -webkit-animation: return3 0.4s linear 1;
            animation: return3 0.4s linear 1;
    height:300px;
}
.propertyCardPro .propsImagePro{
    -webkit-animation: turn2 0.4s linear 1;
            animation: turn2 0.4s linear 1;
    height:385px;
}
@-webkit-keyframes turn2 {
    0% {
        height:300px;
    }
    /* 50% {
        height:335px;
    } */
    100% {
        height:385px;
    }
  }
@keyframes turn2 {
    0% {
        height:300px;
    }
    /* 50% {
        height:335px;
    } */
    100% {
        height:385px;
    }
  }
  @-webkit-keyframes return3 {
    0% {
        height:385px;
    }
    /* 50% {
        height:335px;
    } */
    100% {
        height:300px;
    }
  }
  @keyframes return3 {
    0% {
        height:385px;
    }
    /* 50% {
        height:335px;
    } */
    100% {
        height:300px;
    }
  }
  .dropdown-item:hover{
    background-color:rgb(228, 228, 228);
    color:rgb(0, 0, 0);
    
  }
  .propertyCardAlign{
      min-width: 300px;
      max-width: 400px;
    margin-right:3px;
    margin-left:3px;
 }

  @media only screen and (min-width: 530px) and (max-width: 650px) {

    .propsAddress{
        font-size: 12px;
    }
    .propertyCardAlign{
       margin-right:3px;
       margin-left:3px;
    }
  }
  @media only screen and (min-width: 400px) and (max-width: 530px) {

    .propsAddress{
        font-size: 12px;
    }
    .propertyCardAlign{
       margin-right:3px;
       margin-left:3px;
    }
  }
  @media only screen and (min-width: 100px) and (max-width: 400px) {

    .propsAddress{
        font-size: 12px;
    }
    .propertyCardAlign{
       margin-right:1px;
       margin-left:1px;
    }
  }

.paymentHead {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}
.recentPayment{
  border-color: #F3F4F5;
  background-color:#fff;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.paymentDashCardMobile {
  background-color: rgb(255, 255, 255);
  border-radius: 28px;
  padding-top: 20px;
  padding-bottom: 10px;
  /* min-width: 400px; */
  margin-left: 15px;

  display: none;
  margin-right: 15px;
  margin-bottom: 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.paymentDashCard {
  background-color: rgb(255, 255, 255);
  border-radius: 28px;
  padding-top: 20px;
  padding-bottom: 10px;
  /* min-width: 400px; */
  margin-left: 15px;
  max-width: 300px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  margin-right: 15px;
}
.paymentDashCardCenterMain {
  margin: 0px;
  padding: 0px;
  
}
.paymentDashCardCenter {
  background-color: rgb(255, 255, 255);
  border-radius: 28px;
  border-top-left-radius: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1500;
  box-shadow: 0px 15px 15px rgb(242, 242, 242), 0px -15px 15px rgb(241, 241, 241);
}
hr.new {
  border-top: 1px dashed #e1e1e1e0;
}
.paymentText1 {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin: 5px;
  font-weight: 600;
}
.paymentText2 {
  font-family: "Montserrat", sans-serif;
  margin: 5px;
  font-size: 13px;
}
.paymentText3 {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  margin: 5px;
  font-weight: 600;
}
.paymentText4 {
  font-family: "Montserrat", sans-serif;
  margin: 5px;
  font-size: 17px;
}
.paymentImage {
  height: 30px;
  width: 30px;
}
.roundPaymentProfile {
  border-radius: 30px;
  border: solid 1px rgb(181, 181, 181);
  color: rgb(0, 0, 0);
  font-size: 11px;
  padding-top: 6px;
  text-align: center;
  width: 30px;
  height: 30px;
}
.roundPaymentProfileBudge {
  border-radius: 30px;
  color: rgb(0, 0, 0);
  font-size: 11px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  height: 32px;
  padding-left: 16px;
  padding-top: 0px;
  color: #fff;
  background-color: #f46f57;
}
.roundPaymentProfileButton {
  border-radius: 30px;

  color: rgb(0, 0, 0);
  font-size: 15px;
  padding-top: 2px;
  margin-left: 6px;
  margin-right: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: #fff;

  text-align: center;
  width: 26px;
  height: 26px;

  font-weight: normal !important;
  font-family: "Montserrat", sans-serif;
}
.roundPaymentProfileButton:hover {
  background-color: #000;
  color: rgb(255, 255, 255);
  cursor: pointer;
}
#chartdiv {
  width: 100%;
  height: 400px;
}
.AppPayment {
  background-color: rgba(179, 166, 166, 0.542);
}
.paymentProfile {
  background-color: #fff;
  margin-top: 100px;
  margin-bottom: 5%;
  z-index: 5;
  border-radius: 25px;
  width: 90%;
  padding-bottom: 1%;
}
.paymentProfileImage {
  z-index: 5;
  border-radius: 50%;
  margin-top: -100px;
  /* border: 1rem solid #fff; */
}
.paymentProfileDetails {
  z-index: 1;
  border-radius: 25px;
  /* background-color:  #fff; */
}
.round {
  border-radius: 25px;
}
.paymentDetails {
  z-index: 1;
  border-radius: 25px;
  /* background-color: #fff; */
}
.payeeName {
  font-family: "Montserrat", sans-serif;
}
.loginStripe {
  background-color: #6495ed;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}
.loginStripe:hover {
  background-color: #2e86c1;
  text-decoration: none;
  color: #fff;
}
.payOut {
  background-color: #52be80;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}
.payOut:hover {
  background-color: #138d75;
  text-decoration: none;
  color: #fff;
}
.profileText {
  font-family: "Montserrat", sans-serif;
}
.profileContentText {
  font-family: "Montserrat", sans-serif;
}
.informationListAppNormal {
  margin-top: 50px;
}
.recentPaymentTableMobile{
  display:none;
}
.recentPaymentTableWeb{
  display:block;
}
@media only screen and (min-width: 1100px) and (max-width: 1230px) {
  .stripeBalance{
    display:none;
  }
}
@media only screen and (min-width: 630px) and (max-width: 1100px) {
  .recentPaymentTableMobile{
    display:block;
  }
  .recentPaymentTableWeb{
    display:none;
  }
  .paymentDashCard {
    display: none;
  }
  .paymentDashCardCenterMain {
    margin-right: 20px;
    margin-left: 20px;
  }
  .paymentDashCardMobile {
    display: block;
  }
}

@media only screen and (min-width: 550px) and (max-width: 630px) {
  .recentPaymentTableMobile{
    display:block;
  }
  .recentPaymentTableWeb{
    display:none;
  }
  .stripeBalance{
    display:none;
  }
  .informationListAppNormal {
    min-width: 440px;
    margin-top: 10px;
  }
  .paymentDashCard {
    min-width: 350px;
    display: none;
  }
  .paymentDashCardMobile {
    display: block;
    min-width: 350px;
  }
  .paymentDashCardCenterMain {
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 440px) and (max-width: 550px) {
  .recentPaymentTableMobile{
    display:block;
  }
  .recentPaymentTableWeb{
    display:none;
  }
  .stripeBalance{
    display:none;
  }
  .stripeBalance2{
    display:none;
  }
  .informationListAppNormal {
    min-width: 340px;
    margin-top: 10px;
  }
  .paymentDashCard {
    min-width: 350px;
    display: none;
  }
  .paymentDashCardCenterMain {
    margin-right: 10px;
    margin-left: 10px;
  }
  .paymentDashCardMobile {
    display: block;
    min-width: 350px;
  }
}

@media only screen and (min-width: 350px) and (max-width: 440px) {
  .recentPaymentTableMobile{
    display:block;
  }
  .recentPaymentTableWeb{
    display:none;
  }
  .stripeBalance{
    display:none;
  }
  .stripeBalance2{
    display:none;
  }
  .informationListAppNormal {
    min-width: 250px;
    margin-top: 10px;
  }
  .paymentDashCard {
    min-width: 350px;
    margin-left: 10px;
    margin-right: 10px;
    display: none;
  }
  .paymentDashCardCenterMain {
    margin-right: 10px;
    margin-left: 10px;
  }
  .paymentDashCardMobile {
    display: block;
    min-width: 350px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 350px) {
  .recentPaymentTableMobile{
    display:block;
  }
  .recentPaymentTableWeb{
    display:none;
  }
  .stripeBalance{
    display:none;
  }
  .stripeBalance2{
    display:none;
  }
  .informationListAppNormal {
    min-width: 180px;
    margin-top: 10px;
  }
  .paymentDashCard {
    min-width: 350px;
    margin-left: 5px;
    margin-right: 5px;
    display: none;
  }
  .paymentDashCardCenterMain {
    margin-right: 5px;
    margin-left: 5px;
  }
  .paymentDashCardMobile {
    display: block;
    min-width: 350px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.default {
	 background-color: #5e5e5e;
	 cursor: pointer;
}
 .default:hover {
	 background-color: #a3a3a3;
}
 .delete:hover {
	 background-color: #e96868;
	 cursor: pointer;
}

 .font-normal {
	 font-family: "Montserrat", sans-serif;
}
 .font-number {
	 font-family: "Play", sans-serif;
}
 .font-logo {
	 font-family: "Montserrat", sans-serif;
}
 .cardContainerAlign {
	 min-width: 395px;
	 max-width: 455px;
}
 .card1 {
	 padding: 0px;
	 height: 270px;
	 z-index: 100;
	 border: none;
	 position: relative;
	 background: transparent;
}
 .card1__container {
	 position: relative;
	 top: 0;
	 left: 0;
	 -webkit-transform: translate(-50%, -50%);
	         transform: translate(-50%, -50%);
	 width: 370px;
	 height: 240px;
}
 .card1__extra {
	 position: absolute;
	 top: 36%;
	 right: 20px;
	 font-size: 26px;
	 -webkit-transform: rotate(90deg);
	         transform: rotate(90deg);
	 color: #101010;
}
 .card1__front {
	 width: 370px;
	 height: 240px;
	 border-radius: 15px;
	 overflow: hidden;
	 box-shadow: 0 0 38px 4px rgba(0, 0, 0, 0.2);
	 position: absolute;
	 z-index: 22;
	 -webkit-animation-name: cardFront;
	         animation-name: cardFront;
	 -webkit-animation-duration: 1s;
	         animation-duration: 1s;
	 -webkit-animation-delay: 1s;
	         animation-delay: 1s;
	 -webkit-animation-fill-mode: forwards;
	         animation-fill-mode: forwards;
}
 .card1__back {
	 width: 370px;
	 height: 240px;
	 border-radius: 15px;
	 overflow: hidden;
	 box-shadow: 0 0 38px 4px rgba(0, 0, 0, 0.2);
	 position: absolute;
	 background-color: #ff4b2b;
	 background-image: linear-gradient(135deg, #f3a775 25%, #ff4b2b 100%);
	 z-index: 11;
	 -webkit-animation-name: cardBack;
	         animation-name: cardBack;
	 -webkit-animation-duration: 1s;
	         animation-duration: 1s;
	 -webkit-animation-delay: 1s;
	         animation-delay: 1s;
	 -webkit-animation-fill-mode: forwards;
	         animation-fill-mode: forwards;
}
 .card1__back--bottom {
	 display: -webkit-flex;
	 display: flex;
	 -webkit-justify-content: space-between;
	         justify-content: space-between;
	 width: 88%;
	 margin: 7px auto;
	 position: absolute;
	 bottom: 0;
	 left: 50%;
	 -webkit-transform: translateX(-50%);
	         transform: translateX(-50%);
	 font-size: 10px;
	 color: #ffa44b;
}
 .card1__back--shape {
	 position: absolute;
	 background: #101010;
	 width: 160px;
	 height: 500px;
	 -webkit-transform: rotate(84deg);
	         transform: rotate(84deg);
	 left: 55px;
	 border-radius: 27%;
	 border: 4px solid #8d8b86;
	 box-shadow: 0 0 1px 5px #101010;
	 top: 13px;
	 z-index: -1;
}
 .card1__contact {
	 display: -webkit-flex;
	 display: flex;
	 -webkit-justify-content: space-between;
	         justify-content: space-between;
	 -webkit-align-items: center;
	         align-items: center;
	 border-bottom: 1px solid black;
}
 .card1__contact--txt {
	 font-size: 9px;
	 font-weight: 700;
	 padding: 7px 20px;
	 color: #fff;
	 text-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
}
 .card1__strip {
	 width: 100%;
	 height: 45px;
	 background: #131313;
	 margin: 4px 0;
}
 .card1__company--address {
	 font-size: 8px;
	 margin-top: 15px;
	 margin-left: 20px;
}
 .card1__company--msg {
	 font-size: 8px;
	 margin-top: 2px;
	 margin-left: 20px;
}
 .card1__cvc {
	 display: -webkit-flex;
	 display: flex;
	 -webkit-align-items: center;
	         align-items: center;
	 margin-top: 10px;
	 margin-left: 20px;
}
 .card1__cvc--strip {
	 width: 200px;
	 background: #d3d3d3;
	 height: 45px;
	 position: relative;
	 overflow: hidden;
	 display: -webkit-flex;
	 display: flex;
	 -webkit-align-items: center;
	         align-items: center;
	 -webkit-justify-content: space-around;
	         justify-content: space-around;
}
 .card1__cvc--txt {
	 background: wheat;
	 margin-left: 6px;
	 padding: 2px 6px;
	 font-style: italic;
	 font-weight: 700;
	 color: #000;
	 font-size: 16px;
}
 .card1__cvc--num {
	 font-size: 16px;
	 font-weight: 600;
	 color: black;
}
 .card1__cvc--warning {
	 font-size: 5px;
	 margin-left: 25px;
	 margin-top: 2px;
	 font-style: italic;
}
 .card1__brand {
	 display: -webkit-flex;
	 display: flex;
	 width: 80%;
	 -webkit-justify-content: space-between;
	         justify-content: space-between;
	 -webkit-align-items: center;
	         align-items: center;
	 margin-left: 50%;
	 position: absolute;
	 -webkit-transform: translate(-50%);
	         transform: translate(-50%);
}
 .card1__brand--name {
	 font-size: 13px;
	 font-weight: 700;
	 color: black;
	 text-shadow: 0 0 2px rgba(0, 0, 0, .5);
}
 .card1__brand--logo {
	 font-size: 21px;
}
 .card1__number {
	 position: relative;
	 top: 62%;
	 padding-left: 30px;
	 font-size: 18px;
	 font-weight: 700;
	 color: #e96048;
	 text-shadow: 0px 0px 2px #4a2d00;
	 letter-spacing: 1px;
}
 .card1__number1 {
	 position: relative;
	 top: 62%;
	 padding-left: 30px;
	 font-size: 18px;
	 font-weight: 700;
	 color: #b3b0aa;
	 text-shadow: 0px 0px 2px #4a2d00;
	 letter-spacing: 1px;
}
 .card1__user {
	 position: relative;
	 top: 70%;
	 display: -webkit-flex;
	 display: flex;
	 left: 30px;
	 color: #fff;
}
 .card1__user--name {
	 font-size: 12px;
	 letter-spacing: 1.4px;
	 font-weight: 700;
	 text-shadow: 0 0 3px #060606;
}
 .card1__user--valid {
	 display: -webkit-flex;
	 display: flex;
	 -webkit-align-items: center;
	         align-items: center;
	 -webkit-flex-direction: column;
	         flex-direction: column;
	 margin-left: 20px;
}
 .card1__user--valid-txt {
	 display: -webkit-inline-flex;
	 display: inline-flex;
	 font-size: 10px;
	 margin-bottom: 5px;
	 text-shadow: 0 0 3px #060606;
}
 .card1__user--valid-date {
	 font-size: 12px;
	 font-weight: 700;
	 text-shadow: 0 0 3px #060606;
}
 .card1__payment {
	 position: absolute;
	 color: #e96048;
	 bottom: 20px;
	 right: 27px;
	 font-size: 2.5em;
}
 .card1__payment1 {
	 position: absolute;
	 color: #b3b0aa;
	 bottom: 20px;
	 right: 27px;
	 font-size: 2.5em;
}
 .card1__front--bg {
	 position: absolute;
	 background-color: #ff4b2b;
	 background-image: linear-gradient(135deg, #f3a775 25%, #ff4b2b 100%);
	 width: 100%;
	 height: 100%;
	 z-index: 0;
}
 .card1__front1--bg {
	 position: absolute;
	 background-color: #b8acac;
	 background-image: linear-gradient(135deg, rgba(242, 240, 240, 0.603) 25%, rgba(190, 183, 183, 0.603) 100%);
	 width: 100%;
	 height: 100%;
	 z-index: 0;
}
 .card1__chip {
	 position: absolute;
	 top: 86px;
	 left: 45px;
	 display: -webkit-flex;
	 display: flex;
	 -webkit-flex-direction: column;
	         flex-direction: column;
	 -webkit-align-items: center;
	         align-items: center;
	 background: #101010;
}
 .card1__chip--shape {
	 width: 43px;
	 height: 9px;
	 background: #efb35b;
	 border-radius: 1px;
	 margin-bottom: 1px;
}
 .card1__chip--shape:last-child {
	 margin-bottom: 0;
	 border-radius: 1px 1px 3px 3px;
}
 .card1__chip--shape:first-child {
	 border-radius: 3px 3px 1px 1px;
}
 .card1__chip--shape:first-child:after {
	 content: "";
	 position: absolute;
	 background: #efb35b;
	 border: 1px solid #101010;
	 width: 12px;
	 height: 23px;
	 top: 50%;
	 left: 50%;
	 -webkit-transform: translate(-50%, -50%);
	         transform: translate(-50%, -50%);
	 border-radius: 5px;
}
 .card1__chip:before {
	 content: "";
	 position: absolute;
	 width: 0;
	 height: 0;
	 border-top: 8px solid transparent;
	 border-right: 12px solid #fab25e 40;
	 border-bottom: 8px solid transparent;
	 left: -20px;
	 top: 7px;
}
 .card1__stamp {
	 width: 54px;
	 height: 35px;
	 background: radial-gradient(#fff, #a9a9a9);
	 position: absolute;
	 right: 15px;
	 bottom: 80px;
	 border-radius: 20px;
	 display: -webkit-flex;
	 display: flex;
	 -webkit-justify-content: center;
	         justify-content: center;
	 -webkit-align-items: center;
	         align-items: center;
	 box-shadow: 0 0 13px 0px rgba(0, 0, 0, 0.2);
}
 .card1__stamp:after {
	 content: "d&p";
	 position: absolute;
	 opacity: 0.1;
	 color: #4c320c;
	 font-weight: 900;
	 font-size: 18px;
	 text-shadow: 0 1px 1px #4e4d4d;
	 border: 1px solid #8e8b8b;
	 width: 70%;
	 height: 70%;
	 text-align: center;
	 border-radius: 30px;
}
 .card1__plast {
	 position: absolute;
	 left: 0;
	 width: 15px;
	 height: 100%;
}
 .card1__plast:after {
	 content: "www.samplecard-plast.com.xx";
	 position: absolute;
	 -webkit-transform: rotate(270deg) translateY(-50%);
	         transform: rotate(270deg) translateY(-50%);
	 text-align: center;
	 width: 15px;
	 white-space: nowrap;
	 bottom: 30%;
	 font-size: 6px;
}
 .card1 .logo {
	 display: -webkit-flex;
	 display: flex;
	 position: relative;
	 bottom: 2px;
}
 .card1 .logo--shape {
	 position: relative;
	 width: 25px;
	 height: 25px;
	 border-radius: 50%;
	 opacity: 0.6;
}
 .card1 .logo-red {
	 background: #f00;
	 left: 5px;
}
 .card1 .logo-yellow {
	 background: #ff0;
	 right: 5px;
}
 .card1 .logo-name {
	 position: relative;
	 left: 0;
	 top: -3px;
	 font-size: 12px;
	 color: white;
	 opacity: 0.2;
}
 .card1 .logo-mid {
	 font-size: 20px;
}
 .card1 .logo-d {
	 left: 3px;
}
 .card1 .logo-p {
	 right: 7px;
}
 .card1 .logo-letter {
	 position: relative;
	 text-shadow: 0 0 5px #000 b8;
}
 .card1 .front--bg--s1 {
	 position: relative;
	 background: #101010;
	 width: 260px;
	 height: 510px;
	 -webkit-transform: rotate(105deg);
	         transform: rotate(105deg);
	 bottom: 21px;
	 left: 75px;
	 border-radius: 30%;
	 border: 4px solid #ee6b54;
	 box-shadow: 0 0 1px 5px #101010;
}
 .card1 .front1--bg--s1 {
	 position: relative;
	 background: #101010;
	 width: 260px;
	 height: 510px;
	 -webkit-transform: rotate(105deg);
	         transform: rotate(105deg);
	 bottom: 21px;
	 left: 75px;
	 border-radius: 30%;
	 border: 4px solid #b3a8a6;
	 box-shadow: 0 0 1px 5px #101010;
}
 .card1 .front--bg--s2 {
	 position: absolute;
	 top: -20px;
	 display: -webkit-flex;
	 display: flex;
	 -webkit-transform: rotate(-1deg);
	         transform: rotate(-1deg);
	 z-index: -1;
}
 .card1 .strip-shape {
	 background: #dcaa7b 80;
	 width: 80px;
	 height: 60px;
	 -webkit-transform: rotate(130deg) translateX(-50%);
	         transform: rotate(130deg) translateX(-50%);
	 position: relative;
	 left: -11px;
	 top: 35%;
	 border: 1px solid #fff a8;
}
 @-webkit-keyframes cardFront {
	 from {
		 left: 0;
		 top: 0;
	}
}
 @keyframes cardFront {
	 from {
		 left: 0;
		 top: 0;
	}
}
 @-webkit-keyframes cardBack {
	 from {
		 left: 0;
		 top: 0;
	}
	 to {
		 left: 52%;
		 top: 12%;
	}
}
 @keyframes cardBack {
	 from {
		 left: 0;
		 top: 0;
	}
	 to {
		 left: 52%;
		 top: 12%;
	}
}
 @media all and (max-width: 768px) {
	 @-webkit-keyframes cardFront {
		 from {
			 left: 0;
			 top: 0;
		}
	}
	 @keyframes cardFront {
		 from {
			 left: 0;
			 top: 0;
		}
	}
	 @-webkit-keyframes cardBack {
		 from {
			 left: 0;
			 top: 0;
		}
		 to {
			 left: 0%;
			 top: 54%;
		}
	}
	 @keyframes cardBack {
		 from {
			 left: 0;
			 top: 0;
		}
		 to {
			 left: 0%;
			 top: 54%;
		}
	}
}
 @media all and (max-width: 420px) {
	 .card__container {
		 -webkit-transform: translate(-50%, -50%) scale(0.8);
		         transform: translate(-50%, -50%) scale(0.8);
	}
}

 @media only screen and (min-width: 740px) and (max-width: 880px) {
	 .card1 {
		 padding: 0px;
		 height: 250px;
	}
	 .card1__container {
		 width: 350px;
		 height: 240px;
	}
	 .card1__extra {
		 top: 36%;
		 right: 20px;
		 font-size: 26px;
	}
	 .card1__front {
		 width: 340px;
		 height: 220px;
	}

}
 @media only screen and (min-width: 380px) and (max-width: 410px) {

	 .card1 {
		 padding: 0px;
		 height: 230px;
	}
	 .card1__container {
		 width: 350px;
		 height: 240px;
	}
	 .card1__extra {
		 top: 36%;
		 right: 20px;
		 font-size: 26px;
	}
	 .card1__front {
		 width: 340px;
		 height: 220px;
	}
}
 @media only screen and (min-width: 100px) and (max-width: 380px) {

	 .cardContainerAlign {
		 min-width: 300px;
	}
	 .card1 {
		 width: 300px;
		 padding: 0px;
		 height: 200px;
	}
	 .card1__container {
		 width: 350px;
		 height: 240px;
	}
	 .card1__extra {
		 top: 36%;
		 right: 20px;
		 font-size: 26px;
	}
	 .card1__front {
		 width: 300px;
		 height: 200px;
	}
	 .font-number {
		 margin-top: -10px;
	}
	 .font-normal {
		 margin-top: 13px;
	}
}
 
.maintenanceRequestContainer{
     padding-top:100px;
     margin-bottom: 5%;
}
.maintenanceRequestHead{
     font-family: "Montserrat", sans-serif;
     color:#414141;
     letter-spacing: 0.5px;
  
     font-size: 16px;
     font-weight: 800;
}
.maintenanceSpan{
     font-family: "Montserrat", sans-serif;
     color:#908f8f;
     letter-spacing: 0.5px;
     font-size: 11px;
     font-weight: 500;
}
.maintenanceRequestText1{
     font-family: "Montserrat", sans-serif;
     color:#aeacac;
     letter-spacing: 0.5px;
     font-size: 11px;
     font-weight: 600;
}
.maintenanceRequestText11{
     font-family: "Montserrat", sans-serif;
     color:#9c9c9c;
     font-size: 12px;
     letter-spacing: 1.2px;
     text-align: justify;
}
.maintenanceRequestText2{
     font-family: "Montserrat", sans-serif;
     color:#414141;
     letter-spacing: 0.5px;
     font-size: 13px;
     font-weight: 600;
     line-height: 20px !important;
}
.maintenanceRequestText3{
     font-family: "Montserrat", sans-serif;
     color:#414141;
     letter-spacing: 1px;
     font-size: 12px;
     font-weight: 500;
     text-align: justify;
     line-height: 21px !important;
}
.maintenanceRequestText4{
     font-family: "Montserrat", sans-serif;
     color:#838383  !important;
     letter-spacing: 0.5px;
     font-size: 13px;
     font-weight: 600;
     line-height: 20px !important;
}
.maintenanceRequestText5{
     font-family: "Montserrat", sans-serif;
     color:#1f1f1f  !important;
     letter-spacing: 0.5px;
     font-size: 13px;
     font-weight: 600;
     text-align: justify;
     line-height: 20px !important;
}
.maintenanceCard{
     background-color: #fff;
     padding:20px;
     padding-top:15px;
     padding-bottom:15px;
     border-radius: 5px;
     box-shadow: rgba(179, 179, 179, 0.28) 0px 2px 30px 0px;
     height: 100%;
}
.maintenanceCard2{
     background-color: #fff;
     padding:20px;
     padding-top:15px;
     padding-bottom:15px;
     border-radius: 5px;
     border:1px solid #9b9b9b5e;
     /* box-shadow: rgba(157, 157, 157, 0.066) 0px 8px 20px 0px; */
}
.row.equal-cols {
     display: -webkit-flex;
     display: flex;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
   }
   
   .darkBtn {
     border-radius: 30px;
     font-size: 12px;
     font-weight: 500;
     padding: 10px 20px !important;
     letter-spacing: 1px;
     text-transform: capitalize !important;
     transition: -webkit-transform 80ms ease-in;
     transition: transform 80ms ease-in ;
     transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
     border: solid 1px #313131 !important;
     background-color: #313131 !important;
     color: #fff;
     cursor: pointer;
   }
   .successBtn {
     border-radius: 30px;
     font-size: 12px;
     font-weight: 500;
     padding: 10px 20px !important;
     letter-spacing: 1px;
     text-transform: capitalize !important;
     transition: -webkit-transform 80ms ease-in;
     transition: transform 80ms ease-in ;
     transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
     border: solid 1px #79cd6c !important;
     background-color: #79cd6c !important;
     color: #fff;
     height:55px;
   }
   .maintenanceRequestAlign{
     /* padding-right:30px;
     padding-left:30px; */
    
}
.maintenanceRequestHeaderAlign1{
     display:inline-block;
   }
   .maintenanceRequestHeaderAlign2{
     display:none;
   }
   .maintenanceRequestAlignMain{
        padding-right:30px;
        padding-left:30px;
        max-height: 320px;
        overflow-y: scroll;
        /* overflow-x: hidden; */
        margin-top:1%;
        margin-bottom:1%;
        
   }
   .maintenanceRequestAlignMain2{
     padding-right:30px;
     padding-left:30px;
     max-height: 400px;
     overflow-y: scroll;
     /* overflow-x: hidden; */
     margin-top:1%;
     margin-bottom:1%;
     
}
   .maintenanceRequestAlign3{
     display:none;
     }

.maintenanceRequestAlignMain::-webkit-scrollbar {
     width: 4px;
      height: 6px;
      border-radius: 15%;
   }
   
   /* Track */
   .maintenanceRequestAlignMain::-webkit-scrollbar-track {
     background: #f1f1f1;
   }
   
   /* Handle */
   .maintenanceRequestAlignMain::-webkit-scrollbar-thumb {
     background: #dcdcdc;
   }
   
   /* Handle on hover */
   .maintenanceRequestAlignMain::-webkit-scrollbar-thumb:hover {
     background: #fe947f;
   }
 

.maintenanceRequestAlignMain2::-webkit-scrollbar {
     width: 4px;
      height: 6px;
      border-radius: 15%;
   }
   
   /* Track */
   .maintenanceRequestAlignMain2::-webkit-scrollbar-track {
     background: #f1f1f1;
   }
   
   /* Handle */
   .maintenanceRequestAlignMain2::-webkit-scrollbar-thumb {
     background: #dcdcdc;
   }
   
   /* Handle on hover */
   .maintenanceRequestAlignMain2::-webkit-scrollbar-thumb:hover {
     background: #fe947f;
   }

 
     .squareDivPri {
          /* border: solid 1px #ff4b2b; */
          background-color: #ff4b2b;
          color: #fff;
          font-size: 16px;
          border-radius: 5px;
          font-weight: 600;
          padding:12px 10px;
          letter-spacing: 1px;
          text-align: center;
          font-family: "Montserrat", sans-serif;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
     }

     .squareDivPri2 {
          border: solid 1px #ff4b2b;
          background-color: #fff;
          color: #ff4b2b;
          font-size: 16px;
          border-radius: 5px;
          font-weight: 600;
          padding:12px 10px;
          letter-spacing: 1px;
          text-align: center;
          font-family: "Montserrat", sans-serif;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
     }
     .chipsBadge {
          background-color: #ff4b2b;
          color: #fff;
          font-size: 13px;
          border-radius: 25px;
          font-weight: 600;
          padding:10px 1px;
          letter-spacing: 1px;
          text-align: center;
          font-family: "Montserrat", sans-serif;
          max-width: 180px;
     }
    
     .chipsBadge2 {
          border: solid 1px #ff4b2b;
          background-color: #fff;
          color: #ff4b2b;
          font-size: 13px;
          border-radius: 25px;
          font-weight: 600;
          padding:10px 1px;
          letter-spacing: 1px;
          text-align: center;
          font-family: "Montserrat", sans-serif;
          max-width: 180px;
     }

     .maintenanceInputSelect {
          
          background-color: rgb(255, 255, 255);
          border: none;
          font-family: "Montserrat", sans-serif;
          height: 100%;
          font-size: 16px;
          border-radius: 5px;
          font-weight: 700;
          padding: 0px 25px;
          width: 100%;
       
          font-size: 12px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
     }
     
     .maintenanceRequestCardCount{
          background-color: #f84343;
          color: #fff;
          text-align: center;
          padding-top: 10px;
          width: 35px !important;
          height: 35px !important;
          border-radius: 50%;
          font-family: "Montserrat", sans-serif;  
          font-size: 13px;
          font-weight: 800;
          margin-top: -3px !important;
          margin-left: 20px !important;
          position: absolute;
     }
        .requestShowMore{
          font-family: "Montserrat", sans-serif;
          color:#b2b2b2  !important;
          letter-spacing: 0.5px;
          font-size: 12px !important;
          font-weight: 600;
          line-height: 20px !important;
          text-transform: uppercase;
          width:100%;
          cursor: pointer;
          text-align: right;
          margin: auto;
     }
     .requestShowMore:hover{
          color:#3e3e3e  !important;
     }
     .maintenanceRequestHeaderAlign{
          display:-webkit-flex;
          display:flex;
     }
     .maintenanceRequestHeaderAlign2{
          display:none;
     }
   @media only screen and (min-width: 368px) and (max-width: 568px) {


     .maintenanceRequestAlign{
          padding-right:10px;
          padding-left:10px;
     }
     .maintenanceRequestAlign2{
          display:none;
     }
     .maintenanceRequestAlign3{
          display:block;
          margin-left:6px;
     }
}
@media only screen and (min-width: 568px) and (max-width: 868px) {
     .maintenanceRequestAlign{
          padding-right:20px;
          padding-left:20px;
     }
}
   @media only screen and (min-width: 100px) and (max-width: 768px) {
     .maintenanceRequestHeaderAlign2{
          display:-webkit-flex;
          display:flex;
        }
        .maintenanceRequestHeaderAlign1{
          display:none;
        }
     .maintenanceRequestHeaderAlign{
          display:none;
     }
     .maintenanceRequestHeaderAlign2{
          display:block;
     }
     .maintenanceInputSelect {
          height: 45px;
     }
     .maintenanceRequestContainer{
          padding:0px;
          padding-top:110px;
          margin-bottom: 5%;
     }
     .maintenanceInputSelectAlign{
          margin-top: 2%;
          margin-bottom: 2%;
     }
     
   }

   @media only screen and (min-width: 00px) and (max-width: 368px) {

     .maintenanceRequestAlign{
          padding-right:5px;
          padding-left:5px;
     }
     .maintenanceRequestAlign2{
         
          display:none;
     }
     .maintenanceRequestAlign3{
          margin-left:0px;
          display:block;
     }
}

/* maintenance 01 */

.maintenanceContainer{
     padding-top:110px;
     margin-bottom: 15%;
}
.maintenanceHeadText{
     font-family: "Montserrat", sans-serif;
     color:#181818;
     /* letter-spacing: 0.5px;
     line-height: 25px; */
     
     line-height: 5px;
     font-size: 15px;
     font-weight: 500;
     
   }
   .maintenanceHeadText1{
    font-family: "Montserrat", sans-serif;
    color:#181818;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    margin:25px 0 0px !important; 
  }
  .maintenanceHeadText3{
     font-family: "Montserrat", sans-serif;
     color:#181818;
     /* letter-spacing: 0.5px;
     line-height: 25px; */
     
     line-height: 5px;
     font-size: 15px;
     font-weight: 500;
     margin:15px 0 10px !important; 
   }
   .maintenanceHeadText4{
     font-family: "Montserrat", sans-serif;
     color:#ababab;
     /* letter-spacing: 0.5px;
     line-height: 25px; */
     
     line-height: 5px;
     font-size: 13px;
     font-weight: 500;
     margin:15px 0 20px !important; 
   }
  .maintenanceHeadTextDark1{
    font-family: "Montserrat", sans-serif;
    color:#fff;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    padding-top: 17px;
  }
   .maintenanceHeadTextDark{
    font-family: "Montserrat", sans-serif;
    color:#fff;
    /* letter-spacing: 0.5px;
    line-height: 25px; */
    font-size: 15px;
    font-weight: 500;
  }
   .maintenanceContentText{
     font-family: "Montserrat", sans-serif;
     color:#807e7e;
     letter-spacing: 1px;
     /* line-height: 25px; */
     font-size: 12px;
   }
   .bgGreen{
     background-color: #16A085;
     color:#fff;
   }
   .bgGreen:hover{
     background-color: #0f7762;
     color:#fff;
   }
   .bgRed{
     background-color: #E74C3C; 
     color:#fff;
}
.bgRed:hover{
     background-color: #c22b1a; 
     color:#fff;
}
.maintenanceImage{
     width:100%;
     height:200px;

}
.maintenanceHide{
  display:none;
}
.payeeImage {
     height: 30px;
     width: 30px;
   }
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .maintenanceHide{
    display:block;
  }
}
@media only screen and (min-width: 100px) and (max-width: 768px) {
  .payeeImage{
    margin-top: 14px;
  }
  .maintenanceHeadTextDark1{
   
    text-align: center;
    padding-top: 9px;
  }
}

@media only screen and (min-width: 100px) and (max-width: 425px) {
     .maintenanceHeadText1{
          line-height: 25px;
        }
        .maintenanceHeadText3{
           line-height: 20px;
         }
         .maintenanceHeadText4{
           line-height: 20px;
         }
}


.chart-skills li:nth-child(1) span {
  top: 5px;
  left: 10px;
  -webkit-transform: rotate(-21.6deg);
          transform: rotate(-21.6deg);
}

.chart-skills li:nth-child(2) span {
  top: 20px;
  left: 10px;
  -webkit-transform: rotate(-79.2deg);
          transform: rotate(-79.2deg);
  -webkit-animation-delay: .4s;
          animation-delay: .4s;
}

.chart-skills li:nth-child(3) span {
  top: 18px;
  left: 10px;
  -webkit-transform: rotate(-140.4deg);
          transform: rotate(-140.4deg);
  -webkit-animation-delay: .8s;
          animation-delay: .8s;
}

.chart-skills li:nth-child(4) span {
  top: 10px;
  left: 10px;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}


@-webkit-keyframes fade-in {
  0%,
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes fade-in {
  0%,
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    visibility: visible;
  }
}
@keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    visibility: visible;
  }
}
@-webkit-keyframes animate-height {
  0% {
    height: 0;
  }
  100% {
    visibility: visible;
  }
}
@keyframes animate-height {
  0% {
    height: 0;
  }
  100% {
    visibility: visible;
  }
}
.curve1 {
  background-color: #ffffff;
  height: 50px;
  border-top-right-radius: 28px;
  border-top-left-radius: 28px;
 
box-shadow: 0px 0px 0px rgb(255, 255, 255), 0px -5px 5px rgb(245, 245, 245);
}
.curve2 {
  border-bottom-left-radius:28px ;
  /* box-shadow: 0px 0px 0px rgb(255, 255, 255),0px 0px 0px rgb(255, 255, 255),0px 0px 0px rgb(255, 255, 255), 0px 27px 0px rgb(255, 255, 255); */
  height: 50px;
  padding: 0px;
  margin: 0px;

}
.curve3 {
  /* background-color: #f3f4f5; */
  height: 50px;
  padding: 0px;
  margin: 0px;
  border-bottom-left-radius: 28px;
}
#bar-chart {
  height: 250px;
  width: 100%;
  position: relative;
  margin-top: 30px;
  margin-left: 50px;
  font-family: "Montserrat", sans-serif;
  color: #a2a2a2;
}
#bar-chart * {
  box-sizing: border-box;
}
#bar-chart .graph {
  height: 233px;
  position: relative;
}
#bar-chart .bars {
  height: 203px;
  padding: 0 2%;
  position: absolute;
  width: 100%;
  z-index: 10;
}
#bar-chart .bar-group {
  display: block;
  float: left;
  height: 100%;
  position: relative;
  width: 10%;
  margin-right: 5%;
}
#bar-chart .bar-group:last-child {
  margin-right: 0;
}
#bar-chart .bar-group .bar {
  visibility: hidden;
  height: 0;
  -webkit-animation: animate-height;
  animation: animate-height;
  animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  -webkit-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  -moz-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  animation-duration: 0.4s;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.15);
  /* border: 1px solid #2d2d2d; */
  border-radius: 10px 10px 0 0;
  bottom: 0;
  cursor: pointer;
  height: 0;
  position: absolute;
  text-align: center;
  width: 18%;
}
#bar-chart .bar-group .bar:nth-child(2) {
  left: 30%;
}
#bar-chart .bar-group .bar:nth-child(3) {
  left: 60%;
}
#bar-chart .bar-group .bar span {
  display: none;
}
#bar-chart .bar-group .bar-1 {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
#bar-chart .bar-group .bar-2 {
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}
#bar-chart .bar-group .bar-3 {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}
#bar-chart .bar-group .bar-4 {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}
#bar-chart .bar-group .bar-5 {
  animation-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
}
#bar-chart .bar-group .bar-6 {
  animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
}
#bar-chart .bar-group .bar-7 {
  animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
}
#bar-chart .bar-group .bar-8 {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
#bar-chart .bar-group .bar-9 {
  animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
}
#bar-chart .bar-group .bar-10 {
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}
#bar-chart .bar-group .bar-11 {
  animation-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
}
#bar-chart .bar-group .bar-12 {
  animation-delay: 1.4s;
  -webkit-animation-delay: 1.4s;
}
#bar-chart .bar-group .bar-13 {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
}
#bar-chart .bar-group .bar-14 {
  animation-delay: 1.6s;
  -webkit-animation-delay: 1.6s;
}
#bar-chart .bar-group .bar-15 {
  animation-delay: 1.7s;
  -webkit-animation-delay: 1.7s;
}
#bar-chart .bar-group .bar-16 {
  animation-delay: 1.8s;
  -webkit-animation-delay: 1.8s;
}
#bar-chart .bar-group .bar-17 {
  animation-delay: 1.9s;
  -webkit-animation-delay: 1.9s;
}
#bar-chart .bar-group .bar-18 {
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
}

#bar-chart .bar-group .bar-19 {
  animation-delay: 2.1s;
  -webkit-animation-delay: 2.1s;
}
#bar-chart .bar-group .bar-20 {
  animation-delay: 2.2s;
  -webkit-animation-delay: 2.2s;
}
#bar-chart .bar-group .bar-21 {
  animation-delay: 2.3s;
  -webkit-animation-delay: 2.3s;
}
#bar-chart .bar-group .bar-22 {
  animation-delay: 2.4s;
  -webkit-animation-delay: 2.4s;
}
#bar-chart .bar-group .bar-23 {
  animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
}
#bar-chart .bar-group .bar-24 {
  animation-delay: 2.6s;
  -webkit-animation-delay: 2.6s;
}
#bar-chart .bar-group .bar-25 {
  animation-delay: 2.7s;
  -webkit-animation-delay: 2.7s;
}
#bar-chart .bar-group .bar-26 {
  animation-delay: 2.8s;
  -webkit-animation-delay: 2.8s;
}
#bar-chart .bar-group .bar-27 {
  animation-delay: 2.9s;
  -webkit-animation-delay: 2.9s;
}
#bar-chart .bar-group .bar-28 {
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
}
#bar-chart .bar-group .bar-29 {
  animation-delay: 3.1s;
  -webkit-animation-delay: 3.1s;
}
#bar-chart .bar-group .bar-30 {
  animation-delay: 3.2s;
  -webkit-animation-delay: 3.2s;
}
#bar-chart .bar-group .bar-31 {
  animation-delay: 3.3s;
  -webkit-animation-delay: 3.3s;
}
#bar-chart .bar-group .bar-32 {
  animation-delay: 3.4s;
  -webkit-animation-delay: 3.4s;
}
#bar-chart .bar-group .bar-33 {
  animation-delay: 3.5s;
  -webkit-animation-delay: 3.5s;
}
#bar-chart .bar-group .bar-34 {
  animation-delay: 3.6s;
  -webkit-animation-delay: 3.6s;
}
#bar-chart .bar-group .bar-35 {
  animation-delay: 3.7s;
  -webkit-animation-delay: 3.7s;
}
#bar-chart .bar-group .bar-36 {
  animation-delay: 3.8s;
  -webkit-animation-delay: 3.8s;
}
#bar-chart ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#bar-chart .x-axis {
  bottom: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
#bar-chart .x-axis li {
  float: left;
  margin-right: 3%;
  font-size: 11px;
  width: 11.5%;
}
#bar-chart .x-axis li:last-child {
  margin-right: 0;
}
#bar-chart .y-axis {
  position: absolute;
  text-align: right;
  width: 100%;
}
#bar-chart .y-axis li {
  border-top: 1px solid #e8eaed;
  display: block;
  height: 40.25px;
  width: 100%;
}
#bar-chart .y-axis li span {
  display: block;
  font-size: 11px;
  margin: -10px 0 0 -60px;
  padding: 0 0px;
  width: 40px;
}
#bar-chart .stat-1 {
  background-image: linear-gradient(
    to right,
    #7777ff 0%,
    #7777ff 47%,
    #7777ff 50%,
    #7777ff 100%
  );
}

#bar-chart .stat-1-Disable {
  background-image: linear-gradient(
    to right,
    rgb(98, 98, 98) 0%,
    rgb(98, 98, 98) 47%,
    rgb(98, 98, 98) 50%,
    rgb(98, 98, 98) 100%
  );
}
#bar-chart .stat-2 {
  background-image: linear-gradient(
    to right,
    #feb342 0%,
    #feb342 47%,
    #feb342 50%,
    #feb342 100%
  );
}
#bar-chart .stat-2-Disable {
  background-image: linear-gradient(
    to right,
    rgb(141, 141, 141) 0%,
    rgb(141, 141, 141) 47%,
    rgb(141, 141, 141) 50%,
    rgb(141, 141, 141) 100%
  );
}
#bar-chart .stat-3 {
  background-image: linear-gradient(
    to right,
    #ff7be5 0%,
    #ff7be5 47%,
    #ff7be5 50%,
    #ff7be5 100%
  );
}
#bar-chart .stat-3-Disable {
  background-image: linear-gradient(
    to right,
    #cecece 0%,
    #cecece 47%,
    #cecece 50%,
    #cecece 100%
  );
}
.bg-paymentGraph1 {
  background-color: #7777ff;
}
.bg-paymentGraph2 {
  background-color: #feb342;
}
.bg-paymentGraph3 {
  background-color: #ff7be5;
}

.graphLabel {
  margin: 20px;
}

.paymentGraphBigScreen1 {
  display: none !important;
}
.paymentGraphBigScreen2 {
  display: none !important;
}

@media only screen and (min-width: 1440px) and (max-width: 1900px) {
  .paymentGraphBigScreen1 {
    display: block !important;
  }
  #bar-chart .bar-group {
    width: 6%;
    margin-right: 5%;
  }
  #bar-chart .x-axis li {
    margin-right: 3%;
    width: 7.6%;
  }
}
@media only screen and (min-width: 1900px) and (max-width: 5000px) {
  .paymentGraphBigScreen2 {
    display: block !important;
  }
  .paymentGraphBigScreen1 {
    display: block !important;
  }
  #bar-chart .bar-group {
    width: 4%;
    margin-right: 4%;
  }
  #bar-chart .x-axis li {
    margin-right: 3%;
    width: 4.8%;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1100px) {
  .paymentGraphBigScreen2 {
    display: block !important;
  }
  .paymentGraphBigScreen1 {
    display: block !important;
  }
  #bar-chart .bar-group {
    width: 4%;
    margin-right: 4%;
  }
  #bar-chart .x-axis li {
    margin-right: 3%;
    width: 4.8%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 800px) {
  .paymentGraphBigScreen1 {
    display: block !important;
  }
  #bar-chart .bar-group {
    width: 6%;
    margin-right: 5%;
  }
  #bar-chart .x-axis li {
    margin-right: 3%;
    width: 7.6%;
  }
}
@media only screen and (min-width: 380px) and (max-width: 500px) {
  .paymentGraphMobile1 {
    display: none !important;
  }
  #bar-chart .bar-group {
    width: 14%;
    margin-right: 5%;
  }
  #bar-chart .x-axis li {
    margin-right: 3%;
    width: 15.1%;
  }
  .paymentHead {
    font-size: 13px;
  }

  .ApplicationInputSelectNormal {
    font-size: 12px;
  }

  .ApplicationInputOptionNormal {
    font-size: 11px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 380px) {
  .paymentGraphMobile2 {
    display: none !important;
  }
  .paymentGraphMobile1 {
    display: none !important;
  }
  #bar-chart .bar-group {
    width: 17%;
    margin-right: 5%;
  }
  #bar-chart .x-axis li {
    margin-right: 3%;
    width: 18.1%;
  }
  .paymentHead {
    font-size: 10px;
  }
  .ApplicationInputSelectNormal {
    font-size: 12px;
  }
  .addStripePayment {
    margin-left: 0px;
  }

  .ApplicationInputOptionNormal {
    font-size: 11px;
  }
}

.addprops{
     margin-top: 80px;
     padding-top: 20px;
     margin-bottom: 20%;
}
.dropHead{
     font-family: "Montserrat", sans-serif;
	font-size:29px;
	font-weight: 1950;
	font-weight: bold;
	padding-left:15px;
	/* margin-top:10px; */
	line-height: 20px;
     
     color: rgba(255, 243, 243, 0.87);
}
.dropContent1{
     font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	line-height: 1px;
}
.dropContent2{
     font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	line-height: 1px;
     font-weight: 900;
}
.dropContent3{
     font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	line-height: 1px;
     color: rgb(243, 69, 69);
     font-weight: 900;
}

.navTabLink {
  margin: 0px;
  padding: 15px;
  padding-right: 25px;
  padding-left: 25px;
  color: black;
  font-family: "Montserrat", sans-serif;
}
.navTabLink .active {
  color: #ff4b2b;
}
.navTabLink.makeWhite {
  color: #bebebe !important;
}
.navTabLink.makeWhite.active {
  color: #ff4b2b !important;
}
.navTabLink:hover {
  color: #ff4b2b;
}
.viewListingPad{
  box-shadow: 0px 20px 20px rgb(232, 232, 232), 0px -25px 25px rgb(228, 228, 228), 0px 25px 25px rgb(224, 224, 224), 0px 20px 20px rgb(222, 222, 222);
}
#myTab{
  z-index: 1012 !important;
}
.nav-item.makeWhite {
  background-color: #bebebe5d;
}
.searchTab {
  position: absolute;
  margin-top: 22px;
  margin-left: -30px;
}
.tabSearchInput {
  padding-right: 40px !important;
}

.listCheck:indeterminate {
}
input[type="checkbox"]:before {
  border: 7px solid rgb(255, 11, 11);
}
input[type="checkbox"]:after {
  border: 1px solid rgb(255, 11, 11);
}
.addList {
  margin-bottom: -200px;
}
@media only screen and (min-width: 550px) and (max-width: 680px) {
  .addList {
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 400px) and (max-width: 550px) {
  .addList {
    margin-bottom: 10px;
  }
  .removeUnit {
    display: none;
  }
  .dragDrop {
    width: 300px;
  }
}
.addListingTabMobile{
  display: none;
}
.addListingTabWeb{
  display: -webkit-flex;
  display: flex;
}
@media only screen and (min-width: 350px) and (max-width: 400px) {
  .addListingTabMobile{
    display: block;
  }
  .addListingTabWeb{
    display: none;
  }
  .addList {
    margin-bottom: 10px;
  }
  .dragDrop {
    width: 300px;
  }
  .navTabLink {
    padding-right: 15px;
    padding-left: 15px;
  }
  .searchListContentApp {
    font-size: 9px;
    line-height: 10px;
  }
  .removeUnit {
    display: none;
  }
}
@media only screen and (min-width: 00px) and (max-width: 350px) {
  .addListingTabMobile{
    display: block;
  }
  .addListingTabWeb{
    display: none;
  }
  .addList {
    margin-bottom: 10px;
  }
  .dragDrop {
    width: 250px;
  }
  .removeUnit {
    display: none;
  }
  .navTabLink {
    padding-right: 8px;
    padding-left: 8px;
  }
  .searchListContentApp {
    font-size: 9px;
    line-height: 10px;
  }
}

.navBottom1 {
  border-radius: 10px;
  border: 1px solid #97b5d648;
  border-top: 6px solid #ff4b2bcc;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  min-width: 320px;
  max-width: 720px;
  /* margin-left: 40%; */

  padding: 2%;
  padding-top: 0%;
  background-color: #fff;
}
.navBottom0 {
  /* border-radius: 10px; */
  border-right: 1px solid #97b5d648;
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px; */
  /* height:50px; */
  /* width:300px; */
  z-index: 1000;
  height:800px;
  position: fixed;
  padding-top:30px;
  top:80px;
  width:30%;
  /* background-color: #fff; */
}
.verticalLine{
  height:18px;
  margin-left: 19px;
  border-left: 1px solid rgba(179, 177, 177, 0.712); 
}
.verticalLineRed{
  height:18px;
  margin-left: 20px;
  border-left: 1px solid #ff4b2b; 
}
.officeBar {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-left: 3%;
  padding-bottom: 3%;
  margin-bottom: 2%;
  margin-top: 2%;
  border: 1px solid #ff4b2b80;
  border-radius: 15px;
  cursor: pointer;
}
.stepBottom {
  height: 100px;
}
.form-control {
  font-family: "Montserrat", sans-serif;
  /* border: 1px solid rgba(167, 165, 165, 0.349); */
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgba(99, 99, 99, 0.13) 0px 2px 8px 0px; */
}
.input-group-text {
  font-family: "Montserrat", sans-serif;
}
.officeApplicationSubTitle1 {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 20px;
  color: rgba(34, 32, 32, 0.664);
  font-weight: 600;
}
.officeApplicationTitle {
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  line-height: 20px;
  font-weight: 100;
  font-weight: 600;
}
.officeApplicationSubTitle {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 20px;
  /* font-weight: 600 ; */
}
.officeApplicationPara {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}
.officeOpenClock {
  width: 90%;
}

.containerRadioOfficeApp {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  white-space: pre;
}

/* Hide the browser's default checkbox */
.containerRadioOfficeApp input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgb(228, 224, 224);
}

/* On mouse-over, add a grey background color */
.containerRadioOfficeApp:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerRadioOfficeApp input:checked ~ .checkmark {
  background-color: #ff4b2b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerRadioOfficeApp input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerRadioOfficeApp .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.containerOfficeApp {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Montserrat", sans-serif;
}

.containerOfficeApp input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkMark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}
.containerOfficeApp:hover input ~ .checkMark {
  background-color: #ccc;
}

.containerOfficeApp input:checked ~ .checkMark {
  background-color: #ff4b2b;
}

.checkMark:after {
  content: "";
  position: absolute;
  display: none;
}

.containerOfficeApp input:checked ~ .checkMark:after {
  display: block;
}

.containerOfficeApp .checkMark:after {
  top: 8px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

@media only screen and (min-width: 850px) and (max-width: 1150px) {
  .navBottom1 {
    min-width: 320px;
    max-width: 720px;
 
  }
}
@media only screen and (min-width: 450px) and (max-width: 850px) {
  .navBottom1 {
    min-width: 320px;
    max-width: 720px;

  }
}
@media only screen and (min-width: 130px) and (max-width: 450px) {
  .navBottom1 {
    min-width: 300px;
    max-width: 720px;
    
  }
}
@media only screen and (min-width: 130px) and (max-width: 350px) {
  .navBottom1 {
    min-width: 280px;
    max-width: 720px;
    
  }
}

.torontoAgreementHeadLeft{
    padding:0px;
    margin:0px;
    margin-top:27px;
  }

  .torontoagreementTableBody{
    margin-right:30px;
    margin-left:30px;
  }

  .torontoagreementTitleBar{
    font-size: 16px ;
    font-weight: bold;
    background-color: rgb(211,211,211);
    color:#000;
    border-bottom: 1px solid rgba(000,000,000);
    border-top: 2px solid rgba(000,000,000);
  }

  .agreementBoder{
    border-bottom: 1px solid rgba(000,000,000);
  }

  .agreementRightBoder{
    border-right: 1px solid rgba(000,000,000);
  }

  hr.hrline {
    border: 1px solid rgba(000,000,000);
    border-width: 1px;
    background-color:black
  }

  .ApplicationNote{
	background-color: rgba(224, 220, 220, 0.603);
	border: none;
    font-family: "Montserrat", sans-serif;
	width: 100%;     
    /* padding-right:50px; */
    font-size: 12px;
  }

  .TrontoInput{
    border: none;
    border-bottom: 2px solid black;
      font-family: "Montserrat", sans-serif;
      padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
  }

  .bottomspanLeft{
    font-size: 10px ;
    text-align: left;
    padding-top:50px;
  }
  .bottomspanRight{
    font-size: 10px ;
    text-align: right;
    padding-top:50px;
  }
  .bottomspancenter{
    font-size: 10px ;
    text-align: center;
    padding-top:50px;
  }
.page404Contract {

  
  -webkit-flex-direction: column;

  
          flex-direction: column;
  font-family: "Montserrat", sans-serif;
  padding-top: 8%;
  margin: -20px 0 50px;
}
.page404ContractDark {
  background: #424241;
  color:#fff;
  -webkit-flex-direction: column;
          flex-direction: column;
  
  padding-top: 8%;
  margin: -20px 0 50px;
}

.PageText404 {
  padding-top: 20%;
}
.head {
  font-size: 100px;
}
.head3 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  line-height: 30px;
  padding-right: 10%;
  padding-left: 10%;
}
.head4 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  line-height: 30px;
  padding-right: 10%;
  font-weight: 800 !important;
  padding-left: 10%;
}
@media only screen and (min-device-width: 800px) and (max-device-width: 900px) {
  .head {
    font-size: 60px;
  }
  .head2 {
    font-size: 30px;
  }
  .page404Contract {
    padding-top: 8%;
    margin: -20px 0 50px;
  }
  .Image404Contract {
    padding-top: 5%;
  }
  .PageText404 {
    padding-top: 16%;
  }
  .span1 {
    font-size: 17px;
  }
}
@media only screen and (min-device-width: 650px) and (max-device-width: 800px) {
  .head {
    font-size: 30px;
  }
  .head2 {
    font-size: 23px;
  }
  .page404Contract {
    padding-top: 15%;
    margin: -20px 0 80px;
  }
  .Image404 {
    padding-top: 10%;
  }
  .PageText404 {
    padding-top: 16%;
  }
  .span1 {
    font-size: 12px;
    line-height: 20px;
  }
}
@media only screen and (min-device-width: 550px) and (max-device-width: 650px) {
  .head {
    font-size: 24px;
  }
  .head2 {
    font-size: 16px;
  }
  .page404Contract {
    padding-top: 18%;
    margin: -20px 0 50px;
  }
  .Image404 {
    padding-top: 10%;
  }
  .PageText404 {
    padding-top: 1%;
  }
  .span1 {
    font-size: 9px;
    line-height: 20px;
  }

}
@media only screen and (min-device-width: 100px) and (max-device-width: 550px) {
  .head {
    font-size: 19px;
  }
  .head2 {
    font-size: 10px;
  }
  .page404Contract {
    padding-top: 20%;
    margin: -20px 0 50px;
  }
  .Image404Contract {
    padding-top: 10% !important;
  }
  .PageText404 {
    padding-top: 1%;
  }
  .span1 {
    font-size: 8px;
    line-height: 15px;
  }
  .pri {
    /* margin-top: -20px !important; */
  }
}

.page404Contract {


  -webkit-flex-direction: column;


          flex-direction: column;
  font-family: "Montserrat", sans-serif;
  padding-top: 8%;
  margin: -20px 0 50px;
}
.registrationUI{
  padding-top: 15%;
  padding-bottom: 15%;
}
.PageText404 {
  padding-top: 20%;
}
.forgotAlign{
  /* padding:20%; */
  padding-top: 200px;
  padding-bottom: 10%;
  
}
.forgotAlign1{
  /* padding:20%; */
  padding-top: 200px;
  padding-bottom: 10%;
  
}
.forgotHead{
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
}
.forgotHead{
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
}
.forgotCard{
  padding: 1%;
  padding-top: 3%;
  width:500px;
  background-color: #fff;
  border-radius: 25px;
}
.resetLabel{
  margin-left: 32px;
  margin-bottom: -5px;
}
.resetAlert{
  margin-left: 14%;
  margin-right: 14%;
}
.head {
  font-size: 100px;
}
.resetCreateNew{
  color:rgb(142, 142, 142);
	cursor:pointer;
  font-size: 12px;
  
}
.resetCreateNew:hover{
	color:rgb(71, 175, 255);
}
.passwordDropDown{
  font-family: "Montserrat", sans-serif !important;
}
.resetStrength{
  width:300px !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px !important;
}
.resetStrengthText{
  font-size: 14px !important;

}
.resetLoader{
  position:absolute;
  bottom:20px;
  padding-left:25px;
}
.resetLoader1{
  position:absolute;
  bottom:15px;
  padding-left:65px;
}
@media only screen and (min-device-width: 1100px) and (max-device-width: 1800px) {
  .forgotAlign{
    padding-top: 150px;
  }
  .forgotAlign1{
    /* padding:20%; */
    padding-top: 150px;
    
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1100px) {
  .forgotAlign{
    padding-top: 150px;
  }
  .forgotAlign1{
    /* padding:20%; */
    padding-top: 150px;
    
  }
}
@media only screen and (min-device-width: 800px) and (max-device-width: 900px) {
  .forgotAlign{
    padding-top: 150px;
  }
  .forgotAlign1{
    /* padding:20%; */
    padding-top: 150px;
    
  }
  .head {
    font-size: 60px;
  }
  .head2 {
    font-size: 30px;
  }
  .page404Contract {
    padding-top: 8%;
    margin: -20px 0 50px;
  }
  .Image404Contract {
    padding-top: 5%;
  }
  .PageText404 {
    padding-top: 16%;
  }
  .span1 {
    font-size: 17px;
  }
}
@media only screen and (min-device-width: 650px) and (max-device-width: 800px) {
  .forgotAlign{
    padding-top: 150px;
  }
  .forgotAlign1{
    /* padding:20%; */
    padding-top: 150px;
    
  }
  .head {
    font-size: 30px;
  }
  .head2 {
    font-size: 23px;
  }
  .page404Contract {
    padding-top: 15%;
    margin: -20px 0 80px;
  }
  .Image404 {
    padding-top: 10%;
  }
  .PageText404 {
    padding-top: 16%;
  }
  .span1 {
    font-size: 12px;
    line-height: 20px;
  }
}

@media only screen and (min-device-width: 550px) and (max-device-width: 650px) {
  .forgotAlign{
    padding-top: 150px;
  }
  .forgotAlign1{
    /* padding:20%; */
    padding-top: 150px;
  }
  .head {
    font-size: 24px;
  }
  .head2 {
    font-size: 16px;
  }
  .page404Contract {
    padding-top: 18%;
    margin: -20px 0 50px;
  }
  .Image404 {
    padding-top: 10%;
  }
  .PageText404 {
    padding-top: 1%;
  }
  .span1 {
    font-size: 9px;
    line-height: 20px;
  }

}
@media only screen and (min-device-width: 100px) and (max-device-width: 550px) {
  
  .resetAlert{
    margin-left: 10%;
    margin-right: 10%;
  }
  .forgotCard{
    padding: 5%;
    padding-top: 3%;
  }

  .forgotAlign{
    padding-top: 130px; 
  }
  .forgotAlign1{
    /* padding:20%; */
    padding-top: 130px; 
  }
  .head {
    font-size: 19px;
  }
  .head2 {
    font-size: 10px;
  }
  .page404Contract {
    padding-top: 20%;
    margin: -20px 0 50px;
  }
  .Image404Contract {
    padding-top: 10% !important;
  }
  .PageText404 {
    padding-top: 1%;
  }
  .span1 {
    font-size: 8px;
    line-height: 15px;
  }
  .pri {
    /* margin-top: -20px !important; */
  }
}

.backPerson {
  background-image: url(/static/media/backPerson.4cab570a.jpg);
  background-position: center;
  background-size: cover;
  height: 650px;
}
.mainContent {
  padding: 50px;
  padding-top: 100px;
  background: linear-gradient(to right, #cac7c7, #e2dfdf);
}
.mainContentMobile {
  padding: 50px;
  padding-top: 100px;
  background: linear-gradient(to right, #cac7c7, #e2dfdf);
  display: none;
}
.mainContent1 {
  width: 100%;
  background: linear-gradient(to right, #e2e3e5, #fff);
}
.two {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 1950;
  padding-left: 15px;
}
.twoDark {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 1950;
  padding-left: 15px;
  color: #fff;
}
.oneRes {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 1950;
  padding-left: 15px;
}
.oneResDark {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 1950;
  padding-left: 15px;
  color: #fff;
}
.three {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 1950;
  padding-left: 15px;
  color: #828282;
  line-height: 10px;
}
.span3 {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  line-height: 30px;
}
.mainContentText {
  color: #4f4f4f;
  text-align: justify;
  padding: 20px;
}
.mainContentTextDark {
  color: #fff;
  text-align: justify;
  padding: 20px;
}
.mainContentText1 {
  color: #4f4f4f;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: justify;
}
.mainContentText1Dark {
  color: #fff;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: justify;
}
.backImage01 {
  background-image: url(/static/media/backImage01.15ea2149.png);
  background-position: center;
  background-size: cover;
  height: 650px;
}
.backImage02 {
  background-image: url(/static/media/backImage02.9c3cf586.png);
  background-position: center;
  background-size: cover;
  height: 650px;
}
.backImage00 {
  background-image: url(/static/media/backImage00.043e7137.jpg);
  background-position: top;
  /* background-size: cover; */
  border-radius: 25px;
  height: 550px;
}

.homePortfolio {
  margin-top: 60px;
}
.profileImage1 {
  background-image: url(/static/media/face1.73623ccd.png);
  background-position: center;
  background-size: cover;
  height: 190px;
}
.profileImage2 {
  background-image: url(/static/media/face2.4b4158ed.png);
  background-position: center;
  background-size: cover;
  height: 190px;
}
.profileImage3 {
  background-image: url(/static/media/face3.d888a207.png);
  background-position: center;
  background-size: cover;
  height: 190px;
}
.profileContentHome {
  margin-top: 20px;
}
.roundUnit {
  background-color: #e4e1e1;
  color: #e84a4a;
  padding: 10px;
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 11px;
  border-radius: 10px;
}

.footer {
  background-color: #ffffff;
  /* height:550px; */
  max-width: 1440px;
  margin: auto;
}

.footer0 {
  border-top: 1px solid #f2f2f2c7;
  box-shadow: 0px 15px 15px rgb(242, 242, 242),
    0px -15px 15px rgb(241, 241, 241);
}
.mobile {
  display: none;
}
.spanRes {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  word-wrap: break-word;
  font-weight: 100;
  /* line-height: 12px ; */
}
.spanResDark {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  word-wrap: break-word;
  font-weight: 100;
  /* line-height: 12px ; */
  color: #fff;
}
.spanFooter {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  line-height: 13px;
  margin: 15px;
}
.textPri2 {
  color: #ff6a50;
}
.footerLogo {
  margin-top: -40px;
}
.semi {
  color: #ff6a50;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
}
.footerContent {
  min-width: 250px;

  -webkit-align-content: center;

          align-content: center;
}
.companyName {
  display: -webkit-flex;
  display: flex;
}
.companyName-mobile {
  display: none;
}
.homeButtonAlign {
  margin-left: 20px;
}
.homeContent2 {
  padding: 50px;
}
.backImagePadding2 {
  padding: 50px;
  display: block;
}
.backImagePadding2Mobile {
  display: none;
  padding: 50px;
}
.homeButtonAlign2 {
  margin-left: 20px;
}
.homeContent2Mobile {
  display: none;
}
@media only screen and (min-width: 1830px) and (max-width: 3230px) {
  .backImagePadding {
    padding: 50px;
    padding-top: 80px;
  }
  .backPerson {
    height: 750px;
  }
  .two {
    font-size: 35px;
  }
  .twoDark {
    font-size: 35px;
  }
  .span3 {
    font-size: 21px;
    line-height: 40px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 11px;
    font-weight: bold;
    padding: 10px 35px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  }
  .backImage00 {
    background-position: center;
    background-size: cover;
    height: 600px;
  }
  .homeContent2 {
    padding: 160px;
  }
  .backImagePadding {
    padding: 50px;
    padding-top: 100px;
  }
  .backImagePadding2 {
    padding: 30px;
  }
  .backImage02 {
    height: 900px;
  }
  .backImage01 {
    height: 900px;
  }
  .homeProImage {
    height: 450px;
    width: 450px;
  }
  .roundUnit {
    padding: 20px;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
  }
  .mainContentText1 {
    font-size: 25px;
    line-height: 30px;
    padding-right: 150px;
    padding-left: 150px;
  }
  .mainContentText1Dark {
    font-size: 25px;
    line-height: 30px;
    padding-right: 150px;
    padding-left: 150px;
  }
}
@media only screen and (min-width: 1230px) and (max-width: 1830px) {
  .backImagePadding {
    padding: 40px;
    padding-top: 80px;
  }
  .backPerson {
    height: 650px;
  }
  .two {
    font-size: 30px;
  }
  .twoDark {
    font-size: 30px;
  }
  .span3 {
    font-size: 17px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 11px;
    font-weight: bold;
    padding: 10px 35px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  }
  .backImage02 {
    height: 600px;
  }
  .backImage01 {
    height: 600px;
  }
  .three {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: -18px;
    margin-top: -18px;
  }
  .homeProImage {
    height: 220px;
  }
  .homeProImage {
    height: 300px;
    width: 300px;
  }
  .mainContentText1 {
    font-size: 18px;
    line-height: 30px;
    padding-right: 50px;
    padding-left: 50px;
  }
  .mainContentText1Dark {
    font-size: 18px;
    line-height: 30px;
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media only screen and (min-width: 970px) and (max-width: 1230px) {
  .backImagePadding {
    padding: 30px;
    padding-top: 80px;
  }
  .backPerson {
    height: 650px;
  }
  .two {
    font-size: 30px;
  }
  .twoDark {
    font-size: 30px;
  }
  .span3 {
    font-size: 17px;
  }
  .mainContent {
    padding: 40px;
    padding-top: 60px;
  }
  .homeContent2 {
    padding: 40px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 11px;
    font-weight: bold;
    padding: 10px 35px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  }
  .backImage02 {
    height: 500px;
  }
  .backImage01 {
    height: 600px;
  }
  .homeContent3 {
    margin-top: -30px;
  }
  .three {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: -18px;
    margin-top: -18px;
  }
  .homeProImage {
    height: 220px;
    width: 220px;
  }
}
@media only screen and (min-width: 715px) and (max-width: 970px) {
  .backImagePadding {
    padding: 30px;
    padding-top: 80px;
  }
  .backPerson {
    height: 550px;
  }
  .two {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    font-weight: 1950;
    padding-left: 8px;
  }
  .twoDark {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    font-weight: 1950;
    padding-left: 8px;
  }
  .span3 {
    font-size: 15px;
    line-height: 20px;
  }
  .mainContent {
    padding: 30px;
    padding-top: 60px;
  }

  .mainContentText {
    padding: 12px;
  }
  .mainContentTextDark {
    padding: 12px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 11px;
    font-weight: bold;
    padding: 10px 35px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  }
  .backImage00 {
    height: 470px;
  }
  .backImagePadding2 {
    padding: 20px;
    padding-top: 80px;
  }
  .homeContent2 {
    padding: 20px;
  }
  .backImage02 {
    height: 500px;
  }
  .backImage01 {
    height: 500px;
  }
  .homeContent3 {
    margin-top: 60px;
  }
  .three {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: -12px;
    margin-top: -12px;
  }
  .homeProImage {
    height: 150px;
    width: 150px;
  }
  .roundUnit {
    padding: 10px;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 10px;
  }
  .homeButtonAlign {
    margin-left: 5px;
  }
}
@media only screen and (min-width: 575px) and (max-width: 715px) {
  .backPerson {
    height: 450px;
  }
  .two {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 1950;
    padding-left: 2px;
  }
  .twoDark {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 1950;
    padding-left: 2px;
  }
  .span3 {
    font-size: 12px;
    line-height: 17px;
  }
  .mainContent {
    padding: 30px;
    padding-top: 60px;
  }
  .mainContentText {
    padding: 6px;
  }
  .mainContentTextDark {
    padding: 6px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 9px;
    font-weight: bold;
    padding: 6px 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  }
  .homeButtonAlign {
    margin-left: 5px;
  }
  .backImage00 {
    background-size: cover;
    height: 410px;
  }
  .homeContent2 {
    padding: 13px;
  }
  .backImagePadding2 {
    padding: 23px;
    padding-top: 63px;
  }
  .backImagePadding {
    padding: 2px;
    padding-top: 50px;
  }
  .backImage02 {
    height: 400px;
  }
  .backImage01 {
    margin-left: 10px;
    height: 400px;
  }
  .homeContent3 {
    margin-top: 40px;
  }
  .homeButtonAlign2 {
    margin-left: 10px;
  }
  .three {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: -16px;
    margin-top: -16px;
  }
  .homeProImage {
    height: 100px;
    width: 100px;
  }
  .roundUnit {
    padding: 5px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 9px;
  }
  .spanRes {
    font-size: 10px;
    line-height: 10px;
  }
  .spanResDark {
    font-size: 10px;
    line-height: 10px;
  }
  .oneRes {
    font-size: 12px;
  }
  .oneResDark {
    font-size: 12px;
  }
}
@media only screen and (min-width: 430px) and (max-width: 575px) {
  .backPerson {
    height: 380px;
  }
  .two {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 1950;
    padding-left: 0px;
  }
  .twoDark {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 1950;
    padding-left: 0px;
  }
  .span3 {
    font-size: 11px;
    line-height: 14px;
  }
  .mainContent {
    padding: 20px;
    padding-top: 50px;
  }
  .mainContentText {
    padding: 2px;
    margin-bottom: 5px;
  }
  .mainContentTextDark {
    padding: 2px;
    margin-bottom: 5px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 6px;
    font-weight: bold;
    padding: 3px 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  }
  .homeButtonAlign {
    margin-left: 5px;
    margin-left: 5px;
  }
  .backImage00 {
    background-size: cover;
    height: 350px;
  }
  .homeContent2 {
    padding: 5px;
  }
  .backImagePadding {
    padding: 2px;
    padding-top: 30px;
  }
  .backImagePadding2 {
    padding: 30px;
  }
  .backImage02 {
    margin-top: 30px;
    height: 350px;
  }
  .backImage01 {
    margin-top: 30px;
    height: 350px;
    margin-left: 10px;
  }
  .homeContent3 {
    margin-top: 40px;
  }
  .homeContent4 {
    padding: 15px;
  }
  .homeButtonAlign2 {
    margin-left: 5px;
  }
  .three {
    font-size: 15px;
    line-height: 15px;
    margin-bottom: -16px;
    margin-top: -5px;
  }
  .homeProImage {
    height: 150px;
    width: 150px;
  }
  .roundUnit {
    padding: 5px;
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .spanRes {
    font-size: 15px;
    line-height: 10px;
  }
  .spanResDark {
    font-size: 15px;
    line-height: 10px;
  }
  .oneRes {
    font-size: 15px;
  }
  .oneResDark {
    font-size: 15px;
  }
  .profileContentPart {
    min-width: 400px;
  }
  .mainContentText1 {
    font-size: 23px;
    line-height: 25px;
    padding: 30px;
  }
  .mainContentText1Dark {
    font-size: 23px;
    line-height: 25px;
    padding: 30px;
  }
}
@media only screen and (min-width: 350px) and (max-width: 430px) {
  .backPerson {
    height: 280px;
    background-position: top;
    background-size: cover;
  }
  .two {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 1950;
    padding-left: 0px;
  }
  .twoDark {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 1950;
    padding-left: 0px;
  }
  .span3 {
    font-size: 14px;
    line-height: 18px;
  }
  .mainContent {
    padding: 20px;
    padding-top: 30px;
    display: none;
  }
  .backImagePadding2 {
    display: none;
  }
  .backImagePadding2Mobile {
    display: block;
    padding: 30px;
  }
  .homePortfolio {
    margin-top: -10px;
    padding: 10px;
  }
  .mainContentMobile {
    padding: 30px;
    padding-top: 30px;
    background: linear-gradient(to right, #cac7c7, #e2dfdf);
    display: block;
  }
  .mainContentText {
    padding: 2px;
  }
  .mainContentTextDark {
    padding: 2px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 11px;
    font-weight: bold;
    padding: 7px 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  }
  .homeButtonAlign {
    margin-left: 3px;
    margin-top: -10px;
  }
  .companyName-mobile {
    display: block;
  }
  .companyName {
    display: none;
  }
  .backImage00 {
    background-position: center;
    background-size: cover;
    height: 260px;
  }
  .homeContent2 {
    padding: 0px;
  }
  .web {
    display: none;
  }
  .mobile {
    display: block;
  }
  .backImagePadding {
    padding: 0px;
    padding-top: 30px;
  }
  .backImagePadding2 {
    padding: 30px;
  }
  .backImage02 {
    margin-top: -10px;
    margin-bottom: -27px;
    height: 300px;
  }
  .backImage01 {
    margin-top: 0px;
    height: 300px;
    margin-left: 0px;
  }
  .homeContent3 {
    margin-top: 40px;
  }
  .homeContent4 {
    padding: 15px;
  }
  .homeButtonAlign2 {
    margin-left: 5px;
  }
  .three {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: -16px;
    margin-top: -16px;
  }
  .homeProImage {
    height: 120px;
    width: 120px;
  }
  .roundUnit {
    padding: 5px;
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .spanRes {
    font-size: 13px;
    line-height: 10px;
  }
  .spanResDark {
    font-size: 13px;
    line-height: 10px;
  }
  .oneRes {
    font-size: 13px;
  }
  .oneResDark {
    font-size: 13px;
  }
  .profileContentPart {
    min-width: 300px;
  }
  .mainContentText1 {
    font-size: 21px;
    line-height: 25px;
    padding: 20px;
  }
  .mainContentText1Dark {
    font-size: 21px;
    line-height: 25px;
    padding: 20px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 350px) {
  .backPerson {
    height: 320px;
  }
  .web {
    display: none;
  }
  .mobile {
    display: block;
  }
  .two {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 1950;
    padding-left: 0px;
  }
  .twoDark {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 1950;
    padding-left: 0px;
  }
  .span3 {
    font-size: 11px;
    line-height: 13px;
  }
  .mainContent {
    padding: 15px;
    padding-top: 30px;
    display: none;
  }

  .mainContentMobile {
    padding: 30px;
    padding-top: 30px;
    background: linear-gradient(to right, #cac7c7, #e2dfdf);
    display: block;
  }
  .mainContentText {
    padding: 2px;
  }
  .mainContentTextDark {
    padding: 2px;
  }
  button.buttonPriHome {
    border-radius: 20px;
    font-size: 9px;
    font-weight: bold;
    padding: 5px 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  }
  .homeButtonAlign {
    margin-right: 3px;
    margin-top: -20px;
  }
  .backImagePadding2 {
    display: none;
  }
  .backImagePadding2Mobile {
    display: block;
    padding: 20px;
  }
  .homePortfolio {
    margin-top: -10px;
    padding: 10px;
  }
  .companyName-mobile {
    display: block;
  }
  .companyName {
    display: none;
  }
  .backImage00 {
    background-size: cover;
    height: 260px;
  }
  .homeContent2 {
    padding: 0px;
  }
  .backImagePadding {
    padding: 0px;
    padding-top: 20px;
  }
  .backImagePadding2 {
    padding: 20px;
  }
  .backImage02 {
    margin-top: -10px;
    height: 250px;
  }
  .backImage01 {
    margin-top: -20px;
    margin-left: 0px;
    height: 290px;
  }
  .homeContent3 {
    margin-top: 20px;
  }
  .homeButtonAlign2 {
    margin-left: 20px;
    margin-top: -500px;
  }
  .homeContent4 {
    padding: 10px;
  }
  .homeButtonAlign2 {
    margin-left: 5px;
  }
  .three {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: -16px;
    margin-top: -16px;
  }
  .homeProImage {
    height: 120px;
    width: 120px;
  }
  .roundUnit {
    padding: 5px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .spanRes {
    font-size: 13px;
    line-height: 10px;
  }
  .spanResDark {
    font-size: 13px;
    line-height: 10px;
  }
  .oneRes {
    font-size: 13px;
  }
  .oneResDark {
    font-size: 13px;
  }
  .profileContentPart {
    min-width: 250px;
  }
  .mainContentText1 {
    font-size: 18px;
    line-height: 25px;
    padding: 1px;
  }
  .mainContentText1Dark {
    font-size: 18px;
    line-height: 25px;
    padding: 1px;
  }
}

.container-contact100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 15px;
  background: #ffffff;
  
}

.wrap-contact100 {
  width: 1120px;
  background: #fff;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/*==================================================================
[ Text ]*/
.txt1 {
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
  line-height: 1.2;
  color: #fff;
}

.txt2 {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  line-height: 1.6;
  color: #d2d2d2;
}

.txt3 {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  color: #fecec5;
}

/*==================================================================
[ Size ]*/
.size1 {
  width: 355px;
  max-width: 100%;
  margin-bottom: 7%;
}

.size2 {
  width: calc(100% - 43px);
}

textarea:focus,
input:focus {
  outline: none;
}
/*==================================================================
   [ Contact more ]*/
.contact100-more {
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-align-content: center;
          align-content: center;
  position: relative;
  z-index: 1;
  padding: 30px 15px 0px 15px;
  padding-top: 15%;
  padding-left: 10%;
  
}

.contact100-more::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(146, 34, 22, 0.822);
}

/*==================================================================
   [ Form ]*/

.contact100-form {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 56px 55px 63px 55px;
}
hr.rdLine {
  width: 115px;
  border: 2px solid #ff4b2b;
  margin-bottom: 40px;
}
hr.lightLine {
  width: 100%;
  border: 1px dashed #edededb6;
  margin-bottom: 10px;
  margin-top: 5px;
}
.contact100-form-title {
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 33px;
}

/*------------------------------------------------------------------
   [ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border: 1px solid #e6e6e6;
}

.rs1-wrap-input100,
.rs2-wrap-input100 {
  width: 50%;
}

.rs2-wrap-input100 {
  border-left: none;
}

.label-input100 {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #373737;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  min-height: 55px;
  border: 1px solid #e6e6e6;
  border-bottom: none;
  padding: 10px 25px;
  margin-top: 15px;
  margin-bottom: 0;
}
.input100::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(198, 198, 198);
  opacity: 1; /* Firefox */
}
.input100:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(198, 198, 198);
  opacity: 1; /* Firefox */
}
.input100::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(198, 198, 198);
  opacity: 1; /* Firefox */
}
.input100 {
  display: block;
  width: 100%;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;

  line-height: 1.2;
  padding: 0 25px;
}

input.input100 {
  height: 55px;
}

textarea.input100 {
  min-height: 139px;
  padding-top: 19px;
  padding-bottom: 15px;
  border: 1px solid #f4f4f4;
}

/*---------------------------------------------*/

.focus-input100 {
  color: #c7c7c7;

  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #bdbdbd;

  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;

  -webkit-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}

.input100:focus + .focus-input100 {
  visibility: visible;
  opacity: 1;
  color: #c7c7c7;

  -webkit-transform: scale(1);
  transform: scale(1);
}

/*------------------------------------------------------------------
   [ Button ]*/
.container-contact100-form-btn {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 23px;
}

.contact100-form-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 20px;
  min-width: 200px;
  height: 50px;
  border-radius: 2px;
  background: #ff4b2b;

  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.4s;
}

.contact100-form-btn:hover {
  background: #333333;
}

/*------------------------------------------------------------------
   [ Responsive ]*/

@media (max-width: 992px) {
  .contact100-form {
    width: 60%;
    padding: 56px 30px 63px 30px;
  }

  .contact100-more {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .contact100-form {
    width: 100%;
  }

  .contact100-more {
    width: 100%;
  }
}
@media (max-width: 356px) {
  .txt1 {
    font-size: 20px;
    line-height: 1.2;
  }

  .txt2 {
    font-size: 14px;
    line-height: 1.2;
  }

  .txt3 {
    font-size: 13px;
    line-height: 1.2;
  }
}
@media (max-width: 576px) {
  .contact100-form {
    padding: 56px 15px 63px 15px;
  }

  .rs1-wrap-input100,
  .rs2-wrap-input100 {
    width: 100%;
  }

  .rs2-wrap-input100 {
    border-left: 1px solid #e6e6e6;
    border-top: none;
  }
}

/*------------------------------------------------------------------
   [ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #ff4b2b;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 2px;
  pointer-events: none;

  font-family: "Montserrat", sans-serif;
  color: #ff4b2b;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\2757";
  font-family: "Montserrat", sans-serif;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

.aboutImage {
  /* position: fixed; */
  z-index: -1;
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  -webkit-align-content: center;
          align-content: center;
  padding-top: 16%;
}
.aboutContain{
  padding-bottom: 11%;
  padding-right: 12%;
  padding-left: 12%;
}
.line-1 {
  position: relative;

  width: 22em;
  height: 1.3em;
  margin: 4px auto;
  padding-top: 12px;
  padding-bottom: 12px;
  border-right: 3px solid rgba(255, 255, 255, 0.75);
  font-size: 240%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-family: "Montserrat", sans-serif;

  font-weight: 600;
  color: rgb(255, 255, 255);

  text-shadow: rgb(36, 2, 2) 9px 0 15px;
}
.aboutText{

  color: rgb(109, 109, 109);
  line-height:40px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
  text-align: justify;
  text-justify: inter-word;

}
/* Animation */
.anim-typewriter {
  -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
          animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}
@-webkit-keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 22em;
  }
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 22em;
  }
}
@-webkit-keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 22em;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}

@media (max-width: 1200px) {
  .aboutImage {
    height: 400px;
    padding-top: 19%;
  }
  .line-1 {
    width: 22em;
    height: 1.3em;
    margin: 4px auto;
    padding-top: 12px;
    padding-bottom: 12px;
    border-right: 3px solid rgba(255, 255, 255, 0.75);
    font-size: 240%;
  }
}


@media (max-width: 850px) {
     .aboutImage {
       height: 350px;
       padding-top: 22%;
     }
     .line-1 {
       width: 22em;
       height: 1.3em;
       margin: 4px auto;
       padding-top: 12px;
       padding-bottom: 12px;
       border-right: 3px solid rgba(255, 255, 255, 0.75);
       font-size: 180%;
     }
   }
   
@media (max-width: 750px) {
  .aboutContain{
    padding-bottom: 11%;
    padding-right: 10%;
    padding-left: 10%;
  }
     .aboutImage {
       height: 350px;
       padding-top: 26%;
     }
     .line-1 {
       width: 22em;
       height: 1.3em;
       margin: 4px auto;
       padding-top: 12px;
       padding-bottom: 12px;
       border-right: 3px solid rgba(255, 255, 255, 0.75);
       font-size: 180%;
     }
   }
   @media (max-width: 650px) {
    .aboutContain{
      padding-bottom: 11%;
      padding-right: 9%;
      padding-left: 9%;
    }
     .aboutImage {
       height: 300px;
       padding-top: 28%;
     }
     .line-1 {
       width: 22em;
       height: 0.8em;
       margin: 4px auto;
       padding-top: 0px;
       padding-bottom: 12px;
       border-right: 3px solid rgba(255, 255, 255, 0.75);
       font-size: 140%;
     }
   }
   @media (max-width: 540px) {
    .aboutContain{
      padding-bottom: 11%;
      padding-right: 8%;
      padding-left: 8%;
    }
    .aboutText{
      line-height:30px;
      font-size: 20px;
    }
     .aboutImage {
       height: 280px;
       padding-top: 30%;
       background-size: cover;
     }
     .line-1 {
       width: 22em;
       height: 0.8em;
       margin: 4px auto;
       padding-top: 0px;
       padding-bottom: 21px;
       border-right: 3px solid rgba(255, 255, 255, 0.75);
       font-size: 100%;
     }
   }
   @media (max-width: 440px) {
    .aboutContain{
      padding-bottom: 11%;
      padding-right: 7%;
      padding-left: 7%;
    }
    .aboutText{
      line-height:25px;
      font-size: 18px;
    }
     .aboutImage {
       height: 250px;
       padding-top: 38%;
       background-size: cover;
     }
     .line-1 {
       width: 22em;
       height: 0.8em;
       margin: 4px auto;
       padding-top: 0px;
       padding-bottom: 21px;
       border-right: 3px solid rgba(255, 255, 255, 0.75);
       font-size: 100%;
     }
   }
   @media (max-width: 360px) {
    .aboutText{
      line-height:25px;
      font-size: 17px;
    }
    .aboutContain{
      padding-bottom: 11%;
      padding-right: 6%;
      padding-left: 6%;
    }
     .aboutImage {
       height: 230px;
       padding-top: 40%;
       background-size: cover;
     }
     .line-1 {
       width: 22em;
       height: 0.8em;
       margin: 4px auto;
       padding-top: 0px;
       padding-bottom: 21px;
       border-right: 3px solid rgba(255, 255, 255, 0.75);
       font-size: 80%;
     }
   }
.AxText1{
     font-family: "Montserrat", sans-serif;
     color:#838383  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     line-height: 20px !important;
     text-transform: uppercase;
}
.AxText2{
     font-family: "Montserrat", sans-serif;
     color:#000000  !important;
     letter-spacing: 0.5px;
     font-size: 19px;
     font-weight: 800;
     line-height: 20px !important;
     margin:  6px 0px;
}
.AxText3{
     font-family: "Montserrat", sans-serif;
     color:#f84343  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     line-height: 20px !important;
}
.AxTextDisable3{
     font-family: "Montserrat", sans-serif;
     color:#c1c1c1  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     line-height: 20px !important;
}
.AxText4{
     font-family: "Montserrat", sans-serif;
     color:#000000  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 400;
     text-align: justify;
     line-height: 18px !important;
}
.AxText5{
     font-family: "Montserrat", sans-serif;
     color:#414141;
     letter-spacing: 0.5px;
     font-size: 15px;
     font-weight: 600;
     line-height: 20px !important;
}
.AxText6{
     font-family: "Montserrat", sans-serif;
     color:#949494  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     text-align: center;
     line-height: 18px !important;
}
.AxText7{
     font-family: "Montserrat", sans-serif;
     color:#9f9f9f  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 400;
     text-align: justify;
     line-height: 18px !important;
}
.AxText8{
     font-family: "Montserrat", sans-serif;
     color:#f84343  !important;
     letter-spacing: 0.5px;
     font-size: 16px;
     font-weight: 600;
     line-height: 20px !important;
}

.profileAvatar{
     height: 40px;
     width: 40px !important;
     padding: 5px;
     border-radius: 50%;
     background-color: #ffffff;
}
.MaintenanceRequestCardProfile{
     width:15%;
     -webkit-align-self: left;
             align-self: left;
     text-align: left;
}
.MaintenanceRequestCardName{
     width:50%;
     -webkit-align-self: left;
             align-self: left;
     text-align: left;
     padding-top: 3px;
}
.MaintenanceRequestCardDuration{
     width:35%;
     padding-top: 5px;
}
.maintenanceCardAlign{
     min-width: 400px;
     max-width: 500px;
}
.maintenanceHistoryCardAlign{
     min-width: 300px;
     max-width: 500px;
}

@media only screen and (min-width: 1200px) and (max-width: 1250px), (min-width:768px) and (max-width: 992px) , (min-width:10px) and (max-width: 485px){
     .AxText1{
          font-size: 10px;
     }
     .AxText2{
          font-size: 17px;
     }
     .AxText3{
          font-size: 10px;
     }
     .AxTextDisable3{
          font-size: 10px;
     }
     .AxText4{
          font-size: 10px;
     }
     .AxText5{
          font-size: 13px;
     }
     .AxText6{
          font-size: 10px;
     }
     .AxText7{
          font-size: 10px;
     }
}

.fullNotificationWindow{
     width:100%;
     border:1px solid #ff4949;
     background-color: #FFD6BF;
     display: -webkit-flex;
     display: flex;
     margin:10px 0px;
}
.fullNotificationIcon{
     -webkit-flex:2 1;
             flex:2 1;
     color:#ff4949;
     -webkit-align-self: center;
             align-self: center;
     text-align: center;
}
.fullNotificationText{
     -webkit-flex:35 1;
             flex:35 1;
     -webkit-align-self: center;
             align-self: center;
     text-align: left;
     margin-bottom: 13px;
     margin-top:13px;
     font-family: "Montserrat", sans-serif;  
     font-size: 14px;
     line-height: 21px;
     color:#000000;
}
.textNotificationRed{
     color:#ff5628;
     font-weight: 600 !important;
     font-size: 14px;
     
}
.fullNotificationClose{
     color:#0000009c;
     font-size: 18px;
     -webkit-flex:1.5 1;
             flex:1.5 1;
     -webkit-align-self: center;
             align-self: center;
     text-align: center;
     font-weight: 300 !important;
     cursor: pointer;
}

@media only screen and (min-width: 750px) and (max-width:1000px) {
     .fullNotificationIcon{
          -webkit-flex:3 1;
                  flex:3 1;
     }
     .fullNotificationClose{

          -webkit-flex:3 1;

                  flex:3 1;
          
     }

}
@media only screen and (min-width: 500px) and (max-width:750px) {
     .fullNotificationIcon{
          -webkit-flex:5 1;
                  flex:5 1;
     }
     .fullNotificationClose{

          -webkit-flex:5 1;

                  flex:5 1;
          
     }

}
@media only screen and (min-width: 00px) and (max-width:500px) {
     .fullNotificationIcon{
          -webkit-flex:7 1;
                  flex:7 1;
          -webkit-align-self: flex-start;
                  align-self: flex-start;
          padding-top: 4%;
          text-align: center;
     }
     .fullNotificationClose{
          -webkit-align-self: flex-start;
                  align-self: flex-start;
          padding-top: 4%;
          text-align: center;
          -webkit-flex:7 1;
                  flex:7 1;
          
     }

}
@media only screen and (min-width: 00px) and (max-width:400px) {
     .fullNotificationIcon{
          -webkit-flex:8 1;
                  flex:8 1;
          -webkit-align-self: flex-start;
                  align-self: flex-start;
          padding-top: 6%;
          text-align: center;
     }
     .fullNotificationClose{
          -webkit-align-self: flex-start;
                  align-self: flex-start;
          padding-top: 4%;
          text-align: center;
          -webkit-flex:8 1;
                  flex:8 1;
          
     }
     .fullNotificationText{
          font-size: 11px;
     }
     .textNotificationRed{
          font-size: 11px;  
     }

}
.maintenanceRequestDetailWindow{
     padding-top:85px;
     margin-bottom: 5%;
     margin-right: 2%;
     margin-left: 2%;
}
.maintenanceRequestCard{
     background-color: #fff;
     border-radius: 5px;
     box-shadow: rgba(179, 179, 179, 0.28) 0px 2px 30px 0px;
     padding:15px 25px;
}
.maintenanceRequestDetailContainer{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-wrap: wrap;
             flex-wrap: wrap;
}


.maintenanceRequestDetailText1{
     font-family: "Montserrat", sans-serif;
     color:#838383  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     line-height: 20px !important;
     text-transform: uppercase;
}
.maintenanceRequestDetailText2{
     font-family: "Montserrat", sans-serif;
     color:#000000  !important;
     letter-spacing: 0.5px;
     font-size: 19px;
     font-weight: 800;
     line-height: 20px !important;
     margin:  6px 0px;
}
.maintenanceRequestDetailText3{
     font-family: "Montserrat", sans-serif;
     color:#f84343  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     line-height: 20px !important;
}
.maintenanceRequestDetailTextDisable3{
     font-family: "Montserrat", sans-serif;
     color:#c1c1c1  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     line-height: 20px !important;
}
.maintenanceRequestDetailText4{
     font-family: "Montserrat", sans-serif;
     color:#000000  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 400;
     text-align: justify;
     line-height: 18px !important;
}
.maintenanceRequestDetailText5{
     font-family: "Montserrat", sans-serif;
     color:#414141;
     letter-spacing: 0.5px;
     font-size: 15px;
     font-weight: 600;
     line-height: 20px !important;
}
.maintenanceRequestDetailText6{
     font-family: "Montserrat", sans-serif;
     color:#949494  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     text-align: center;
     line-height: 18px !important;
}
.maintenanceRequestDetailText7{
     font-family: "Montserrat", sans-serif;
     color:#9f9f9f  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 400;
     text-align: justify;
     line-height: 18px !important;
}
 .maintenanceRequestDetailUnitImage{
     height: 45px;
     width: 45px;
     border-radius: 10px;
   }
.maintenanceRequestDetailTenantImage{
     height: 45px;
     width: 45px;
     border-radius: 50%;
}
.maintenanceRequestDetailIconImage{
     height: 45px;
     width: 45px;
}
.maintenanceRequestDetailIconImage2{
     height: 60px;
     width: 60px;
    
     
}
.maintenanceRequestDetailNotJet{
     font-family: "Montserrat", sans-serif;
     color:#F89212;
     letter-spacing: 0.5px;
     font-size: 13px;
     font-weight: 600;
     -webkit-align-self: center;
             align-self: center;
}
.webViewHistory{
     display: block;
}
.tabViewHistory{
     display: none;
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
     .webViewHistory{
          display: none;
     }
     .tabViewHistory{
          display: block;
     }
}

@media only screen and (min-width: 0px) and (max-width: 425px) {
     .maintenanceRequestDetailWindow{

          margin-right: 0.5%;
          margin-left: 0.5%;
     }
}

@media only screen and (min-width: 1200px) and (max-width: 1250px), (min-width:768px) and (max-width: 992px) , (min-width:10px) and (max-width: 485px){
     .maintenanceRequestDetailText1{
          font-size: 10px;
     }
     .maintenanceRequestDetailText2{
          font-size: 17px;
     }
     .maintenanceRequestDetailText3{
          font-size: 10px;
     }
     .maintenanceRequestDetailTextDisable3{
          font-size: 10px;
     }
     .maintenanceRequestDetailText4{
          font-size: 10px;
     }
     .maintenanceRequestDetailText5{
          font-size: 13px;
     }
     .maintenanceRequestDetailText6{
          font-size: 10px;
     }
     .maintenanceRequestDetailText7{
          font-size: 10px;
     }
}

/* Time line */

#timeline-content {
     /* margin-top: 50px; */
     /* text-align: center; */
   }
   .timeline {
     border-left: 4px solid #ff4b2b73;
     border-bottom-right-radius: 4px;
     border-top-right-radius: 4px;
     background: rgba(255, 255, 255, 0.03);
     color: rgba(255, 255, 255, 0.8);
     font-family: "Montserrat", sans-serif;
     margin: 5px auto;
     letter-spacing: 0.5px;
     position: relative;
     line-height: 1.2em;
     font-size: 1.03em;
     padding: 22px;
     padding-top: 0px;
     margin-top: 20px;
     padding-bottom: 25px;
     list-style: none;
     text-align: left;
     font-weight: 100;
     margin-left:-2px;
   }
   .timeline h1 {
     font-family: "Montserrat", sans-serif;
     letter-spacing: 1.5px;
     font-weight: 100;
     font-size: 1.4em;
   }
   .timeline h2,
   .timeline h3 {
     font-family: "Montserrat", sans-serif;
     letter-spacing: 1.5px;
     font-weight:600;
     font-size: 1.2em;
     color:#3e3a3a;
   }
   .timeLineMobileDate{
     font-family: "Montserrat", sans-serif;
     letter-spacing: 1px;
     font-size: 11px;
     font-weight: 500;
     text-align: justify;
     padding-bottom: 12px;
     display: block;
   }
   .timeline .event {
     border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
     padding-bottom: 22px;
     /* margin-bottom: 50px; */
     position: relative;
     font-family: "Montserrat", sans-serif;
     color:#414141;
     letter-spacing: 0.5px;
     font-size: 13px;
     font-weight: 600;
     line-height: 20px !important;
   }
   .timeline .event:last-of-type {
     padding-bottom: 0;
     margin-bottom: 0;
     border: none;
   }
   .timeline .event:before,
   .timeline .event:after {
     position: absolute;
     display: block;
     top: 0;
   }
   .timeline .event:before {
     left: 0px;
     content: attr(data-date);
     text-align: right;
     font-weight: 100;
     font-size: 0.9em;
     min-width: 120px;
     
     font-family: "Montserrat", sans-serif;
     color:#908f8f;
     letter-spacing: 0.5px;
     font-size: 11px;
     font-weight: 500;
     display: none;
   }
   .timeline .event:after {
     box-shadow: 0 0 0 4px #ff4b2b;
     left: -30px;
     background: #fafafa;
     border-radius: 50%;
     height: 11px;
     width: 11px;
     content: "";
     top: 5px;
   }
    .maintenanceRequestTimelineText{
          font-family: "Montserrat", sans-serif;
          letter-spacing: 1px;
          font-size: 11px;
          font-weight: 500;
          text-align: left;
          line-height: 21px !important;
          display:inline-block;
     
          word-wrap:break-word;
          white-space: normal;

     }
     
     .timeLineLabel{
          word-break: break-all;
     }

     @media only screen and (min-width: 680px) and (max-width: 767px) {
          .timeline-container{
               margin-top: 3%;
          }
          .timeline .event:before {
               display: block;
          }
          .timeline {
               margin: 10px auto;
           
               margin-left: 29%;
          }
          .timeline .event:before {
               left: -210px;
          }
          #timeline-content {
               margin-top: 5%;
               
          }
          .timeLineMobileDate{
               display: none;
             }
     } 
     
   @media only screen and (min-width: 568px) and (max-width: 680px) {
     .timeline-container{
          margin-top: 3%;
     }
     .timeline {
        margin-left: 14%;
      }
      .timeLineMobileDate{
          display: block;
     }
     .timeline .event:before {
          display: none;
     }
     #timeline-content {
          margin-top: 5%;
     }
   }
   @media only screen and (min-width: 368px) and (max-width: 568px) {
     .timeline-container{
          margin-top: 5%;
     }
     .timeline {
          margin-left: 2%;
        }
     .timeLineMobileDate{
          display: block;
     }
     .timeline .event:before {
          display: none;
     }
     #timeline-content {
          margin-top: 2%;
     }
}
@media only screen and (min-width: 00px) and (max-width: 368px) {
     .timeline-container{
          margin-top: 5%;
     }
     .timeline {
          margin-left: 2%;
        }
     .timeLineMobileDate{
          display: block;
     }
     .timeline .event:before {
          display: none;
     }
     #timeline-content {
          margin-top: 2%;
     }
}

.maintenanceBasicDetailsNameAlign{
     padding-top:1%;
     width:70%;
}
.maintenanceBasicDetailsExpandAlign{
     width:10%;
}
.maintenanceBasicDetailsImageAlign{
     width:20%;
     -webkit-align-self: left;
             align-self: left;
     text-align: left;
}
.maintenanceBasicDetailsDateAlign{
     width:20%;
     -webkit-align-self: left;
             align-self: left;
     text-align: left;
     margin-top:-5px !important;
}

@media only screen and (min-width: 614px) and (max-width: 730px) {
     .maintenanceBasicDetailsDateAlign{
          width:30%;
          -webkit-align-self: left;
                  align-self: left;
          text-align: left;
     }
}
@media only screen and (min-width: 0px) and (max-width: 890px) {
     .maintenanceBasicDetailsImageAlign{
          width:100%;
          
     }
     .maintenanceBasicDetailsNameAlign{
          padding-top:3%;
          width:90%;
     }
     .maintenanceBasicDetailsExpandAlign{
          width:10%;
     }
}

/* Toggle Button  */

.dropdownButton {
     border-top-right-radius: 0%;
     border-top-left-radius: 10px;
     border-bottom-left-radius:  10px;
     border-bottom-right-radius: 0%;
     font-size: 12px;
     font-weight: bold;
     padding: 4px 10px;
     letter-spacing: 1px;
     text-transform: uppercase;
     border: none;
     color: #fff;
   }
   
   .dropdownButton.icon {
     border-top-right-radius:  10px;
     border-top-left-radius: 0%;
     border-bottom-left-radius:0%;
     border-bottom-right-radius:  10px;
     border-left: solid 1px #ffffff;
   }
   
   .dropdownButton-component {
     position: absolute;
     display: inline-block;
   }
   
   .dropdownButton-content {
     display: none;
     position: absolute;
     background-color: #f1f1f1;
     min-width: 160px;
     z-index: 1;
   }
   
   .dropdownButton-content span {
     color: black;
     padding: 6px 10px;
     text-decoration: none;
     display: block;
     cursor: pointer;
     font-size: 14px;
     font-family: "Montserrat", sans-serif;
   }
   
   .dropdownButton-content span:hover {background-color: #ddd}
   
   .dropdownButton-component:hover .dropdownButton-content {
     display: block;
   }
   
   .yellowDropdownButton .dropdownButton:hover.icon{
     background-color: #febe6f;
   }
   .yellowDropdownButton .dropdownButton{
     background-color: #F89212;
   }

.maintenanceImage{
     height:300px;
     border-radius: 10px;
     width:100%;
     background-position: center;
     background-size: cover;
     background-repeat: no-repeat;
     background-color: #3e3e3e;

   }
   .maintenanceImage2{
     height:50px;
     border-radius: 10px;
     width:100%;
     background-position: center;
     background-size: cover;
     margin-bottom: 12px;
}
.maintenanceImage4{
    
     height:40rem;
     width:100%;
     background-position: center;
     background-size: contain;
     background-repeat: no-repeat;
     background-color:transparent;

   }
.maintenanceImage2Active{
     height:50px;
     border-radius: 10px;
     border: 4px solid #f84b4b97;
     width:100%;
     background-position: center;
     background-size: cover;
     margin-bottom: 12px;
}

   .maintenanceImage3{
     height:50px;
     width:65px;
     border-radius: 10px;
     background-position: center;
     background-size: cover;
     margin-right: 12px;
     margin-bottom: 12px;
     margin-top: 12px;
     display: inline-block; 
}

.maintenanceImage3Active{
     height:50px;
     width:65px;
     border-radius: 10px;
     border: 4px solid #f84b4b97;
     
     background-position: center;
     background-size: cover;
     margin-right: 12px;
     margin-top: 12px;
     margin-bottom: 12px;
     display: inline-block; 
}
.maintenanceImage5{
     height:70px;
     width:125px;
     border-radius: 7px;
     background-position: center;
     background-size: cover;
     margin-right: 12px;
     display: inline-block; 
}


     .maintenanceImageCollection{
          max-height:300px;
          overflow: scroll;
     }
   .maintenanceImageCollection::-webkit-scrollbar {
     width: 1px;
      height: 0px;
   }
   .maintenanceImageCollection::-webkit-scrollbar-track {
     background: #f1f1f1;
   }
   .maintenanceImageCollection::-webkit-scrollbar-thumb {
     background: #fd753f36;
   }
   .maintenanceImageCollection::-webkit-scrollbar-thumb:hover {
     background: #918c8b;
   }
   .imageViewerIconClose{
     color:rgb(255, 255, 255);
     font-size: 20px;
     cursor: pointer;
     position: absolute;
     top: 0%;
     right:0%;
   }
   .imageViewerIconClose2{
     color:rgb(255, 255, 255);
     font-size: 20px;
     cursor: pointer;
     position: absolute;
     top: 5%;
     right:5%;
   }
   .imageViewerIconExpand{
     color:rgb(255, 255, 255);
     font-size: 20px;
     cursor: pointer;
     position: absolute;
     top: 5%;
     right:3%;
   }
   .imageViewerIconRight{
        color:rgba(255, 255, 255, 0.885);
        font-size: 50px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        right:10px;
        text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.165),
                          1px 1px 0 rgba(0, 0, 0, 0.165),
                         1px -1px 0 rgba(0, 0, 0, 0.165),
                        -1px -1px 0 rgba(0, 0, 0, 0.165);
   }
   .imageViewerIconRight:hover{
          color:rgb(255, 255, 255);
          text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.165),
                          1px 1px 0 rgba(0, 0, 0, 0.165),
                         1px -1px 0 rgba(0, 0, 0, 0.165),
                        -1px -1px 0 rgba(0, 0, 0, 0.165);
   }
   .imageViewerIconRightDisable{
     color:rgba(255, 255, 255, 0.416);
     font-size: 50px;
     cursor:  not-allowed;
     position: absolute;
     top: 50%;
     -webkit-transform: translateY(-50%);
             transform: translateY(-50%);
     right:10px;
     text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.165),
     1px 1px 0 rgba(0, 0, 0, 0.165),
    1px -1px 0 rgba(0, 0, 0, 0.165),
   -1px -1px 0 rgba(0, 0, 0, 0.165);
}
   .imageViewerIconLeft{
     color:rgba(255, 255, 255, 0.885);
          font-size: 50px;
          cursor: pointer;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          left:10px;
          text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.165),
                          1px 1px 0 rgba(0, 0, 0, 0.165),
                         1px -1px 0 rgba(0, 0, 0, 0.165),
                        -1px -1px 0 rgba(0, 0, 0, 0.165);
     }
     .imageViewerIconLeft:hover{
          color:rgb(255, 255, 255);
          text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.165),
                          1px 1px 0 rgba(0, 0, 0, 0.165),
                         1px -1px 0 rgba(0, 0, 0, 0.165),
                        -1px -1px 0 rgba(0, 0, 0, 0.165);
     }
     .imageViewerIconLeftDisable{
          color:rgba(255, 255, 255, 0.416);
          font-size: 50px;
          cursor:  not-allowed;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          left:10px;
          text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.165),
                          1px 1px 0 rgba(0, 0, 0, 0.165),
                         1px -1px 0 rgba(0, 0, 0, 0.165),
                        -1px -1px 0 rgba(0, 0, 0, 0.165);
     }
   .maintenanceImageCollection2{
          max-height: 80px;
          overflow-y: auto;
          display: none;
          width: 100%;
          white-space: nowrap;
     }
     .maintenanceImageCollection2::-webkit-scrollbar {
          width: 0px;
          height: 1px;
     }
     .maintenanceImageCollection2::-webkit-scrollbar-track {
     background: #f1f1f1;
     }
     .maintenanceImageCollection2::-webkit-scrollbar-thumb {
     background: #fd753f36;
     }
     .maintenanceImageCollection2::-webkit-scrollbar-thumb:hover {
     background: #918c8b;
     }

     .maintenanceImageCollection3{
          /* max-height:300px; */
          overflow-y: auto;
          width: 100%;
          white-space: nowrap;
     }
     .imageViewerRightArrow{
         -webkit-align-content: center;
                 align-content: center;
           -webkit-align-self: center;
                   align-self: center;
           text-align: center;
           color: #918c8b;
           font-size: 13px;
           cursor: pointer;
           width: 30px;
     }
     .maintenanceImageCollection3::-webkit-scrollbar {
          width: 0px;
          height: 1px;
     }
     .maintenanceImageCollection3::-webkit-scrollbar-track {
     background: #f1f1f1;
     }
     .maintenanceImageCollection3::-webkit-scrollbar-thumb {
     background: #fd753f36;
     }
     .maintenanceImageCollection3::-webkit-scrollbar-thumb:hover {
     background: #918c8b;
     }

     .maintenanceImageCollection4{
          max-height: 80px;
          overflow-y: auto;
          width: 100%;
          white-space: nowrap;
          /* margin-top: -50px; */
          position: absolute;
          bottom: 0%;
          left: 5%;
     }
     .maintenanceImageCollection4::-webkit-scrollbar {
          width: 0px;
          height: 1px;
     }
     .maintenanceImageCollection4::-webkit-scrollbar-track {
     background: #f1f1f1;
     }
     .maintenanceImageCollection4::-webkit-scrollbar-thumb {
     background: #fd753f36;
     }
     .maintenanceImageCollection4::-webkit-scrollbar-thumb:hover {
     background: #918c8b;
     }
     .viewerModal{
          max-width: auto !important;
     }
   @media only screen and (min-width: 00px) and (max-width: 380px) {
     .maintenanceImageCollection{
         display: none;
     }
     .maintenanceImageCollection2{
          display: block;
      }
      .maintenanceImage4{
    
          height:18em;

      }
   }
   @media only screen and (min-width: 380px) and (max-width: 580px) {
     .maintenanceImageCollection{
         display: none;
     }
     .maintenanceImageCollection2{
          display: block;
      }
      .maintenanceImage4{
    
          height:23rem;

      }
   }
   @media only screen and (min-width: 580px) and (max-width: 880px) {

      .maintenanceImage4{
    
          height:29rem;

      }
   }
   @media only screen and (min-width: 880px) and (max-width: 1250px) {

     .maintenanceImage4{
   
         height:30rem;

     }
  }
.requestDescriptionDivider{
     padding-left:10px !important;
     margin-right: 10px !important;
}
.requestDescriptionText{
     padding-top: 20px;
     padding-bottom: 10px;
    
}
@media only screen and (min-width: 0px) and (max-width: 344px) {
 .requestDescriptionText{
     padding-top: 45px;
 }
}

.maintenanceRequestInvitationCount{
  background-color: #ffd6bfc3;
  padding: 5px 10px;
  border-radius: 50%;
  font-family: "Montserrat", sans-serif;  
  font-size: 13px;
  font-weight: 800;
}

   .maintenanceProviderContainer{
     padding-left: 3%;
     padding-right: 3%;
     padding-top: 2%;
     padding-bottom: 2%;
     
   }
   .invitationDivider{
    background-color: rgb(239, 239, 239);
    -webkit-margin-before: 0.1em;
            margin-block-start: 0.1em;
    -webkit-margin-after: 0.1em;
            margin-block-end: 0.1em;
    border: none;
    height: 1px;
   }
   
   .requestInvitationDivider{
    padding-left:10px !important;
    margin-right: 10px !important;
}

.requestInvitationText{
    padding-top: 3%;
    padding-bottom: 0%;
  padding-right: 4%;
  overflow-y: scroll;
  max-height:600px;
}

.requestInvitationText::-webkit-scrollbar {
width: 2px;
height: 2px;
}
.requestInvitationText::-webkit-scrollbar-track {
background: rgb(255, 255, 255);
}
.requestInvitationText::-webkit-scrollbar-thumb {
background: #ff6d53;
}
.requestInvitationText::-webkit-scrollbar {
width: 2px;
height: 0px;
}
.requestInvitationText::-webkit-scrollbar-track {
background: rgba(189, 189, 189, 0.01);
}
.requestInvitationText::-webkit-scrollbar-thumb {
background: #ff6d53;
}
.maintenanceRequestInvitationImage{
  height: 35px;
  width: 35px;
  border-radius: 50%;

}
.maintenanceRequestDetailIcon{
  height: 20px;
  width: 20px;
  margin-top: -2% !important;
}

.maintenanceInvitationAlign{
  border-right: 1px solid rgb(239, 239, 239);
  border-left: 1px solid rgb(239, 239, 239);
  width:15%;
  text-align: center;
  padding-top: 11px ;
}
.maintenanceInvitationHourAlign{
width:25%;
border-left: 1px solid rgb(239, 239, 239);
}
.maintenanceProviderInvitationNameAlign{
  width:40%;
  -webkit-align-self: center;
          align-self: center;
}
.maintenanceProviderInvitationImageAlign{
  width:12%;
  -webkit-align-self: center;
          align-self: center;
  margin-top: -1% !important;
}
.maintenanceInvitationProviderIconAlign{
  width:5%;
  padding-left: 3%;

  margin-top: -2% !important;
  -webkit-align-self: center;
          align-self: center;
  text-align: right;
  
}
@media only screen and   (min-width:10px) and (max-width: 321px){
    .requestInvitationDivider{
         opacity: 0;
    }
   .requestInvitationText{
      margin-top:15%;
   }
}

@media only screen and (min-width: 1200px) and (max-width: 1250px),(min-width:768px) and (max-width: 992px), (min-width:10px) and (max-width: 485px){
  .maintenanceProviderInvitationNameAlign{
    width:70%;
    -webkit-align-self: center;
            align-self: center;
  }
  .maintenanceProviderInvitationImageAlign{
    width:30%;
    -webkit-align-self: center;
            align-self: center;
    margin-top: -1% !important;
    padding-left: 5%;
  }
  .maintenanceInvitationHourAlign{
    width:60%;
    border-left: none;
    padding-left: 15%;
    }
    .maintenanceInvitationProviderIconAlign{
      width:20%;
    }
    
    .maintenanceInvitationAlign{
      border-right: none;
      border-left: none;
      width:15%;
      text-align: center;
    
    }
}
.smallSwitch {
     position: relative;
     display: inline-block;
     width: 30px;
     height: 14px;
   }
   .smallSwitch input {
     display: none;
   }
   .smallSlider {
     position: absolute;
     cursor: pointer;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background-color: #b7bbba;
     transition: 0.4s;
   }
   .smallSlider:before {
     position: absolute;
     content: "";
     height: 12px;
     width: 12px;
     left: 2px;
     bottom: 1px;
     background-color: white;
     transition: 0.4s;
   }
   input:checked + .smallSlider:before {
     -webkit-transform: translateX(54px);
     transform: translateX(54px);
   }
   input:checked + .smallSlider {
     background-color: #ff4b2bc7;
   }
   input:focus + .smallSlider {
     box-shadow: 0 0 1px #2196f3;
   }
   input:checked + .smallSlider:before {
     -webkit-transform: translateX(14px);
     transform: translateX(14px);
   }
   input:checked + .smallSlider .onApp {
     display: block;
   }
   input:checked + .smallSlider .offApp {
     display: none;
   }
   .smallSlider.round {
     border-radius: 34px;
   }
   .smallSlider.round:before {
     border-radius: 50%;
   }
.maintenanceRequestQuotationCount{
  background-color: #ffd6bfc3;
  padding: 5px 10px;
  border-radius: 50%;
  font-family: "Montserrat", sans-serif;  
  font-size: 13px;
  font-weight: 800;
}

   .maintenanceProviderContainer{
     padding-left: 3%;
     padding-right: 3%;
     padding-top: 2%;
     padding-bottom: 2%;
     
   }
   .quotationDivider{
    background-color: rgb(239, 239, 239);
    -webkit-margin-before: 0.1em;
            margin-block-start: 0.1em;
    -webkit-margin-after: 0.1em;
            margin-block-end: 0.1em;
    border: none;
    height: 1px;
   }
   
   .requestQuotationDivider{
    padding-left:10px !important;
    margin-right: 10px !important;
}

.requestQuotationText{
    padding-top: 3%;
    padding-bottom: 0%;
    padding-right: 4%;
    overflow-y: scroll;
    max-height:600px;
}

.requestQuotationText::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.requestQuotationText::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}
.requestQuotationText::-webkit-scrollbar-thumb {
  background: #ff6d53;
}
.requestQuotationText::-webkit-scrollbar {
  width: 2px;
  height: 0px;
}
.requestQuotationText::-webkit-scrollbar-track {
  background: rgba(189, 189, 189, 0.01);
}
.requestQuotationText::-webkit-scrollbar-thumb {
  background: #ff6d53;
}
.maintenanceRequestQuotationImage{
  height: 35px;
  width: 35px;
  border-radius: 50%;

}
.maintenanceRequestDetailIcon{
  height: 20px;
  width: 20px;
  margin-top: -2% !important;
}

.maintenanceQuotationAlign{
  
  border-left: 1px solid rgb(239, 239, 239);
  width:15%;
  text-align: center;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  display: block;
}
.maintenanceQuotationAlign0{
  display:none;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  border-left: 1px solid rgb(239, 239, 239);
  width:15%;
  text-align: center;

}
.maintenanceQuotationHourAlign{
width:25%;
/* border-right: 1px solid rgb(239, 239, 239); */
border-left: 1px solid rgb(239, 239, 239);
padding-top: 2% !important;
  padding-bottom: 2% !important;
text-align: center;
-webkit-align-self: center;
        align-self: center;
}
.maintenanceProviderQuotationNameAlign{
  width:40%;
  -webkit-align-self: center;
          align-self: center;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
}
.maintenanceProviderQuotationImageAlign{
  width:12%;
  -webkit-align-self: center;
          align-self: center;
  margin-top: -1% !important;
}
.maintenanceQuotationProviderIconAlign{
  width:5%;
  padding-left: 3%;
  border-left: 1px solid rgb(239, 239, 239);
  margin-top: -2% !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  -webkit-align-self: center;
          align-self: center;
  text-align: right;
  
}

.maintenanceQuotationImageAlign{
  width:12%;
  -webkit-align-self: center;
          align-self: center;
  margin-top: -1% !important;
}
.maintenanceRequestQuotationImage2{
  height: 35px;
  width: 35px !important;
  padding: 5px;
  border-radius: 50%;

}
.maintenanceQuotationNameAlign{
  width:20%;
  -webkit-align-self: center;
          align-self: center;
}
.maintenanceQuotationCostAlign{
  width:35%;
  text-align: center;
  -webkit-align-self: center;
          align-self: center;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  /* border-right: 1px solid rgb(239, 239, 239); */
  border-left: 1px solid rgb(239, 239, 239);
}

.maintenanceQuotationImageDummyAlign{
  display: none;
}
@media only screen and (min-width:615px) and (max-width: 651px) , (min-width:10px) and (max-width: 375px){
    .requestQuotationDivider{
         opacity: 0;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1250px), (min-width:768px) and (max-width: 992px) , (min-width:10px) and (max-width: 485px){
  .maintenanceQuotationImageDummyAlign{
    display: block;
    width:30%;
  }
  .maintenanceQuotationNameAlign{    
    padding-bottom: 5%;
    width:40%;
    -webkit-align-self: center;
            align-self: center;
  }
  .maintenanceQuotationImageAlign{
      padding-bottom: 5%;
      padding-left: 3%;
      width:30%;
  }
  .maintenanceQuotationCostAlign{
    width:30%;
    border-right: none;
    border-left: none;
    text-align: left;
    margin-top: -25px !important;
  }

  .maintenanceProviderQuotationNameAlign{
    width:50%;
    -webkit-align-self: center;
            align-self: center;
  }
  .maintenanceProviderQuotationImageAlign{
    width:30%;
    -webkit-align-self: center;
            align-self: center;
    margin-top: -1% !important;
    padding-left: 5%;
  }
  .maintenanceQuotationHourAlign{
    width:50%;
    border-left: none;
    border-right: none;
  }
  .maintenanceQuotationHourAlign .maintenanceRequestDetailText6{
    text-align: left;
  }
  .maintenanceQuotationProviderIconAlign{
    width:20%;
    border-left: none;
    padding-left: 0%;
    text-align: left;
  }
  
  .maintenanceQuotationAlign{
    border-right: none;
    border-left: none;
    width:15%;
    text-align: center;
    display:none;
  }
  .maintenanceQuotationAlign0{
    border-right: none;
    border-left: none;
    width:15%;
    text-align: left;
    display:block;
  }

}
   .requestProviderDivider{
       padding-left:10px !important;
       margin-right: 10px !important;
   }
   .requestProviderText{
       padding-top: 3%;
       padding-bottom: 0%;
    
       padding-right: 4%;
       overflow-y: scroll;
       max-height:650px;
     }
     
     .requestProviderText::-webkit-scrollbar {
     width: 2px;
     height: 2px;
     }
     .requestProviderText::-webkit-scrollbar-track {
     background: rgb(255, 255, 255);
     }
     .requestProviderText::-webkit-scrollbar-thumb {
     background: #ff6d53;
     }
     .requestProviderText::-webkit-scrollbar {
     width: 2px;
     height: 0px;
     }
     .requestProviderText::-webkit-scrollbar-track {
     background: rgba(189, 189, 189, 0.01);
     }
     .requestProviderText::-webkit-scrollbar-thumb {
     background: #ff6d53;
     }
   .maintenanceProviderPart{
     width:50%;
     padding-left: 0%;
     padding-bottom: 2%;
     padding-top: 2%;
     border-bottom: 1px solid rgb(239, 239, 239);
     
   }
   .maintenanceProviderPart2{
     width:50%;
     padding-left: 0%;
     padding-bottom: 2%;
     padding-top: 2%;
   }
   .maintenanceProviderPartContainer{
     padding-left: 3%;
     padding-right: 3%;
     padding-top: 2%;
     padding-bottom: 2%;
     
   }
   .maintenanceProviderPartImageAlign{
     width:27%;
     -webkit-align-self: center;
             align-self: center;
     margin-top: -1% !important;
   }
   .maintenanceRequestProviderPartImage{
     height: 35px;
     width: 35px;
     border-radius: 50%;
   
   }
   .maintenanceProviderPartNameAlign{
     width:72%;
     -webkit-align-self: center;
             align-self: center;
   }
   .activeSkillBudge {
     color: #ff4b2b;
     border:1px solid #ff4b2b;
     border-radius: 15px;
     padding-right: 10px;
     padding-left: 10px;
     padding-top: 4px;
     padding-bottom: 4px;
     font-family: "Montserrat", sans-serif;
     cursor: pointer;
     margin-right: 3%;
     margin-bottom: 3% !important;
     white-space:nowrap;
     font-size: 12px;
     -webkit-align-self: flex-start;
             align-self: flex-start;
   }
   .skillBudge{
     color: #adadad;
     border:1px solid #adadad;
     border-radius: 15px;
     padding-right: 10px;
     padding-left: 10px;
     padding-top: 4px;
     padding-bottom: 4px;
     font-size: 12px;
     font-family: "Montserrat", sans-serif;
     white-space:nowrap;
     margin-right: 3%;
     margin-bottom: 3% !important;
     cursor: pointer;
     -webkit-align-self: flex-start;
             align-self: flex-start;
     
   }
   .skillBudgeCollection{
      padding-top: 2%;
      padding-bottom: 2%;
      padding-right: 4%;
      padding-left: 4%;
      word-wrap: break-word ;
     
      
   }
   .borderProviderLeftGray{
    border-left: 1px solid rgb(239, 239, 239);
   }

   @media only screen and   (min-width:10px) and (max-width: 407px),(min-width: 768px) and (max-width: 839px){
       .requestProviderDivider{
            opacity: 0;
       }
      .requestProviderText{
         margin-top:15%;
      }
      .requestProviderText{
        margin-top:10%;
       }
   }
   
   @media only screen and (min-width: 768px) and (max-width: 1000px), (min-width:10px) and (max-width: 485px){
    .borderProviderLeftGray{
      border:none;
    }
    .maintenanceProviderPart{
      width:100%;
      padding-left: 3%;
      padding-bottom: 2%;
      padding-top: 2%;
    }
    .maintenanceProviderPart2{
      width:100%;
      padding-left: 3%;
      padding-bottom: 2%;
      padding-top: 2%;
    }
   }

.requestOrderText{
     padding-top: 20px;
     padding-bottom: 10px;
    
}
.maintenanceRoundIcon {
     margin: 0px 0;
   }
   
   .maintenanceRoundIcon span {
     border: 1px solid #dddddd;
     background-color: rgb(216, 216, 216);
     border-radius: 50%;
     display: -webkit-inline-flex;
     display: inline-flex;
     -webkit-justify-content: center;
             justify-content: center;
     -webkit-align-items: center;
             align-items: center;
     margin: 0 5px;
     height: 40px;
     width: 40px;
   }
   .maintenanceRequestOrderImage{
     height: 35px;
     width: 35px !important;
     padding: 5px;
     border-radius: 50%;
     background-color: #E1EF90;
     
   }
   .maintenanceRequestOrderImage2{
     
     height: 35px;
     width: 35px !important;
     padding: 5px;
     border-radius: 50%;
   
   }
   .mobileResponseMaintenanceOrder1{

     display: none;
   }
   .maintenanceOrderNameAlign{
     width:25%;
     -webkit-align-self: center;
             align-self: center;
   }
   .maintenanceOrderImageAlign{
     width:12%;
     -webkit-align-self: center;
             align-self: center;
     margin-top: -1% !important;
   }
   .maintenanceOrderImageDummyAlign{
     display: none;
   }
   .maintenanceOrderContainer{
     padding-left: 3%;
     padding-right: 3%;
     padding-top: 4%;
     padding-bottom: 0%;
     
   }
   .maintenanceOrderCostAlign{
     width:35%;
     text-align: center;
     -webkit-align-self: center;
             align-self: center;
     padding-top: 2% !important;
     padding-bottom: 2% !important;
     border-right: 1px solid rgb(239, 239, 239);
     border-left: 1px solid rgb(239, 239, 239);
   }
   .maintenanceOrderTimeAlign{
     text-align: center;
     -webkit-align-self: center;
             align-self: center;
     width:28%;
     align-self: center;

   }
.mobileResponseMaintenanceOrder4{
     display: none !important;
}
.orderDivider{
     background-color: rgb(239, 239, 239);
     -webkit-margin-before: 0.1em;
             margin-block-start: 0.1em;
     -webkit-margin-after: 0.1em;
             margin-block-end: 0.1em;
     border: none;
     height: 1px;
    }
@media only screen and (min-width: 1200px) and (max-width: 1250px), (min-width:768px) and (max-width: 992px) , (min-width:10px) and (max-width: 485px){
     .maintenanceOrderImageDummyAlign{
          display: block;
          width:30%;
        }
     .maintenanceOrderNameAlign{
          
          padding-bottom: 5%;
       width:70%;
       -webkit-align-self: center;
               align-self: center;
     }
     .maintenanceOrderImageAlign{
          padding-bottom: 5%;
          padding-left: 3%;
          width:30%;
     }
     .maintenanceOrderCostAlign{
          width:30%;
          border-right: none;
          border-left: none;
          text-align: left;
        }
}
   @media only screen and (min-width:1200px) and (max-width: 1275px) , (min-width:768px) and (max-width: 994px),(min-width:00px) and (max-width: 545px){
     .mobileResponseMaintenanceOrder1{
          display: -webkit-flex;
          display: flex;
     }
     .mobileResponseMaintenanceOrder2{
          display: none;
     }
   }

   @media only screen and (min-width:00px) and (max-width: 350px){
     .mobileResponseMaintenanceOrder4{
          display: -webkit-flex !important;
          display: flex !important;
     }
     .mobileResponseMaintenanceOrder3{
          display: none !important;
     }
   }
.loginModal {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center;
	flex-direction: column; */
  font-family: "Montserrat", sans-serif;

  margin: 20px 50px;
  padding-top: 150px;
  padding-bottom: 90px;
}
.loginForgot {
  color: rgb(142, 142, 142);
  cursor: pointer;
}
.loginForgot:hover {
  color: rgb(71, 175, 255);
}
.loginModal-mobile {
  /* display: flex; */
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 530px;
  margin: 10px 30px;
  margin-top: 90px;
  display: none;
}
#container {
  /* margin-top:100px; */
}
.landlordLogin{
  color: rgb(120, 119, 119);
}
.logo {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-weight: bold;
  font-weight: 1950;
  text-decoration: none;
  color: #fff;
}
.logo:hover {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-weight: bold;
  font-weight: 1950;
  color: #fff;
  text-decoration: none;
}
h1 {
  font-weight: bold;
  margin: 0;
}
h1.one {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 1950;
  padding-left: 15px;
}
h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
 margin: 20px 0 30px; 
}
p.nav {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
span {
  font-size: 12px;
}
.span1 {
  font-size: 20px;
  line-height: 30px;
}
.spanvalidate {
  font-size: 12px;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  padding-right: 10px;
  /* position:fixed; */
  padding-top: 0px;
}
a {
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

button {
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

button:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.ghost {
  background-color: transparent;
}
button.pri {
}
button.pri:hover {
  background-color: transparent;
  border-color: #ff4b2b;
  color: #ff4b2b;
}
button.pri:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.formlogin {
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input.tag {
  border: none;

  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
input.alert {
  font-family: "Montserrat", sans-serif;
}
input.toggle {
}
.term {
  color: #868b8b;
}
.term:hover {
  color: #ff4b2b;
  cursor: pointer;
}
.term1 {
  color: #14a1f9;
}
.term1:hover {
  color: #ff4b2b;
  cursor: pointer;
}
.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  overflow: hidden;
  width: 868px;
  max-width: 100%;
  min-height: 520px;
}
.container-mobile {
  background-color: #fff;
  border-radius: 10px;
  /* box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22); */
  position: relative;
  /* overflow: hidden; */
  width: 868px;
  max-width: 100%;
  /* min-height: 630px; */
}
.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}
.sign-in-container-mobile {
  top: 0;
  width: 100%;
  z-index: 2;
}
.container.right-panel-active .sign-in-container {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  padding-top: 30px;
}
.sign-up-container-mobile {
  top: 0;
  width: 100%;
  z-index: 1;
}
.container.right-panel-active .sign-up-container {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  -webkit-animation: show 0.6s;
          animation: show 0.6s;
}

@-webkit-keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.overlay {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.overlay-left {
  -webkit-transform: translateX(-20%);
          transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.overlay-right {
  right: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  -webkit-transform: translateX(20%);
          transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}
.passDropHead {
  background-color: #cfd6d6;
}
.passDropContent {
  background-color: #e4e9e9;
}
/* 
footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
}

footer i {
    color: red;
}

footer a {
    color: #3c97bf;
    text-decoration: none;
} */

/* toggle */

.switch {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a3e4d7;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f5cba7;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(85px);
  transform: translateX(85px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on,
.off {
  color: rgb(104, 98, 98);
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.iconPassShow {
  padding-top: 30px;
  right: 0;
  padding-right: 60px;
}
@media only screen and (min-device-width: 1800px) and (max-device-width: 6400px) {
  .loginModal {
    margin: 20px 50px;
    padding-top: 200px;
    padding-bottom: 150px;
  }
}
@media only screen and (min-device-width: 1300px) and (max-device-width: 1800px) {
  .loginModal {
    margin: 20px 50px;
    padding-top: 150px;
    padding-bottom: 90px;
  }
}
@media only screen and (min-device-width: 980px) and (max-device-width: 1300px) {
  .loginModal {
    margin: 20px 50px;
    margin-top: -10px;
    margin-bottom: -45px;
  }
}
@media only screen and (min-device-width: 790px) and (max-device-width: 980px) {
  h1.loginHead {
    font-size: 29px;
  }
  .sign-up-container {
    padding-top: 40px;
  }
  .loginModal {
    margin: 20px 50px;
    margin-top: -10px;
    margin-bottom: -45px;
  }
}
@media only screen and (min-device-width: 630px) and (max-device-width: 790px) {
  .sign-up-container {
    padding-top: 10px;
  }
  .loginModal {
    margin: 20px 50px;

    margin-top: -10px;
    margin-bottom: -45px;
  }
  h1.loginHead {
    font-size: 20px;
  }
  a.logo {
    font-size: 20px;
  }
  button {
    padding: 8px 25px;
    font-size: 10px;
  }
  .formlogin {
    padding: 0 30px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 12px 0;
  }
  input.tag {
    padding: 9px 11px;
    margin: 5px 0;
    width: 100%;
    font-size: 10px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 360px;
  }
  span {
    font-size: 10px;
    /* line-height: 30px; */
  }
  .switch {
    width: 40px;
    height: 26px;
    margin: top -1px;
    margin-bottom: 0px;
  }

  .slider:before {
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
  }
  .on,
  .off {
    top: 60%;
    left: 50%;
    font-size: 7px;
    margin-top: -4px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(52px);
    transform: translateX(52px);
  }
}
@media only screen and (min-device-width: 520px) and (max-device-width: 630px) {
  h1.loginHead {
    font-size: 20px;
  }
  a.logo {
    font-size: 20px;
  }
  .loginModal {
    margin: 10px 30px;
    margin-top: -5px;
    margin-bottom: -45px;
  }
  a {
    font-size: 13px;
  }
  button {
    padding: 6px 20px;
    font-size: 10px;
  }
  p {
    font-size: 12px;
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 12px 0;
  }
  input.tag {
    padding: 9px 11px;
    margin: 5px 0;
    width: 100%;
    font-size: 8px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 340px;
  }
  span {
    font-size: 8px;
    /* line-height: 30px; */
  }
  .switch {
    width: 60px;
    height: 20px;
  }

  .slider:before {
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 3px;
  }
  .on,
  .off {
    top: 60%;
    left: 50%;
    font-size: 7px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(41px);
    transform: translateX(41px);
  }
}

@media only screen and (min-device-width: 100px) and (max-device-width: 520px) {
  .loginModal-mobile {
    display: block;
    margin: 70px 20px;
  }
  .loginModal {
    display: none;
  }
  h1.loginHead {
    font-size: 19px;
  }
  a.logo {
    font-size: 15px;
  }
  a {
    font-size: 13px;
  }
  button {
    padding: 5px 17px;
    font-size: 10px;
  }
  p {
    font-size: 10px;
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 5px 0;
  }
  input.tag {
    padding: 7px 9px;
    margin: 3px 0;
    width: 100%;
    font-size: 8px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 380px;
  }
  span {
    font-size: 7px;
    /* line-height: 30px; */
  }
  .switch {
    width: 100px;
    height: 17px;

  }

  .slider:before {
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
  }
  .on,
  .off {
    top: 60%;
    left: 50%;
    font-size: 6px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(43px);
    transform: translateX(43px);
  }
}
@media only screen and (min-width: 790px) and (max-width: 890px) {
  .iconPassShow {
    margin-top: -10px;
    right: 0;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 630px) and (max-width: 790px) {
  .iconPassShow {
    margin-top: -60px;
    right: 0;
    padding-right: 30px;
  }
  h1.loginHead {
    font-size: 20px;
  }
  a.logo {
    font-size: 20px;
  }
  button {
    padding: 8px 25px;
    font-size: 10px;
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 6px 0 !important;
  }
  input.tag {
    padding: 9px 11px;
    margin: 5px 0;
    width: 100%;
    font-size: 10px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 380px;
  }
  span {
    font-size: 10px;
    /* line-height: 30px; */
  }
  .switch {
    width: 90px;
    height: 26px;
  }

  .slider:before {
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
  }
  .on,
  .off {
    top: 60%;
    left: 50%;
    font-size: 7px;
    
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(62px);
    transform: translateX(62px);
  }
}
@media only screen and (min-width: 522px) and (max-width: 629px) {
  .iconPassShow {
    margin-top: -45px;
    right: 0;
    padding-right: 30px;
  }
  h1.loginHead {
    font-size: 20px;
  }
  .sign-up-container {
    padding-top: 10px;
  }
  a.logo {
    font-size: 20px;
  }
  a {
    font-size: 13px;
  }
  button {
    padding: 6px 20px;
    font-size: 10px;
  }
  p {
    font-size: 12px;
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 12px 0;
  }
  input.tag {
    padding: 9px 11px;
    margin: 5px 0;
    width: 100%;
    font-size: 8px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 390px;
  }
  span {
    font-size: 8px;
    /* line-height: 30px; */
  }
  .switch {
    width: 80px;
    height: 20px;
  }

  .slider:before {
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 3px;
  }
  .on,
  .off {
    top: 60%;
    left: 50%;
    font-size: 7px;
    margin-top:-3px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(61px);
    transform: translateX(61px);
  }
}
@media only screen and (min-width: 00px) and (max-width: 350px) {
  .iconPassShow {
    padding-top: 213px;
    top: 0;
    margin-right: -30px;
  }
}
@media only screen and (min-width: 350px) and (max-width: 430px) {
  .iconPassShow {
    padding-top: 213px;
    top: 0;
    margin-right: -20px;
  }
}
@media only screen and (min-width: 430px) and (max-width: 480px) {
  .iconPassShow {
    padding-top: 213px;
    top: 0;
    margin-right: -10px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 522px) {
  .iconPassShow {
    padding-top: 213px;
    top: 0;
    margin-right: 20px;
  }
}
@media only screen and (min-width: 791px) and (max-width: 851px) {
  .iconPassShow {
    padding-top: 25px;
    right: 0;
    padding-right: 60px;
  }
}
@media only screen and (min-width: 851px) and (max-width: 980px) {
  .iconPassShow {
    padding-top: 20px;
    right: 0;
    padding-right: 60px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 522px) {
  .loginModal-mobile {
    display: block;
  }
  .loginModal {
    display: none;
  }
  h1.loginHead {
    font-size: 25px;
  }
  a.logo {
    font-size: 15px;
  }
  a {
    font-size: 13px;
  }
  button {
    padding: 5px 17px;
    font-size: 10px;
  }
  p {
    font-size: 10px;
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 5px 0;
  }
  input.tag {
    padding: 7px 9px;
    margin: 3px 0;
    width: 100%;
    font-size: 8px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 380px;
  }
  span {
    font-size: 12px;
    /* line-height: 30px; */
  }
  .switch {
    width: 80px;
    height: 17px;
    /* margin-left:-10px; */
  }

  .slider:before {
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
  }
  .on,
  .off {
    top: 60%;
    left: 50%;
    margin-top:-1px;
    font-size: 6px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(63px);
    transform: translateX(63px);
  }
}


   h1.chat {
     margin: 0;
     font-size: 16px;
     line-height: 1;
     font-family: "Montserrat", sans-serif;
   }
   
   button.chat {
     color: inherit;
     background-color: transparent;
     border: 0;
     outline: 0 !important;
     cursor: pointer;
   }
   .chatComponent{
     position: fixed;
     bottom: 0;
     right: 0;
     z-index: 1000 !important;
     font-family: "Montserrat", sans-serif;
   }

   span.chatBox-open {
     position: fixed;
     bottom: 0;
     right: 0;
     width: 52px;
     height: 52px;
     color: #fff;
     background-color: #fc2222bc;
     background-position: center center;
     background-repeat: no-repeat;
     box-shadow: 12px 15px 20px 0 rgba(46, 61, 73, 0.15);
    font-size: 18px !important;
     border: 1px solid rgba(255, 255, 255, 0.26);
     border-radius: 50%;
     cursor: pointer;
     margin: 16px;
    padding-top:12px;
     text-align: center;
   
   }
   
   textarea.chat{
     box-sizing: border-box;
     width: 100%;
     margin: 0;
     height: calc(16px + 16px / 2);
     padding: 0 calc(16px / 2);
     font-family: inherit;
     font-size: 15px;
     line-height: calc(16px + 16px / 2);
     color: #888;
     background-color: none;
     border: 0;
     outline: 0 !important;
     resize: none;
     overflow: hidden;
   }
   textarea:-ms-input-placeholder {
     color: #888;
   }
   textarea::-webkit-input-placeholder {
     color: #888;
   }
   textarea::placeholder {
     color: #888;
   }
   
   .chatBox-popup {
     position: absolute;
   
     -webkit-flex-direction: column;
   
             flex-direction: column;
     bottom: calc(2 * 16px + 52px);
     right: 16px;
     width: 377px;
     height: auto;
     box-shadow: rgba(111, 105, 100, 0.269) 0px 7px 29px 20px;
     
     border-radius: 16px;
     -webkit-animation: hideChat 0.6s;
             animation: hideChat 0.6s;
     opacity:0;
     display: none;
   }
   .showChat{
     opacity: 1;
     display: block;
     -webkit-animation: showChat 0.6s;
             animation: showChat 0.6s;
   }
   @-webkit-keyframes showChat {
     0%{
         display: none; 
         opacity: 0;
     }
     90% {
         opacity: 0.9;
     }
     100% {
         opacity: 1;
         display: block; 
     }
 }
   @keyframes showChat {
     0%{
         display: none; 
         opacity: 0;
     }
     90% {
         opacity: 0.9;
     }
     100% {
         opacity: 1;
         display: block; 
     }
 }
 @-webkit-keyframes hideChat {
     0%{
          display: block; 
         opacity: 1;
     }
     90% {
         display: block; 
         opacity: 0.1;
     }
     100% {
         opacity: 0;
         display: none; 
     }
 }
 @keyframes hideChat {
     0%{
          display: block; 
         opacity: 1;
     }
     90% {
         display: block; 
         opacity: 0.1;
     }
     100% {
         opacity: 0;
         display: none; 
     }
 }
   .chatBox-popup .chatBox-popup__header {
     box-sizing: border-box;
     display: -webkit-flex;
     display: flex;
     width: 100%;
     padding: 16px;
     height: 55px !important;
     color: #fff;
     background-color: #f13e3ed1;
     -webkit-align-items: center;
             align-items: center;
     -webkit-justify-content: space-around;
             justify-content: space-around;
     border-top-right-radius: 12px;
     border-top-left-radius: 12px;
   }
   .chatBox-popup .chatBox-popup__header .chatBox-popup__avatar {
     margin-top: -32px;
     font-size: 50px;
     background-color: #f13e3ed1;
     border: 5px solid rgba(0, 0, 0, 0.1);
     border-radius: 50%;
   }
   .chatIcon{
    margin-top: -40px;
    margin-left: -0px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.893);
    border: 5px solid rgba(234, 9, 9, 0.815);
   }
   .chatBox-popup .chatBox-popup__main {
     box-sizing: border-box;
     width: 100%;
     padding: calc(2 * 16px) 16px;
     line-height: calc(16px + 16px / 2);
     color: #888;
     text-align: center;
   }
   .chatBox-popup .chatBox-popup__footer {
     box-sizing: border-box;
     display: -webkit-flex;
     display: flex;
     width: 100%;
     padding: 16px;
     border-top: 1px solid #ddd;
     -webkit-align-items: center;
             align-items: center;
     -webkit-justify-content: space-around;
             justify-content: space-around;
     border-bottom-right-radius: 12px;
     border-bottom-left-radius: 12px;
   }
   
   .chatBox-panel {
     display: -webkit-flex;
     display: flex;
     position: absolute;
     box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
     
     -webkit-flex-direction: column;
     
             flex-direction: column;
     display: none;
     top: 0;
     right: 0;
     bottom: 0;
     width: 377px;
     background-color: rgba(255, 255, 255, 0.19);
   }
   .chatBox-panel .chatBox-panel__header {
     box-sizing: border-box;
     display: -webkit-flex;
     display: flex;
     width: 100%;
     padding: 16px;
     color: #fff;
     background-color: #f13e3ed1;
     -webkit-align-items: center;
             align-items: center;
     -webkit-justify-content: space-around;
             justify-content: space-around;
     -webkit-flex: 0 0 auto;
             flex: 0 0 auto;
   }
   .chatBox-panel .chatBox-panel__main {
     box-sizing: border-box;
     width: 100%;
     padding: calc(2 * 16px) 16px;
     line-height: calc(16px + 16px / 2);
     color: #888;
     text-align: center;
     -webkit-flex: 1 1 auto;
             flex: 1 1 auto;
   }
   .chatBox-panel .chatBox-panel__footer {
     box-sizing: border-box;
     display: -webkit-flex;
     display: flex;
     width: 100%;
     padding: 16px;
     border-top: 1px solid #ddd;
     -webkit-align-items: center;
             align-items: center;
     -webkit-justify-content: space-around;
             justify-content: space-around;
     -webkit-flex: 0 0 auto;
             flex: 0 0 auto;
   }
   #chat-messages {
     
     /* margin-top: 30px; */
     min-width: 320px;
     height: 355px;
     overflow-y: scroll;
     overflow-x: hidden;
     /* padding-right: 20px; */
     transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
     /* display: flex;
     flex-direction: column-reverse; */
   }
   
   #chat-messages::-webkit-scrollbar {
     width: 4px;
     height: 4px;
     background: transparent;
   }
   #chat-messages::-webkit-scrollbar-track {
     box-shadow: inset 0 0 5px rgb(185, 179, 179);
     border-radius: 0px;
   }
   #chat-messages::-webkit-scrollbar-thumb {
     background: #e1422d9f;
     border-radius: 0px;
   }
   #chat-messages::-webkit-scrollbar-thumb:hover {
     background: #7c7a7a;
   }
   #chat-messages.animate {
     opacity: 1;
     margin-top: 0;
   }
   #chat-messages label {
     color: #aab8c2;
     font-weight: 600;
     font-size: 12px;
     text-align: center;
     margin: 15px 0;
     min-width: 320px;
     display: block;
   }
   #chat-messages div.message {
     padding: 0 0 5px 58px;
     clear: both;
     margin-bottom: -5px;
   }
   #chat-messages div.message.right {
     padding: 0 58px 0px 0;
     margin-right: 0px;
     margin-left: 19px;
   }
   #chat-messages .message img {
     float: left;
     margin-left: -38px;
     border-radius: 50%;
     width: 30px;
    height: 30px;
     margin-top: 4px;
   }
   #chat-messages div.message.right img {
     float: right;
     margin-left: 0;
     margin-right: -38px;
   }
   .message .bubble.myChat{
    background: #d1e7f9 !important;
   }
   .message .bubble {
     background: #f0f4f7;
     font-size: 13px;
     font-weight: 500;
     padding: 12px 13px;
     border-radius: 5px 5px 5px 0px;
     color: #4e5961;
     /* position:relative; */
     float: left;
   }
   #chat-messages div.message.right .bubble {
     float: right;
     border-radius: 5px 5px 0px 5px;
   }
   .bubble .corner {
    
   
     width: 7px;
     height: 7px;
     left: 0px;
     bottom: 0;
   }
   
   div.message.right .corner {
     
     left: auto;
     right: -5px;
   }
   .bubble {
     margin-bottom: 23px;
   }
   .message span {
     color: #aab8c2;
     font-size: 11px;
     font-weight: 100;
     /* position: absolute; */
   
     right: 0;
     bottom: -2px;
   }
   
   .chatTab{
     background: #f0f4f711;
     padding: 12px;
     font-size: 22px;
     border: 1px solid rgba(255, 255, 255, 0.127);
     width: 100%;
     -webkit-align-items: center;
             align-items: center;
     text-align: center;
     cursor: pointer;
   }
   .chatTab:hover{
     background: #f0f4f751;
     color:#ffffff;
   }
   .chatTab.active{
     background: #ffffff;
     color:#f13e3ed1;
}
   .chatBox-popup .chatBox-popup__header0 {
     box-sizing: border-box;
     display: -webkit-flex;
     display: flex;
     width: 100%;
     padding: 0px;
     height: 55px !important;
     color: #fff;
     background-color: #f13e3ed1;
     -webkit-align-items: center;
             align-items: center;
     -webkit-justify-content: space-around;
             justify-content: space-around;
     border-top-right-radius: 12px;
     border-top-left-radius: 12px;
   }



#topmenu span {
     float: left;
     width: 96px;
     height: 70px;
     background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/top-menu.png") -3px -118px
       no-repeat;
       
   }
   
   #topmenu span.contacts {
     margin-bottom: -1px;
   }
   #topmenu span.chats {
     background-position: -95px 25px;
     cursor: pointer;
   }
   #topmenu span.chats:hover {
     background-position: -95px -46px;
     cursor: pointer;
   }
   #topmenu span.history {
     background-position: -190px 24px;
     cursor: pointer;
   }
   #topmenu span.history:hover {
     background-position: -190px -47px;
     cursor: pointer;
   }
   .contact {
     height: 70px;
     border-bottom: 1px solid #e7ebee;
     position: relative;
   }
   .contact:hover {
     background: #f1f4f6;
     cursor: pointer;
   }
   .contact img {
     width: 40px;
     height: 40px;
     border-radius: 50%;
     margin: 15px;
     float: left;
   }

   .floatingImg {
     width: 40px;
     border-radius: 50%;
     /* position: absolute; */
     top: 0;
     left: 12px;
     border: 3px solid #fff;
   }
   .contact p {
     padding: 15px 0 0 0 !important;
     margin: 0px !important;
     float: left !important;
     width: 220px !important;
   }
   .contact p strong {
     font-weight: 600 !important;
     font-size: 15px !important;
     color: #597a96 !important;
   }
   .contact p span {
     font-size: 13px !important;
     font-weight: 400 !important;
     color: #aab8c2 !important;
   }
   .contact .status {
     background: #1edc8d;
     border-radius: 50%;
     width: 9px;
     height: 9px;
     position: absolute;
     top: 31px;
     right: 17px;
   }
   .contact .status.away {
     background: #ffcf6e;
   }
   .contact .status.inactive {
     background: #eaeef0;
   }
   .topmenu {
    min-width: 320px;
    height: 355px;
    transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
   }
   
   #contacts {
     height: 355px;
     overflow-y: scroll;
   }
   
   #contacts::-webkit-scrollbar {
     width: 4px;
     height: 4px;
     background: transparent;
   }
   #contacts::-webkit-scrollbar-track {
     box-shadow: inset 0 0 5px rgb(185, 179, 179);
     border-radius: 0px;
   }
   #contacts::-webkit-scrollbar-thumb {
     background: #e1422d9f;
     border-radius: 0px;
   }
   #contacts::-webkit-scrollbar-thumb:hover {
     background: #7c7a7a;
   }
   #contacts.animate {
     opacity: 1;
     margin-top: 0;
   }

#chatview {
     min-width: 320px;
     height: 523px;
     /* position:absolute; */
     top: 0;
     left: 0;
     /* display:none; */
     background: #fff;
     border-radius: 0px;
   }
   
   #profile {
     height: 130px;
     overflow: hidden;
     width: 100%;
     text-align: center;
     color: #fff;
   
   }
   .chatSetting {
     height: 523px;
     overflow-y: scroll !important;
   }
 
#profile {

       background: linear-gradient(rgba(208, 29, 29, 0.306), rgba(24, 1, 1, 0.499)),
       url(/static/media/property.1c6d0d06.jpg) 0 0;
     background-size: cover;
     background-position: center;
     height: 130px;
     border-radius: 0px;
   }
   #profile .avatar {
     width: 68px;
     border: 3px solid #fff;
     margin: 23px 0 0;
     border-radius: 50%;
   }
   #profile p {
     font-weight: 600;
     font-size: 15px;
     margin: 1px 0 -1px;
     /* opacity:0; */
     transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
   }


   .chatProfile {
     width: 45px;
     height: 45px;
     border-radius: 50%;
     margin: 15px;
     border: 2px solid #fff;
     background-color: #fff;
   }

   @media only screen and (min-device-width: 370px) and (max-device-width: 405px) {

    .chatBox-popup {
      right: 1px;
      width: 370px;
    }
   }
   @media only screen and (min-device-width: 350px) and (max-device-width: 370px) {

    .chatBox-popup {
      right: 1px;
      width: 350px;
    }
   }
   @media only screen and (min-device-width: 00px) and (max-device-width: 350px) {

    .chatBox-popup {
      right: 1px;
      width: 320px;
      height: 100%;
    }
   }
