.tableHead {
    white-space: nowrap;
  }
.rowTable{
    width: 100%;
    text-align: left;
    display: inline-table;
    /* white-space: nowrap; */
    /* table-layout: fixed; */
  }
  
  .headName{
    text-align: left;
    color:rgb(243, 243, 243) !important;
    font-family: 'Montserrat', sans-serif;
  }
  .tableRental {
    height: 510px;
  }
  thead {
    overflow-y: scroll;
    width: 100%;
    background-color: #eb6658 ;
  }
  tbody {
    /* overflow-y: scroll; 
    width: 100%;     
    height: 500px;            
    position: absolute; */
  }
  .tableYAxis {
  }
  /* width */

  .ratingName{
    min-width:180px !important;
    /* display: ; */
    /* overflow: scroll !important; */
  }
  .table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  .table::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .table::-webkit-scrollbar-thumb {
    background: #aca9a8;
  }
  
  /* Handle on hover */
  .table::-webkit-scrollbar-thumb:hover {
    background: #FF4B2B;
  }