.aboutImage {
  /* position: fixed; */
  z-index: -1;
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  align-content: center;
  padding-top: 16%;
}
.aboutContain{
  padding-bottom: 11%;
  padding-right: 12%;
  padding-left: 12%;
}
.line-1 {
  position: relative;

  width: 22em;
  height: 1.3em;
  margin: 4px auto;
  padding-top: 12px;
  padding-bottom: 12px;
  border-right: 3px solid rgba(255, 255, 255, 0.75);
  font-size: 240%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
  font-family: "Montserrat", sans-serif;

  font-weight: 600;
  color: rgb(255, 255, 255);

  text-shadow: rgb(36, 2, 2) 9px 0 15px;
}
.aboutText{

  color: rgb(109, 109, 109);
  line-height:40px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
  text-align: justify;
  text-justify: inter-word;

}
/* Animation */
.anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 22em;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 22em;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}

@media (max-width: 1200px) {
  .aboutImage {
    height: 400px;
    padding-top: 19%;
  }
  .line-1 {
    width: 22em;
    height: 1.3em;
    margin: 4px auto;
    padding-top: 12px;
    padding-bottom: 12px;
    border-right: 3px solid rgba(255, 255, 255, 0.75);
    font-size: 240%;
  }
}


@media (max-width: 850px) {
     .aboutImage {
       height: 350px;
       padding-top: 22%;
     }
     .line-1 {
       width: 22em;
       height: 1.3em;
       margin: 4px auto;
       padding-top: 12px;
       padding-bottom: 12px;
       border-right: 3px solid rgba(255, 255, 255, 0.75);
       font-size: 180%;
     }
   }
   
@media (max-width: 750px) {
  .aboutContain{
    padding-bottom: 11%;
    padding-right: 10%;
    padding-left: 10%;
  }
     .aboutImage {
       height: 350px;
       padding-top: 26%;
     }
     .line-1 {
       width: 22em;
       height: 1.3em;
       margin: 4px auto;
       padding-top: 12px;
       padding-bottom: 12px;
       border-right: 3px solid rgba(255, 255, 255, 0.75);
       font-size: 180%;
     }
   }
   @media (max-width: 650px) {
    .aboutContain{
      padding-bottom: 11%;
      padding-right: 9%;
      padding-left: 9%;
    }
     .aboutImage {
       height: 300px;
       padding-top: 28%;
     }
     .line-1 {
       width: 22em;
       height: 0.8em;
       margin: 4px auto;
       padding-top: 0px;
       padding-bottom: 12px;
       border-right: 3px solid rgba(255, 255, 255, 0.75);
       font-size: 140%;
     }
   }
   @media (max-width: 540px) {
    .aboutContain{
      padding-bottom: 11%;
      padding-right: 8%;
      padding-left: 8%;
    }
    .aboutText{
      line-height:30px;
      font-size: 20px;
    }
     .aboutImage {
       height: 280px;
       padding-top: 30%;
       background-size: cover;
     }
     .line-1 {
       width: 22em;
       height: 0.8em;
       margin: 4px auto;
       padding-top: 0px;
       padding-bottom: 21px;
       border-right: 3px solid rgba(255, 255, 255, 0.75);
       font-size: 100%;
     }
   }
   @media (max-width: 440px) {
    .aboutContain{
      padding-bottom: 11%;
      padding-right: 7%;
      padding-left: 7%;
    }
    .aboutText{
      line-height:25px;
      font-size: 18px;
    }
     .aboutImage {
       height: 250px;
       padding-top: 38%;
       background-size: cover;
     }
     .line-1 {
       width: 22em;
       height: 0.8em;
       margin: 4px auto;
       padding-top: 0px;
       padding-bottom: 21px;
       border-right: 3px solid rgba(255, 255, 255, 0.75);
       font-size: 100%;
     }
   }
   @media (max-width: 360px) {
    .aboutText{
      line-height:25px;
      font-size: 17px;
    }
    .aboutContain{
      padding-bottom: 11%;
      padding-right: 6%;
      padding-left: 6%;
    }
     .aboutImage {
       height: 230px;
       padding-top: 40%;
       background-size: cover;
     }
     .line-1 {
       width: 22em;
       height: 0.8em;
       margin: 4px auto;
       padding-top: 0px;
       padding-bottom: 21px;
       border-right: 3px solid rgba(255, 255, 255, 0.75);
       font-size: 80%;
     }
   }