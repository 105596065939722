.navBottom1 {
  border-radius: 10px;
  border: 1px solid #97b5d648;
  border-top: 6px solid #ff4b2bcc;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  min-width: 320px;
  max-width: 720px;
  /* margin-left: 40%; */

  padding: 2%;
  padding-top: 0%;
  background-color: #fff;
}
.navBottom0 {
  /* border-radius: 10px; */
  border-right: 1px solid #97b5d648;
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px; */
  /* height:50px; */
  /* width:300px; */
  z-index: 1000;
  height:800px;
  position: fixed;
  padding-top:30px;
  top:80px;
  width:30%;
  /* background-color: #fff; */
}
.verticalLine{
  height:18px;
  margin-left: 19px;
  border-left: 1px solid rgba(179, 177, 177, 0.712); 
}
.verticalLineRed{
  height:18px;
  margin-left: 20px;
  border-left: 1px solid #ff4b2b; 
}
.officeBar {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-left: 3%;
  padding-bottom: 3%;
  margin-bottom: 2%;
  margin-top: 2%;
  border: 1px solid #ff4b2b80;
  border-radius: 15px;
  cursor: pointer;
}
.stepBottom {
  height: 100px;
}
.form-control {
  font-family: "Montserrat", sans-serif;
  /* border: 1px solid rgba(167, 165, 165, 0.349); */
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgba(99, 99, 99, 0.13) 0px 2px 8px 0px; */
}
.input-group-text {
  font-family: "Montserrat", sans-serif;
}
.officeApplicationSubTitle1 {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 20px;
  color: rgba(34, 32, 32, 0.664);
  font-weight: 600;
}
.officeApplicationTitle {
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  line-height: 20px;
  font-weight: 100;
  font-weight: 600;
}
.officeApplicationSubTitle {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 20px;
  /* font-weight: 600 ; */
}
.officeApplicationPara {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}
.officeOpenClock {
  width: 90%;
}

.containerRadioOfficeApp {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  white-space: pre;
}

/* Hide the browser's default checkbox */
.containerRadioOfficeApp input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgb(228, 224, 224);
}

/* On mouse-over, add a grey background color */
.containerRadioOfficeApp:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerRadioOfficeApp input:checked ~ .checkmark {
  background-color: #ff4b2b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerRadioOfficeApp input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerRadioOfficeApp .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.containerOfficeApp {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Montserrat", sans-serif;
}

.containerOfficeApp input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkMark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}
.containerOfficeApp:hover input ~ .checkMark {
  background-color: #ccc;
}

.containerOfficeApp input:checked ~ .checkMark {
  background-color: #ff4b2b;
}

.checkMark:after {
  content: "";
  position: absolute;
  display: none;
}

.containerOfficeApp input:checked ~ .checkMark:after {
  display: block;
}

.containerOfficeApp .checkMark:after {
  top: 8px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

@media only screen and (min-width: 850px) and (max-width: 1150px) {
  .navBottom1 {
    min-width: 320px;
    max-width: 720px;
 
  }
}
@media only screen and (min-width: 450px) and (max-width: 850px) {
  .navBottom1 {
    min-width: 320px;
    max-width: 720px;

  }
}
@media only screen and (min-width: 130px) and (max-width: 450px) {
  .navBottom1 {
    min-width: 300px;
    max-width: 720px;
    
  }
}
@media only screen and (min-width: 130px) and (max-width: 350px) {
  .navBottom1 {
    min-width: 280px;
    max-width: 720px;
    
  }
}
