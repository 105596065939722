.badgeFileType {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding:3px;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: -7px;
  margin-top: 10px;
  font-weight: 100;
  font-size: 13px;
  border-radius:6px;
  text-transform: capitalize !important;
  background-color: rgb(233, 233, 233);
  color: #828282;
  
}
.badgeFileType1 {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding:3px;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: -7px;
  margin-top: 10px;
  font-weight: 100;
  font-size: 13px;
  border-radius: 6px;
  text-transform: capitalize !important;
  background-color: rgb(140, 140, 140);
  color: #ffffff;
  
}
.fileTypeMenuPro{
  /* background-color: rgba(246, 246, 246, 0.692); */

 margin-top: -10px;
 /* margin-top: 10px; */
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 5px;
  padding:5px;
  padding-top:15px;
  padding-left:15px;

  width: 10px;
  height: 10px;
  right:-20px;
  position: static;
  background-color: rgba(231, 228, 228, 0.692);
 
}
.fileTypeMenuPro1{
  
  margin-left:-10px;
  margin-top:-35px;
}
.fileTypeMenuPro:hover{
  background-color: rgba(193, 193, 193, 0.692);
 
}
.fileType {
  position: relative;
  display: block;
  width: 120px;
  margin-right:9%;
  margin-left:9%;
  height: 140px;
  border-radius: 10px;
  background: #ccc;
  color: #888;
  font: bold 30px/160px Verdana;
  text-align: center;
  text-transform: uppercase;
}
.fileType:before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 40px;
  height: 40px;
  border-bottom-left-radius: 10px;
  background: #aaa;
}
.fileType:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 40px 40px 0;
  /* change #fff to color of your background. This doesn't work on non-solid backgrounds, but it's a trade-off for compatibility's sake. If you don't care about that, then you can get rid of this psuedo-element and use angled background gradients fading to transparent instead. */
  border-color: transparent #fff transparent transparent;
}

a.fileType {
  text-indent: 180px;
  text-decoration: none;
  overflow: visible;
  white-space: nowrap;
}

a.fileType:before {
  content: attr(data-extension);
  text-indent: -90px;
  /* IE8 hack, I really suggest using Paul Irish's conditional HTML classes instead: http://www.paulirish.com/2008/conditional-stylesheets-vs-css-hacks-answer-neither */
  text-indent: -55px\9;
}
.cardContract{
  background-color: #fff;
  padding-top: 3%;
  padding-bottom: 3%;
  margin-top: 2%;
  margin-bottom: 5%;
  border-radius: 8px;
  margin-right: 3%;
  margin-left: 3%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contractContainer {
  margin-right: 4%;
  margin-left: 4%;
  margin-top: 0px;
}
.contractCard {
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
}
.paymentModalColum {
  width: 100%;
  background-color: #f7f7f796;
  border: solid 1px #e1e1e1;
  padding: 10px;
}
.contractCard2 {
  background-color: rgb(244, 244, 244);
  border-radius: 15px;
  margin-bottom: 30px;
}
.contractCardDark {
  background-color: rgb(75, 75, 75);
  border-radius: 15px;
  margin-bottom: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
}
.contractImage {
  height: 50px;
}
.contractDivider {
  border-bottom: 1px solid rgba(214, 214, 214, 0.418);
}
.contractDivider2 {
  border-top: 1px solid rgba(153, 153, 153, 0.336);
}
.contractDivider3 {
  border-bottom: 2px solid rgba(95, 95, 95, 0.336);
}
.bg-gray {
  background-color: rgb(231, 231, 231);
}
.agreePayment {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}
.badge02 {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 500;
  background-color: rgb(231, 231, 231);
  color: #ff4d2d;
}
.badge03 {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 800;
  background-color: rgb(231, 231, 231);
  color: #ff4d2d;
}
.badge04 {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  padding-right: 15px;
  padding-left: 15px;
  font-weight: 800;
  background-color: rgb(255, 255, 255);
  color: #ff4d2d;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.badge05 {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 800;
  background-color: rgba(241, 239, 239, 0.514);
  border:1px solid rgba(222, 221, 221, 0.496);
  color: #323232;
  box-shadow: rgba(100, 100, 111, 0.052) 0px 7px 29px 0px;

}
.badge06 {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 800;
  background-color: rgba(210, 210, 210, 0.604);
  border:1px solid rgba(192, 190, 190, 0.492);
  color: rgb(32, 32, 32);
  box-shadow: rgba(100, 100, 111, 0.052) 0px 7px 29px 0px;

}
.badge07 {
  /* border: solid 1px #ff4b2b; */
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 800;
  background-color: rgba(255, 255, 255, 0.604);
  border:1px solid rgba(192, 190, 190, 0.492);
  color: rgb(32, 32, 32);
  box-shadow: rgba(100, 100, 111, 0.112) 0px 7px 29px 0px;
}
.contractText {
  font-family: "Montserrat", sans-serif;
  color: #807e7e;
  letter-spacing: 0.5px;
  /* line-height: 5px; */
  font-size: 15px;
  padding-top: 3px;
  margin: 0px;
}
.contractTextDark {
  font-family: "Montserrat", sans-serif;
  color: #fafafa;
  letter-spacing: 0.5px;
  /* line-height: 15px; */
  font-size: 15px;
  padding-top: 3px;
  margin: 0px;
}
.contractStatus {
  text-align: "center";
  padding: 7px;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  margin-bottom: 10px;
}

.agreementDark {
  border-radius: 20px;
  border: solid 1px #ffffff;
  background-color: transparent;
  color: #ffffff;

  font-size: 17px;
  font-weight: 500;
  padding: 5px 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
.agreementDark:hover {
  color: #7b7b7b;
  border: solid 1px #fff;
  background-color: #fff;
}
.listButtonIcon {
  border-radius: 29px;
  border: solid 3px #f2f2f2;
  background-color: #eb6658;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  padding: 3px 5px;
  width: 45px;
  height: 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
.listButtonIcon:hover {
  border: solid 3px #ffffff;
  background-color: #eb6658;
  color: #fff;
}

.listButtonIconDisable {
  border-radius: 29px;
  border: solid 3px #ffffff;
  background-color: #c0c0c0;
  color: #ffffff;
  font-size: 17px;
  font-weight: 500;
  padding: 3px 5px;
  width: 45px;
  height: 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
.listButtonIconDisable:hover {
  cursor: not-allowed;
}
.agreement {
  border-radius: 20px;
  border: solid 1px #eb6658;
  background-color: #eb6658;
  color: #fff;

  font-size: 17px;
  font-weight: 500;
  padding: 5px 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
.agreementDisable {
  border: solid 1px #a79e9d;
  background-color: transparent;
  color: #a79e9d;
  cursor: not-allowed !important;
}
.roundButton {
  border-radius: 20px;
  border: solid 1px #ff4b2b;
  background-color: transparent;
  color: #ff4b2b;
  font-size: 17px;
  font-weight: 500;
  padding: 3px 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  width: 35px;
  height: 35px;
}
.roundButton:hover {
  color: #fff;
  border: solid 1px #ff4b2b;
  background-color: #ff4b2b;
}
.roundButtonActive {
  border-radius: 20px;
  color: #fff;
  border: solid 1px #ff4b2b;
  background-color: #ff4b2b;
  font-size: 17px;
  font-weight: 500;
  padding: 3px 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  width: 35px;
  height: 35px;
}

.roundButtonDisable {
  border-radius: 20px;
  border: solid 1px #b8b8b8;
  background-color: transparent;
  color: #b8b8b8;
  font-size: 17px;
  font-weight: 500;
  padding: 3px 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  width: 35px;
  height: 35px;
}

.roundButtonDark {
  border-radius: 20px;
  border: solid 1px #ffffff;
  background-color: transparent;
  color: #ffffff;

  font-size: 17px;
  font-weight: 500;
  padding: 3px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
.roundButtonDarkDisable {
  border-radius: 20px;
  border: solid 1px #b8b8b841;
  background-color: transparent;
  color: #b8b8b841;

  font-size: 17px;
  font-weight: 500;
  padding: 3px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
.roundButtonDark:hover {
  color: #7b7b7b;
  border: solid 1px #fff;
  background-color: #fff;
}
.payImage {
  height: 70px;
  width: 70px;
  border-radius: 15px;
  border: solid 1px #7b7b7b;
}
.payImageAlign {
  width: 100px;
  margin-left: 30px;
  margin-left: 30px;
}
.contractListAlign {
  min-width: 180px;
}
.applicationListAlign {
  min-width: 130px;
}
.applicationListAlign2 {
  min-width: 120px;
}
.addApp {
  margin-top: -10px;
}
.appUnit {
  margin-top: 20px;
}
.applicationTenantText {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;

  font-weight: 100;
  line-height: 20px;
  letter-spacing: 1.5px;
}

.contractListImage2 {
  display: none;
}
.applicationListImage1 {
  display: flex;
}
.applicationListImage2 {
  display: none;
}
.applicationListImage3 {
  height: 250px;
  display: none;
}
@media only screen and (min-width: 850px) and (max-width: 5550px) {
.alignSmokeDetect{
  padding-right:30px;
  padding-left:30px;
}
}
@media only screen and (min-width: 850px) and (max-width:1150px) {
  .applicationListImage1 {
    display: none;
  }
  .applicationListImage2 {
    display: flex;
  }
  .applicationListImage3 {
    display: flex;
  }
}
@media only screen and (min-width: 770px) and (max-width: 850px) {
  .alignSmokeDetect{
    padding-right:20px;
    padding-left:20px;
  }
  .agreement {
    font-size: 16px;
    padding: 5px 18px;
  }
  .contractListImage1 {
    display: none;
  }
  .contractListImage2 {
    display: block;
  }
  .applicationListImage1 {
    display: none;
  }
  .applicationListImage2 {
    display: flex;
  }
  .applicationListImage3 {
    display: flex;
  }
  .contractText {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .agreement {
    margin-top: 10px;
  }
  .applicationTenantText {
    font-size: 17px;
  }
}
@media only screen and (min-width: 580px) and (max-width: 770px) {
  .paymentPlanToggleAlign {
    margin-left: -50px;
  }
  .agreement {
    font-size: 15px;
    padding: 5px 16px;
  }
  .contractListImage1 {
    display: none;
  }
  .contractListImage2 {
    display: block;
  }
  .applicationListImage1 {
    display: none;
  }
  .applicationListImage2 {
    display: flex;
  }
  .applicationListImage3 {
    display: flex;
  }
  .addApp {
    margin-top: -30px;
  }
  .contractText {
    font-size: 13px;
  }

  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .agreement {
    margin-top: 10px;
  }
  .appUnit {
    margin-top: -18px;
  }
  .appNot {
    margin-top: -13px;
  }
  .applicationTenantText {
    font-size: 15px;
    word-break: normal;
  }
  .payImageAlign2 {
    margin-left: 35px;
    margin-right: 35px;
  }
}

@media only screen and (min-width: 430px) and (max-width: 580px) {
  .paymentPlanToggleAlign {
    margin-left: -150px;
  }
  .agreement {
    font-size: 14px;
    padding: 4px 14px;
  }
  .contractListImage1 {
    display: none;
  }
  .contractListImage2 {
    display: block;
  }
  .applicationListImage1 {
    display: none;
  }
  .applicationListImage2 {
    display: flex;
  }
  .applicationListImage3 {
    display: flex;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .addApp {
    margin-top: -30px;
  }
  .contractText {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .agreement {
    margin-top: 10px;
  }
  .appUnit {
    margin-top: -18px;
  }
  .appNot {
    margin-top: -13px;
  }
  .applicationTenantText {
    font-size: 13px;
    word-break: normal;
  }
  .contractCardDetail {
    padding-right: 30px;
    padding-left: 30px;
  }
  .payImageAlign2 {
    margin-left: 35px;
    margin-right: 35px;
  }
}

@media only screen and (min-width: 100px) and (max-width: 430px) {
  .paymentPlanToggleAlign {
    margin-left: -150px;
  }
  .agreement {
    font-size: 13px;
    padding: 3px 12px;
  }
  .contractListImage1 {
    display: none;
  }
  .contractListImage2 {
    display: block;
  }
  .applicationListImage1 {
    display: none;
  }
  .applicationListImage2 {
    display: flex;
   
  }
  .applicationListImage3 {
    display: flex;
    height: 250px !important;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .contractText {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
    border: 2px solid rgb(219, 219, 219);
  }
  .addApp {
    margin-top: -30px;
  }
  .agreement {
    margin-top: 10px;
  }
  .appUnit {
    margin-top: -18px;
  }
  .appNot {
    margin-top: -13px;
  }
  .applicationTenantText {
    font-size: 11px;
    word-break: normal;
  }
  .contractCardDetail {
    padding-right: 20px;
    padding-left: 20px;
  }
  .payImageAlign2 {
    margin-left: 35px;
    margin-right: 35px;
  }
}
