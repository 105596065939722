.octagon {
  width: 150px;
  height: 150px;
  background: #273746;
  /* background: linear-gradient(rgb(206, 197, 197), rgb(2, 7, 15)); */
  position: relative;
}
.octagon:before {
  content: "";

  width: 150px;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 60px solid#273746;
  border-left: 60px solid #fff;
  border-right: 60px solid #fff;
}
.octagon:after {
  content: "";
  width: 150px;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 60px solid #273746;
  border-left: 60px solid #fff;
  border-right: 60px solid #fff;
}

.square {
  /* z-index: 0; */
  /* border: 5px outset #273746; */
  /* border-style: inset; */
  /* background: url("http://localhost:3000/53.png"); */
  /* border-radius: 50px; */
  /* border-color: #d2d7db; */
  /* background-color: #EBEDEF ; */
  /* background-position: 5ch -10ch; */
  /* background-attachment: fixed; */
  /* transform: perspective(150px) rotateY(10deg); */

  /* background: linear-gradient(rgba(232, 236, 245, 1), rgba(180, 187, 192, 1)), url("http://localhost:3000/3.png"); */
}
.square1 {
}
.squareStyle {
  /* background: url("http://localhost:3000/build.svg"),
linear-gradient(rgb(255, 255, 255,1), rgba(255, 255, 255, 1)); */
  /* background-position: center; */
  background-size: cover;
}
.BackStatus {
  /* background: url("http://localhost:3000/53.png"),
  linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)); */
  background:#fff;
  background-position: -27ch -4ch;
}
.square:hover {
  /* opacity: 1; */
  /* background: linear-gradient(to bottom, #b6bece 0%, #4d78a0 100%); */
  /* background: url("http://localhost:3000/7.png"); */
  /* background-image: none; */
  /* background-color: #273746; */
  /* transform-origin: 0px 0px; */
  /* animation: boro 0.001s both linear; */
  /* z-index: 5; */
}
.round1 {
  background-color: #82e0aa;
  border-radius: 20px;
  background: linear-gradient(to bottom right, #f7f7f7 0%, #f7f7f7 100%);
}
.round1:hover {
  /* padding-top: 5px;
  background: #d16356;
  background-color: #e74d3c38;
  border: 1px solid #f39f96; */
}
.square1 .textGreen {
  background-color: #82e0aa;
  color: #fff;
}
.square1:hover .textGreen {
  background-color: #fff;
  color: #82e0aa;
}
.square1 .textBlue {
  background-color: #5dade2;
  color: #fff;
}
.square1:hover .textBlue {
  background-color: #fff;
  color: #5dade2;
}
.square1 .textRed {
  background-color: #df978c;
  color: #fff;
}
.square1:hover .textRed {
  background-color: #fff;
  color: #ec7063;
}
.square1 .textPurple {
  background-color: #c39bd3;
  color: #fff;
}
.square1:hover .textPurple {
  background-color: #fff;
  color: #c39bd3;
}
.square1 .textGray {
  background-color: #abb2b9;
  color: #fff;
}
.square1:hover .textGray {
  background-color: #fff;

  color: #abb2b9;
}
.textGreen:hover {
  color: #82e0aa;
  color: #ff9082f5;
  cursor: pointer;
}
.textBlue:hover {
  color: #5dade2;
  color: #ff9082f5;
  cursor: pointer;
  /* -webkit-text-stroke: 0.5px white; */
}
.textRed:hover {
  color: #ff9082f5;
  cursor: pointer;
}
.textPurple:hover {
  /* color: #C39BD3; */
  color: #ff9082f5;
  cursor: pointer;
}
.textGray:hover {
  color: #abb2b9;
  color: #ff9082f5;
  cursor: pointer;
}

.bgGreen {
  border-color: #82e0aa;
}
.bgBl {
  border-color: #5dade2;
}

.bgRed {
  border-color: #ec7063;
}
.bgPurple {
  border-color: #c39bd3;
}
.bgGray {
  border-color: #808b96;
}
.cardIconDash {
  font-size: 50px;
  z-index: 5;
  position: relative;

  /* color: #fff; */
}
.cardTextDash {
  /* color: #fff; */
  z-index: 5;
  font-size: 50px;
  position: relative;
}
.innerSquare {
  /* background-color: #fff; */
  width: 100%;
}
@keyframes boro {
  to {
    transform: perspective(0px) rotateY(-20deg);
  }
  from {
    transform: perspective(200px) rotateY(20deg);
  }
}
.scaled-square {
  transform: scaleX(20deg) /* flip "up" */ scaleY(-20deg) /* flip "right" */
    scaleZ(20deg); /* rotate counter-clockwise */
}

.scaled-square-shorthand {
  transform: scale3d(20deg, -20deg, 20deg);
}
@media only screen and (min-device-width: 250px) and (max-device-width: 319px) {
}
@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .square {
    margin-top: 30px;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 480px) {
  .square {
    margin-top: 30px;
  }
}
@media only screen and (min-device-width: 479px) and (max-device-width: 767px) {
  .square {
    margin-top: 80px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .square {
    margin-right: 30px;
    margin-top: 30px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .square {
    margin-right: 30px;
    margin-top: 30px;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1320px) {
  .square {
    margin-right: 0px;
    margin-top: 30px;
  }
}
@media only screen and (max-device-width: 3000px) and (min-device-width: 1024px) {
  .square {
    margin-left: 10px;
  }
  .firstSquare {
  }
}

@media only screen and (max-width: 1024px) and (min-width: 980px) {
  .square {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 980px) and (min-width: 800px) {
  .square {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 800px) and (min-width: 650px) {
  .square {
    margin-right: 50px;
  }
}
@media only screen and (max-width: 650px) and (min-width: 500px) {
  .square {
    margin-left: 80px;
    margin-top: 90px;
  }
}
@media only screen and (max-width: 500px) and (min-width: 360px) {
  .square {
    margin-left: 80px;
    margin-top: 60px;
  }
}
@media only screen and (max-width: 360px) and (min-width: 200px) {
  .square {
    margin-left: 40px;
    margin-top: 50px;
  }
}
