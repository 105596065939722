

.chart-skills li:nth-child(1) span {
  top: 5px;
  left: 10px;
  transform: rotate(-21.6deg);
}

.chart-skills li:nth-child(2) span {
  top: 20px;
  left: 10px;
  transform: rotate(-79.2deg);
  animation-delay: .4s;
}

.chart-skills li:nth-child(3) span {
  top: 18px;
  left: 10px;
  transform: rotate(-140.4deg);
  animation-delay: .8s;
}

.chart-skills li:nth-child(4) span {
  top: 10px;
  left: 10px;
  transform: rotate(-180deg);
  animation-delay: 1.2s;
}


@keyframes fade-in {
  0%,
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}