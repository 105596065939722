.page404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  padding-top: 15%;
  margin: -20px 0 50px;
}
.PageText404 {
  padding-top: 20%;
}
.head {
  font-size: 100px;
}
@media only screen and (min-device-width: 800px) and (max-device-width: 900px) {
  .head {
    font-size: 60px;
  }
  .head2 {
    font-size: 30px;
  }
  .page404 {
    padding-top: 8%;
    margin: -20px 0 50px;
  }
  .PageText404 {
    padding-top: 16%;
  }
  .span1 {
    font-size: 17px;
  }
}
@media only screen and (min-device-width: 650px) and (max-device-width: 800px) {
  .head {
    font-size: 30px;
  }
  .head2 {
    font-size: 23px;
  }
  .page404 {
    padding-top: 8%;
    margin: -20px 0 50px;
  }
  .PageText404 {
    padding-top: 16%;
  }
  .span1 {
    font-size: 12px;
    line-height: 20px;
  }
}
@media only screen and (min-device-width: 550px) and (max-device-width: 650px) {
  .head {
    font-size: 24px;
  }
  .head2 {
    font-size: 16px;
    padding-top: 10% !important;
  }
  .page404 {
    padding-top: 8%;
    margin: -20px 0 50px;
  }
  .PageText404 {
    padding-top: 1%;
  }
  .span1 {
    font-size: 9px;
    line-height: 20px;
  }

}
@media only screen and (min-device-width: 100px) and (max-device-width: 550px) {
  .head {
    font-size: 19px;
  }
  .head2 {
    padding-top: 15% !important;
    font-size: 20px !important;
  }
  .page404 {
    padding-top: 8%;
    margin: -20px 0 50px;
  }
  .PageText404 {
    padding-top: 1%;
  }
  .span1 {
    font-size: 8px;
    line-height: 15px;
  }
  .pri {
    /* margin-top: -20px !important; */
  }
}
