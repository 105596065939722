.navItemSearch1 {
  height: 3rem;
  width: 3rem;
  margin-left: 30px;
  padding-top: 5px;
}
.searchInsideButton1 {
  border-radius: 25px;
  float: left;
  background-color: #eb6658;
  height: 100%;
  color: #ffffff;
  /* position:absolute; */
  margin-left: -15px;
}
.show {
  display: block;
}
.hide {
  display: none;
}
.searchBoxOpen {
  width: 19rem;
  border: none;
  border-radius: 25px;
  /* padding-left: 2rem; */
  padding-right: 0px;
  flex-grow: 2;
}
.searchForm1 {
  width: 16rem;
  display: flex;
  flex-direction: row;
  border: 1px solid grey;
  border-radius: 25px;
  border-width: 40;
  color: #000;
  padding: 1px;
  margin-right: 20px;
}

.searchInput2 {
  border: none;
  border-radius: 25px;
  border: 20px;
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
}


@media only screen and (min-width: 425px) and (max-width: 745px) {
  .searchBoxOpen {
    width: 15rem;
  }
  .searchInput2 {
    font-size: 10px;
  }
  .searchBoxOpen {
    width: 15rem;
  }
  .navItemSearch1 {

    margin-left: 30px;

  }
  .searchInsideButton1 {
    margin-left: 42px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 425px) {
  .searchBoxOpen {
    width: 12rem;
  }
  .searchInput2 {
    font-size: 10px;
  }
  .searchBoxOpen {
    width: 12rem;
  }
  .navItemSearch1 {

    margin-left: 30px;

  }
  .searchInsideButton1 {
    margin-left: -22px;
  }
  .searchForm1 {
 
    margin-left: 14px;
  
  }
  .searchInput2{
    padding-left: 20px;
  }
  
}