.page404{

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	padding-top: 8%;
	margin: -20px 0 50px;
}
.page404Original{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	padding-top: 8%;
	margin: -150px 0 50px;
}
.Image404{
	padding-top: 10%;
}
.PageText404{
    padding-top: 20%;
}
.head{
    font-size: 100px;
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1500px) {
	.page404Original{
		margin: -90px 0 50px;
	}

}
@media only screen and (min-device-width: 800px) and (max-device-width: 900px) {
	.page404Original{
		margin: -40px 0 50px;
	}
	.head{
		font-size: 60px;
	}
	.head2{
		font-size: 30px;
	}
	.page404{
		padding-top: 8%;
		margin: -20px 0 50px;
	}
	.Image404{
		padding-top: 10%;
	}
	.PageText404{
		padding-top: 16%;
	}
	.span1{
		font-size: 17px;
	}
}
@media only screen and (min-device-width: 650px) and (max-device-width: 800px) {
	.page404Original{
		margin: 0px 0 50px;
	}
	.head{
		font-size: 30px;
	}
	.head2{
		font-size: 23px;
	}
	.page404{
		padding-top: 15%;
		margin: -20px 0 80px;
	}
	.Image404{
		padding-top: 10%;
	}
	.PageText404{
		padding-top: 16%;
	}
	.span1{
		font-size: 12px;
		line-height: 20px;
	}
}
@media only screen and (min-device-width: 550px) and (max-device-width: 650px) {
	.page404Original{
		margin: 30px 0 50px;
	}
	.head{
		font-size: 24px;
	}
	.head2{
		font-size: 16px;
	}
	.page404{
		padding-top: 18%;
		margin: -20px 0 50px;
	}
	.Image404{
		padding-top: 10%;
	}
	.PageText404{
		padding-top: 1%;
	}
	.span1{
		font-size: 9px;
		line-height: 20px;
	}

}
@media only screen and (min-device-width: 100px) and (max-device-width: 550px) {
	.page404Original{
		margin: 60px 0 50px;
	}
	.head{
		font-size: 19px;
	}
	.head2{
		font-size: 10px;
	}
	.page404{
		padding-top: 20%;
		margin: -20px 0 50px;
	}
	.Image404{
		padding-top: 10%;
	}
	.PageText404{
		padding-top: 1%;
	}
	.span1{
		font-size: 8px;
		line-height: 15px;
	}
	.pri{
		/* margin-top: -20px !important; */
	}
}