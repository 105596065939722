.requestDescriptionDivider{
     padding-left:10px !important;
     margin-right: 10px !important;
}
.requestDescriptionText{
     padding-top: 20px;
     padding-bottom: 10px;
    
}
@media only screen and (min-width: 0px) and (max-width: 344px) {
 .requestDescriptionText{
     padding-top: 45px;
 }
}
