
/* Toggle Button  */

.dropdownButton {
     border-top-right-radius: 0%;
     border-top-left-radius: 10px;
     border-bottom-left-radius:  10px;
     border-bottom-right-radius: 0%;
     font-size: 12px;
     font-weight: bold;
     padding: 4px 10px;
     letter-spacing: 1px;
     text-transform: uppercase;
     border: none;
     color: #fff;
   }
   
   .dropdownButton.icon {
     border-top-right-radius:  10px;
     border-top-left-radius: 0%;
     border-bottom-left-radius:0%;
     border-bottom-right-radius:  10px;
     border-left: solid 1px #ffffff;
   }
   
   .dropdownButton-component {
     position: absolute;
     display: inline-block;
   }
   
   .dropdownButton-content {
     display: none;
     position: absolute;
     background-color: #f1f1f1;
     min-width: 160px;
     z-index: 1;
   }
   
   .dropdownButton-content span {
     color: black;
     padding: 6px 10px;
     text-decoration: none;
     display: block;
     cursor: pointer;
     font-size: 14px;
     font-family: "Montserrat", sans-serif;
   }
   
   .dropdownButton-content span:hover {background-color: #ddd}
   
   .dropdownButton-component:hover .dropdownButton-content {
     display: block;
   }
   
   .yellowDropdownButton .dropdownButton:hover.icon{
     background-color: #febe6f;
   }
   .yellowDropdownButton .dropdownButton{
     background-color: #F89212;
   }