.propertyCardPro{
    border-radius: 15px;
    height:100%;
    width:100%;
    min-width:305px;
   max-width:375px;
  
   
  
   background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    /* background-color: black; */
}
.propertyCardProAlign{
    max-width:375px;
}
.viewListingButton {
    display: none;
    position: absolute;
    margin-top: 140px;
    border: solid 1px #fff;
    background-color: #ff4b2b91;
   
    color: #fff;
    cursor:pointer;
    border-radius: 25px;
    box-shadow: rgba(255, 255, 255, 0.438) 0px 0.0625em 0.0625em, rgba(255, 255, 255, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.344) 0px 0px 0px 1px inset;
}
 .propertyCardPro:hover .viewListingButton{
    display: flex;
    
}
.property-avatar{
    width: 60px;
    height: 60px;
    
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
}
.propsMenuPro{
    /* background-color: rgba(246, 246, 246, 0.692); */

   margin-top: -5px;
   /* margin-top: 10px; */
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 10px;
    padding:18px;
    width: 20px;
    height: 20px;
    right:-20px;
    position: absolute;
}
.propsMenuPro1{
    position: absolute;
    margin-left:-10px;
    margin-top:-10px;
}
.propsMenuPro:hover{
    
    background-color: rgba(231, 228, 228, 0.692);
}
.propsAddress{
    font-family: 'Montserrat', sans-serif;
    color:#555555;
    font-size: 13px;
    line-height: 13px;
}
.propsAddressHead{
    font-family: 'Montserrat', sans-serif;
    color:#555555;
    font-size: 13px;
    font-weight:800;
    line-height: 13px;
}
.propsContent{
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    color:rgb(134, 128, 128);
}
.propsContent2{
    font-family: 'Montserrat', sans-serif;
    color:rgb(134, 128, 128);
    font-size: 12px;
}
.propCoverContentPro{
    
    border: 0px solid #fff;
     border-bottom: 1px solid rgb(225, 225, 225);
    -moz-box-shadow:    inset 0 0 20px #bcbfc0;
    /* -webkit-box-shadow: inset 0 0 20px #bcbfc0; */
    /* box-shadow:         inset 0 -1px 10px #bcbfc0; */
}
.propCoverContentPro1{
    padding-Top :10px;
    font-size: 14px;
    color:#555555;
}
.click{
    cursor: pointer;
}
.propsImagePro{
    width: 100%;
    object-fit: cover;
    display: block;
  margin-left: auto;
  margin-right: auto;
  height:300px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.propertyCardPro:hover .propCoverContentPro{
    
    animation: return2 0.4s linear 1;
    opacity: 1;
    height:85px;
}
.propertyCardPro .propCoverContentPro1{
    
    /* display:none; */

}
.propertyHead{
   
}
.propFooterButton{
   
    
}
.propertyCardPro:hover .propCoverContentPro1{
    
    /* display:block; */

}
.propertyCardPro .propCoverContentPro{
    
    animation: turn3 0.4s linear 1;
    opacity: 0;
    height:0px;
   
}

@keyframes turn3 {
    0% {
        height:85px;
        opacity: 1;
    }
    30%{
        opacity: 0.2;
       }
    100% {
        opacity: 0;
        height:0px;
    }
  }
  @keyframes return2 {
    0% {
        height:0px;
        opacity: 0;
    }
   70%{
    opacity: 0.4;
   }
    100% {
        opacity: 1;
        height:85px;
    }
  }
.propertyCardPro:hover .propsImagePro{
    animation: return3 0.4s linear 1;
    height:300px;
}
.propertyCardPro .propsImagePro{
    animation: turn2 0.4s linear 1;
    height:385px;
}
@keyframes turn2 {
    0% {
        height:300px;
    }
    /* 50% {
        height:335px;
    } */
    100% {
        height:385px;
    }
  }
  @keyframes return3 {
    0% {
        height:385px;
    }
    /* 50% {
        height:335px;
    } */
    100% {
        height:300px;
    }
  }
  .dropdown-item:hover{
    background-color:rgb(228, 228, 228);
    color:rgb(0, 0, 0);
    
  }
  .propertyCardAlign{
      min-width: 300px;
      max-width: 400px;
    margin-right:3px;
    margin-left:3px;
 }

  @media only screen and (min-width: 530px) and (max-width: 650px) {

    .propsAddress{
        font-size: 12px;
    }
    .propertyCardAlign{
       margin-right:3px;
       margin-left:3px;
    }
  }
  @media only screen and (min-width: 400px) and (max-width: 530px) {

    .propsAddress{
        font-size: 12px;
    }
    .propertyCardAlign{
       margin-right:3px;
       margin-left:3px;
    }
  }
  @media only screen and (min-width: 100px) and (max-width: 400px) {

    .propsAddress{
        font-size: 12px;
    }
    .propertyCardAlign{
       margin-right:1px;
       margin-left:1px;
    }
  }