.page404Contract {


  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  padding-top: 8%;
  margin: -20px 0 50px;
}
.registrationUI{
  padding-top: 15%;
  padding-bottom: 15%;
}
.PageText404 {
  padding-top: 20%;
}
.forgotAlign{
  /* padding:20%; */
  padding-top: 200px;
  padding-bottom: 10%;
  
}
.forgotAlign1{
  /* padding:20%; */
  padding-top: 200px;
  padding-bottom: 10%;
  
}
.forgotHead{
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
}
.forgotHead{
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
}
.forgotCard{
  padding: 1%;
  padding-top: 3%;
  width:500px;
  background-color: #fff;
  border-radius: 25px;
}
.resetLabel{
  margin-left: 32px;
  margin-bottom: -5px;
}
.resetAlert{
  margin-left: 14%;
  margin-right: 14%;
}
.head {
  font-size: 100px;
}
.resetCreateNew{
  color:rgb(142, 142, 142);
	cursor:pointer;
  font-size: 12px;
  
}
.resetCreateNew:hover{
	color:rgb(71, 175, 255);
}
.passwordDropDown{
  font-family: "Montserrat", sans-serif !important;
}
.resetStrength{
  width:300px !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px !important;
}
.resetStrengthText{
  font-size: 14px !important;

}
.resetLoader{
  position:absolute;
  bottom:20px;
  padding-left:25px;
}
.resetLoader1{
  position:absolute;
  bottom:15px;
  padding-left:65px;
}
@media only screen and (min-device-width: 1100px) and (max-device-width: 1800px) {
  .forgotAlign{
    padding-top: 150px;
  }
  .forgotAlign1{
    /* padding:20%; */
    padding-top: 150px;
    
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1100px) {
  .forgotAlign{
    padding-top: 150px;
  }
  .forgotAlign1{
    /* padding:20%; */
    padding-top: 150px;
    
  }
}
@media only screen and (min-device-width: 800px) and (max-device-width: 900px) {
  .forgotAlign{
    padding-top: 150px;
  }
  .forgotAlign1{
    /* padding:20%; */
    padding-top: 150px;
    
  }
  .head {
    font-size: 60px;
  }
  .head2 {
    font-size: 30px;
  }
  .page404Contract {
    padding-top: 8%;
    margin: -20px 0 50px;
  }
  .Image404Contract {
    padding-top: 5%;
  }
  .PageText404 {
    padding-top: 16%;
  }
  .span1 {
    font-size: 17px;
  }
}
@media only screen and (min-device-width: 650px) and (max-device-width: 800px) {
  .forgotAlign{
    padding-top: 150px;
  }
  .forgotAlign1{
    /* padding:20%; */
    padding-top: 150px;
    
  }
  .head {
    font-size: 30px;
  }
  .head2 {
    font-size: 23px;
  }
  .page404Contract {
    padding-top: 15%;
    margin: -20px 0 80px;
  }
  .Image404 {
    padding-top: 10%;
  }
  .PageText404 {
    padding-top: 16%;
  }
  .span1 {
    font-size: 12px;
    line-height: 20px;
  }
}

@media only screen and (min-device-width: 550px) and (max-device-width: 650px) {
  .forgotAlign{
    padding-top: 150px;
  }
  .forgotAlign1{
    /* padding:20%; */
    padding-top: 150px;
  }
  .head {
    font-size: 24px;
  }
  .head2 {
    font-size: 16px;
  }
  .page404Contract {
    padding-top: 18%;
    margin: -20px 0 50px;
  }
  .Image404 {
    padding-top: 10%;
  }
  .PageText404 {
    padding-top: 1%;
  }
  .span1 {
    font-size: 9px;
    line-height: 20px;
  }

}
@media only screen and (min-device-width: 100px) and (max-device-width: 550px) {
  
  .resetAlert{
    margin-left: 10%;
    margin-right: 10%;
  }
  .forgotCard{
    padding: 5%;
    padding-top: 3%;
  }

  .forgotAlign{
    padding-top: 130px; 
  }
  .forgotAlign1{
    /* padding:20%; */
    padding-top: 130px; 
  }
  .head {
    font-size: 19px;
  }
  .head2 {
    font-size: 10px;
  }
  .page404Contract {
    padding-top: 20%;
    margin: -20px 0 50px;
  }
  .Image404Contract {
    padding-top: 10% !important;
  }
  .PageText404 {
    padding-top: 1%;
  }
  .span1 {
    font-size: 8px;
    line-height: 15px;
  }
  .pri {
    /* margin-top: -20px !important; */
  }
}
