
/* Time line */

#timeline-content {
     /* margin-top: 50px; */
     /* text-align: center; */
   }
   .timeline {
     border-left: 4px solid #ff4b2b73;
     border-bottom-right-radius: 4px;
     border-top-right-radius: 4px;
     background: rgba(255, 255, 255, 0.03);
     color: rgba(255, 255, 255, 0.8);
     font-family: "Montserrat", sans-serif;
     margin: 5px auto;
     letter-spacing: 0.5px;
     position: relative;
     line-height: 1.2em;
     font-size: 1.03em;
     padding: 22px;
     padding-top: 0px;
     margin-top: 20px;
     padding-bottom: 25px;
     list-style: none;
     text-align: left;
     font-weight: 100;
     margin-left:-2px;
   }
   .timeline h1 {
     font-family: "Montserrat", sans-serif;
     letter-spacing: 1.5px;
     font-weight: 100;
     font-size: 1.4em;
   }
   .timeline h2,
   .timeline h3 {
     font-family: "Montserrat", sans-serif;
     letter-spacing: 1.5px;
     font-weight:600;
     font-size: 1.2em;
     color:#3e3a3a;
   }
   .timeLineMobileDate{
     font-family: "Montserrat", sans-serif;
     letter-spacing: 1px;
     font-size: 11px;
     font-weight: 500;
     text-align: justify;
     padding-bottom: 12px;
     display: block;
   }
   .timeline .event {
     border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
     padding-bottom: 22px;
     /* margin-bottom: 50px; */
     position: relative;
     font-family: "Montserrat", sans-serif;
     color:#414141;
     letter-spacing: 0.5px;
     font-size: 13px;
     font-weight: 600;
     line-height: 20px !important;
   }
   .timeline .event:last-of-type {
     padding-bottom: 0;
     margin-bottom: 0;
     border: none;
   }
   .timeline .event:before,
   .timeline .event:after {
     position: absolute;
     display: block;
     top: 0;
   }
   .timeline .event:before {
     left: 0px;
     content: attr(data-date);
     text-align: right;
     font-weight: 100;
     font-size: 0.9em;
     min-width: 120px;
     
     font-family: "Montserrat", sans-serif;
     color:#908f8f;
     letter-spacing: 0.5px;
     font-size: 11px;
     font-weight: 500;
     display: none;
   }
   .timeline .event:after {
     box-shadow: 0 0 0 4px #ff4b2b;
     left: -30px;
     background: #fafafa;
     border-radius: 50%;
     height: 11px;
     width: 11px;
     content: "";
     top: 5px;
   }
    .maintenanceRequestTimelineText{
          font-family: "Montserrat", sans-serif;
          letter-spacing: 1px;
          font-size: 11px;
          font-weight: 500;
          text-align: left;
          line-height: 21px !important;
          display:inline-block;
     
          word-wrap:break-word;
          white-space: normal;

     }
     
     .timeLineLabel{
          word-break: break-all;
     }

     @media only screen and (min-width: 680px) and (max-width: 767px) {
          .timeline-container{
               margin-top: 3%;
          }
          .timeline .event:before {
               display: block;
          }
          .timeline {
               margin: 10px auto;
           
               margin-left: 29%;
          }
          .timeline .event:before {
               left: -210px;
          }
          #timeline-content {
               margin-top: 5%;
               
          }
          .timeLineMobileDate{
               display: none;
             }
     } 
     
   @media only screen and (min-width: 568px) and (max-width: 680px) {
     .timeline-container{
          margin-top: 3%;
     }
     .timeline {
        margin-left: 14%;
      }
      .timeLineMobileDate{
          display: block;
     }
     .timeline .event:before {
          display: none;
     }
     #timeline-content {
          margin-top: 5%;
     }
   }
   @media only screen and (min-width: 368px) and (max-width: 568px) {
     .timeline-container{
          margin-top: 5%;
     }
     .timeline {
          margin-left: 2%;
        }
     .timeLineMobileDate{
          display: block;
     }
     .timeline .event:before {
          display: none;
     }
     #timeline-content {
          margin-top: 2%;
     }
}
@media only screen and (min-width: 00px) and (max-width: 368px) {
     .timeline-container{
          margin-top: 5%;
     }
     .timeline {
          margin-left: 2%;
        }
     .timeLineMobileDate{
          display: block;
     }
     .timeline .event:before {
          display: none;
     }
     #timeline-content {
          margin-top: 2%;
     }
}
