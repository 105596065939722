
   h1.chat {
     margin: 0;
     font-size: 16px;
     line-height: 1;
     font-family: "Montserrat", sans-serif;
   }
   
   button.chat {
     color: inherit;
     background-color: transparent;
     border: 0;
     outline: 0 !important;
     cursor: pointer;
   }
   .chatComponent{
     position: fixed;
     bottom: 0;
     right: 0;
     z-index: 1000 !important;
     font-family: "Montserrat", sans-serif;
   }

   span.chatBox-open {
     position: fixed;
     bottom: 0;
     right: 0;
     width: 52px;
     height: 52px;
     color: #fff;
     background-color: #fc2222bc;
     background-position: center center;
     background-repeat: no-repeat;
     box-shadow: 12px 15px 20px 0 rgba(46, 61, 73, 0.15);
    font-size: 18px !important;
     border: 1px solid rgba(255, 255, 255, 0.26);
     border-radius: 50%;
     cursor: pointer;
     margin: 16px;
    padding-top:12px;
     text-align: center;
   
   }
   
   textarea.chat{
     box-sizing: border-box;
     width: 100%;
     margin: 0;
     height: calc(16px + 16px / 2);
     padding: 0 calc(16px / 2);
     font-family: inherit;
     font-size: 15px;
     line-height: calc(16px + 16px / 2);
     color: #888;
     background-color: none;
     border: 0;
     outline: 0 !important;
     resize: none;
     overflow: hidden;
   }
   textarea::-moz-placeholder {
     color: #888;
   }
   textarea:-ms-input-placeholder {
     color: #888;
   }
   textarea::placeholder {
     color: #888;
   }
   
   .chatBox-popup {
     position: absolute;
   
     flex-direction: column;
     bottom: calc(2 * 16px + 52px);
     right: 16px;
     width: 377px;
     height: auto;
     box-shadow: rgba(111, 105, 100, 0.269) 0px 7px 29px 20px;
     
     border-radius: 16px;
     animation: hideChat 0.6s;
     opacity:0;
     display: none;
   }
   .showChat{
     opacity: 1;
     display: block;
     animation: showChat 0.6s;
   }
   @keyframes showChat {
     0%{
         display: none; 
         opacity: 0;
     }
     90% {
         opacity: 0.9;
     }
     100% {
         opacity: 1;
         display: block; 
     }
 }
 @keyframes hideChat {
     0%{
          display: block; 
         opacity: 1;
     }
     90% {
         display: block; 
         opacity: 0.1;
     }
     100% {
         opacity: 0;
         display: none; 
     }
 }
   .chatBox-popup .chatBox-popup__header {
     box-sizing: border-box;
     display: flex;
     width: 100%;
     padding: 16px;
     height: 55px !important;
     color: #fff;
     background-color: #f13e3ed1;
     align-items: center;
     justify-content: space-around;
     border-top-right-radius: 12px;
     border-top-left-radius: 12px;
   }
   .chatBox-popup .chatBox-popup__header .chatBox-popup__avatar {
     margin-top: -32px;
     font-size: 50px;
     background-color: #f13e3ed1;
     border: 5px solid rgba(0, 0, 0, 0.1);
     border-radius: 50%;
   }
   .chatIcon{
    margin-top: -40px;
    margin-left: -0px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.893);
    border: 5px solid rgba(234, 9, 9, 0.815);
   }
   .chatBox-popup .chatBox-popup__main {
     box-sizing: border-box;
     width: 100%;
     padding: calc(2 * 16px) 16px;
     line-height: calc(16px + 16px / 2);
     color: #888;
     text-align: center;
   }
   .chatBox-popup .chatBox-popup__footer {
     box-sizing: border-box;
     display: flex;
     width: 100%;
     padding: 16px;
     border-top: 1px solid #ddd;
     align-items: center;
     justify-content: space-around;
     border-bottom-right-radius: 12px;
     border-bottom-left-radius: 12px;
   }
   
   .chatBox-panel {
     display: flex;
     position: absolute;
     box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
     
     flex-direction: column;
     display: none;
     top: 0;
     right: 0;
     bottom: 0;
     width: 377px;
     background-color: rgba(255, 255, 255, 0.19);
   }
   .chatBox-panel .chatBox-panel__header {
     box-sizing: border-box;
     display: flex;
     width: 100%;
     padding: 16px;
     color: #fff;
     background-color: #f13e3ed1;
     align-items: center;
     justify-content: space-around;
     flex: 0 0 auto;
   }
   .chatBox-panel .chatBox-panel__main {
     box-sizing: border-box;
     width: 100%;
     padding: calc(2 * 16px) 16px;
     line-height: calc(16px + 16px / 2);
     color: #888;
     text-align: center;
     flex: 1 1 auto;
   }
   .chatBox-panel .chatBox-panel__footer {
     box-sizing: border-box;
     display: flex;
     width: 100%;
     padding: 16px;
     border-top: 1px solid #ddd;
     align-items: center;
     justify-content: space-around;
     flex: 0 0 auto;
   }
   #chat-messages {
     
     /* margin-top: 30px; */
     min-width: 320px;
     height: 355px;
     overflow-y: scroll;
     overflow-x: hidden;
     /* padding-right: 20px; */
     -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
     -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
     -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
     -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
     transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
     /* display: flex;
     flex-direction: column-reverse; */
   }
   
   #chat-messages::-webkit-scrollbar {
     width: 4px;
     height: 4px;
     background: transparent;
   }
   #chat-messages::-webkit-scrollbar-track {
     box-shadow: inset 0 0 5px rgb(185, 179, 179);
     border-radius: 0px;
   }
   #chat-messages::-webkit-scrollbar-thumb {
     background: #e1422d9f;
     border-radius: 0px;
   }
   #chat-messages::-webkit-scrollbar-thumb:hover {
     background: #7c7a7a;
   }
   #chat-messages.animate {
     opacity: 1;
     margin-top: 0;
   }
   #chat-messages label {
     color: #aab8c2;
     font-weight: 600;
     font-size: 12px;
     text-align: center;
     margin: 15px 0;
     min-width: 320px;
     display: block;
   }
   #chat-messages div.message {
     padding: 0 0 5px 58px;
     clear: both;
     margin-bottom: -5px;
   }
   #chat-messages div.message.right {
     padding: 0 58px 0px 0;
     margin-right: 0px;
     margin-left: 19px;
   }
   #chat-messages .message img {
     float: left;
     margin-left: -38px;
     border-radius: 50%;
     width: 30px;
    height: 30px;
     margin-top: 4px;
   }
   #chat-messages div.message.right img {
     float: right;
     margin-left: 0;
     margin-right: -38px;
   }
   .message .bubble.myChat{
    background: #d1e7f9 !important;
   }
   .message .bubble {
     background: #f0f4f7;
     font-size: 13px;
     font-weight: 500;
     padding: 12px 13px;
     border-radius: 5px 5px 5px 0px;
     color: #4e5961;
     /* position:relative; */
     float: left;
   }
   #chat-messages div.message.right .bubble {
     float: right;
     border-radius: 5px 5px 0px 5px;
   }
   .bubble .corner {
    
   
     width: 7px;
     height: 7px;
     left: 0px;
     bottom: 0;
   }
   
   div.message.right .corner {
     
     left: auto;
     right: -5px;
   }
   .bubble {
     margin-bottom: 23px;
   }
   .message span {
     color: #aab8c2;
     font-size: 11px;
     font-weight: 100;
     /* position: absolute; */
   
     right: 0;
     bottom: -2px;
   }
   
   .chatTab{
     background: #f0f4f711;
     padding: 12px;
     font-size: 22px;
     border: 1px solid rgba(255, 255, 255, 0.127);
     width: 100%;
     align-items: center;
     text-align: center;
     cursor: pointer;
   }
   .chatTab:hover{
     background: #f0f4f751;
     color:#ffffff;
   }
   .chatTab.active{
     background: #ffffff;
     color:#f13e3ed1;
}
   .chatBox-popup .chatBox-popup__header0 {
     box-sizing: border-box;
     display: flex;
     width: 100%;
     padding: 0px;
     height: 55px !important;
     color: #fff;
     background-color: #f13e3ed1;
     align-items: center;
     justify-content: space-around;
     border-top-right-radius: 12px;
     border-top-left-radius: 12px;
   }



#topmenu span {
     float: left;
     width: 96px;
     height: 70px;
     background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/top-menu.png") -3px -118px
       no-repeat;
       
   }
   
   #topmenu span.contacts {
     margin-bottom: -1px;
   }
   #topmenu span.chats {
     background-position: -95px 25px;
     cursor: pointer;
   }
   #topmenu span.chats:hover {
     background-position: -95px -46px;
     cursor: pointer;
   }
   #topmenu span.history {
     background-position: -190px 24px;
     cursor: pointer;
   }
   #topmenu span.history:hover {
     background-position: -190px -47px;
     cursor: pointer;
   }
   .contact {
     height: 70px;
     border-bottom: 1px solid #e7ebee;
     position: relative;
   }
   .contact:hover {
     background: #f1f4f6;
     cursor: pointer;
   }
   .contact img {
     width: 40px;
     height: 40px;
     border-radius: 50%;
     margin: 15px;
     float: left;
   }

   .floatingImg {
     width: 40px;
     border-radius: 50%;
     /* position: absolute; */
     top: 0;
     left: 12px;
     border: 3px solid #fff;
   }
   .contact p {
     padding: 15px 0 0 0 !important;
     margin: 0px !important;
     float: left !important;
     width: 220px !important;
   }
   .contact p strong {
     font-weight: 600 !important;
     font-size: 15px !important;
     color: #597a96 !important;
   }
   .contact p span {
     font-size: 13px !important;
     font-weight: 400 !important;
     color: #aab8c2 !important;
   }
   .contact .status {
     background: #1edc8d;
     border-radius: 50%;
     width: 9px;
     height: 9px;
     position: absolute;
     top: 31px;
     right: 17px;
   }
   .contact .status.away {
     background: #ffcf6e;
   }
   .contact .status.inactive {
     background: #eaeef0;
   }
   .topmenu {
    min-width: 320px;
    height: 355px;
    -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
   }
   
   #contacts {
     height: 355px;
     overflow-y: scroll;
   }
   
   #contacts::-webkit-scrollbar {
     width: 4px;
     height: 4px;
     background: transparent;
   }
   #contacts::-webkit-scrollbar-track {
     box-shadow: inset 0 0 5px rgb(185, 179, 179);
     border-radius: 0px;
   }
   #contacts::-webkit-scrollbar-thumb {
     background: #e1422d9f;
     border-radius: 0px;
   }
   #contacts::-webkit-scrollbar-thumb:hover {
     background: #7c7a7a;
   }
   #contacts.animate {
     opacity: 1;
     margin-top: 0;
   }

#chatview {
     min-width: 320px;
     height: 523px;
     /* position:absolute; */
     top: 0;
     left: 0;
     /* display:none; */
     background: #fff;
     border-radius: 0px;
   }
   
   #profile {
     height: 130px;
     overflow: hidden;
     width: 100%;
     text-align: center;
     color: #fff;
   
   }
   .chatSetting {
     height: 523px;
     overflow-y: scroll !important;
   }
 
#profile {

       background: linear-gradient(rgba(208, 29, 29, 0.306), rgba(24, 1, 1, 0.499)),
       url("../../../Images/property.jpg") 0 0;
     background-size: cover;
     background-position: center;
     height: 130px;
     border-radius: 0px;
   }
   #profile .avatar {
     width: 68px;
     border: 3px solid #fff;
     margin: 23px 0 0;
     border-radius: 50%;
   }
   #profile p {
     font-weight: 600;
     font-size: 15px;
     margin: 1px 0 -1px;
     /* opacity:0; */
     -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
     -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
     -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
     -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
     transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
   }


   .chatProfile {
     width: 45px;
     height: 45px;
     border-radius: 50%;
     margin: 15px;
     border: 2px solid #fff;
     background-color: #fff;
   }

   @media only screen and (min-device-width: 370px) and (max-device-width: 405px) {

    .chatBox-popup {
      right: 1px;
      width: 370px;
    }
   }
   @media only screen and (min-device-width: 350px) and (max-device-width: 370px) {

    .chatBox-popup {
      right: 1px;
      width: 350px;
    }
   }
   @media only screen and (min-device-width: 00px) and (max-device-width: 350px) {

    .chatBox-popup {
      right: 1px;
      width: 320px;
      height: 100%;
    }
   }