.timeInputDropDown{
     width:300px;
     z-index: 1600 !important;
     position: absolute;
     background-color: #ffffff;
     box-shadow: rgba(126, 126, 126, 0.809) 0px 7px 29px 0px;
}
.timeInputList{
     max-height:300px;
     overflow-y: scroll;
}
.inputClose{
     font-size: 16px;
     background-color: #ff6d53;
     color: #fff;
     cursor: pointer;
}
.inputClose1{
     font-size: 11px;
     background-color: #ff6d5374;
     color: #fff;
     cursor: pointer;
}

.inputClose1:hover{
     background-color: #ff6d53;
}
.timeInputList::-webkit-scrollbar {
     width: 4px;
     height: 2px;
   }
   .timeInputList::-webkit-scrollbar-track {
     background: #f2f2f2;
   }
   .timeInputList::-webkit-scrollbar-thumb {
     background: #8f8f8f;
   }
.timeInputListPart{
     padding:10px;
     cursor:pointer;
     
}
.timeInputListPart:hover{
     background-color: rgb(255, 187, 158);
     color: aliceblue;
}

.timeInputListPart.active{
     background-color: #ff6d53;
     color: aliceblue;
}

