.smallSwitch {
     position: relative;
     display: inline-block;
     width: 30px;
     height: 14px;
   }
   .smallSwitch input {
     display: none;
   }
   .smallSlider {
     position: absolute;
     cursor: pointer;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background-color: #b7bbba;
     -webkit-transition: 0.4s;
     transition: 0.4s;
   }
   .smallSlider:before {
     position: absolute;
     content: "";
     height: 12px;
     width: 12px;
     left: 2px;
     bottom: 1px;
     background-color: white;
     -webkit-transition: 0.4s;
     transition: 0.4s;
   }
   input:checked + .smallSlider:before {
     -webkit-transform: translateX(54px);
     -ms-transform: translateX(54px);
     transform: translateX(54px);
   }
   input:checked + .smallSlider {
     background-color: #ff4b2bc7;
   }
   input:focus + .smallSlider {
     box-shadow: 0 0 1px #2196f3;
   }
   input:checked + .smallSlider:before {
     -webkit-transform: translateX(14px);
     -ms-transform: translateX(14px);
     transform: translateX(14px);
   }
   input:checked + .smallSlider .onApp {
     display: block;
   }
   input:checked + .smallSlider .offApp {
     display: none;
   }
   .smallSlider.round {
     border-radius: 34px;
   }
   .smallSlider.round:before {
     border-radius: 50%;
   }