.RentalUnitsModal{
  /* background-color: black; */
  margin-top:0px;
  /* padding:50px; */
  padding-top:20px;
}
.RentalUnitsModalList{
  
}
.rentalCol{
/* max-width:405px; */

}
.propertyCard{
  border-radius: 15px;
  margin-bottom:30px;
  width:100%;
 min-width:275px;
 max-width:375px;
 min-height:275px;
 background-color: #fff;
 padding-bottom: 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  /* background-color: black; */
}
.hide{
display: none;
}
.property-avatar{
  width: 60px;
  height: 60px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}
.propsMenu{
  height: 40px;
  width: 40px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  padding:18px;
}
.propsMenu:hover{
  
  background-color: rgb(218, 210, 210);
}
.propsAddress{
  font-family: "Montserrat", sans-serif;
  color:#807e7e;
  letter-spacing: 0.5px;
  line-height: 25px;
  /* font-family: "Kanit", sans-serif; */
  /* font-family: "Titillium Web", sans-serif;  */
  /* font-family: 'Ubuntu', sans-serif; */
  font-size: 15px;
}

.propsAddressDark{
font-family: "Montserrat", sans-serif;
color:#e9e5e5;
letter-spacing: 0.5px;
line-height: 25px;
/* font-family: "Kanit", sans-serif; */
/* font-family: "Titillium Web", sans-serif;  */
/* font-family: 'Ubuntu', sans-serif; */
font-size: 15px;
}
.propsContent{
  font-size: 13px;
  font-family: "Montserrat", sans-serif; 
  color:rgb(134, 128, 128);
line-height: 20px;
  
}
.propsContent2{
  font-family: "Montserrat", sans-serif; 
  color:rgb(134, 128, 128);
  font-size: 13px;
  
}
.propCoverContent{
  
  border: 0px solid #fff;
  border-bottom: 1px solid rgb(225, 225, 225);
  -moz-box-shadow:    inset 0 0 20px #bcbfc0;
  /* -webkit-box-shadow: inset 0 0 20px #bcbfc0; */
  /* box-shadow:         inset 0 -1px 10px #bcbfc0; */
}
.prize{
  font-family: "Montserrat", sans-serif; 
  color:#313131;

}
.propertyCard:hover .viewListingButton2{
display: block;

}
.viewListingButton2 {
display: none;
position: absolute;
margin-top: 100px;
/* margin-left: 70px; */
border: solid 1px #fff;
background-color: #ff4b2b91;
color: #fff;
cursor:pointer;
border-radius: 25px;
font-size: 19px;
box-shadow: rgba(255, 255, 255, 0.438) 0px 0.0625em 0.0625em, rgba(255, 255, 255, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.344) 0px 0px 0px 1px inset;
}
.card-price {
display: inline-block;

width: auto;
height: 38px;

background-color: #FF4B2B;
-webkit-border-radius: 3px 4px 4px 3px;
-moz-border-radius: 3px 4px 4px 3px;
border-radius: 3px 4px 4px 3px;

border-left: 1px solid #FF4B2B;

/* This makes room for the triangle */
margin-left: 19px;

position: relative;

color: white;
font-weight: 300;
font-size: 22px;
line-height: 38px;

padding: 0 10px 0 10px;
  background: -webkit-linear-gradient(to right, #FF4B2B, #f37650);
background: linear-gradient(to right, #FF4B2B, #f37650);
}

/* Makes the triangle */
.card-price:before {
content: "";
position: absolute;
display: block;
left: -19px;
width: 0;
height: 0;
border-top: 19px solid transparent;
border-bottom: 19px solid transparent;
border-right: 19px solid #FF4B2B;
}

/* Makes the circle */
.card-price:after {
content: "";
background-color: white;
border-radius: 50%;
width: 4px;
height: 4px;
display: block;
position: absolute;
left: -9px;
top: 17px;
}
.propCoverContent1{
  padding-Top :10px;
}
.click{
  cursor: pointer;
}
.propsImage{
  width: 100%;
  object-fit: cover;
  display: block;
margin-left: auto;
margin-right: auto;
height:270px;
border-top-left-radius: 15px;
border-top-right-radius: 15px;
}
.propertyCard:hover .propCoverContent{
  
  animation: return 0.4s linear 1;
  opacity: 1;
  height:62px;
}
.propertyCard .propCoverContent1{
  
  /* display:none; */

}
.propertyHead{
 
}
.propertyCard:hover .propCoverContent1{
  
  /* display:block; */

}
.propertyCard .propCoverContent{
  
  animation: turn 0.4s linear 1;
  opacity: 0;
  height:0px;
 
}

@keyframes turn {
  0% {
      height:62px;
      opacity: 1;
  }
  30%{
      opacity: 0.2;
     }
  100% {
      opacity: 0;
      height:0px;
  }
}
@keyframes return {
  0% {
      height:0px;
      opacity: 0;
  }
 70%{
  opacity: 0.4;
 }
  100% {
      opacity: 1;
      height:62px;
  }
}
.propertyCard:hover .propsImage{
  animation: return1 0.4s linear 1;
  height:270px;
}
.propertyCard .propsImage{
  animation: turn1 0.4s linear 1;
  height:332px;
}
@keyframes turn1 {
  0% {
      height:270px;
  }
  /* 50% {
      height:335px;
  } */
  100% {
      height:332px;
  }
}
@keyframes return1 {
  0% {
      height:332px;
  }
  /* 50% {
      height:335px;
  } */
  100% {
      height:270px;
  }
}
.RentalInput{
background-color: rgb(255, 255, 255);
border: none;
  font-family: "Montserrat", sans-serif;
padding: 12px 15px;
margin: 8px 0;
width: 100%;
  padding-right:50px;

}

.RentalSearch{
    
    padding:10px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    background-color: rgba(192, 192, 192, 0.314);
    margin-top: 80px;
    z-index:500;

    padding-right:15px;
     padding-left:15px;
    border-bottom: 1px solid #e0e0e096;
  
}
.RentalSearchDark{
  padding-bottom: 20px;
  padding:10px;
  background-color: #fff;
  margin-top: 80px;
  z-index:500;
  background: #424241;
    padding-right:15px;
    padding-left:15px;
    color:#fff;
}
.RentalSearch1{
  min-width:200px;
}
.searchListHeadButton1{
  cursor: pointer;
  position: relative;
  margin: auto;
  margin-right:1px;
  color:#ffffff;
  background-color: rgb(212, 212, 212);
  height:36px;
  width:36px;
  padding: 10px;
  padding-left: 11px;
  border-radius: 25px;
}
.searchListHeadButton{
  cursor: pointer;
  /* z-index:230; */
  margin-top:25px;
  margin-left:-35px;
  position:absolute;
  color:#FF4B2B;
}
.searchListHead{
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  
  /* margin-bottom: 0px; */
  /* position:absolute; */
}
.RentalInput:focus{
    border:none;
}
.heart{
  color:#fff;
  font-size: 25px;
  /* right:0; */
  padding-left:240px;
  padding-top:10px;

}
.heart:hover{
  cursor: pointer;
}

.RentalUnitsMapOff{
    animation: slideRight 1s linear 1;
    width:47%;
    display:block;
}
.RentalUnitsMapOn{
  animation: slideRight1 1s linear 1;
    width:0%;
    /* display:none; */
  }   
  .RentalUnitsMapOffMobile{
      animation: slideRightMobile 1s linear 1;
      height:400px;
      display:block;
  }
  .RentalUnitsMapOnMobile{
    animation: slideRight1Mobile 1s linear 1;
      height:0px;
    }  
@keyframes slideRight {
  0% {
      width:0%;
      /* display:block; */
  }
  /* 50% {
      height:335px;
  } */
  100% {
      width:47%;
      display:block;
  }
}
@keyframes slideRight1 {
  0% {
      width:47%;
      display:block;
  }
  /* 50% {
      height:335px;
  } */
  100% {
      width:0%;
      display:none;
  }
}
@keyframes slideRightMobile {
  0% {
      height:0px;
      /* display:block; */
  }
  /* 50% {
      height:335px;
  } */
  100% {
      height:400px;
      display:block;
  }
}
@keyframes slideRight1Mobile {
  0% {
      height:400px;
      display:block;
  }
  /* 50% {
      height:335px;
  } */
  100% {
      height:0px;
      display:none;
  }
}

.mapNavigatorRoot{
  width:1%;
  margin-left:1%;
  left:0;
  z-index:1;
  position: relative;
}
.mapNavigatorRootMobile{
  width:100%;
  position: relative;
}
.padNavMap{
  position:absolute;
    z-index:10;
    right:0;
    top:0;
    background-color: rgb(255, 255, 255);
    color:#FF4B2B;
    width:60px;
    padding-right:25px;
    padding-top:10px;
    padding-bottom:10px;
    margin-right:-31px;
    margin-top:16px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
.mapNavigator{
    position:absolute;
    z-index:10;
    right:0;
    top:0;
    background-color: rgb(255, 255, 255);
    
    color:#FF4B2B;
    width:60px;
    padding-right:10px;
    padding-top:10px;
    padding-bottom:10px;
    margin-right:30px;
    margin-top:16px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}
.padNavMapMobile{
    z-index:10;
    background-color: rgb(255, 255, 255);
    
    color:#FF4B2B;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
.mapNavigatorMobile{
z-index:10;
padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgb(255, 255, 255);
    color:#948f8f;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}
.mapNavigator:hover{
  color:#948f8f;
      cursor: pointer;
}
.padNavMap:hover{
  
  color:#948f8f;
  cursor: pointer;
}
.mapNavigatorRootMobile{
  display:none;
}.RentalUnitsMapOnMobile{
  display:none;
}.RentalUnitsMapOffMobile{
    display:none;
}
.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
.vert-move {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-5px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-5px); }
}
@media only screen and (min-width: 425px) and (max-width: 770px) {
  .RentalUnitsModal{
    padding:10px;
    margin-top:15px;
}
  .RentalUnitsMapOn{
    display:none;
}
.RentalUnitsMapOff{
  display:none;
}
.mapNavigatorRoot{
  display:none;
}
.mapNavigatorRootMobile{
  display:block;
}
.RentalUnitsMapOnMobile{
  display:block;
}
.RentalUnitsMapOffMobile{
  display:block;
}
}
@media only screen and (min-width: 150px) and (max-width: 425px) {
  .RentalUnitsModal{
      padding:10px;
      margin-top:15px;
  }
  .RentalUnitsMapOn{
      display:none;
  }
  .RentalUnitsMapOff{
    display:none;
  }
  .mapNavigatorRoot{
    display:none;
  }
  .mapNavigatorRootMobile{
    display:block;
  }
  .RentalUnitsMapOnMobile{
    display:block;
  }
  .RentalUnitsMapOffMobile{
    display:block;
  }
}
@media only screen and (min-width: 150px) and (max-width: 425px) {
  .RentalUnitsModal{
      padding:10px;
  }
  .RentalUnitsMapOn{
      display:none;
  }
  .RentalUnitsMapOff{
    display:none;
  }
  .mapNavigatorRoot{
    display:none;
  }
  .mapNavigatorRootMobile{
    display:block;
  }
  .RentalUnitsMapOnMobile{
    display:block;
  }
  .RentalUnitsMapOffMobile{
    display:block;
  }
}



.lds-roller {
display: inline-block;
position: relative;
width: 80px;
height: 80px;
top:200%;
Left:50%;
}
.lds-roller div {
animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
transform-origin: 40px 40px;
}
.lds-roller div:after {
content: " ";
display: block;
position: absolute;
width: 7px;
height: 7px;
border-radius: 50%;
background: #f76c39;
margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
top: 63px;
left: 63px;
}
.lds-roller div:nth-child(2) {
animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
top: 68px;
left: 56px;
}
.lds-roller div:nth-child(3) {
animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
top: 71px;
left: 48px;
}
.lds-roller div:nth-child(4) {
animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
top: 72px;
left: 40px;
}
.lds-roller div:nth-child(5) {
animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
top: 71px;
left: 32px;
}
.lds-roller div:nth-child(6) {
animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
top: 68px;
left: 24px;
}
.lds-roller div:nth-child(7) {
animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
top: 63px;
left: 17px;
}
.lds-roller div:nth-child(8) {
animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
top: 56px;
left: 12px;
}
@keyframes lds-roller {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
}
.closeModal{
font-size:20px;
padding:13px;
}
.textAvailableUnit {
font-family: "Montserrat", sans-serif;
font-size:40px !important;
font-weight: 1950;
padding-left:15px;
}
.detailsHead{
font-family: "Montserrat", sans-serif;
font-size: 20px;
font-weight: 600;
margin-top: 15px;
margin-bottom: 10px;
}
.detailContentAvailable{
font-family: "Montserrat", sans-serif;
font-size: 15px;
font-weight: 500;
}
.shadow1 {
 
box-shadow: 0px 0 25px rgba(0, 0, 0, 0.4);
}
.shadow2 {
 
box-shadow: 7px 7px 25px rgba(227, 225, 225, 0.871);
}
.box2 {
box-shadow:
0 2.8px 2.2px rgba(206, 203, 203, 0.034),
0 6.7px 5.3px rgba(212, 211, 211, 0.048),
0 12.5px 10px rgba(228, 227, 227, 0.06),
0 22.3px 17.9px rgba(194, 191, 191, 0.072),
0 41.8px 33.4px rgba(206, 205, 205, 0.086),
0 100px 80px rgba(230, 228, 228, 0.12)
;}
.modal{
/* width:1800px; */
}
.modal-content{


}
.img-wrap{

width: 100%;        

}
.imageAvailable{
max-width: 100%;
max-height: 100%;
display: block;
width:100%;
}
.detailAvailable{
overflow-y: scroll;
overflow-x: hidden;
max-height:30vmax;
width:100%;
}

.detailAvailable::-webkit-scrollbar {
width: 5px;
height: 1px;
}

/* Track */
.detailAvailable::-webkit-scrollbar-track {
background: #f1f1f1;
}

/* Handle */
.detailAvailable::-webkit-scrollbar-thumb {
background: #FF4B2B;
}

/* Handle on hover */
.detailAvailable::-webkit-scrollbar-thumb:hover {
background: #918c8b;
}



.detailAvailable::-moz-scrollbar {
width: 5px;
height: 1px;
}

/* Track */
.detailAvailable::-moz-scrollbar-track {
background: #f1f1f1;
}

/* Handle */
.detailAvailable::-moz-sc {
background: #FF4B2B;
}

/* Handle on hover */
.detailAvailable::-moz-scrollbar-thumb:hover {
background: #918c8b;
}
.modal{
height: fit-content;
}
.bodyAvailable{

}
#exampleModal{
/* width:1500px; */
}
.availableTable {
border-top: 1px solid #FF4B2B;
border-bottom: 1px solid #FF4B2B;
padding-top: 3%;
}
.availableTableRow {
padding-bottom: 4%;
}
.availableUnitTableHead {
margin-bottom: 1%;
}
.availableTableContent {
font-weight: 690;
}
.availableTableHead {
margin-left: 4%;
font-size: 15px;
text-transform: uppercase;
color: #d15428;
font-family: sans-serif;
}
.availableUnitSection {
padding-top: 7%;
}
.availableUnitSection2 {
padding-top: 15%;
}
.availableUnitTable {
padding-left: 4%;
padding-right: 4%;
}
hr.line {
color: #d15428;
height: 9px;
}
.brownYellow {
color: #d15428;
}

.unitModalImageMobile{
display:none;
}
.listingModalLoader{
  display: block;
}
.listingModalLoader1{
  display: none;
}
@media only screen and (min-width: 1300px) and (max-width: 15000px) {
.detailAvailable{
  width:65%
}
.availableUnitSection2 {
  display: none;
}
.availableUnitSection {
  display: block;
}
}
@media only screen and (min-width: 1040px) and (max-width: 1300px) {
.detailAvailable{
  width:65%
}
.availableUnitSection2 {
  display: none;
}
.availableUnitSection {
  display: block;
}
.availableUnitButton {
  font-size: 10px;
  padding: 10px 15px;
}
.primaryAvailableUnitButton {
  font-size: 10px;
  padding: 10px 15px;
}
}
@media only screen and (min-width: 800px) and (max-width: 1040px) {
  .listingModalLoader{
    display: none;
  }
  .listingModalLoader1{
    display: block;
  }
.detailAvailable{
  width:55%
}
.unitModalImage{
  display:none;
}
.unitModalImageMobile{
  display:block;
}
.availableUnitSection2 {
  display: none;
}
.availableUnitSection {
  display: block;
}
.textAvailableUnit {
  font-size: 31px !important;
}
.unitDetailListTop{
  
  margin-bottom:-15px;
  margin-top:5px;
}
.detailsHead{
  font-size: 15px !important;
}
.detailContentAvailable{
  font-size: 13px;
}
.span2{
  padding-left:10px;
  font-size: 10px ;
}
.availableUnitTable {
  padding-left: 0%;
  padding-right: 0%;
}
.availableUnitSection {
  padding-top: 10%;
}
.availableUnitButton {
  font-size: 8px;
  padding: 8px 10px;
}
.primaryAvailableUnitButton {
  font-size: 8px;
  padding: 8px 10px;
}
}
@media only screen and (min-width: 500px) and (max-width: 800px) {
  .listingModalLoader{
    display: none;
  }
  .listingModalLoader1{
    display: block;
  }
.unitModalImage{
  display:none;
}
.unitModalImageMobile{
  display:block;
}
.availableUnitSection2 {
  display: block;
}
.availableUnitSection {
  display: none;
}
.textAvailableUnit {
  padding-top:7px;
  font-size: 28px !important;
}
.unitDetailListTop{
  
  margin-bottom:-15px;
  margin-top:5px;
}
.detailsHead{
  font-size: 15px !important;
}
.detailContentAvailable{
  font-size: 13px;
}
.span2{
  padding-left:10px;
  font-size: 12px ;
}
.span7{
  font-size: 10px ;
}
.span8{
  font-size: 11px ;
}
.availableUnitTable {
  padding-left: 0%;
  padding-right: 0%;
}
.availableTableContent {
  line-height: 10px;
}
.availableUnitButton {
  margin-top: 0.5%;
  display: block;
}
.primaryAvailableUnitButton {
  margin-top: 0.5%;
  display: block;
}
}
@media only screen and (min-width: 400px) and (max-width: 500px) {
  .listingModalLoader{
    display: none;
  }
  .listingModalLoader1{
    display: block;
  }
.unitModalImage{
  display:none;
}
.unitModalImageMobile{
  display:block;
}
.availableUnitSection2 {
  padding-top: 20%;
}
.availableUnitSection2 {
  display: block;
}
.availableUnitSection {
  display: none;
}
.textAvailableUnit {
  padding-top:10px;
  font-size: 25px !important;
}
.unitDetailListTop{
 
  margin-bottom:-15px;
  margin-top:5px;
}
.detailsHead{
  font-size: 15px !important;
}
.detailContentAvailable{
  font-size: 13px;
}
.span2{
  padding-left:10px;
  font-size: 15px ;
}
.availableUnitTable {
  padding-left: 0%;
  padding-right: 0%;
}
.availableTableContent {
  line-height: 10px;
}
.availableUnitButton {
  margin-top: 0.5%;
  display: block;
}
.primaryAvailableUnitButton {
  margin-top: 0.5%;
  display: block;
}
}
@media only screen and (min-width: 300px) and (max-width: 400px) {
  .listingModalLoader{
    display: none;
  }
  .listingModalLoader1{
    display: block;
  }
.unitModalImage{
  display:none;
}
.unitModalImageMobile{
  display:block;
}
.availableUnitSection2 {
  padding-top: 30%;
}
.availableUnitSection2 {
  display: block;
}
.availableUnitSection {
  display: none;
}
.textAvailableUnit {
  padding-top:15px;
  font-size: 18px !important;
}
.unitDetailListTop{
  
 
  
}
.detailsHead{
  font-size: 15px !important;
}
.detailContentAvailable{
  font-size: 13px;
}
.span2{
  padding-left:10px;
  font-size: 15px ;
}
.availableUnitTable {
  padding-left: 0%;
  padding-right: 0%;
}
.availableTableContent {
  line-height: 10px;
}
.availableUnitButton {
  margin-top: 0.5%;
  display: block;
}
.primaryAvailableUnitButton {
  margin-top: 0.5%;
  display: block;
}
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
  .listingModalLoader{
    display: none;
  }
  .listingModalLoader1{
    display: block;
  }
.unitModalImage{
  display:none;
}
.unitModalImageMobile{
  display:block;
}
.availableUnitSection2 {
  padding-top: 35%;
}
.availableUnitSection2 {
  display: block;
}
.availableUnitSection {
  display: none;
}
.textAvailableUnit {
  font-size: 20px !important;
}
.unitDetailListTop{
  
  margin-bottom:-15px;
  margin-top:5px;
}
.detailsHead{
  font-size: 15px !important;
}
.detailContentAvailable{
  font-size: 13px;
}
.span2{
  padding-left:10px;
  font-size: 10px ;
}
.availableUnitTable {
  padding-left: 0%;
  padding-right: 0%;
}
.availableTableContent {
  line-height: 10px;
}
.availableUnitButton {
  margin-top: 0.5%;
  display: block;
}
.primaryAvailableUnitButton {
  margin-top: 0.5%;
  display: block;
}
}@media only screen and (min-width: 200px) and (max-width: 600px) {
.modal-footer{
  height: 80px;
  padding-bottom: 0px;
}
.listingModalLoader{
  display: none;
}
.listingModalLoader1{
  display: block;
}

}
@media only screen and (min-width: 400px) and (max-width: 600px) {


  .listingModalLoader{
    display: none;
  }
  .listingModalLoader1{
    display: block;
  }
.heart{
position: fixed;

right: auto;
padding-left: 300px;
padding-top:10px;

}
}
