.sig-canvas {
     border: 2px dotted #CCCCCC;
     border-radius: 15px;
     cursor: crosshair;
     margin-bottom: 3%;
     touch-action: none;
}
/* @media only screen and (min-width: 650px) and (max-width: 850px) {
  .signatureParent {

    overflow:hidden;
    max-width:600px;
}
}
@media only screen and (min-width: 450px) and (max-width: 650px) {
  .signatureParent {

    overflow:hidden;
    max-width:500px;
}
}
@media only screen and (min-width: 0px) and (max-width: 450px) {
  .signatureParent {

    overflow:hidden;
    max-width:400px;
}
} */