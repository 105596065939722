.maintenanceRequestDetailWindow{
     padding-top:85px;
     margin-bottom: 5%;
     margin-right: 2%;
     margin-left: 2%;
}
.maintenanceRequestCard{
     background-color: #fff;
     border-radius: 5px;
     box-shadow: rgba(179, 179, 179, 0.28) 0px 2px 30px 0px;
     padding:15px 25px;
}
.maintenanceRequestDetailContainer{
     display: flex;
     -ms-flex-wrap: wrap;
     flex-wrap: wrap;
}


.maintenanceRequestDetailText1{
     font-family: "Montserrat", sans-serif;
     color:#838383  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     line-height: 20px !important;
     text-transform: uppercase;
}
.maintenanceRequestDetailText2{
     font-family: "Montserrat", sans-serif;
     color:#000000  !important;
     letter-spacing: 0.5px;
     font-size: 19px;
     font-weight: 800;
     line-height: 20px !important;
     margin:  6px 0px;
}
.maintenanceRequestDetailText3{
     font-family: "Montserrat", sans-serif;
     color:#f84343  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     line-height: 20px !important;
}
.maintenanceRequestDetailTextDisable3{
     font-family: "Montserrat", sans-serif;
     color:#c1c1c1  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     line-height: 20px !important;
}
.maintenanceRequestDetailText4{
     font-family: "Montserrat", sans-serif;
     color:#000000  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 400;
     text-align: justify;
     line-height: 18px !important;
}
.maintenanceRequestDetailText5{
     font-family: "Montserrat", sans-serif;
     color:#414141;
     letter-spacing: 0.5px;
     font-size: 15px;
     font-weight: 600;
     line-height: 20px !important;
}
.maintenanceRequestDetailText6{
     font-family: "Montserrat", sans-serif;
     color:#949494  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     text-align: center;
     line-height: 18px !important;
}
.maintenanceRequestDetailText7{
     font-family: "Montserrat", sans-serif;
     color:#9f9f9f  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 400;
     text-align: justify;
     line-height: 18px !important;
}
 .maintenanceRequestDetailUnitImage{
     height: 45px;
     width: 45px;
     border-radius: 10px;
   }
.maintenanceRequestDetailTenantImage{
     height: 45px;
     width: 45px;
     border-radius: 50%;
}
.maintenanceRequestDetailIconImage{
     height: 45px;
     width: 45px;
}
.maintenanceRequestDetailIconImage2{
     height: 60px;
     width: 60px;
    
     
}
.maintenanceRequestDetailNotJet{
     font-family: "Montserrat", sans-serif;
     color:#F89212;
     letter-spacing: 0.5px;
     font-size: 13px;
     font-weight: 600;
     align-self: center;
}
.webViewHistory{
     display: block;
}
.tabViewHistory{
     display: none;
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
     .webViewHistory{
          display: none;
     }
     .tabViewHistory{
          display: block;
     }
}

@media only screen and (min-width: 0px) and (max-width: 425px) {
     .maintenanceRequestDetailWindow{

          margin-right: 0.5%;
          margin-left: 0.5%;
     }
}

@media only screen and (min-width: 1200px) and (max-width: 1250px), (min-width:768px) and (max-width: 992px) , (min-width:10px) and (max-width: 485px){
     .maintenanceRequestDetailText1{
          font-size: 10px;
     }
     .maintenanceRequestDetailText2{
          font-size: 17px;
     }
     .maintenanceRequestDetailText3{
          font-size: 10px;
     }
     .maintenanceRequestDetailTextDisable3{
          font-size: 10px;
     }
     .maintenanceRequestDetailText4{
          font-size: 10px;
     }
     .maintenanceRequestDetailText5{
          font-size: 13px;
     }
     .maintenanceRequestDetailText6{
          font-size: 10px;
     }
     .maintenanceRequestDetailText7{
          font-size: 10px;
     }
}