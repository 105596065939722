.requestOrderText{
     padding-top: 20px;
     padding-bottom: 10px;
    
}
.maintenanceRoundIcon {
     margin: 0px 0;
   }
   
   .maintenanceRoundIcon span {
     border: 1px solid #dddddd;
     background-color: rgb(216, 216, 216);
     border-radius: 50%;
     display: inline-flex;
     justify-content: center;
     align-items: center;
     margin: 0 5px;
     height: 40px;
     width: 40px;
   }
   .maintenanceRequestOrderImage{
     height: 35px;
     width: 35px !important;
     padding: 5px;
     border-radius: 50%;
     background-color: #E1EF90;
     
   }
   .maintenanceRequestOrderImage2{
     
     height: 35px;
     width: 35px !important;
     padding: 5px;
     border-radius: 50%;
   
   }
   .mobileResponseMaintenanceOrder1{

     display: none;
   }
   .maintenanceOrderNameAlign{
     width:25%;
     align-self: center;
   }
   .maintenanceOrderImageAlign{
     width:12%;
     align-self: center;
     margin-top: -1% !important;
   }
   .maintenanceOrderImageDummyAlign{
     display: none;
   }
   .maintenanceOrderContainer{
     padding-left: 3%;
     padding-right: 3%;
     padding-top: 4%;
     padding-bottom: 0%;
     
   }
   .maintenanceOrderCostAlign{
     width:35%;
     text-align: center;
     align-self: center;
     padding-top: 2% !important;
     padding-bottom: 2% !important;
     border-right: 1px solid rgb(239, 239, 239);
     border-left: 1px solid rgb(239, 239, 239);
   }
   .maintenanceOrderTimeAlign{
     text-align: center;
     align-self: center;
     width:28%;
     align-self: center;

   }
.mobileResponseMaintenanceOrder4{
     display: none !important;
}
.orderDivider{
     background-color: rgb(239, 239, 239);
     margin-block-start: 0.1em;
     margin-block-end: 0.1em;
     border: none;
     height: 1px;
    }
@media only screen and (min-width: 1200px) and (max-width: 1250px), (min-width:768px) and (max-width: 992px) , (min-width:10px) and (max-width: 485px){
     .maintenanceOrderImageDummyAlign{
          display: block;
          width:30%;
        }
     .maintenanceOrderNameAlign{
          
          padding-bottom: 5%;
       width:70%;
       align-self: center;
     }
     .maintenanceOrderImageAlign{
          padding-bottom: 5%;
          padding-left: 3%;
          width:30%;
     }
     .maintenanceOrderCostAlign{
          width:30%;
          border-right: none;
          border-left: none;
          text-align: left;
        }
}
   @media only screen and (min-width:1200px) and (max-width: 1275px) , (min-width:768px) and (max-width: 994px),(min-width:00px) and (max-width: 545px){
     .mobileResponseMaintenanceOrder1{
          display: flex;
     }
     .mobileResponseMaintenanceOrder2{
          display: none;
     }
   }

   @media only screen and (min-width:00px) and (max-width: 350px){
     .mobileResponseMaintenanceOrder4{
          display: flex !important;
     }
     .mobileResponseMaintenanceOrder3{
          display: none !important;
     }
   }