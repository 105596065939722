.page404Contract {

  
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  padding-top: 8%;
  margin: -20px 0 50px;
}
.page404ContractDark {
  background: #424241;
  color:#fff;
  flex-direction: column;
  
  padding-top: 8%;
  margin: -20px 0 50px;
}

.PageText404 {
  padding-top: 20%;
}
.head {
  font-size: 100px;
}
.head3 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  line-height: 30px;
  padding-right: 10%;
  padding-left: 10%;
}
.head4 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  line-height: 30px;
  padding-right: 10%;
  font-weight: 800 !important;
  padding-left: 10%;
}
@media only screen and (min-device-width: 800px) and (max-device-width: 900px) {
  .head {
    font-size: 60px;
  }
  .head2 {
    font-size: 30px;
  }
  .page404Contract {
    padding-top: 8%;
    margin: -20px 0 50px;
  }
  .Image404Contract {
    padding-top: 5%;
  }
  .PageText404 {
    padding-top: 16%;
  }
  .span1 {
    font-size: 17px;
  }
}
@media only screen and (min-device-width: 650px) and (max-device-width: 800px) {
  .head {
    font-size: 30px;
  }
  .head2 {
    font-size: 23px;
  }
  .page404Contract {
    padding-top: 15%;
    margin: -20px 0 80px;
  }
  .Image404 {
    padding-top: 10%;
  }
  .PageText404 {
    padding-top: 16%;
  }
  .span1 {
    font-size: 12px;
    line-height: 20px;
  }
}
@media only screen and (min-device-width: 550px) and (max-device-width: 650px) {
  .head {
    font-size: 24px;
  }
  .head2 {
    font-size: 16px;
  }
  .page404Contract {
    padding-top: 18%;
    margin: -20px 0 50px;
  }
  .Image404 {
    padding-top: 10%;
  }
  .PageText404 {
    padding-top: 1%;
  }
  .span1 {
    font-size: 9px;
    line-height: 20px;
  }

}
@media only screen and (min-device-width: 100px) and (max-device-width: 550px) {
  .head {
    font-size: 19px;
  }
  .head2 {
    font-size: 10px;
  }
  .page404Contract {
    padding-top: 20%;
    margin: -20px 0 50px;
  }
  .Image404Contract {
    padding-top: 10% !important;
  }
  .PageText404 {
    padding-top: 1%;
  }
  .span1 {
    font-size: 8px;
    line-height: 15px;
  }
  .pri {
    /* margin-top: -20px !important; */
  }
}
