.maintenanceRequestQuotationCount{
  background-color: #ffd6bfc3;
  padding: 5px 10px;
  border-radius: 50%;
  font-family: "Montserrat", sans-serif;  
  font-size: 13px;
  font-weight: 800;
}

   .maintenanceProviderContainer{
     padding-left: 3%;
     padding-right: 3%;
     padding-top: 2%;
     padding-bottom: 2%;
     
   }
   .quotationDivider{
    background-color: rgb(239, 239, 239);
    margin-block-start: 0.1em;
    margin-block-end: 0.1em;
    border: none;
    height: 1px;
   }
   
   .requestQuotationDivider{
    padding-left:10px !important;
    margin-right: 10px !important;
}

.requestQuotationText{
    padding-top: 3%;
    padding-bottom: 0%;
    padding-right: 4%;
    overflow-y: scroll;
    max-height:600px;
}

.requestQuotationText::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.requestQuotationText::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}
.requestQuotationText::-webkit-scrollbar-thumb {
  background: #ff6d53;
}
.requestQuotationText::-webkit-scrollbar {
  width: 2px;
  height: 0px;
}
.requestQuotationText::-webkit-scrollbar-track {
  background: rgba(189, 189, 189, 0.01);
}
.requestQuotationText::-webkit-scrollbar-thumb {
  background: #ff6d53;
}
.maintenanceRequestQuotationImage{
  height: 35px;
  width: 35px;
  border-radius: 50%;

}
.maintenanceRequestDetailIcon{
  height: 20px;
  width: 20px;
  margin-top: -2% !important;
}

.maintenanceQuotationAlign{
  
  border-left: 1px solid rgb(239, 239, 239);
  width:15%;
  text-align: center;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  display: block;
}
.maintenanceQuotationAlign0{
  display:none;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  border-left: 1px solid rgb(239, 239, 239);
  width:15%;
  text-align: center;

}
.maintenanceQuotationHourAlign{
width:25%;
/* border-right: 1px solid rgb(239, 239, 239); */
border-left: 1px solid rgb(239, 239, 239);
padding-top: 2% !important;
  padding-bottom: 2% !important;
text-align: center;
align-self: center;
}
.maintenanceProviderQuotationNameAlign{
  width:40%;
  align-self: center;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
}
.maintenanceProviderQuotationImageAlign{
  width:12%;
  align-self: center;
  margin-top: -1% !important;
}
.maintenanceQuotationProviderIconAlign{
  width:5%;
  padding-left: 3%;
  border-left: 1px solid rgb(239, 239, 239);
  margin-top: -2% !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  align-self: center;
  text-align: right;
  
}

.maintenanceQuotationImageAlign{
  width:12%;
  align-self: center;
  margin-top: -1% !important;
}
.maintenanceRequestQuotationImage2{
  height: 35px;
  width: 35px !important;
  padding: 5px;
  border-radius: 50%;

}
.maintenanceQuotationNameAlign{
  width:20%;
  align-self: center;
}
.maintenanceQuotationCostAlign{
  width:35%;
  text-align: center;
  align-self: center;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  /* border-right: 1px solid rgb(239, 239, 239); */
  border-left: 1px solid rgb(239, 239, 239);
}

.maintenanceQuotationImageDummyAlign{
  display: none;
}
@media only screen and (min-width:615px) and (max-width: 651px) , (min-width:10px) and (max-width: 375px){
    .requestQuotationDivider{
         opacity: 0;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1250px), (min-width:768px) and (max-width: 992px) , (min-width:10px) and (max-width: 485px){
  .maintenanceQuotationImageDummyAlign{
    display: block;
    width:30%;
  }
  .maintenanceQuotationNameAlign{    
    padding-bottom: 5%;
    width:40%;
    align-self: center;
  }
  .maintenanceQuotationImageAlign{
      padding-bottom: 5%;
      padding-left: 3%;
      width:30%;
  }
  .maintenanceQuotationCostAlign{
    width:30%;
    border-right: none;
    border-left: none;
    text-align: left;
    margin-top: -25px !important;
  }

  .maintenanceProviderQuotationNameAlign{
    width:50%;
    align-self: center;
  }
  .maintenanceProviderQuotationImageAlign{
    width:30%;
    align-self: center;
    margin-top: -1% !important;
    padding-left: 5%;
  }
  .maintenanceQuotationHourAlign{
    width:50%;
    border-left: none;
    border-right: none;
  }
  .maintenanceQuotationHourAlign .maintenanceRequestDetailText6{
    text-align: left;
  }
  .maintenanceQuotationProviderIconAlign{
    width:20%;
    border-left: none;
    padding-left: 0%;
    text-align: left;
  }
  
  .maintenanceQuotationAlign{
    border-right: none;
    border-left: none;
    width:15%;
    text-align: center;
    display:none;
  }
  .maintenanceQuotationAlign0{
    border-right: none;
    border-left: none;
    width:15%;
    text-align: left;
    display:block;
  }

}