.AxText1{
     font-family: "Montserrat", sans-serif;
     color:#838383  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     line-height: 20px !important;
     text-transform: uppercase;
}
.AxText2{
     font-family: "Montserrat", sans-serif;
     color:#000000  !important;
     letter-spacing: 0.5px;
     font-size: 19px;
     font-weight: 800;
     line-height: 20px !important;
     margin:  6px 0px;
}
.AxText3{
     font-family: "Montserrat", sans-serif;
     color:#f84343  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     line-height: 20px !important;
}
.AxTextDisable3{
     font-family: "Montserrat", sans-serif;
     color:#c1c1c1  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     line-height: 20px !important;
}
.AxText4{
     font-family: "Montserrat", sans-serif;
     color:#000000  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 400;
     text-align: justify;
     line-height: 18px !important;
}
.AxText5{
     font-family: "Montserrat", sans-serif;
     color:#414141;
     letter-spacing: 0.5px;
     font-size: 15px;
     font-weight: 600;
     line-height: 20px !important;
}
.AxText6{
     font-family: "Montserrat", sans-serif;
     color:#949494  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 600;
     text-align: center;
     line-height: 18px !important;
}
.AxText7{
     font-family: "Montserrat", sans-serif;
     color:#9f9f9f  !important;
     letter-spacing: 0.5px;
     font-size: 12px;
     font-weight: 400;
     text-align: justify;
     line-height: 18px !important;
}
.AxText8{
     font-family: "Montserrat", sans-serif;
     color:#f84343  !important;
     letter-spacing: 0.5px;
     font-size: 16px;
     font-weight: 600;
     line-height: 20px !important;
}

.profileAvatar{
     height: 40px;
     width: 40px !important;
     padding: 5px;
     border-radius: 50%;
     background-color: #ffffff;
}
.MaintenanceRequestCardProfile{
     width:15%;
     align-self: left;
     text-align: left;
}
.MaintenanceRequestCardName{
     width:50%;
     align-self: left;
     text-align: left;
     padding-top: 3px;
}
.MaintenanceRequestCardDuration{
     width:35%;
     padding-top: 5px;
}
.maintenanceCardAlign{
     min-width: 400px;
     max-width: 500px;
}
.maintenanceHistoryCardAlign{
     min-width: 300px;
     max-width: 500px;
}

@media only screen and (min-width: 1200px) and (max-width: 1250px), (min-width:768px) and (max-width: 992px) , (min-width:10px) and (max-width: 485px){
     .AxText1{
          font-size: 10px;
     }
     .AxText2{
          font-size: 17px;
     }
     .AxText3{
          font-size: 10px;
     }
     .AxTextDisable3{
          font-size: 10px;
     }
     .AxText4{
          font-size: 10px;
     }
     .AxText5{
          font-size: 13px;
     }
     .AxText6{
          font-size: 10px;
     }
     .AxText7{
          font-size: 10px;
     }
}