.headName {
  color: black;
  /* transform: rotate(-90deg); */
}
thead {
  background-color: #eb6658;
}
td {
  margin: 0ch !important;
}
.tableRating {
  height: 350px;
}
.ratingName {
  width: 20%;
}
.ratingFinal {
  width: 2%;
}

.boxx {
  height: 20px;
  width: 20px;
}

.excellent {
  /* background-color: #f5da6f; */
  background-color: #1a793a;
  /* background-color: #0e2652; */
}

.good {
  /* background-color: #ecb041; */
  background-color: #43dd43;
  /* background-color: #11589e; */
}

.ok {
  background-color: #f5f83f;
  /* background-color: #2180e0; */
}
.bad {
  /* background-color: #fca242; */
  background-color: #ff0000;
}
.veryBad {
  background-color: #ff0000;
  /* background-color: #cce0ff; */
}
.non {
  background-color: #fff;
  /* border: 1px solid black; */
}
.non1 {
  background-color: rgb(255, 254, 254);
  border: 1px solid rgb(199, 195, 195);
}
.vertical {
  transform: rotate(-90deg);
}


@media only screen and (min-width: 655px) and (max-width: 885px) {
  td {
   font-size: 13px;
  }
  .headName{
    font-size: 13px;
  }
}
@media only screen and (min-width: 425px) and (max-width: 655px) {
  td {
   font-size: 11px;
  }
  .headName{
    font-size: 12px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 425px) {
  td {
   font-size: 10px;
  }
  .headName{
    font-size: 10px;
  }
}