

.cardIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cartPart {
  margin-right: 1rem;
  width: 100%;
  margin-left: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-width: 21rem;
  /* max-width: 30rem; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.cartPart2 {
  /* padding-right: 0.2rem; */
  /* padding-left: 0.2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem; */
  background-color: aqua;
}
.received {
  font-size: 13px;
}
.cardSub {
  color: #888;
  font-size: 16px;
  white-space: nowrap;
  width: 100%;
  clear: both;
  display: inline-block;
  float: left;
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
 
}

.cardMain {
  color: #484f58;
  font-weight: 950;
  clear: both;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 33px;
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
}
i.round {
  display: inline-block;
  border-radius: 60px;
  padding: 1em 1em;
  color: white;
  /* background: linear-gradient(to bottom right, #5f90bb 0%, rgb(226,82,61) 100%); */
  /* background-color: #1e5185; */
  background: linear-gradient(to bottom right, #e74c3c 0%, #e27a6f 100%);
  box-shadow: 1px 1px 5px #888888;
  transition: 0.5s ease-in-out;
}
i.round:hover {
  background: linear-gradient(to bottom right, #e74c3c 0%, #e27a6f 100%);
  border-radius: 15px;
}


@media only screen and (min-width: 1148px) and (max-width: 1320px) {
  .cardSub {
    font-size: 14px;
  }
  .cardMain {
    font-size: 26px;
    font-weight: 950;

  }
}
@media only screen and (min-width:  880px) and (max-width: 1148px) {
  .cartPart{
    margin-top: 20px;
  }
}
@media only screen and (min-width:  758px) and (max-width: 880px) {
  .cartPart{
    margin-top: 20px;
  }
  .cardSub {
    font-size: 14px;
  }
  .cardMain {
    font-size: 26px;
    font-weight: 950;

  }
}
@media only screen and (min-width:  500px) and (max-width: 758px) {
  .cartPart{
    margin-top: 20px;
    
  }
 
}
@media only screen and (min-width:  300px) and (max-width: 500px) {
  .cartPart{
    margin-top: 20px;
    min-width: 18rem;
  }
 
}