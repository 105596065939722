.AppDashboard {
 
  padding: 2%;
  margin-top: 4%;
}
.App{
  padding:3%
}
.cardHead {
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
}
th {
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
}
td {
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Ubuntu', sans-serif; */
}
.submit {
  background: linear-gradient(to bottom right, #e2523d 0%, #ee9f77 100%);
  border: none;
  color: azure;
  padding: 8px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.submit:hover {
  background: linear-gradient(to bottom right, #e66754 0%, #ff9966 100%);
}
.submit1 {
  background: linear-gradient(to bottom right, #6d6867 0%, #c4c1bf 100%);
  border: none;
  color: #fff;
  padding: 8px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.submit1:hover {
  background: linear-gradient(to bottom right, #999696 0%, #cac6c5 100%);
}

.primary {
  background: linear-gradient(to bottom right, #3299c9 0%, #e74c3c 100%);
  border: none;
  color: #fff;
  padding: 7px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.primary:hover {
  background: linear-gradient(to bottom right, #51b1dd 0%, #e74c3c 100%);
}

.primaryIcon {
  background: linear-gradient(to bottom right, #3299c9 0%, #e74c3c 100%);
  border: none;
  color: #fff;
  padding: 10px;
  /* padding-right: 10px;
  padding-left: 10px; */
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.primaryIcon:hover {
  background: linear-gradient(to bottom right, #51b1dd 0%, #e74c3c 100%);
}
.success {
  background: linear-gradient(to bottom right, #2fa75b 0%, #bbecc5 100%);
  border: none;
  color: #fff;
  padding: 8px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.success:hover {
  background: linear-gradient(to bottom right, #40c06f 0%, #cdf5d5 100%);
}
.secondary {
  background: linear-gradient(to bottom right, #672fa7 0%, #e2cef8 100%);
  border: none;
  color: #fff;
  padding: 8px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.secondary:hover {
  background: linear-gradient(to bottom right, #955ad8 0%, #d2bbec 100%);
}
.BackApproval {
  background: linear-gradient(rgba(143, 24, 24, 0), rgba(255, 255, 255, 0)),
    url("http://localhost:3000/2.png");
}
.oneLine {
  white-space: nowrap;
}
.bullet {
  position: absolute;
}
.darkBlue {
  color: #244d74;
}
.bgDarkBlue {
  background-color: #eb6658;
}
.blue {
  color: #244d74;
}
.lightBlue {
  color: #4c7faf;
}
.tenant {
  background-color: #f3bd7b;
}
.tenant1 {
  background-color: #ffd29ca6;
}
.landLoad {
  background-color: #af7ac5;
}
.landLoad1 {
  background-color: #af7ac590;
}
.currency {
  background-color: #45b39d;
}
.currency1 {
  background-color: #45b39d88;
}
.application {
  background-color: #7fb3d5;
}
.pet {
  background-color: #f1948a;
}
.pet1 {
  background-color: #ff422dc1;
}
.mail {
  background-color: #aab7b8;
}
.fullHeight {
  height: 100%;
}
.fullWidth {
  width: 100%;
}
.width-1 {
  width: "1rem";
}
.width-1-5 {
  width: "1.5rem";
}
.occupied {
  font-size: 50px;
position: absolute;
right: 0px;
  width:120px;
  padding-top: 40px;
  padding-top: 40px;
  
  color: #e74c3c;
  font-weight: 800;
  z-index: 100;
  width: 100%;
}
@media only screen and (min-width: 224px) and (max-width: 991px) {
  .occupied {
    width: 100%;
    padding-top: 10px;
    padding-right: 50px;
  }
}
@media only screen and (min-device-width: 224px) and (max-device-width: 425px) {
  .occupied {
    width: 100%;
 
    min-width: auto;
    padding-top: 10px;
    padding-right: 50px;
  }
  .AppDashboard {
    margin-top: 12%;
  }
}
@media only screen and (min-device-width: 425px) and (max-device-width: 662px) {
  .AppDashboard {
    margin-top: 8%;
  }
  .occupied {
    width: 100%;
    margin-top: -40px;
    min-width: auto;

  }
}
@media only screen and (min-device-width: 662px) and (max-device-width: 992px) {
  .AppDashboard {
    margin-top: 5%;
  }
  .occupied {
    width: 100%;
    margin-top: -40px;
    min-width: auto;

  }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1245px) {
  .occupied {
    width: 100%;
    margin-top: -30px;

    min-width: auto;
  }
}
@media only screen and (min-width: 425px) and (max-width: 1245px) {
  .occupied {
    width: 100%;
    padding-top: 40px;
    padding-right: 50px;
    min-width: auto;
  }
}
@media only screen and (min-width: 1245px) and (max-width: 2000px) {
  .occupied {
    width: 100%;
    padding-top: 10px;
    padding-right: 50px;

    min-width: auto;
  }
}
@media only screen and (min-width: 2000px) and (max-width: 6045px) {
  .occupied {
    width: 100%;
    padding-top: 10px;
    padding-right: 50px;

    min-width: auto;
  }
}