.tableHead {
    white-space: nowrap;
  }
  .rowTable {
    width: 100%;
    text-align: left;
    display: inline-table;
    /* white-space: nowrap; */
    /* table-layout: fixed; */
  }
  
  .tableMaintain {
    text-align: left;
    /* height: 380px; */
  }
  thead {
    overflow-y: scroll;
    width: 100%;
  }
  tbody {
    /* overflow-y: scroll; 
    width: 100%;     
    height: 500px;            
    position: absolute; */
  }
  .tableYAxis {
  }
  /* width */
  .table::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .table::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .table::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .table::-webkit-scrollbar-thumb:hover {
    background: rgb(85, 85, 85);
  }