@import url('https://fonts.googleapis.com/css2?family=Play&display=swap');
 .default {
	 background-color: #5e5e5e;
	 cursor: pointer;
}
 .default:hover {
	 background-color: #a3a3a3;
}
 .delete:hover {
	 background-color: #e96868;
	 cursor: pointer;
}

 .font-normal {
	 font-family: "Montserrat", sans-serif;
}
 .font-number {
	 font-family: "Play", sans-serif;
}
 .font-logo {
	 font-family: "Montserrat", sans-serif;
}
 .cardContainerAlign {
	 min-width: 395px;
	 max-width: 455px;
}
 .card1 {
	 padding: 0px;
	 height: 270px;
	 z-index: 100;
	 border: none;
	 position: relative;
	 background: transparent;
}
 .card1__container {
	 position: relative;
	 top: 0;
	 left: 0;
	 transform: translate(-50%, -50%);
	 width: 370px;
	 height: 240px;
}
 .card1__extra {
	 position: absolute;
	 top: 36%;
	 right: 20px;
	 font-size: 26px;
	 transform: rotate(90deg);
	 color: #101010;
}
 .card1__front {
	 width: 370px;
	 height: 240px;
	 border-radius: 15px;
	 overflow: hidden;
	 box-shadow: 0 0 38px 4px rgba(0, 0, 0, 0.2);
	 position: absolute;
	 z-index: 22;
	 animation-name: cardFront;
	 animation-duration: 1s;
	 animation-delay: 1s;
	 animation-fill-mode: forwards;
}
 .card1__back {
	 width: 370px;
	 height: 240px;
	 border-radius: 15px;
	 overflow: hidden;
	 box-shadow: 0 0 38px 4px rgba(0, 0, 0, 0.2);
	 position: absolute;
	 background-color: #ff4b2b;
	 background-image: -webkit-linear-gradient(135deg, #f3a775 25%, #ff4b2b 100%);
	 background-image: -moz-linear-gradient(135deg, #f3a775 25%, #ff4b2b 100%);
	 background-image: -o-linear-gradient(135deg, #f3a775 25%, #ff4b2b 100%);
	 background-image: linear-gradient(135deg, #f3a775 25%, #ff4b2b 100%);
	 z-index: 11;
	 animation-name: cardBack;
	 animation-duration: 1s;
	 animation-delay: 1s;
	 animation-fill-mode: forwards;
}
 .card1__back--bottom {
	 display: flex;
	 justify-content: space-between;
	 width: 88%;
	 margin: 7px auto;
	 position: absolute;
	 bottom: 0;
	 left: 50%;
	 transform: translateX(-50%);
	 font-size: 10px;
	 color: #ffa44b;
}
 .card1__back--shape {
	 position: absolute;
	 background: #101010;
	 width: 160px;
	 height: 500px;
	 transform: rotate(84deg);
	 left: 55px;
	 border-radius: 27%;
	 border: 4px solid #8d8b86;
	 box-shadow: 0 0 1px 5px #101010;
	 top: 13px;
	 z-index: -1;
}
 .card1__contact {
	 display: flex;
	 justify-content: space-between;
	 align-items: center;
	 border-bottom: 1px solid black;
}
 .card1__contact--txt {
	 font-size: 9px;
	 font-weight: 700;
	 padding: 7px 20px;
	 color: #fff;
	 text-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
}
 .card1__strip {
	 width: 100%;
	 height: 45px;
	 background: #131313;
	 margin: 4px 0;
}
 .card1__company--address {
	 font-size: 8px;
	 margin-top: 15px;
	 margin-left: 20px;
}
 .card1__company--msg {
	 font-size: 8px;
	 margin-top: 2px;
	 margin-left: 20px;
}
 .card1__cvc {
	 display: flex;
	 align-items: center;
	 margin-top: 10px;
	 margin-left: 20px;
}
 .card1__cvc--strip {
	 width: 200px;
	 background: #d3d3d3;
	 height: 45px;
	 position: relative;
	 overflow: hidden;
	 display: flex;
	 align-items: center;
	 justify-content: space-around;
}
 .card1__cvc--txt {
	 background: wheat;
	 margin-left: 6px;
	 padding: 2px 6px;
	 font-style: italic;
	 font-weight: 700;
	 color: #000;
	 font-size: 16px;
}
 .card1__cvc--num {
	 font-size: 16px;
	 font-weight: 600;
	 color: black;
}
 .card1__cvc--warning {
	 font-size: 5px;
	 margin-left: 25px;
	 margin-top: 2px;
	 font-style: italic;
}
 .card1__brand {
	 display: flex;
	 width: 80%;
	 justify-content: space-between;
	 align-items: center;
	 margin-left: 50%;
	 position: absolute;
	 transform: translate(-50%);
}
 .card1__brand--name {
	 font-size: 13px;
	 font-weight: 700;
	 color: black;
	 text-shadow: 0 0 2px rgba(0, 0, 0, .5);
}
 .card1__brand--logo {
	 font-size: 21px;
}
 .card1__number {
	 position: relative;
	 top: 62%;
	 padding-left: 30px;
	 font-size: 18px;
	 font-weight: 700;
	 color: #e96048;
	 text-shadow: 0px 0px 2px #4a2d00;
	 letter-spacing: 1px;
}
 .card1__number1 {
	 position: relative;
	 top: 62%;
	 padding-left: 30px;
	 font-size: 18px;
	 font-weight: 700;
	 color: #b3b0aa;
	 text-shadow: 0px 0px 2px #4a2d00;
	 letter-spacing: 1px;
}
 .card1__user {
	 position: relative;
	 top: 70%;
	 display: flex;
	 left: 30px;
	 color: #fff;
}
 .card1__user--name {
	 font-size: 12px;
	 letter-spacing: 1.4px;
	 font-weight: 700;
	 text-shadow: 0 0 3px #060606;
}
 .card1__user--valid {
	 display: flex;
	 align-items: center;
	 flex-direction: column;
	 margin-left: 20px;
}
 .card1__user--valid-txt {
	 display: inline-flex;
	 font-size: 10px;
	 margin-bottom: 5px;
	 text-shadow: 0 0 3px #060606;
}
 .card1__user--valid-date {
	 font-size: 12px;
	 font-weight: 700;
	 text-shadow: 0 0 3px #060606;
}
 .card1__payment {
	 position: absolute;
	 color: #e96048;
	 bottom: 20px;
	 right: 27px;
	 font-size: 2.5em;
}
 .card1__payment1 {
	 position: absolute;
	 color: #b3b0aa;
	 bottom: 20px;
	 right: 27px;
	 font-size: 2.5em;
}
 .card1__front--bg {
	 position: absolute;
	 background-color: #ff4b2b;
	 background-image: -webkit-linear-gradient(135deg, #f3a775 25%, #ff4b2b 100%);
	 background-image: -moz-linear-gradient(135deg, #f3a775 25%, #ff4b2b 100%);
	 background-image: -o-linear-gradient(135deg, #f3a775 25%, #ff4b2b 100%);
	 background-image: linear-gradient(135deg, #f3a775 25%, #ff4b2b 100%);
	 width: 100%;
	 height: 100%;
	 z-index: 0;
}
 .card1__front1--bg {
	 position: absolute;
	 background-color: #b8acac;
	 background-image: -webkit-linear-gradient(135deg, rgba(242, 240, 240, 0.603) 25%, rgba(190, 183, 183, 0.603) 100%);
	 background-image: -moz-linear-gradient(135deg, rgba(242, 240, 240, 0.603) 25%, rgba(190, 183, 183, 0.603) 100%);
	 background-image: -o-linear-gradient(135deg, rgba(242, 240, 240, 0.603) 25%, rgba(190, 183, 183, 0.603) 100%);
	 background-image: linear-gradient(135deg, rgba(242, 240, 240, 0.603) 25%, rgba(190, 183, 183, 0.603) 100%);
	 width: 100%;
	 height: 100%;
	 z-index: 0;
}
 .card1__chip {
	 position: absolute;
	 top: 86px;
	 left: 45px;
	 display: flex;
	 flex-direction: column;
	 align-items: center;
	 background: #101010;
}
 .card1__chip--shape {
	 width: 43px;
	 height: 9px;
	 background: #efb35b;
	 border-radius: 1px;
	 margin-bottom: 1px;
}
 .card1__chip--shape:last-child {
	 margin-bottom: 0;
	 border-radius: 1px 1px 3px 3px;
}
 .card1__chip--shape:first-child {
	 border-radius: 3px 3px 1px 1px;
}
 .card1__chip--shape:first-child:after {
	 content: "";
	 position: absolute;
	 background: #efb35b;
	 border: 1px solid #101010;
	 width: 12px;
	 height: 23px;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -50%);
	 border-radius: 5px;
}
 .card1__chip:before {
	 content: "";
	 position: absolute;
	 width: 0;
	 height: 0;
	 border-top: 8px solid transparent;
	 border-right: 12px solid #fab25e 40;
	 border-bottom: 8px solid transparent;
	 left: -20px;
	 top: 7px;
}
 .card1__stamp {
	 width: 54px;
	 height: 35px;
	 background: radial-gradient(#fff, #a9a9a9);
	 position: absolute;
	 right: 15px;
	 bottom: 80px;
	 border-radius: 20px;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 box-shadow: 0 0 13px 0px rgba(0, 0, 0, 0.2);
}
 .card1__stamp:after {
	 content: "d&p";
	 position: absolute;
	 opacity: 0.1;
	 color: #4c320c;
	 font-weight: 900;
	 font-size: 18px;
	 text-shadow: 0 1px 1px #4e4d4d;
	 border: 1px solid #8e8b8b;
	 width: 70%;
	 height: 70%;
	 text-align: center;
	 border-radius: 30px;
}
 .card1__plast {
	 position: absolute;
	 left: 0;
	 width: 15px;
	 height: 100%;
}
 .card1__plast:after {
	 content: "www.samplecard-plast.com.xx";
	 position: absolute;
	 transform: rotate(270deg) translateY(-50%);
	 text-align: center;
	 width: 15px;
	 white-space: nowrap;
	 bottom: 30%;
	 font-size: 6px;
}
 .card1 .logo {
	 display: flex;
	 position: relative;
	 bottom: 2px;
}
 .card1 .logo--shape {
	 position: relative;
	 width: 25px;
	 height: 25px;
	 border-radius: 50%;
	 opacity: 0.6;
}
 .card1 .logo-red {
	 background: #f00;
	 left: 5px;
}
 .card1 .logo-yellow {
	 background: #ff0;
	 right: 5px;
}
 .card1 .logo-name {
	 position: relative;
	 left: 0;
	 top: -3px;
	 font-size: 12px;
	 color: white;
	 opacity: 0.2;
}
 .card1 .logo-mid {
	 font-size: 20px;
}
 .card1 .logo-d {
	 left: 3px;
}
 .card1 .logo-p {
	 right: 7px;
}
 .card1 .logo-letter {
	 position: relative;
	 text-shadow: 0 0 5px #000 b8;
}
 .card1 .front--bg--s1 {
	 position: relative;
	 background: #101010;
	 width: 260px;
	 height: 510px;
	 transform: rotate(105deg);
	 bottom: 21px;
	 left: 75px;
	 border-radius: 30%;
	 border: 4px solid #ee6b54;
	 box-shadow: 0 0 1px 5px #101010;
}
 .card1 .front1--bg--s1 {
	 position: relative;
	 background: #101010;
	 width: 260px;
	 height: 510px;
	 transform: rotate(105deg);
	 bottom: 21px;
	 left: 75px;
	 border-radius: 30%;
	 border: 4px solid #b3a8a6;
	 box-shadow: 0 0 1px 5px #101010;
}
 .card1 .front--bg--s2 {
	 position: absolute;
	 top: -20px;
	 display: flex;
	 transform: rotate(-1deg);
	 z-index: -1;
}
 .card1 .strip-shape {
	 background: #dcaa7b 80;
	 width: 80px;
	 height: 60px;
	 transform: rotate(130deg) translateX(-50%);
	 position: relative;
	 left: -11px;
	 top: 35%;
	 border: 1px solid #fff a8;
}
 @keyframes cardFront {
	 from {
		 left: 0;
		 top: 0;
	}
}
 @keyframes cardBack {
	 from {
		 left: 0;
		 top: 0;
	}
	 to {
		 left: 52%;
		 top: 12%;
	}
}
 @media all and (max-width: 768px) {
	 @keyframes cardFront {
		 from {
			 left: 0;
			 top: 0;
		}
	}
	 @keyframes cardBack {
		 from {
			 left: 0;
			 top: 0;
		}
		 to {
			 left: 0%;
			 top: 54%;
		}
	}
}
 @media all and (max-width: 420px) {
	 .card__container {
		 transform: translate(-50%, -50%) scale(0.8);
	}
}

 @media only screen and (min-width: 740px) and (max-width: 880px) {
	 .card1 {
		 padding: 0px;
		 height: 250px;
	}
	 .card1__container {
		 width: 350px;
		 height: 240px;
	}
	 .card1__extra {
		 top: 36%;
		 right: 20px;
		 font-size: 26px;
	}
	 .card1__front {
		 width: 340px;
		 height: 220px;
	}

}
 @media only screen and (min-width: 380px) and (max-width: 410px) {

	 .card1 {
		 padding: 0px;
		 height: 230px;
	}
	 .card1__container {
		 width: 350px;
		 height: 240px;
	}
	 .card1__extra {
		 top: 36%;
		 right: 20px;
		 font-size: 26px;
	}
	 .card1__front {
		 width: 340px;
		 height: 220px;
	}
}
 @media only screen and (min-width: 100px) and (max-width: 380px) {

	 .cardContainerAlign {
		 min-width: 300px;
	}
	 .card1 {
		 width: 300px;
		 padding: 0px;
		 height: 200px;
	}
	 .card1__container {
		 width: 350px;
		 height: 240px;
	}
	 .card1__extra {
		 top: 36%;
		 right: 20px;
		 font-size: 26px;
	}
	 .card1__front {
		 width: 300px;
		 height: 200px;
	}
	 .font-number {
		 margin-top: -10px;
	}
	 .font-normal {
		 margin-top: 13px;
	}
}
 