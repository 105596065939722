   .requestProviderDivider{
       padding-left:10px !important;
       margin-right: 10px !important;
   }
   .requestProviderText{
       padding-top: 3%;
       padding-bottom: 0%;
    
       padding-right: 4%;
       overflow-y: scroll;
       max-height:650px;
     }
     
     .requestProviderText::-webkit-scrollbar {
     width: 2px;
     height: 2px;
     }
     .requestProviderText::-webkit-scrollbar-track {
     background: rgb(255, 255, 255);
     }
     .requestProviderText::-webkit-scrollbar-thumb {
     background: #ff6d53;
     }
     .requestProviderText::-webkit-scrollbar {
     width: 2px;
     height: 0px;
     }
     .requestProviderText::-webkit-scrollbar-track {
     background: rgba(189, 189, 189, 0.01);
     }
     .requestProviderText::-webkit-scrollbar-thumb {
     background: #ff6d53;
     }
   .maintenanceProviderPart{
     width:50%;
     padding-left: 0%;
     padding-bottom: 2%;
     padding-top: 2%;
     border-bottom: 1px solid rgb(239, 239, 239);
     
   }
   .maintenanceProviderPart2{
     width:50%;
     padding-left: 0%;
     padding-bottom: 2%;
     padding-top: 2%;
   }
   .maintenanceProviderPartContainer{
     padding-left: 3%;
     padding-right: 3%;
     padding-top: 2%;
     padding-bottom: 2%;
     
   }
   .maintenanceProviderPartImageAlign{
     width:27%;
     align-self: center;
     margin-top: -1% !important;
   }
   .maintenanceRequestProviderPartImage{
     height: 35px;
     width: 35px;
     border-radius: 50%;
   
   }
   .maintenanceProviderPartNameAlign{
     width:72%;
     align-self: center;
   }
   .activeSkillBudge {
     color: #ff4b2b;
     border:1px solid #ff4b2b;
     border-radius: 15px;
     padding-right: 10px;
     padding-left: 10px;
     padding-top: 4px;
     padding-bottom: 4px;
     font-family: "Montserrat", sans-serif;
     cursor: pointer;
     margin-right: 3%;
     margin-bottom: 3% !important;
     white-space:nowrap;
     font-size: 12px;
     align-self: flex-start;
   }
   .skillBudge{
     color: #adadad;
     border:1px solid #adadad;
     border-radius: 15px;
     padding-right: 10px;
     padding-left: 10px;
     padding-top: 4px;
     padding-bottom: 4px;
     font-size: 12px;
     font-family: "Montserrat", sans-serif;
     white-space:nowrap;
     margin-right: 3%;
     margin-bottom: 3% !important;
     cursor: pointer;
     align-self: flex-start;
     
   }
   .skillBudgeCollection{
      padding-top: 2%;
      padding-bottom: 2%;
      padding-right: 4%;
      padding-left: 4%;
      word-wrap: break-word ;
     
      
   }
   .borderProviderLeftGray{
    border-left: 1px solid rgb(239, 239, 239);
   }

   @media only screen and   (min-width:10px) and (max-width: 407px),(min-width: 768px) and (max-width: 839px){
       .requestProviderDivider{
            opacity: 0;
       }
      .requestProviderText{
         margin-top:15%;
      }
      .requestProviderText{
        margin-top:10%;
       }
   }
   
   @media only screen and (min-width: 768px) and (max-width: 1000px), (min-width:10px) and (max-width: 485px){
    .borderProviderLeftGray{
      border:none;
    }
    .maintenanceProviderPart{
      width:100%;
      padding-left: 3%;
      padding-bottom: 2%;
      padding-top: 2%;
    }
    .maintenanceProviderPart2{
      width:100%;
      padding-left: 3%;
      padding-bottom: 2%;
      padding-top: 2%;
    }
   }
