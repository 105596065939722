.tabSignature{
  border: solid 1px #b7b5b5 !important;
  border-bottom: solid 1px #eaeaea !important;
  background-color: #fff !important;
  color: #b7b5b5 !important;
  font-size: 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px  !important;
  border-bottom-left-radius: 0px !important;
  font-weight: 600;
  padding:12px 10px;
  letter-spacing: 1px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
.tabSignature2{
 border: solid 1px #fff !important;
      background-color: #b7b5b5 !important;
      color: #fff !important;
      font-size: 16px;
      border-radius: 5px;
      border-bottom-right-radius: 0px  !important;
  border-bottom-left-radius: 0px !important;
      font-weight: 600;
      padding:12px 10px;
      letter-spacing: 1px;
      text-align: center;
      font-family: "Montserrat", sans-serif;
      box-shadow: rgba(238, 238, 238, 0.2) 0px 7px 29px 0px !important;
}
.StepRound {

  background-color: blueviolet;
}
.cardApplication{
  background-color: #fff;
  padding: 0px;
  padding-top: 1%;
  margin-top: 2.0%;
  border-radius: 8px;
  margin-right: 3%;
  margin-left: 3%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.social-container {
  margin: 20px 0;
}
.titleStepOpp {
  display: none;
}

.buttonUpload {
  border-radius: 5px;
  border: solid 0px #fff;
  background: linear-gradient(to right, #949494,#ada9a9a4);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

.buttonUploadAlert {
  border-radius: 5px;
  border: solid 1px rgb(228, 38, 38);
  background: linear-gradient(to right, #949494,#ada9a9a4);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}
.ax-success{
  color: rgb(97, 230, 9);
}
.buttonUpload:hover {
  /* border: solid 1px #fff; */
  color: #fff;
  /* background: linear-gradient(to right, #979696, rgb(228, 227, 227)); */
  box-shadow: 0 0px 40px rgba(255, 255, 255, 0.349);
  /* color:#616161; */
}
.social-container-step {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 0px;
  height: 40px;
  width: 40px;
}
.activeStep {
  background-color: #ff4b2b;
  border: 1px solid #ff4b2b;
  color: #fff !important;
}
.activeLine {
  border-bottom: 2px solid #ff4b2b !important;
}
.SideLine {
  /* width: 112px; */
  height: 47px;
  margin-top: -25px;
  border-bottom: 2px solid #e6dddab9;
  /* margin-left: -5%;
    margin-right: -5%; */
}
.stepFormNavTitle {
  padding-top: 25px;
}
.stepFormNav {
  /* padding:10%; */
  padding-top: 120px;
}
.stepSpan {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  white-space: nowrap;
}
.stepTitlePayment {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin-top: 3px;
  white-space: nowrap;
  position: absolute;
  color: #828282;
  /* align-items: center; */
  align-self: center;
}
.stepTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 3px;
  white-space: nowrap;
  position: absolute;
  /* align-items: center; */
  align-self: center;
}
.stepContentTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 3px;
  white-space: nowrap;
  position: absolute;
  /* align-items: center; */
  align-self: center;
}
.ApplicationInputAgg {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;

  /* padding-right:50px; */
  font-size: 12px;
}
.ApplicationInput {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  min-width: 240px;
  max-width: 340px;

  /* padding-right:50px; */
  font-size: 12px;
}
.ApplicationInput2 {
  background-color: rgb(255, 255, 255);
  border: 1px solid #000;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  min-width: 240px;
  max-width: 340px;
  font-size: 12px;
}
.ApplicationInputAgg2 {
  background-color: rgb(255, 255, 255);
  border: 1px solid #000;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  font-size: 12px;
}
.ApplicationInput1 {

  font-family: "Montserrat", sans-serif;
  padding: 8px 15px;
  margin: 8px 0;
  font-size: 12px;
  width: 100%;
  min-width:290px;
  border-bottom: 1px solid #30302f;
  padding-bottom: 0px;
  padding-top: 15px;
  margin-bottom: 0px;
}
.ApplicationInputLabel{
  position: absolute;
  font-size: 9px;
  margin-top:-1px;
  z-index:500;
}
.ApplicationInputLabel1{
  position: absolute;
  font-size: 9px;
  width:100%;
  border-top: 1px solid #000;
  margin-top:-1px;
  z-index:500;
}
.ApplicationInput2 {
  min-width: 240px;
  max-width: 340px;
  font-family: "Montserrat", sans-serif;
  padding: 8px 15px;
  margin: 8px 0;
  font-size: 12px;

  border-bottom: 1px solid #30302f;
  padding-bottom: 0px;

  margin-bottom: 0px;
}
.alignInput {
  min-width: 250px;
  max-width: 350px;
}
.dateInputIcon{
  right:30px;
}
.w-5 {
  width: 5%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.ApplicationInputSelect {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  /* padding: 12px 15px; */
  height: 45px;
  margin: 8px 0;
  width: 100%;
  font-size: 12px;
  /* padding-right:50px; */
  min-width: 240px;
  max-width: 340px;
}
.ApplicationInputSelectAgg {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  height: 45px;
  margin: 8px 0;
  width: 100%;
  font-size: 12px;
}
.ApplicationInputSelectNormal {
  background-color: transparent;
  border: none;
  font-family: "Montserrat", sans-serif;
  height: 30px;
  min-width: 50px;
  width: auto;
  margin: 3px 5px;
  color: #ff7777;
  font-size: 15px;
  font-weight:700;
}
.ApplicationInputSelectNormal1 {
  background-color: transparent;
  border: none;
  font-family: "Montserrat", sans-serif;
  height: 30px;
  min-width: 50px;
  width: auto;
  
  color: #989898;
  font-size: 15px;
  font-weight:200;
}
.ApplicationInputOptionNormal {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  color: #636363;
}
.OfficeApplicationInputSelect {
  border: 1px solid #b8b6b69a;
  font-family: "Montserrat", sans-serif;
  height: 38px;
  width: 100%;
  font-size: 12px;
  box-shadow: rgba(99, 99, 99, 0.13) 0px 2px 8px 0px;
}
.searchListHeadApp {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  white-space: normal;
  word-wrap: break-word !important;
  /* white-space: nowrap !important; */
  display: inline-block;
}
.searchListHeadApp2 {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  white-space: normal;
  word-wrap: break-word !important;
  font-weight: 600;
  display: inline-block;
}
.searchListHeadAppDark {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  white-space: normal;
  word-wrap: break-word !important;
  /* white-space: nowrap !important; */
  display: inline-block;
  color: #fff;
}
.inputContainer {
  padding: 50px;
}
.inputContainer1 {
  padding-left: 50px;
  padding-right:50px;
}
.outSpan {
  /* display: inline-block; */
  /* white-space: nowrap !important; */
}
.noBreak {
  /* white-space: nowrap; */
  word-break: break-word !important;
}
.searchListContentApp {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  color: #615a5a;
}
.informationListApp {
  background-color: #dddddd;
  padding-top: 15px;
  border: 1px solid #cac4c4;
  padding-bottom: 15px;
  padding-right: 25px;
  padding-left: 25px;
  text-align: justify;
  box-shadow: 0 8px 16px -6px rgb(189, 183, 183);
}
.informationListAppAlign {
  padding: 50px;
}
.informationListAppNormal {
  background-color: transparent;
  padding-top: 15px;
  border: 1px solid #e4e4e4;
  padding-bottom: 15px;
  padding-right: 25px;
  padding-left: 25px;
  text-align: justify;
  box-shadow: 0 1px 16px -6px rgb(189, 183, 183);
}

.ashBack{
  background-color: #d3d3d31e !important;
}
.informationListAppInfo {
  background-color: #ddeff3;
  padding-top: 15px;
  border: 1px solid #ddeff3;
  padding-bottom: 15px;
  color: #29bddf;
  padding-right: 25px;
  padding-left: 25px;
  text-align: justify;
  box-shadow: 0 8px 16px -6px rgb(189, 183, 183);
}
.informationListAppWarning {
  background-color: #f1f1c9;
  padding-top: 15px;
  border: 1px solid #f1f1c9;
  padding-bottom: 15px;
  color: #999731;
  padding-right: 25px;
  padding-left: 25px;
  text-align: justify;
  box-shadow: 0 8px 16px -6px rgb(189, 183, 183);
}
.appFootButton {
  margin-top: 20px;
  padding-right: 50px;
  padding-bottom: 50px;
}
hr.line {
  border-bottom: 1px solid rgba(153, 153, 153, 0.308);
  border-top: transparent;
}
hr.line2 {
  border-bottom: 2px solid rgba(153, 153, 153, 0.308);
  border-top: transparent;
}
.spanValidateApp {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  align-self: flex-end;
  /* padding-right: 10px; */
  /* position:fixed; */
  margin-top: -10px;
  margin-bottom: -3px;
}

.licenseButton {
  background-color: transparent;
  border: 1px solid #ff4b2b;
  color: #ff4b2b;
  cursor:pointer;
}
.licenseButton:hover {
  background-color: #ff4b2b;
  border: 1px solid #ff4b2b;
  color: #fff;
 
}
.licenseButtonDis {
  background-color: transparent;
  border: 1px solid #c7c7c7;
  color: #c7c7c7;
  cursor:not-allowed;
}
.licenseButtonDis:hover {
  background-color: #c7c7c7;
  border: 1px solid #c7c7c7;
  color: #fff;
}
.dragDrop {
  height: 250px;
  border: 9px dashed rgb(192, 174, 170);
  border-radius: 5px;
}
.licenseHead {
  font-family: "Montserrat", sans-serif;
}
.licenseHeadDark {
  font-family: "Montserrat", sans-serif;
  color: #fff;
}
.absolute {
  position: absolute;
}
.licenseImage {
  height: 250px;
  opacity: 0.5;
}

.pdf {
}

.pdf::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.pdf::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.pdf::-webkit-scrollbar-thumb {
  background: #ff4b2b;
}

/* Handle on hover */
.pdf::-webkit-scrollbar-thumb:hover {
  background: #918c8b;
}
.applicationContainer {
  margin: 4%;
  margin-right: 3%;
  margin-left: 3%;
  /* margin-top:120px; */
  /* margin-top:100px; */
}
.applicationCard {
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border:1px solid rgb(241, 241, 241);
}
.applicationCard2 {
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border:1px solid rgb(241, 241, 241);
}
.applicationCardDark {
  background-color: rgb(151, 150, 150);
  border-radius: 15px;
  margin-bottom: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.appImage {
  height: 50px;
  width: 50px;
}
.appImage1 {
  height: 50px;
}
.addApp {
  margin-top: -10px;
}
.applicationText {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #807e7e;
  letter-spacing: 0.5px;
  line-height: 25px;
  font-size: 15px;
}
.applicationTextDark {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #fafafa;
  letter-spacing: 0.5px;
  line-height: 25px;
  font-size: 15px;
}
.applicationText1 {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #807e7e;
  letter-spacing: 0.5px;
  line-height: 25px;
  font-size: 15px;
}
.appStatus {
  text-align: "center";
  padding: 7px;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  margin-bottom: 10px;
}

/* #Toggle
   */

.switchApplication {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 27px;
}
.switchApplication1 {
  position: relative;
  display: inline-block;
  width: 190px;
  height: 27px;
}
.switchApplication2 {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 27px;
}
.switchApplication input {
  display: none;
}
.switchApplication1 input {
  display: none;
}
.switchApplication2 input {
  display: none;
}
.sliderApplication {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b7bbba;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.sliderApplication1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b7bbba;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.sliderApplication2 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b7bbba;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.sliderApplication:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 22px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.sliderApplication1:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 22px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.sliderApplication2:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 22px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .sliderApplication {
  background-color: #ff4b2bc7;
}
input:checked + .sliderApplication1 {
  background-color: #ff4b2bc7;
}
input:checked + .sliderApplication2 {
  background-color: #ff4b2bc7;
}
input:focus + .sliderApplication {
  box-shadow: 0 0 1px #2196f3;
}
input:focus + .sliderApplication1 {
  box-shadow: 0 0 1px #2196f3;
}
input:focus + .sliderApplication2{
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .sliderApplication:before {
  -webkit-transform: translateX(54px);
  -ms-transform: translateX(54px);
  transform: translateX(54px);
}
input:checked + .sliderApplication1:before {
  -webkit-transform: translateX(164px);
  -ms-transform: translateX(164px);
  transform: translateX(164px);
}
input:checked + .sliderApplication2:before {
  -webkit-transform: translateX(84px);
  -ms-transform: translateX(84px);
  transform: translateX(84px);
}
/*------ ADDED CSS ---------*/
.onApp {
  display: none;
}

.onApp,
.offApp {
  color: rgb(235, 229, 229);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 47%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .sliderApplication .onApp {
  display: block;
}
input:checked + .sliderApplication1 .onApp {
  display: block;
}
input:checked + .sliderApplication2 .onApp {
  display: block;
}
input:checked + .sliderApplication .offApp {
  display: none;
}
input:checked + .sliderApplication1 .offApp {
  display: none;
}
input:checked + .sliderApplication2 .offApp {
  display: none;
}
/*--------- END --------*/

/* Rounded sliders */
.sliderApplication.round {
  border-radius: 34px;
}
.sliderApplication1.round {
  border-radius: 34px;
}
.sliderApplication2.round {
  border-radius: 34px;
}
.sliderApplication.round:before {
  border-radius: 50%;
}
.sliderApplication1.round:before {
  border-radius: 50%;
}
.sliderApplication2.round:before {
  border-radius: 50%;
}

.stepTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 3px;

  white-space: nowrap;
  position: absolute;

  /* align-items: center; */
  align-self: center;
}
.stepContentTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 3px;

  white-space: nowrap;
  position: absolute;

  /* align-items: center; */
  align-self: center;
}
.infoIcon {
  margin-left: 15px;
  /* margin-top:5px; */
  font-size: 20px;
  cursor: help;
  color: #ff4b2b;
}
.infoIcon:hover {
  color: #968c8c;
}
.infoContent {
  display: none;
  position: absolute;
  z-index: 1000;
}
.infoHandel:hover .infoContent {
  display: block;
}
.infoContent2 {
  display: none;
  position: absolute;
  right:0;
  z-index: 1000;
}
.infoHandel:hover .infoContent2 {
  display: block;
}
.iconL {
  color: #999393;
}

.drop-list {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  height: 25px;
  padding-left: 5px;
  color: #4f5050;
}
.drop-list:hover {
  background-color: #e8e8e9;
  cursor: pointer;
  color: #000;
}
.act {
  background-color: #cccdce;
}

.searchList {
  max-height: 150px;

  width: 93%;
  z-index: 100;
  overflow-y: scroll;
  position: absolute;
  /* margin-right: 8px; */
}
.searchList::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.searchList::-webkit-scrollbar-track {
  background: #dad9d9;
}

/* Handle */
.searchList::-webkit-scrollbar-thumb {
  background: #ff4b2b;
}

/* Handle on hover */
.searchList::-webkit-scrollbar-thumb:hover {
  background: #aca9a8;
}

.checkedContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  white-space: pre;
}

/* Hide the browser's default checkbox */
.checkedContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ccc;
}

/* On mouse-over, add a grey background color */
.checkedContainer:hover input ~ .checkmark {
  background-color: #ff9380;
}

/* When the checkbox is checked, add a blue background */
.checkedContainer input:checked ~ .checkmark {
  background-color: #ff4b2b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkedContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkedContainer .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.optionContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
}
.spanFont {
  font-size: 13px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #4d4d4d;
}
/* Hide the browser's default radio button */
.optionContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ccc;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.optionContainer:hover input ~ .checkmark1 {
  background-color: #ff9380;
}

/* When the radio button is checked, add a blue background */
.optionContainer input:checked ~ .checkmark1 {
  background-color: #ff4b2b;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark1:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.optionContainer input:checked ~ .checkmark1:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.optionContainer .checkmark1:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.optionTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  white-space: nowrap;

  /* align-items: center; */
  align-self: center;
}
.SideLine {
  display: block;
}
.SideLineHide {
  display: block;
}
.applicationAgreement {
  padding-right: 30px;
  padding-left: 30px;
  
}
.applicationAgreementCard{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.applicationAgreementMobile {
  min-width: 1100px;
  max-width: 1100px;
  padding-right: 30px;
  padding-left: 30px;
  padding: 0px;
  margin: 0px;
}
.applicationAgreementHead {
  padding-top: 50px;
  padding-left: 80px;
}
.applicationAgreementHead4 {
  padding-top: 30px;
  padding-left: 60px;
  padding-right: 60px;
}
.applicationAgreementHeadBar {
  background-color: rgba(224, 220, 220, 0.603);
  border: 1px solid rgb(228, 226, 226);
  height: 20px;
  margin: 30px;
  margin-bottom: 0px;
}
.applicationAgreementHeadLeftBox {
  border: 1px solid #000;
  padding: 0px;
  margin: 0px;
  margin-top: 27px;
}
.applicationAgreementHeadLeft {
  border: 1px solid #000;
  padding: 0px;
  margin: 0px;
  margin-top: 27px;
}
.applicationAgreementHeadLeftBoxMobile {
  border: 1px solid #000;
  padding: 0px;
  margin: 0px;
  margin-top: 27px;
  display: none;
}
.agreementLogo {
  width: 190px;
  margin-bottom: 20px;
}
.agreementSign {
  width: 90px;
  height: 34px;
  margin-bottom: 20px;
}
.applicationAgreementHeadRight {
  padding-right: 55px;
  padding-left: 25px;
  margin: 0px;
}
.applicationAgreementHead2 {
  padding-top: 30px;
  padding-left: 80px;
  padding-left: 80px;
}
.applicationAgreementHead3 {
  padding-top: 0px;
  padding-right: 80px;
  padding-left: 80px;
}
.agreementTableBody {
  border: 1px solid #000;
  margin-right: 30px;
  margin-left: 30px;
}
.agreementTableBody1 {
  margin-right: 40px;
  margin-left: 40px;
}
.borderBlack {
  /* border:1px solid #000; */
  border-style: ridge;
}
.borderBlack1 {
  /* border:1px solid #000; */
  border: ridge 1.7px #fff;
}
.borderRightBlack {
  border-right: 1px solid #000;
}
.borderLeftBlack {
  border-left: 1px solid #000;
}
.borderTopBlack {
  border-top: 1px solid #000;
}
.borderBottomBlack {
  border-bottom: 1px solid #000;
}
.ApplicationInputAgreement {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  /* width: 100%; */

  /* padding-right:50px; */
  font-size: 12px;
}
.ApplicationInputAgreement2 {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  height: 70%;
  /* padding-right:50px; */
  font-size: 12px;
}
.ApplicationInputAgreement4 {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  height: 70%;
  /* padding-right:50px; */
  top:15px;
  font-size: 12px;
  margin-right:10px;
  margin-left:10px;

}
.bgGray {
  background-color: rgba(224, 220, 220, 0.603);
}
.ApplicationInputAgreement3 {
  background-color: rgba(255, 255, 255, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;

  width: 100%;
  height: 100%;
  /* padding-right:50px; */
  font-size: 12px;
}
.agreementTitleBar {
  background-color: #000;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
.threeQuarterBreak {
  width: 75%;
}

.headApplicationReview {
  font-size: 19px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.7px;
}
.headApplicationReview1 {
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 25px;
  font-family: "Montserrat", sans-serif;
}
.fixedInputSize {
  min-width: 150px;
}
.tableLeftPad {
  padding-left: 15px;
}
.quarterBreak {
  min-width: 130px;
}
.tableResNo {
  display: none;
  position: absolute;
  padding-left: 5px;
  padding-top: 5px;
}
.tableResNo2 {
  display: none;
  position: absolute;
  padding-left: 5px;
  padding-top: 5px;
}
.pageNumberAlign {
  text-align: right;
}
.preEmployeeHide1 {

  display: block;
}
.preEmployeeHide2 {
  display: none;

}
.contractAlign{
  padding-right:10%;
  padding-left:10%;
}
.buttonTabSec {
  border-radius: 0px;
  font-size: 10px;
  font-weight: bold;
  padding: 7px 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;    
  border: solid 1px #efefef;
  background-color: #efefef;
  color: #939292;
  cursor: pointer;
  border-bottom: none;

}
.buttonTabLineSec {
  /* border-radius: 5px; */
  font-size: 10px;
  font-weight: bold;
  padding: 7px 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;    
  border: solid 1px #efefef;
  border-bottom: none;
  background-color:  #ffffff;
  color: #939292;
  cursor: pointer;
}
.buttonTabSec:hover{
  background-color:  #c7c6c6;
  color: #fff;
}
.tabAppList{
  padding-bottom: 3px;
  background-color:  #efefef;
}
.badgeApplicationStatusRed {
  position: absolute;
  right:43px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  padding: 0px 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 5px #ffffff;
  background-color: #fd846f;
  color:#fff;
  margin-top: -7px;
}
.badgeApplicationStatusYellow {
  position: absolute;
  right:43px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  padding: 0px 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 5px #ffffff;
  background-color: #f4da2e;
  color:#fff;
  margin-top: -7px;
}
.badgeApplicationStatusGreen {
  position: absolute;
  right:43px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  padding: 0px 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 5px #ffffff;
  background-color: #66c533;
  color:#fff;
  margin-top: -7px;
}
.applicationCardAlign{
  min-width: 500px !important;
}
@media only screen and (min-width: 1900px) and (max-width: 5900px) {
  .dateInputIcon{
    right:30px;
    top:22px;
  }
  .applicationCard {
    height: 320px !important;
  }
  .applicationCard2 {
    height: 320px !important;
  }
  .optionInfo{
    min-width: 1600px;
    max-width: 1400px;
  }
  .alignInput {
    min-width: 280px;
    max-width: 380px;
  }
  .dateInputIcon{
  
  }
  .inputContainer {
    padding: 60px;
    padding-right: 80px;
    padding-left: 80px;
    
  }
  .inputContainer1 {
    padding-left: 80px;
    padding-right:80px;
  }
  .subTitle {
    padding-left: 80px;
  }
  .stepTitle {
    font-size: 18px;
  }
  .stepContentTitle {
    font-size: 18px;
  }
  .applicationAgreement {

    padding-top: 20px;

    margin-right: 50px;
  margin-left: 50px;
  }
  .managerLandlord {
    min-width: 400px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 60px;
  }
}

@media only screen and (min-width: 1450px) and (max-width: 1900px) {
  .applicationCard {
    height: 320px !important;
  }
  .applicationCard2 {
    height: 320px !important;
  }
  .dateInputIcon{
    right:30px;
    top:22px;
  }

  .alignInput {
    min-width: 280px;
    max-width: 380px;
  }
  .dateInputIcon{
  
  }
  .optionInfo{
    min-width: 1200px;
    max-width: 1000px;
  }
  .inputContainer {
    padding: 60px;
    padding-right: 80px;
    padding-left: 80px;
  }
  .inputContainer1 {
    padding-left: 80px;
    padding-right:80px;
  }
  .subTitle {
    padding-left: 80px;
  }
  .stepTitle {
    font-size: 18px;
  }
  .stepContentTitle {
    font-size: 18px;
  }
  .applicationAgreement {
    margin-top: 50px;
    padding-top: 20px;
    margin-right: 50px;
  margin-left: 50px;
  }
  .managerLandlord {
    min-width: 350px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 1270px) and (max-width: 1450px) {
  .applicationCard {
    height: 340px !important;
  }
  .applicationCard2 {
    height: 340px !important;
  }
  .dateInputIcon{
    right:25px;
    top:22px;
  }

  .headApplicationReview {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.7px;
  }
  .optionInfo{
    min-width: 1000px;
    max-width: 800px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 24px;
  }
  .inputContainer {
    padding: 55px;
    padding-right: 80px;
    padding-left: 80px;
  }
  .inputContainer1 {
    padding-left: 80px;
    padding-right:80px;
  }
  .subTitle {
    padding-left: 60px;
  }
  .stepTitle {
    font-size: 16px;
  }
  .stepContentTitle {
    font-size: 18px;
  }
  .managerLandlord {
    min-width: 350px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
  .applicationAgreement {
    padding-right: 50px;
    padding-left: 50px;
    padding-top: 20px;

    margin-right: 50px;
  margin-left: 50px;
  }
}


@media only screen and (min-width: 1150px) and (max-width: 1270px) {
  .applicationCard {
    height: 360px !important;
  }
  .applicationCard2 {
    height: 360px !important;
  }
  .dateInputIcon{
    right:25px;
    top:22px;
  }

  .headApplicationReview {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.7px;
  }
  .optionInfo{
    min-width: 900px;
    max-width: 700px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 23px;
  }
  .inputContainer {
    padding: 60px;
  }
  .inputContainer1 {
    padding-left: 60px;
    padding-right:60px;
  }
  .subTitle {
    padding-left: 60px;
  }
  .stepTitle {
    font-size: 16px;
  }
  .stepContentTitle {
    font-size: 17px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
  .managerLandlord {
    min-width: 450px;
  }
  .QuarterText {
    min-width: 200px;
  }
  .agreementLogoDiv {
    min-width: 550px;
  }
  .applicationAgreement {
    padding-right: 50px;
    padding-left: 50px;
    padding-top: 20px;

    margin-right: 50px;
  margin-left: 50px;
  }
}

@media only screen and (min-width: 850px) and (max-width: 1150px) {
  .dateInputIcon{
    right:25px;
    top:22px;
  }
  .applicationCard {
    height: 580px !important;
  }
  .applicationCard2 {
    height: 550px !important;
  }
  .headApplicationReview {
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.7px;
  }
  .optionInfo{
    min-width: 700px;
    max-width: 500px;
  }
  .tableLeftPad {
    padding-left: 13px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 21px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }
  .inputContainer {
    padding: 40px;
  }
  .inputContainer1 {
    padding-left: 40px;
    padding-right:40px;
  }
  .subTitle {
    padding-left: 40px;
  }

  .stepContentTitle {
    font-size: 15px;
  }

  .stepContentTitle {
    font-size: 17px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
  .applicationAgreement {
    padding-right: 50px;
    padding-left: 50px;
    padding-top: 20px;

    margin-right: 50px;
  margin-left: 50px;
  }
  .managerLandlord {
    min-width: 420px;
  }
  .span7 {
    font-size: 12px;
  }

  .span8 {
    font-size: 12px;
  }
  .applicationAgreementHeadRight {
    /* padding:0px; */
    min-width: 400px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .QuarterText {
    min-width: 200px;
  }
  .agreementLogoDiv {
    min-width: 550px;
  }
}
@media only screen and (min-width: 770px) and (max-width: 850px) {
  .applicationCard {
    height: 600px !important;
   
  }
  .applicationCard2 {
    height: 550px !important;
  }
  .tableLeftPad {
    padding-left: 13px;
  }
  .optionInfo{
    min-width: 550px;
    max-width: 400px;
  }
  .headApplicationReview {
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 21px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }

  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }

  .inputContainer {
    padding: 30px;
  }
  .inputContainer1 {
    padding-left: 30px;
    padding-right:30px;
  }
  .subTitle {
    padding-left: 30px;
  }
  .stepTitle {
    font-size: 15px;
  }
  .stepContentTitle {
    font-size: 17px;
  }

  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
  .applicationAgreement {
    margin-top: 30px;
    padding-right: 50px;
    padding-left: 50px;
    padding-top: 20px;
    margin-left: 50px;
  }
  .managerLandlord {
    min-width: 320px;
  }
  .span7 {
    font-size: 12px;
  }
  .span8 {
    font-size: 12px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .halfBreak {
    min-width: 500px;
  }
  .threeQuarterBreak {
    min-width: 600px;
  }

  .QuarterText {
    min-width: 600px;
  }
  .agreementLogoDiv {
    min-width: 550px;
  }
}
@media only screen and (min-width: 671px) and (max-width: 770px) {

  .applicationCard {
    height: 600px !important;
  }
  .applicationCard2 {
    height: 630px !important;
  }
  .tableResNo2 {
    display: block;
  }
  .tableLeftPad {
    padding-left: 11px;
  }
  .optionInfo{
    min-width: 550px;
    max-width: 400px;
  }
  .headApplicationReview {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 20px;
  }
  .addApp {
    margin-top: -30px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }
  .stepTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 10px !important;
    align-self: center;
    line-height: 10px;
  }
  .stepContentTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 16px !important;
    align-self: center;
    line-height: 10px;
  }
  .stepFormNavTitle {
    padding-top: 25px;
  }
  .inputContainer {
    padding: 20px;
  }
  .inputContainer1 {
    padding-left: 20px;
    padding-right:20px;
  }
  .subTitle {
    padding-left: 20px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 120px;
  }
  .applicationAgreement {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;

  }
  .managerLandlord {
    min-width: 220px;
  }
  .span7 {
    font-size: 12px;
  }
  .span8 {
    font-size: 12px;
  }
  .applicationAgreementHead {
    padding-top: 30px;
    padding-left: 60px;
    padding-right: 20px;
  }
  .applicationAgreementHead2 {
    padding-top: 20px;
    padding-left: 80px;
  }
  .checkedContainer {
    font-size: 11px;
  }
  .agreementCheck {
    min-width: 150px;
  }
  .agreementCheckService {
    min-width: 150px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .applicationAgreementHead3 {
    padding-top: 0px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .halfBreak {
    min-width: 450px;
  }
  .QuarterText {
    min-width: 600px;
  }
  .agreementLogoDiv {
    min-width: 400px;
  }

  .searchListContentApp {
    font-size: 13px;
  }
}

@media only screen and (min-width: 580px) and (max-width: 671px) {
  .dateInputIcon{
    right:20px;
    top:22px;
  }
  .applicationCard {
    height: 600px !important;
  }
  .applicationCard2 {
    height: 630px !important;
  }
  .searchInput{
    font-size: 12px;
  }
  .optionInfo{
    min-width: 450px;
    max-width: 350px;
  }
  .tableResNo2 {
    display: block;
  }
  .pageNumberAlign {
    text-align: center;
  }
  .tableResNo {
    display: block;
  }
  .tableLeftPad {
    padding-left: 11px;
  }
  .headApplicationReview {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 20px;
  }
  .addApp {
    margin-top: -30px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }
  .stepTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 10px !important;
    align-self: center;
    line-height: 10px;
  }
  .stepContentTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 16px !important;
    align-self: center;
    line-height: 10px;
  }
  .stepFormNavTitle {
    padding-top: 25px;
  }
  .inputContainer {
    padding: 20px;
  }
  .inputContainer1 {
    padding-left: 20px;
    padding-right:20px;
  }
  .subTitle {
    padding-left: 20px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 120px;
  }
  .applicationAgreement {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;

  }
  .managerLandlord {
    min-width: 220px;
  }
  .span7 {
    font-size: 11px;
  }
  .span8 {
    font-size: 12px;
  }
  .applicationAgreementHead {
    padding-top: 30px;
    padding-left: 60px;
    padding-right: 20px;
  }
  .applicationAgreementHead2 {
    padding-top: 20px;
    padding-left: 80px;
  }
  .checkedContainer {
    font-size: 11px;
  }
  .agreementCheck {
    min-width: 150px;
  }
  .agreementCheckService {
    min-width: 150px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .applicationAgreementHead3 {
    padding-top: 0px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .halfBreak {
    min-width: 450px;
  }
  .QuarterText {
    min-width: 600px;
  }
  .agreementLogoDiv {
    min-width: 400px;
  }

  .searchListContentApp {
    font-size: 13px;
  }
}
@media only screen and (min-width: 430px) and (max-width: 580px) {

  .dateInputIcon{
    right:28px;
    top:23px;
  }
  .applicationCard {
    height: 600px !important;
  }
  .applicationCard2 {
    height: 660px !important;
  }
  .applicationCardTop{
    margin-top: 40px;
  }
  .mobileStepView{
    padding:0px;
  }
  .optionInfo{
    min-width: 350px;
    max-width: 300px;
  }
  .searchInput{
    font-size: 14px;
  }
  .titleStep {
    display: none;
  }
  .titleStepOpp {
    display: block;
  }
  .pageNumberAlign {
    text-align: center;
  }
  .tableResNo2 {
    display: block;
  }
  .tableResNo {
    display: block;
  }
  .tableLeftPad {
    padding-left: 11px;
  }
  .headApplicationReview {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 19px;
  }
  .informationListAppAlign {
    padding: 30px;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .addApp {
    margin-top: -30px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 11px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }
  .stepTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 12px !important;
    align-self: center;
    line-height: 10px;
  }
  .stepContentTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 16px !important;
    align-self: center;
    line-height: 10px;
  }
  .stepFormNavTitle {
    padding-top: 25px;
  }
  .inputContainer {
    padding: 20px;
  }
  .inputContainer1 {
    padding-left: 20px;
    padding-right:20px;
  }
  .subTitle {
    padding-left: 20px;
    font-size: 15px !important;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
  .applicationAgreement {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
  }

  .span7 {
    font-size: 11px;
  }
  .span8 {
    font-size: 12px;
  }
  .applicationAgreementHeadLeftBox {
    display: none;
  }
  .applicationAgreementHeadLeftBoxMobile {
    display: block;
    min-width: 300px;
  }
  .applicationAgreementHeadRight {
    padding: 0px;
    min-width: 300px;
  }
  .managerLandlord {
    min-width: 220px;
  }
  .applicationAgreementHead {
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 45px;
  }
  .checkedContainer {
    font-size: 11px;
  }
  .agreementCheck {
    min-width: 150px;
  }
  .agreementCheckService {
    min-width: 150px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .applicationAgreementHead3 {
    padding-top: 0px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .halfBreak {
    min-width: 300px;
  }
  .Quarter {
    min-width: 300px;
  }
  .QuarterText {
    min-width: 500px;
  }
  .agreementTableBody {
    margin-right: 15px;
    margin-left: 15px;
  }
  .agreementTableBody1 {
    margin-right: 15px;
    margin-left: 15px;
  }
  .agreementLogoDiv {
    min-width: 300px;
  }
  .applicationAgreementHead4 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .ApplicationInput {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInputAgg {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInput1 {
    height: 45px;
    font-size: 16px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    
  }
  .searchListContentApp {
    font-size: 11px;
  }
}
@media only screen and (min-width: 354px) and (max-width: 430px) {
  .cardApplication{

    margin-right: 1%;
    margin-left: 1%;
    margin-top: 5%;

  }
  .dateInputIcon{
    right:30px;
  }
  .applicationCard {
    height: 600px !important;
  }
  .applicationCard2 {
    height: 660px !important;
  }
  .applicationCardTop{
    margin-top: 40px;
  }
  .mobileStepView{
    padding:0px;
  }
  .optionInfo{
    min-width: 300px;
    max-width: 300px;
  }
  .searchInput{
    font-size: 14px;
  }
  .preEmployeeHide2 {
    display: block;
  }
  .preEmployeeHide1 {
    display: none;
  }
  .titleStep {
    display: none;
  }
  .titleStepOpp {
    display: block;
  }
  .pageNumberAlign {
    text-align: center;
  }
  .tableResNo2 {
    display: block;
  }
  .tableResNo {
    display: block;
  }
  .tableLeftPad {
    padding-left: 8px;
  }
  .headApplicationReview {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 17px;
  }
  .informationListAppAlign {
    padding: 15px;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 11px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .addApp {
    margin-top: -30px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }

  .stepTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    /* align-items: center; */
    font-size: 12px !important;
    align-self: center;
    line-height: 17px;
  }
  .stepContentTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    /* align-items: center; */
    font-size: 16px !important;
    align-self: center;
    line-height: 17px;
  }
  .stepFormNavTitle {
    padding-top: 25px;
  }
  .social-container {
    margin: 20px 0;
  }

  .social-container-step {
    border: 1px solid #dddddd;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 0px;
    height: 30px;
    width: 30px;
  }
  .social {
    font-size: 10px;
  }
  .stepSpan {
    font-family: "Montserrat", sans-serif;
    font-size: 8px;
    white-space: nowrap;
  }
  .inputContainer {
    padding: 10px;
    padding-top: 25px;
  }
  .inputContainer1 {
    padding-left: 10px;
    padding-right:10px;
  }
  .subTitle {
    padding-left: 10px;
    font-size: 15px !important;
  }

  .span7 {
    font-size: 10px;
  }
  .span8 {
    font-size: 11px;
  }
  .applicationAgreementHeadLeftBox {
    display: none;
  }
  .applicationAgreementHeadLeftBoxMobile {
    display: block;
    min-width: 200px;
  }
  .applicationAgreementHeadRight {
    padding: 0px;

    min-width: 200px;
  }
  .managerLandlord {
    min-width: 220px;
  }
  .applicationAgreementHead {
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 35px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 30px;
  }
  .applicationAgreement {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 20px;
  }
  .agreementLogo {
    width: 150px;
  }
  .checkedContainer {
    font-size: 11px;
  }
  .agreementCheck {
    min-width: 150px;
  }
  .agreementCheckService {
    min-width: 150px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .applicationAgreementHead3 {
    padding-top: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .halfBreak {
    min-width: 200px;
  }
  .Quarter {
    min-width: 200px;
  }
  .QuarterText {
    min-width: 200px;
  }
  .agreementTableBody {
    margin-right: 5px;
    margin-left: 5px;
  }
  .agreementTableBody1 {
    margin-right: 5px;
    margin-left: 5px;
  }
  .agreementLogoDiv {
    min-width: 200px;
  }
  .applicationAgreementHead4 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .ApplicationInput {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInputAgg {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInput1 {
    height: 35px;
    font-size: 14px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    min-width:220px;
  }
  .searchListContentApp {
    font-size: 11px;
  }
  .applicationCardAlign{
    min-width: 420px !important;
  }
}
@media only screen and (min-width: 00px) and (max-width: 353px) {
  .cardApplication{

    margin-right: 1%;
    margin-left: 1%;
    margin-top: 5%;
  }
  .dateInputIcon{
    right:30px;
  }
  .applicationCard {
    height: 600px !important;
  }
  .applicationCard2 {
    height: 720px !important;
  }
  .applicationCardTop{
    margin-top: 40px;
  }
  .mobileStepView{
    padding:0px;
  }
  .optionInfo{
    min-width: 300px;
    max-width: 300px;
  }
  .searchInput{
    font-size: 14px;
  }
  .preEmployeeHide2 {
    display: block;
  }
  .preEmployeeHide1 {
    display: none;
  }
  .titleStep {
    display: none;
  }
  .titleStepOpp {
    display: block;
  }
  .pageNumberAlign {
    text-align: center;
  }
  .tableResNo2 {
    display: block;
  }
  .tableResNo {
    display: block;
  }
  .tableLeftPad {
    padding-left: 8px;
  }
  .headApplicationReview {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 17px;
  }
  .informationListAppAlign {
    padding: 15px;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 11px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .addApp {
    margin-top: -30px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }

  .stepTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    /* align-items: center; */
    font-size: 12px !important;
    align-self: center;
    line-height: 17px;
  }
  .stepContentTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    /* align-items: center; */
    font-size: 14px !important;
    align-self: center;
    line-height: 17px;
  }
  .stepFormNavTitle {
    padding-top: 25px;
  }
  .social-container {
    margin: 20px 0;
  }

  .social-container-step {
    border: 1px solid #dddddd;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 0px;
    height: 30px;
    width: 30px;
  }
  .social {
    font-size: 10px;
  }
  .stepSpan {
    font-family: "Montserrat", sans-serif;
    font-size: 8px;
    white-space: nowrap;
  }
  .inputContainer {
    padding: 10px;
    padding-top: 25px;
  }
  .inputContainer1 {
    padding-left: 10px;
    padding-right:10px;
  }
  .subTitle {
    padding-left: 10px;
    font-size: 15px !important;
  }

  .span7 {
    font-size: 10px;
  }
  .span8 {
    font-size: 11px;
  }
  .applicationAgreementHeadLeftBox {
    display: none;
  }
  .applicationAgreementHeadLeftBoxMobile {
    display: block;
    min-width: 200px;
  }
  .applicationAgreementHeadRight {
    padding: 0px;

    min-width: 200px;
  }
  .managerLandlord {
    min-width: 220px;
  }
  .applicationAgreementHead {
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 35px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 30px;
  }
  .applicationAgreement {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 20px;
  }
  .agreementLogo {
    width: 150px;
  }
  .checkedContainer {
    font-size: 11px;
  }
  .agreementCheck {
    min-width: 150px;
  }
  .agreementCheckService {
    min-width: 150px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .applicationAgreementHead3 {
    padding-top: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .halfBreak {
    min-width: 200px;
  }
  .Quarter {
    min-width: 200px;
  }
  .QuarterText {
    min-width: 200px;
  }
  .agreementTableBody {
    margin-right: 5px;
    margin-left: 5px;
  }
  .agreementTableBody1 {
    margin-right: 5px;
    margin-left: 5px;
  }
  .agreementLogoDiv {
    min-width: 200px;
  }
  .applicationAgreementHead4 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .ApplicationInput {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInputAgg {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInput1 {
    height: 35px;
    font-size: 14px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    min-width:220px;
  }
  .searchListContentApp {
    font-size: 11px;
  }
  .applicationCardAlign{
    min-width: 420px !important;
  }
}
