.maintenanceRequestInvitationCount{
  background-color: #ffd6bfc3;
  padding: 5px 10px;
  border-radius: 50%;
  font-family: "Montserrat", sans-serif;  
  font-size: 13px;
  font-weight: 800;
}

   .maintenanceProviderContainer{
     padding-left: 3%;
     padding-right: 3%;
     padding-top: 2%;
     padding-bottom: 2%;
     
   }
   .invitationDivider{
    background-color: rgb(239, 239, 239);
    margin-block-start: 0.1em;
    margin-block-end: 0.1em;
    border: none;
    height: 1px;
   }
   
   .requestInvitationDivider{
    padding-left:10px !important;
    margin-right: 10px !important;
}

.requestInvitationText{
    padding-top: 3%;
    padding-bottom: 0%;
  padding-right: 4%;
  overflow-y: scroll;
  max-height:600px;
}

.requestInvitationText::-webkit-scrollbar {
width: 2px;
height: 2px;
}
.requestInvitationText::-webkit-scrollbar-track {
background: rgb(255, 255, 255);
}
.requestInvitationText::-webkit-scrollbar-thumb {
background: #ff6d53;
}
.requestInvitationText::-webkit-scrollbar {
width: 2px;
height: 0px;
}
.requestInvitationText::-webkit-scrollbar-track {
background: rgba(189, 189, 189, 0.01);
}
.requestInvitationText::-webkit-scrollbar-thumb {
background: #ff6d53;
}
.maintenanceRequestInvitationImage{
  height: 35px;
  width: 35px;
  border-radius: 50%;

}
.maintenanceRequestDetailIcon{
  height: 20px;
  width: 20px;
  margin-top: -2% !important;
}

.maintenanceInvitationAlign{
  border-right: 1px solid rgb(239, 239, 239);
  border-left: 1px solid rgb(239, 239, 239);
  width:15%;
  text-align: center;
  padding-top: 11px ;
}
.maintenanceInvitationHourAlign{
width:25%;
border-left: 1px solid rgb(239, 239, 239);
}
.maintenanceProviderInvitationNameAlign{
  width:40%;
  align-self: center;
}
.maintenanceProviderInvitationImageAlign{
  width:12%;
  align-self: center;
  margin-top: -1% !important;
}
.maintenanceInvitationProviderIconAlign{
  width:5%;
  padding-left: 3%;

  margin-top: -2% !important;
  align-self: center;
  text-align: right;
  
}
@media only screen and   (min-width:10px) and (max-width: 321px){
    .requestInvitationDivider{
         opacity: 0;
    }
   .requestInvitationText{
      margin-top:15%;
   }
}

@media only screen and (min-width: 1200px) and (max-width: 1250px),(min-width:768px) and (max-width: 992px), (min-width:10px) and (max-width: 485px){
  .maintenanceProviderInvitationNameAlign{
    width:70%;
    align-self: center;
  }
  .maintenanceProviderInvitationImageAlign{
    width:30%;
    align-self: center;
    margin-top: -1% !important;
    padding-left: 5%;
  }
  .maintenanceInvitationHourAlign{
    width:60%;
    border-left: none;
    padding-left: 15%;
    }
    .maintenanceInvitationProviderIconAlign{
      width:20%;
    }
    
    .maintenanceInvitationAlign{
      border-right: none;
      border-left: none;
      width:15%;
      text-align: center;
    
    }
}