@-webkit-keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    visibility: visible;
  }
}
@-moz-keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    visibility: visible;
  }
}
@keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    visibility: visible;
  }
}
@-webkit-keyframes animate-height {
  0% {
    height: 0;
  }
  100% {
    visibility: visible;
  }
}
@-moz-keyframes animate-height {
  0% {
    height: 0;
  }
  100% {
    visibility: visible;
  }
}
@keyframes animate-height {
  0% {
    height: 0;
  }
  100% {
    visibility: visible;
  }
}
.curve1 {
  background-color: #ffffff;
  height: 50px;
  border-top-right-radius: 28px;
  border-top-left-radius: 28px;
 
box-shadow: 0px 0px 0px rgb(255, 255, 255), 0px -5px 5px rgb(245, 245, 245);
}
.curve2 {
  border-bottom-left-radius:28px ;
  /* box-shadow: 0px 0px 0px rgb(255, 255, 255),0px 0px 0px rgb(255, 255, 255),0px 0px 0px rgb(255, 255, 255), 0px 27px 0px rgb(255, 255, 255); */
  height: 50px;
  padding: 0px;
  margin: 0px;

}
.curve3 {
  /* background-color: #f3f4f5; */
  height: 50px;
  padding: 0px;
  margin: 0px;
  border-bottom-left-radius: 28px;
}
#bar-chart {
  height: 250px;
  width: 100%;
  position: relative;
  margin-top: 30px;
  margin-left: 50px;
  font-family: "Montserrat", sans-serif;
  color: #a2a2a2;
}
#bar-chart * {
  box-sizing: border-box;
}
#bar-chart .graph {
  height: 233px;
  position: relative;
}
#bar-chart .bars {
  height: 203px;
  padding: 0 2%;
  position: absolute;
  width: 100%;
  z-index: 10;
}
#bar-chart .bar-group {
  display: block;
  float: left;
  height: 100%;
  position: relative;
  width: 10%;
  margin-right: 5%;
}
#bar-chart .bar-group:last-child {
  margin-right: 0;
}
#bar-chart .bar-group .bar {
  visibility: hidden;
  height: 0;
  -webkit-animation: animate-height;
  -moz-animation: animate-height;
  animation: animate-height;
  animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  -webkit-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  -moz-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  animation-duration: 0.4s;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.15);
  /* border: 1px solid #2d2d2d; */
  border-radius: 10px 10px 0 0;
  bottom: 0;
  cursor: pointer;
  height: 0;
  position: absolute;
  text-align: center;
  width: 18%;
}
#bar-chart .bar-group .bar:nth-child(2) {
  left: 30%;
}
#bar-chart .bar-group .bar:nth-child(3) {
  left: 60%;
}
#bar-chart .bar-group .bar span {
  display: none;
}
#bar-chart .bar-group .bar-1 {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
#bar-chart .bar-group .bar-2 {
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}
#bar-chart .bar-group .bar-3 {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}
#bar-chart .bar-group .bar-4 {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}
#bar-chart .bar-group .bar-5 {
  animation-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
}
#bar-chart .bar-group .bar-6 {
  animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
}
#bar-chart .bar-group .bar-7 {
  animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
}
#bar-chart .bar-group .bar-8 {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
#bar-chart .bar-group .bar-9 {
  animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
}
#bar-chart .bar-group .bar-10 {
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}
#bar-chart .bar-group .bar-11 {
  animation-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
}
#bar-chart .bar-group .bar-12 {
  animation-delay: 1.4s;
  -webkit-animation-delay: 1.4s;
}
#bar-chart .bar-group .bar-13 {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
}
#bar-chart .bar-group .bar-14 {
  animation-delay: 1.6s;
  -webkit-animation-delay: 1.6s;
}
#bar-chart .bar-group .bar-15 {
  animation-delay: 1.7s;
  -webkit-animation-delay: 1.7s;
}
#bar-chart .bar-group .bar-16 {
  animation-delay: 1.8s;
  -webkit-animation-delay: 1.8s;
}
#bar-chart .bar-group .bar-17 {
  animation-delay: 1.9s;
  -webkit-animation-delay: 1.9s;
}
#bar-chart .bar-group .bar-18 {
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
}

#bar-chart .bar-group .bar-19 {
  animation-delay: 2.1s;
  -webkit-animation-delay: 2.1s;
}
#bar-chart .bar-group .bar-20 {
  animation-delay: 2.2s;
  -webkit-animation-delay: 2.2s;
}
#bar-chart .bar-group .bar-21 {
  animation-delay: 2.3s;
  -webkit-animation-delay: 2.3s;
}
#bar-chart .bar-group .bar-22 {
  animation-delay: 2.4s;
  -webkit-animation-delay: 2.4s;
}
#bar-chart .bar-group .bar-23 {
  animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
}
#bar-chart .bar-group .bar-24 {
  animation-delay: 2.6s;
  -webkit-animation-delay: 2.6s;
}
#bar-chart .bar-group .bar-25 {
  animation-delay: 2.7s;
  -webkit-animation-delay: 2.7s;
}
#bar-chart .bar-group .bar-26 {
  animation-delay: 2.8s;
  -webkit-animation-delay: 2.8s;
}
#bar-chart .bar-group .bar-27 {
  animation-delay: 2.9s;
  -webkit-animation-delay: 2.9s;
}
#bar-chart .bar-group .bar-28 {
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
}
#bar-chart .bar-group .bar-29 {
  animation-delay: 3.1s;
  -webkit-animation-delay: 3.1s;
}
#bar-chart .bar-group .bar-30 {
  animation-delay: 3.2s;
  -webkit-animation-delay: 3.2s;
}
#bar-chart .bar-group .bar-31 {
  animation-delay: 3.3s;
  -webkit-animation-delay: 3.3s;
}
#bar-chart .bar-group .bar-32 {
  animation-delay: 3.4s;
  -webkit-animation-delay: 3.4s;
}
#bar-chart .bar-group .bar-33 {
  animation-delay: 3.5s;
  -webkit-animation-delay: 3.5s;
}
#bar-chart .bar-group .bar-34 {
  animation-delay: 3.6s;
  -webkit-animation-delay: 3.6s;
}
#bar-chart .bar-group .bar-35 {
  animation-delay: 3.7s;
  -webkit-animation-delay: 3.7s;
}
#bar-chart .bar-group .bar-36 {
  animation-delay: 3.8s;
  -webkit-animation-delay: 3.8s;
}
#bar-chart ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#bar-chart .x-axis {
  bottom: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
#bar-chart .x-axis li {
  float: left;
  margin-right: 3%;
  font-size: 11px;
  width: 11.5%;
}
#bar-chart .x-axis li:last-child {
  margin-right: 0;
}
#bar-chart .y-axis {
  position: absolute;
  text-align: right;
  width: 100%;
}
#bar-chart .y-axis li {
  border-top: 1px solid #e8eaed;
  display: block;
  height: 40.25px;
  width: 100%;
}
#bar-chart .y-axis li span {
  display: block;
  font-size: 11px;
  margin: -10px 0 0 -60px;
  padding: 0 0px;
  width: 40px;
}
#bar-chart .stat-1 {
  background-image: -webkit-linear-gradient(
    left,
    #7777ff 0%,
    #7777ff 47%,
    #7777ff 50%,
    #7777ff 100%
  );
  background-image: linear-gradient(
    to right,
    #7777ff 0%,
    #7777ff 47%,
    #7777ff 50%,
    #7777ff 100%
  );
}

#bar-chart .stat-1-Disable {
  background-image: -webkit-linear-gradient(
    left,
    rgb(98, 98, 98) 0%,
    rgb(98, 98, 98) 47%,
    rgb(98, 98, 98) 50%,
    rgb(98, 98, 98) 100%
  );
  background-image: linear-gradient(
    to right,
    rgb(98, 98, 98) 0%,
    rgb(98, 98, 98) 47%,
    rgb(98, 98, 98) 50%,
    rgb(98, 98, 98) 100%
  );
}
#bar-chart .stat-2 {
  background-image: -webkit-linear-gradient(
    left,
    #feb342 0%,
    #feb342 47%,
    #feb342 50%,
    #feb342 100%
  );
  background-image: linear-gradient(
    to right,
    #feb342 0%,
    #feb342 47%,
    #feb342 50%,
    #feb342 100%
  );
}
#bar-chart .stat-2-Disable {
  background-image: -webkit-linear-gradient(
    left,
    rgb(141, 141, 141) 0%,
    rgb(141, 141, 141) 47%,
    rgb(141, 141, 141) 50%,
    rgb(141, 141, 141) 100%
  );
  background-image: linear-gradient(
    to right,
    rgb(141, 141, 141) 0%,
    rgb(141, 141, 141) 47%,
    rgb(141, 141, 141) 50%,
    rgb(141, 141, 141) 100%
  );
}
#bar-chart .stat-3 {
  background-image: -webkit-linear-gradient(
    left,
    #ff7be5 0%,
    #ff7be5 47%,
    #ff7be5 50%,
    #ff7be5 100%
  );
  background-image: linear-gradient(
    to right,
    #ff7be5 0%,
    #ff7be5 47%,
    #ff7be5 50%,
    #ff7be5 100%
  );
}
#bar-chart .stat-3-Disable {
  background-image: -webkit-linear-gradient(
    left,
    #cecece 0%,
    #cecece 47%,
    #cecece 50%,
    #cecece 100%
  );
  background-image: linear-gradient(
    to right,
    #cecece 0%,
    #cecece 47%,
    #cecece 50%,
    #cecece 100%
  );
}
.bg-paymentGraph1 {
  background-color: #7777ff;
}
.bg-paymentGraph2 {
  background-color: #feb342;
}
.bg-paymentGraph3 {
  background-color: #ff7be5;
}

.graphLabel {
  margin: 20px;
}

.paymentGraphBigScreen1 {
  display: none !important;
}
.paymentGraphBigScreen2 {
  display: none !important;
}

@media only screen and (min-width: 1440px) and (max-width: 1900px) {
  .paymentGraphBigScreen1 {
    display: block !important;
  }
  #bar-chart .bar-group {
    width: 6%;
    margin-right: 5%;
  }
  #bar-chart .x-axis li {
    margin-right: 3%;
    width: 7.6%;
  }
}
@media only screen and (min-width: 1900px) and (max-width: 5000px) {
  .paymentGraphBigScreen2 {
    display: block !important;
  }
  .paymentGraphBigScreen1 {
    display: block !important;
  }
  #bar-chart .bar-group {
    width: 4%;
    margin-right: 4%;
  }
  #bar-chart .x-axis li {
    margin-right: 3%;
    width: 4.8%;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1100px) {
  .paymentGraphBigScreen2 {
    display: block !important;
  }
  .paymentGraphBigScreen1 {
    display: block !important;
  }
  #bar-chart .bar-group {
    width: 4%;
    margin-right: 4%;
  }
  #bar-chart .x-axis li {
    margin-right: 3%;
    width: 4.8%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 800px) {
  .paymentGraphBigScreen1 {
    display: block !important;
  }
  #bar-chart .bar-group {
    width: 6%;
    margin-right: 5%;
  }
  #bar-chart .x-axis li {
    margin-right: 3%;
    width: 7.6%;
  }
}
@media only screen and (min-width: 380px) and (max-width: 500px) {
  .paymentGraphMobile1 {
    display: none !important;
  }
  #bar-chart .bar-group {
    width: 14%;
    margin-right: 5%;
  }
  #bar-chart .x-axis li {
    margin-right: 3%;
    width: 15.1%;
  }
  .paymentHead {
    font-size: 13px;
  }

  .ApplicationInputSelectNormal {
    font-size: 12px;
  }

  .ApplicationInputOptionNormal {
    font-size: 11px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 380px) {
  .paymentGraphMobile2 {
    display: none !important;
  }
  .paymentGraphMobile1 {
    display: none !important;
  }
  #bar-chart .bar-group {
    width: 17%;
    margin-right: 5%;
  }
  #bar-chart .x-axis li {
    margin-right: 3%;
    width: 18.1%;
  }
  .paymentHead {
    font-size: 10px;
  }
  .ApplicationInputSelectNormal {
    font-size: 12px;
  }
  .addStripePayment {
    margin-left: 0px;
  }

  .ApplicationInputOptionNormal {
    font-size: 11px;
  }
}
