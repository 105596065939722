.card__image {
    height: 160px;
    width: 160px;
    border-radius: 50%;
    border: 5px solid #244d74;
    box-shadow: 0 3px 15px rgb(83, 130, 172);
   
  }
  .detailsCard{
    border-radius: 15px;
    width:500px;
    /* background: linear-gradient(to bottom right, #feffff 0%, #aabdcf 100%); */
    background-color: #fff;
  } 
  .detailsCard1{
    border-radius: 15px;
    width:250px;
    /* background: linear-gradient(to bottom right, #feffff 0%, #aabdcf 100%); */
    background-color: #fff;
  } 
  .detailsText{
    white-space: nowrap;
    color:#26445f;
    font-weight: 1500;
  }