.progressProfileApp {
  width: 150px;
  height: 150px !important;
  float: left;
  line-height: 150px;
  background: none;
  margin: 20px;
  box-shadow: none;
  position: relative;
}

.progressProfileApp:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.progressProfileApp > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progressProfileApp .progressProfileApp-left {
  left: 0;
}

.progressProfileApp .progressProfileApp-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 8px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progressProfileApp .progressProfileApp-left .progressProfileApp-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progressProfileApp .progressProfileApp-right {
  right: 0;
}

.progressProfileApp .progressProfileApp-right .progressProfileApp-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progressProfileApp.blue .progressProfileApp-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  /* background: #000; */
  font-size: 24px;
  color: #ffffff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-image: url("../../../Images/profileFill.jpeg");
  background-position: center;
  background-size: cover;
  text-shadow: 0 0 5px #000;
}
.progressProfileApp.red .progressProfileApp-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  /* background: #000; */
  font-size: 24px;
  color: #ffffff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-image: url("../../../Images/profileFill.jpeg");
  background-position: center;
  background-size: cover;
  text-shadow: 0 0 5px #000;
}

.progressProfileApp.blue .progressProfileApp-bar {
  border-color: #dfc4ff;
}

.progressProfileApp.red .progressProfileApp-bar {
  border-color: #ff5d5d;
}

.account-settings .user-profile {
  margin: 0 0 1rem 0;
  padding-bottom: 1rem;
  text-align: center;
}
.account-settings .user-profile .user-avatar {
  margin: 0 0 1rem 0;
}
.account-settings .user-profile .user-avatar img {
  width: 140px;
  height: 140px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}
.account-settings .user-profile h5.user-name {
  margin: 0 0 0.5rem 0;
}
.account-settings .user-profile h6.user-email {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 400;
}
.account-settings .about {
  margin: 1rem 0 0 0;
  font-size: 0.8rem;
  text-align: center;
}
.profileCard {
  background: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 15px;
  border: 0;
  margin-bottom: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.box {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.effect7 {
  position: relative;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.effect7:before,
.effect7:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 10px;
  right: 10px;
  -moz-border-radius: 100px / 10px;
  border-radius: 100px / 10px;
}
.effect7:after {
  right: 10px;
  left: auto;
  -webkit-transform: skew(8deg) rotate(3deg);
  -moz-transform: skew(8deg) rotate(3deg);
  -ms-transform: skew(8deg) rotate(3deg);
  -o-transform: skew(8deg) rotate(3deg);
  transform: skew(8deg) rotate(3deg);
}
.effect8 {
  position: relative;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3),
    0 0 20px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3),
    0 0 20px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.1) inset;
}
.effect8:before,
.effect8:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
  -moz-border-radius: 100px / 10px;
  border-radius: 100px / 10px;
}
.effect8:after {
  right: 10px;
  left: auto;
  /* -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg); */
}
.inputProfile {
  border: 1px solid #596280;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 0.825rem;
  background: rgba(159, 183, 194, 0.22);
  color: #000;
  font-family: "Montserrat", sans-serif;
}
.contentProfile {
  color: #000;
  font-family: "Montserrat", sans-serif;
}
#shop {
  /* background-image: url("images/shop_bg.png"); */
  background-repeat: repeat-x;
  height: 121px;
  width: 984px;
  margin-left: 20px;
  margin-top: 13px;
}

#shop .content {
  width: 182px; /*328 co je 1/3 - 20margin left*/
  height: 121px;
  line-height: 20px;
  margin-top: 0px;
  margin-left: 9px;
  margin-right: 0px;
  display: inline-block;
}
.profileHead {
  color: #f86e56;
  font-family: "Montserrat", sans-serif;
  /* font-family: "Titillium Web", sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
}
.profileLabel {
  color: #000;
  font-family: "Montserrat", sans-serif;
  /* font-family: "Kanit", sans-serif; */
  /* font-family: "Titillium Web", sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
}

button {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  padding: 9px 30px;
  font-weight: bold;

  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

button:active {
  transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}
button.pri {
  background-color: transparent;
  border-color: #ff4b2b;
  color: #ff4b2b;
}
button.pri:hover {
  background-color: #ff4b2b;
  color: #fff;
}
button.pri:active {
  transform: scale(0.95);
}

input.tag {
  font-family: "Montserrat", sans-serif;
  background-color: rgb(241, 241, 241);
  border: none;

  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
input.alert {
  font-family: "Montserrat", sans-serif;
  border: 1px solid #ff4b2b;
}
textarea.alert {
  border: 1px solid #ff4b2b;
  font-family: "Montserrat", sans-serif;
}
div.alert {
  font-family: "Montserrat", sans-serif;
  border: 1px solid #ff4b2b;
}
div.divAlert {
  font-family: "Montserrat", sans-serif;
  border: 3px solid #ff4b2b73;
}
select.alert {
  font-family: "Montserrat", sans-serif;
  border: 1px solid #ff4b2b;
}

@media only screen and (min-device-width: 500px) and (max-device-width: 850px) {

  .profileHead {
    font-size: 18px;
  }
  .profileLabel {
    font-size: 15px;
  }
  .form-control {
    font-size: 15px !important;
  }
}
@media only screen and (min-device-width: 100px) and (max-device-width: 500px) {

  .profileHead {
    font-size: 17px;
  }
  .profileLabel {
    font-size: 15px;
  }
  .form-control {
    font-size: 15px !important;
  }
}
