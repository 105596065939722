.phoneInput{
     position: absolute;
     margin-top: 13px;
     margin-left: 8px;
}
.countryList{
     margin:0px;
     font-family: "Montserrat", sans-serif;
     font-size: 14px;
     font-weight: 100;
     line-height: 20px;
    cursor: pointer;
 }
 .countryList:hover{
     background-color: #c9c0c077;
 }
 .countryList:focus{
     background-color: #fd53539e;
 }
 .phoneInputCode{
     position: absolute;
     margin-top: 4px;
     margin-left: 30px;
     font-family: "Montserrat", sans-serif;
     font-size: 16px;
     font-weight: 100;
     color:#495057;
     line-height: 20px;
 }
 @media only screen and (min-device-width: 500px) and (max-device-width: 850px) {
     .phoneInput{
          margin-top: 10px;
          margin-left: 8px;
     }
   }
   @media only screen and (min-device-width: 100px) and (max-device-width: 500px) {
     .phoneInput{
          margin-top: 5px;
          margin-left: 8px;
     }
   }