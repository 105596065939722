.tableHead {
    white-space: nowrap;
  }
.rowTable{
    width: 100%;
    text-align: left;
    display: inline-table;
    /* white-space: nowrap; */
    table-layout: fixed;
  }
  
  .headName{
    text-align: left;
    color:rgb(243, 243, 243) !important;
    font-family: 'Montserrat', sans-serif;
  }
  .tableRental1 {
    min-height: 510px;
  }
  thead {
    overflow-y: scroll;
    width: 100%;
    background-color: #eb6658 ;
  }
 .tHeadType {
    background-color: #eb6658 ;
    border: none;
    border-radius: 15px;
    /* border-top-right-radius: 15px; */
    color:#fff;
  }
  .tHeadType1 {
    background-color: #fff ;
    border: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color:#eb6658;
  }
  .table{
    overflow-y: scroll; 
    width: 100%;     
    /* height: 1000px;             */
    /* position: absolute; */
  }
  .table::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .table::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .table::-webkit-scrollbar-thumb {
    background: #aca9a8;
  }
  
  /* Handle on hover */
  .table::-webkit-scrollbar-thumb:hover {
    background: #eb6658;
  }
  .tableYAxis {
  }
  /* width */
  .table::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .table::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .table::-webkit-scrollbar-thumb {
    background: #aca9a8;
  }
  
  /* Handle on hover */
  .table::-webkit-scrollbar-thumb:hover {
    background: #FF4B2B;
  }
  .dropDownSlow{
   
  }

  .rowTable{
   
    table-layout: auto;
  }
  @media only screen and (min-width: 15px) and (max-width: 1280px) {
    .rowTable{
      width: 100%;
      text-align: left;
      display: inline-table;
      /* white-space: nowrap; */
      table-layout: auto;
    }
  }