.maintenanceImage{
     height:300px;
     border-radius: 10px;
     width:100%;
     background-position: center;
     background-size: cover;
     background-repeat: no-repeat;
     background-color: #3e3e3e;

   }
   .maintenanceImage2{
     height:50px;
     border-radius: 10px;
     width:100%;
     background-position: center;
     background-size: cover;
     margin-bottom: 12px;
}
.maintenanceImage4{
    
     height:40rem;
     width:100%;
     background-position: center;
     background-size: contain;
     background-repeat: no-repeat;
     background-color:transparent;

   }
.maintenanceImage2Active{
     height:50px;
     border-radius: 10px;
     border: 4px solid #f84b4b97;
     width:100%;
     background-position: center;
     background-size: cover;
     margin-bottom: 12px;
}

   .maintenanceImage3{
     height:50px;
     width:65px;
     border-radius: 10px;
     background-position: center;
     background-size: cover;
     margin-right: 12px;
     margin-bottom: 12px;
     margin-top: 12px;
     display: inline-block; 
}

.maintenanceImage3Active{
     height:50px;
     width:65px;
     border-radius: 10px;
     border: 4px solid #f84b4b97;
     
     background-position: center;
     background-size: cover;
     margin-right: 12px;
     margin-top: 12px;
     margin-bottom: 12px;
     display: inline-block; 
}
.maintenanceImage5{
     height:70px;
     width:125px;
     border-radius: 7px;
     background-position: center;
     background-size: cover;
     margin-right: 12px;
     display: inline-block; 
}


     .maintenanceImageCollection{
          max-height:300px;
          overflow: scroll;
     }
   .maintenanceImageCollection::-webkit-scrollbar {
     width: 1px;
      height: 0px;
   }
   .maintenanceImageCollection::-webkit-scrollbar-track {
     background: #f1f1f1;
   }
   .maintenanceImageCollection::-webkit-scrollbar-thumb {
     background: #fd753f36;
   }
   .maintenanceImageCollection::-webkit-scrollbar-thumb:hover {
     background: #918c8b;
   }
   .imageViewerIconClose{
     color:rgb(255, 255, 255);
     font-size: 20px;
     cursor: pointer;
     position: absolute;
     top: 0%;
     right:0%;
   }
   .imageViewerIconClose2{
     color:rgb(255, 255, 255);
     font-size: 20px;
     cursor: pointer;
     position: absolute;
     top: 5%;
     right:5%;
   }
   .imageViewerIconExpand{
     color:rgb(255, 255, 255);
     font-size: 20px;
     cursor: pointer;
     position: absolute;
     top: 5%;
     right:3%;
   }
   .imageViewerIconRight{
        color:rgba(255, 255, 255, 0.885);
        font-size: 50px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right:10px;
        text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.165),
                          1px 1px 0 rgba(0, 0, 0, 0.165),
                         1px -1px 0 rgba(0, 0, 0, 0.165),
                        -1px -1px 0 rgba(0, 0, 0, 0.165);
   }
   .imageViewerIconRight:hover{
          color:rgb(255, 255, 255);
          text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.165),
                          1px 1px 0 rgba(0, 0, 0, 0.165),
                         1px -1px 0 rgba(0, 0, 0, 0.165),
                        -1px -1px 0 rgba(0, 0, 0, 0.165);
   }
   .imageViewerIconRightDisable{
     color:rgba(255, 255, 255, 0.416);
     font-size: 50px;
     cursor:  not-allowed;
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
     right:10px;
     text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.165),
     1px 1px 0 rgba(0, 0, 0, 0.165),
    1px -1px 0 rgba(0, 0, 0, 0.165),
   -1px -1px 0 rgba(0, 0, 0, 0.165);
}
   .imageViewerIconLeft{
     color:rgba(255, 255, 255, 0.885);
          font-size: 50px;
          cursor: pointer;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left:10px;
          text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.165),
                          1px 1px 0 rgba(0, 0, 0, 0.165),
                         1px -1px 0 rgba(0, 0, 0, 0.165),
                        -1px -1px 0 rgba(0, 0, 0, 0.165);
     }
     .imageViewerIconLeft:hover{
          color:rgb(255, 255, 255);
          text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.165),
                          1px 1px 0 rgba(0, 0, 0, 0.165),
                         1px -1px 0 rgba(0, 0, 0, 0.165),
                        -1px -1px 0 rgba(0, 0, 0, 0.165);
     }
     .imageViewerIconLeftDisable{
          color:rgba(255, 255, 255, 0.416);
          font-size: 50px;
          cursor:  not-allowed;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left:10px;
          text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.165),
                          1px 1px 0 rgba(0, 0, 0, 0.165),
                         1px -1px 0 rgba(0, 0, 0, 0.165),
                        -1px -1px 0 rgba(0, 0, 0, 0.165);
     }
   .maintenanceImageCollection2{
          max-height: 80px;
          overflow-y: auto;
          display: none;
          width: 100%;
          white-space: nowrap;
     }
     .maintenanceImageCollection2::-webkit-scrollbar {
          width: 0px;
          height: 1px;
     }
     .maintenanceImageCollection2::-webkit-scrollbar-track {
     background: #f1f1f1;
     }
     .maintenanceImageCollection2::-webkit-scrollbar-thumb {
     background: #fd753f36;
     }
     .maintenanceImageCollection2::-webkit-scrollbar-thumb:hover {
     background: #918c8b;
     }

     .maintenanceImageCollection3{
          /* max-height:300px; */
          overflow-y: auto;
          width: 100%;
          white-space: nowrap;
     }
     .imageViewerRightArrow{
         align-content: center;
           align-self: center;
           text-align: center;
           color: #918c8b;
           font-size: 13px;
           cursor: pointer;
           width: 30px;
     }
     .maintenanceImageCollection3::-webkit-scrollbar {
          width: 0px;
          height: 1px;
     }
     .maintenanceImageCollection3::-webkit-scrollbar-track {
     background: #f1f1f1;
     }
     .maintenanceImageCollection3::-webkit-scrollbar-thumb {
     background: #fd753f36;
     }
     .maintenanceImageCollection3::-webkit-scrollbar-thumb:hover {
     background: #918c8b;
     }

     .maintenanceImageCollection4{
          max-height: 80px;
          overflow-y: auto;
          width: 100%;
          white-space: nowrap;
          /* margin-top: -50px; */
          position: absolute;
          bottom: 0%;
          left: 5%;
     }
     .maintenanceImageCollection4::-webkit-scrollbar {
          width: 0px;
          height: 1px;
     }
     .maintenanceImageCollection4::-webkit-scrollbar-track {
     background: #f1f1f1;
     }
     .maintenanceImageCollection4::-webkit-scrollbar-thumb {
     background: #fd753f36;
     }
     .maintenanceImageCollection4::-webkit-scrollbar-thumb:hover {
     background: #918c8b;
     }
     .viewerModal{
          max-width: auto !important;
     }
   @media only screen and (min-width: 00px) and (max-width: 380px) {
     .maintenanceImageCollection{
         display: none;
     }
     .maintenanceImageCollection2{
          display: block;
      }
      .maintenanceImage4{
    
          height:18em;

      }
   }
   @media only screen and (min-width: 380px) and (max-width: 580px) {
     .maintenanceImageCollection{
         display: none;
     }
     .maintenanceImageCollection2{
          display: block;
      }
      .maintenanceImage4{
    
          height:23rem;

      }
   }
   @media only screen and (min-width: 580px) and (max-width: 880px) {

      .maintenanceImage4{
    
          height:29rem;

      }
   }
   @media only screen and (min-width: 880px) and (max-width: 1250px) {

     .maintenanceImage4{
   
         height:30rem;

     }
  }