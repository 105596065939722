.addprops{
     margin-top: 80px;
     padding-top: 20px;
     margin-bottom: 20%;
}
.dropHead{
     font-family: "Montserrat", sans-serif;
	font-size:29px;
	font-weight: 1950;
	font-weight: bold;
	padding-left:15px;
	/* margin-top:10px; */
	line-height: 20px;
     
     color: rgba(255, 243, 243, 0.87);
}
.dropContent1{
     font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	line-height: 1px;
}
.dropContent2{
     font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	line-height: 1px;
     font-weight: 900;
}
.dropContent3{
     font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	line-height: 1px;
     color: rgb(243, 69, 69);
     font-weight: 900;
}