header img {
    height: 80px;
    margin-left: 40px;
}

header {
    background-color: white;
   
    /* background-color: rgb(237, 237, 237); */
    position:static ;
    top:0;
    left: 0;
    right: 0;
    height:80px;
    display: flex;
    align-items: center;
    
    Z-index:210;
   
}
header.dark{
    background-color: #30302f;
    color:#fff;
}
.navItem {
    background-color: white;
    /* position: fixed; */
    top:0;
    /* left: 0; */
    right: 0;
    
    display: flex;
    align-items: center;
    box-shadow: 0 8px 6px -6px black;
    Z-index:200;
    display:none;
    height:0px;
}

.itemLogo{
    font-size: 50px !important;
    padding-top:100px !important;
}
.itemList{
    font-size: 20px !important;
    line-height: 35px !important;
}
.menuShow{
    animation: show3 0.6s;
    z-index: 2000;
    height: 350px;
    display:block;
 
}
@keyframes show3 {
	0%{
        height: 0px;
        display: block; 
        opacity: 0;
        
	}
	80% {
        opacity: 0.3;
        height: 250px;
        display: block; 
        
	}
	100% {
        opacity: 1;
        height: 300px;
        display: block; 
        
	}
}
.navHead{
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    z-index:200;
 
}
.headerNav{   
    /* background: -webkit-linear-gradient(to right, #b6b5b5, #e2dfe0); */
	/* background: linear-gradient(to right, #b6b5b5, #e2dfe0); */
    animation: show2 0.6s;
    /* height:65px; */
    display: block; 
    
}
.headerNav1{
    
    animation: show1 0.6s;
    display: none; 
    opacity: 0;
}
@keyframes show2 {
	0%{
        display: block; 
        opacity: 0;
       
	}
	
	100% {
        opacity: 1;
        display: block; 
        
	}
}
@keyframes show1 {
	0%{
        opacity: 1;
        display: block; 
	}
	
	100% {
        display: none; 
        opacity: 0;
	}
}
header * {
    display: inline;
    Z-index:210;
}

header li {
    margin: 20px;
}

header li a {
    color: black;
    text-decoration: none;
}
.navNameTag{
    /* font-size: 20px !important; */
    color: rgb(0, 0, 0);
    /* font-weight: 900 !important; */
    font-family: "Montserrat", sans-serif;
}
.navNameTag1{
 
    color: rgb(0, 0, 0);
    font-weight: 600 !important;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
}
.navOne{
    font-family: "Montserrat", sans-serif;
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
    text-decoration: none;
    letter-spacing: 0;
    position:static;
    cursor: pointer;
    /* color:#000; */
}
/* .fa{
    color:#000;
}
.one{
    color:#000;
    
} */
.navOne:hover{
    text-decoration: none;
    color: #FF4B2B;
}
.activeNav{
    color: #FF4B2B;
    font-weight: 00;
    text-decoration: underline;
    text-underline-position: under;
    text-underline-offset: 5px;
}
.navOne:hover,
.navOne:active {
  letter-spacing: 0;
}
.navOne:after,
.navOne:before {
  backface-visibility: hidden;
  border: 1px solid rgba(#FF4B2B, 0);
  bottom: 0px;
  content: " ";
  /* display: block; */
  margin: 0 auto;
  position: relative;
  transition: all 280ms ease-in-out;
  width: 0;
}

.navOne:hover:after,
.navOne:hover:before {
  backface-visibility: hidden;
  border-color: #FF4B2B;
  transition: width 350ms ease-in-out;
  width: 70%;
}

.navOne:hover:before {
  bottom: auto;
  top: 0;
  width: 70%;
}
.searchTap{
    background: linear-gradient(to right, #FF4B2B, #FF416C);
    /* position:absolute; */
    padding: 12px 15px;
    cursor: pointer;
	/* margin: 8px 3px; */
   height:50px;
   color:#fff;
}
.absolute{
    position:absolute;
}
.searchInput1{
    /* text-align:right; */
    font-family: "Montserrat", sans-serif;
    border:none;
    position:absolute!important;
    display: flex;
    right:190px;
    top:-18px;
    width:200px !important;
    z-index:220;
    padding-right:40px!important;
}
.searchInputMobile{
    /* text-align:right; */
    font-family: "Montserrat", sans-serif;
    border:none;
    position:absolute!important;
    display: flex;
    right:250px;
    top:-5px;
    width:200px !important;
    z-index:220;
    padding-right:40px!important;
}

.searchBack{
    z-index:3;
    background-color: #fff;
    background: -webkit-linear-gradient(to right, #a09f9f, #d0cacb);
	background: linear-gradient(to right, #a09f9f, #d0cacb);
    height:0px;
     /* width:100%; */
    opacity: 0;
}
.ss{
    position: fixed;
}
.searchButton{
    cursor: pointer;
    z-index:230;
    margin-top:5px;
    position:absolute;
}
.menu2{
    display:none;
  }
  .menu3{
    display:none;
  }
.absolute{
    position:absolute;
}
.profileList{
    /* padding:0px; */
    
    /* padding:5px; */
    /* padding-left:15px; */
    margin:0px;
    font-family: "Montserrat", sans-serif;
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
   cursor: pointer;
}
.profileList:hover{
    background-color: #c9c0c077;
    
    /* padding:0px; */
}
.profileList:focus{
    background-color: #fd53539e;
    /* padding:0px; */
}

.profileList1{
    /* padding:0px; */
    /* padding:5px; */
    /* padding-left:15px; */
    margin:0px;
    font-family: "Montserrat", sans-serif;
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
    background-color: #4bbbeb4f;
}
.profileList1:hover{
    background-color: #d7edf7;
    /* padding:0px; */
}
.profileList1:focus{
    background-color: #fd53539e;
    /* padding:0px; */
}
.profileMenu{
    padding:5px;
    margin:0px;
    /* z-index:510; */
    background-color: rgba(250, 250, 250, 0.927);
}
.navProfile{
    z-index:510 ;
}
.notification{
    position:absolute;
    border-radius:50px;
    font-size:10px;
    margin-top:14px;
    padding-top:5px;
    margin-left:11px;
    min-height:20px;
    min-width:20px;
}
.menu1{
    /* position: fixed; */
    max-width:1440px;
    margin: auto;
    top:0;
}
.menuAlign{
    padding: 0px;
    margin:0px;
}
.menuAlignRight{
    position: absolute;
    right:0px;
    min-width: 300px;
}
.menuAlignLeft{
    position: absolute;
    left:0px;
    
    min-width: 1500px;
}
.DeleteNotification{
    color:#FF4B2B;
    background-color: #fff;
    font-size: 15px;
    border:1px solid #f8d5cf;
    z-index:1100;
    position: fixed,
}
.DeleteNotification:hover{
    color:#fff;
    background-color: #FF4B2B;
    font-size: 15px;
    border:1px solid #f8d5cf;
}
.DeleteNotificationDisable{
    color:#a5a5a5;
    background-color: #fff;
    font-size: 15px;
    border:1px solid #f8d5cf;
    z-index:1100;
    cursor:not-allowed;
    position: fixed,
    
}
.DeleteNotificationDisable:hover{
    color:#fff;
    background-color: #a5a5a5;
    font-size: 15px;
    border:1px solid #f8d5cf;
}
.appStatus{
    text-align: "center";
    padding: 7px;
    border-radius:5px;
    font-family: "Montserrat", sans-serif;
    color:#fff;
    margin-bottom:10px;
}
.proImage{
    border-radius: 45px;
    height:40px;
}
.notificationBox{
    right:0;
    top:80px;
    max-height:500px;
    overflow: scroll;
    margin-top: -10px;
    z-index:200 ;
    /* min-width:500px; */
    background-color: rgba(250, 250, 250, 0.857);
    padding:0px;
    border:1px solid rgb(231, 227, 227);
    /* border-radius: 10px; */
}
.notificationBox::-webkit-scrollbar {
    width: 6px;
	height: 6px;
  }
  
  /* Track */
  .notificationBox::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .notificationBox::-webkit-scrollbar-thumb {
    background: #ffe62b;
  }
  
  /* Handle on hover */
  .notificationBox::-webkit-scrollbar-thumb:hover {
    background: #918c8b;
  }

  .notificationMobile{
     display: none;   
}

input[type="radio"]:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
  }
  
  input[class="active"]:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;

    position: relative;
    background-color: #3eda0e;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid rgba(140, 255, 25, 0.987);
  }
  input[class="away"]:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;

    position: relative;
    background-color: #ff846e;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid rgba(244, 238, 58, 0.548);
  }
  input[class="inactive"]:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;

    position: relative;
    background-color: #5f5e5e;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid rgb(212, 212, 212);
  }
.mobileNav{
        margin-top:50px;
    }
    .navOneMobile{
        line-height: 30px;
    }
    @media only screen and (min-width: 1501px) and (max-width: 99170px) {
        .naHead{
            max-width:1440px !important;
            /* position: fixed; */
        }
        .menu1{
            /* position: fixed; */
            /* margin-top: unset; */
            margin-top:-30px !important;
        }
        .menuAlignRight{
            /* position:  unset;
            right:unset;
            min-width: unset; */
        }
        .menuAlignLeft{
            /* position: unset;
            left:unset;
            min-width: unset; */
        }
    }
    @media only screen and (min-width: 1170px) and (max-width: 1500px) {
        .menu1{
           /* top: -500 !important; */
           margin-top: -30px !important;
           
        } 
    }
  @media only screen and (min-width: 790px) and (max-width: 1170px) {
    .menu1{
        display:none;
    } 
    .menu2{
      display:block;
    }
    .menu3{
      display:none;
    }
    .headerNav{
        margin-top:500px;
    }
    .navOneMobile{
        line-height: 40px;
    }
    .mobileNav{
        margin-top:40px;
    }
    .searchInputMobile{
        right:300px;
        top:0px;
    }
  }
  @media only screen and (min-width: 650px) and (max-width: 790px) {
   
    .menu1{
        display:none;
    }
    .menu2{
      display:block;
    }
    .menu3{
      display:none;
    }
    .searchInputMobile{
        right:300px;
        top:3px;
    }
    .navOneMobile{
      line-height: 40px;
      }
      .mobileNav{
          margin-top:40px;
      }
  }
  @media only screen and (min-width: 550px) and (max-width: 650px) {
    .notificationWeb{
        display: none;   
    }
    .notificationMobile{
        display: block;   
    }
      .menu1{
          display:none;
      }
      .menu2{
        display:block;
      }
      .menu3{
        display:none;
      }
      .searchInputMobile{
          right:300px;
          top:5px;
      }
      .navOneMobile{
        line-height: 43px;
        }
        .mobileNav{
            margin-top:40px;
        }
    }
    
  
  @media only screen and (min-width: 370px) and (max-width: 550px) {
    .notificationWeb{
        display: none;   
    }
    .notificationMobile{
        display: block;   
    }
      .menu1{
          display:none;
      }
      .menu2{
          display:none;
      }
      .menuAlign{
          margin-left:-30px;
      }
      .menu3{
          display:block;
      }
      .searchInputMobile{
            right:64px;
            top:-15px;
            height:35px;
          width:200px !important;
          z-index:220;
          padding-right:40px!important;
      }
      .itemLogo{
          font-size: 30px !important;
          padding-top:80px !important;
      }
      .itemList{
          font-size: 13px !important;
          line-height: 20px !important;
      }
      .menuShow{
          animation: show3 0.6s;
          height: 280px;
          display:block;
      }
      @keyframes show3 {
          0%{
              height: 0px;
              display: block; 
              opacity: 0;
              
          }
          80% {
              opacity: 0.3;
              height: 220px;
              display: block; 
              
          }
          100% {
              opacity: 1;
              height: 280px;
              display: block; 
              
          }
      }
      .navOneMobile{
        line-height: 40px;
        }
        .mobileNav{
            margin-top:20px;
            text-align: center;
        }
        .menu4{
            display:none;
        }
    }

  @media only screen and (min-width: 000px) and (max-width: 370px) {
    .notificationWeb{
        display: none;   
    }
    .notificationMobile{
        display: block;   
    }
    .menu1{
        display:none;
    }
    .menu2{
        display:none;
    }
    .menu4{
        display:none;
      
    }
   
    .menuAlign{
        margin-left:-30px;
    }
    .menu3{
        display:block;
    }
    .searchInputMobile{
        right:64px;
        top:-15px;
        width:200px !important;
        z-index:220;
        padding-right:40px!important;
    }
    .itemLogo{
        font-size: 30px !important;
        padding-top:80px !important;
    }
    .itemList{
        font-size: 13px !important;
        line-height: 20px !important;
    }
    .menuShow{
        animation: show3 0.6s;
        
        height: 280px;
        display:block;
    }
    .navOne{
        font-size: 13px;
    }
    @keyframes show3 {
        0%{
            height: 0px;
            display: block; 
            opacity: 0;
            
        }
        80% {
            opacity: 0.3;
            height: 220px;
            display: block; 
            
        }
        100% {
            opacity: 1;
            height: 280px;
            display: block; 
            
        }
    }
    .navOneMobile{
        line-height: 38px;
    }
    .mobileNav{
        margin-top:30px;
        text-align: center;
    }
    
  }
  @media only screen and (min-width: 000px) and (max-width: 340px) {
    .menuMobileRight{
     max-width:220px;
     position: absolute;
     right:0px;
    }
}