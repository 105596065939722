.maintenanceRequestContainer{
     padding-top:100px;
     margin-bottom: 5%;
}
.maintenanceRequestHead{
     font-family: "Montserrat", sans-serif;
     color:#414141;
     letter-spacing: 0.5px;
  
     font-size: 16px;
     font-weight: 800;
}
.maintenanceSpan{
     font-family: "Montserrat", sans-serif;
     color:#908f8f;
     letter-spacing: 0.5px;
     font-size: 11px;
     font-weight: 500;
}
.maintenanceRequestText1{
     font-family: "Montserrat", sans-serif;
     color:#aeacac;
     letter-spacing: 0.5px;
     font-size: 11px;
     font-weight: 600;
}
.maintenanceRequestText11{
     font-family: "Montserrat", sans-serif;
     color:#9c9c9c;
     font-size: 12px;
     letter-spacing: 1.2px;
     text-align: justify;
}
.maintenanceRequestText2{
     font-family: "Montserrat", sans-serif;
     color:#414141;
     letter-spacing: 0.5px;
     font-size: 13px;
     font-weight: 600;
     line-height: 20px !important;
}
.maintenanceRequestText3{
     font-family: "Montserrat", sans-serif;
     color:#414141;
     letter-spacing: 1px;
     font-size: 12px;
     font-weight: 500;
     text-align: justify;
     line-height: 21px !important;
}
.maintenanceRequestText4{
     font-family: "Montserrat", sans-serif;
     color:#838383  !important;
     letter-spacing: 0.5px;
     font-size: 13px;
     font-weight: 600;
     line-height: 20px !important;
}
.maintenanceRequestText5{
     font-family: "Montserrat", sans-serif;
     color:#1f1f1f  !important;
     letter-spacing: 0.5px;
     font-size: 13px;
     font-weight: 600;
     text-align: justify;
     line-height: 20px !important;
}
.maintenanceCard{
     background-color: #fff;
     padding:20px;
     padding-top:15px;
     padding-bottom:15px;
     border-radius: 5px;
     box-shadow: rgba(179, 179, 179, 0.28) 0px 2px 30px 0px;
     height: 100%;
}
.maintenanceCard2{
     background-color: #fff;
     padding:20px;
     padding-top:15px;
     padding-bottom:15px;
     border-radius: 5px;
     border:1px solid #9b9b9b5e;
     /* box-shadow: rgba(157, 157, 157, 0.066) 0px 8px 20px 0px; */
}
.row.equal-cols {
     display: -webkit-flex;
     display: -ms-flexbox;
     display: flex;
     -webkit-flex-wrap: wrap;
     -ms-flex-wrap: wrap;
     flex-wrap: wrap;
   }
   
   .darkBtn {
     border-radius: 30px;
     font-size: 12px;
     font-weight: 500;
     padding: 10px 20px !important;
     letter-spacing: 1px;
     text-transform: capitalize !important;
     transition: transform 80ms ease-in ;
     border: solid 1px #313131 !important;
     background-color: #313131 !important;
     color: #fff;
     cursor: pointer;
   }
   .successBtn {
     border-radius: 30px;
     font-size: 12px;
     font-weight: 500;
     padding: 10px 20px !important;
     letter-spacing: 1px;
     text-transform: capitalize !important;
     transition: transform 80ms ease-in ;
     border: solid 1px #79cd6c !important;
     background-color: #79cd6c !important;
     color: #fff;
     height:55px;
   }
   .maintenanceRequestAlign{
     /* padding-right:30px;
     padding-left:30px; */
    
}
.maintenanceRequestHeaderAlign1{
     display:inline-block;
   }
   .maintenanceRequestHeaderAlign2{
     display:none;
   }
   .maintenanceRequestAlignMain{
        padding-right:30px;
        padding-left:30px;
        max-height: 320px;
        overflow-y: scroll;
        /* overflow-x: hidden; */
        margin-top:1%;
        margin-bottom:1%;
        
   }
   .maintenanceRequestAlignMain2{
     padding-right:30px;
     padding-left:30px;
     max-height: 400px;
     overflow-y: scroll;
     /* overflow-x: hidden; */
     margin-top:1%;
     margin-bottom:1%;
     
}
   .maintenanceRequestAlign3{
     display:none;
     }

.maintenanceRequestAlignMain::-webkit-scrollbar {
     width: 4px;
      height: 6px;
      border-radius: 15%;
   }
   
   /* Track */
   .maintenanceRequestAlignMain::-webkit-scrollbar-track {
     background: #f1f1f1;
   }
   
   /* Handle */
   .maintenanceRequestAlignMain::-webkit-scrollbar-thumb {
     background: #dcdcdc;
   }
   
   /* Handle on hover */
   .maintenanceRequestAlignMain::-webkit-scrollbar-thumb:hover {
     background: #fe947f;
   }
 

.maintenanceRequestAlignMain2::-webkit-scrollbar {
     width: 4px;
      height: 6px;
      border-radius: 15%;
   }
   
   /* Track */
   .maintenanceRequestAlignMain2::-webkit-scrollbar-track {
     background: #f1f1f1;
   }
   
   /* Handle */
   .maintenanceRequestAlignMain2::-webkit-scrollbar-thumb {
     background: #dcdcdc;
   }
   
   /* Handle on hover */
   .maintenanceRequestAlignMain2::-webkit-scrollbar-thumb:hover {
     background: #fe947f;
   }

 
     .squareDivPri {
          /* border: solid 1px #ff4b2b; */
          background-color: #ff4b2b;
          color: #fff;
          font-size: 16px;
          border-radius: 5px;
          font-weight: 600;
          padding:12px 10px;
          letter-spacing: 1px;
          text-align: center;
          font-family: "Montserrat", sans-serif;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
     }

     .squareDivPri2 {
          border: solid 1px #ff4b2b;
          background-color: #fff;
          color: #ff4b2b;
          font-size: 16px;
          border-radius: 5px;
          font-weight: 600;
          padding:12px 10px;
          letter-spacing: 1px;
          text-align: center;
          font-family: "Montserrat", sans-serif;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
     }
     .chipsBadge {
          background-color: #ff4b2b;
          color: #fff;
          font-size: 13px;
          border-radius: 25px;
          font-weight: 600;
          padding:10px 1px;
          letter-spacing: 1px;
          text-align: center;
          font-family: "Montserrat", sans-serif;
          max-width: 180px;
     }
    
     .chipsBadge2 {
          border: solid 1px #ff4b2b;
          background-color: #fff;
          color: #ff4b2b;
          font-size: 13px;
          border-radius: 25px;
          font-weight: 600;
          padding:10px 1px;
          letter-spacing: 1px;
          text-align: center;
          font-family: "Montserrat", sans-serif;
          max-width: 180px;
     }

     .maintenanceInputSelect {
          
          background-color: rgb(255, 255, 255);
          border: none;
          font-family: "Montserrat", sans-serif;
          height: 100%;
          font-size: 16px;
          border-radius: 5px;
          font-weight: 700;
          padding: 0px 25px;
          width: 100%;
       
          font-size: 12px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
     }
     
     .maintenanceRequestCardCount{
          background-color: #f84343;
          color: #fff;
          text-align: center;
          padding-top: 10px;
          width: 35px !important;
          height: 35px !important;
          border-radius: 50%;
          font-family: "Montserrat", sans-serif;  
          font-size: 13px;
          font-weight: 800;
          margin-top: -3px !important;
          margin-left: 20px !important;
          position: absolute;
     }
        .requestShowMore{
          font-family: "Montserrat", sans-serif;
          color:#b2b2b2  !important;
          letter-spacing: 0.5px;
          font-size: 12px !important;
          font-weight: 600;
          line-height: 20px !important;
          text-transform: uppercase;
          width:100%;
          cursor: pointer;
          text-align: right;
          margin: auto;
     }
     .requestShowMore:hover{
          color:#3e3e3e  !important;
     }
     .maintenanceRequestHeaderAlign{
          display:flex;
     }
     .maintenanceRequestHeaderAlign2{
          display:none;
     }
   @media only screen and (min-width: 368px) and (max-width: 568px) {


     .maintenanceRequestAlign{
          padding-right:10px;
          padding-left:10px;
     }
     .maintenanceRequestAlign2{
          display:none;
     }
     .maintenanceRequestAlign3{
          display:block;
          margin-left:6px;
     }
}
@media only screen and (min-width: 568px) and (max-width: 868px) {
     .maintenanceRequestAlign{
          padding-right:20px;
          padding-left:20px;
     }
}
   @media only screen and (min-width: 100px) and (max-width: 768px) {
     .maintenanceRequestHeaderAlign2{
          display:flex;
        }
        .maintenanceRequestHeaderAlign1{
          display:none;
        }
     .maintenanceRequestHeaderAlign{
          display:none;
     }
     .maintenanceRequestHeaderAlign2{
          display:block;
     }
     .maintenanceInputSelect {
          height: 45px;
     }
     .maintenanceRequestContainer{
          padding:0px;
          padding-top:110px;
          margin-bottom: 5%;
     }
     .maintenanceInputSelectAlign{
          margin-top: 2%;
          margin-bottom: 2%;
     }
     
   }

   @media only screen and (min-width: 00px) and (max-width: 368px) {

     .maintenanceRequestAlign{
          padding-right:5px;
          padding-left:5px;
     }
     .maintenanceRequestAlign2{
         
          display:none;
     }
     .maintenanceRequestAlign3{
          margin-left:0px;
          display:block;
     }
}

/* maintenance 01 */

.maintenanceContainer{
     padding-top:110px;
     margin-bottom: 15%;
}
.maintenanceHeadText{
     font-family: "Montserrat", sans-serif;
     color:#181818;
     /* letter-spacing: 0.5px;
     line-height: 25px; */
     
     line-height: 5px;
     font-size: 15px;
     font-weight: 500;
     
   }
   .maintenanceHeadText1{
    font-family: "Montserrat", sans-serif;
    color:#181818;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    margin:25px 0 0px !important; 
  }
  .maintenanceHeadText3{
     font-family: "Montserrat", sans-serif;
     color:#181818;
     /* letter-spacing: 0.5px;
     line-height: 25px; */
     
     line-height: 5px;
     font-size: 15px;
     font-weight: 500;
     margin:15px 0 10px !important; 
   }
   .maintenanceHeadText4{
     font-family: "Montserrat", sans-serif;
     color:#ababab;
     /* letter-spacing: 0.5px;
     line-height: 25px; */
     
     line-height: 5px;
     font-size: 13px;
     font-weight: 500;
     margin:15px 0 20px !important; 
   }
  .maintenanceHeadTextDark1{
    font-family: "Montserrat", sans-serif;
    color:#fff;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    padding-top: 17px;
  }
   .maintenanceHeadTextDark{
    font-family: "Montserrat", sans-serif;
    color:#fff;
    /* letter-spacing: 0.5px;
    line-height: 25px; */
    font-size: 15px;
    font-weight: 500;
  }
   .maintenanceContentText{
     font-family: "Montserrat", sans-serif;
     color:#807e7e;
     letter-spacing: 1px;
     /* line-height: 25px; */
     font-size: 12px;
   }
   .bgGreen{
     background-color: #16A085;
     color:#fff;
   }
   .bgGreen:hover{
     background-color: #0f7762;
     color:#fff;
   }
   .bgRed{
     background-color: #E74C3C; 
     color:#fff;
}
.bgRed:hover{
     background-color: #c22b1a; 
     color:#fff;
}
.maintenanceImage{
     width:100%;
     height:200px;

}
.maintenanceHide{
  display:none;
}
.payeeImage {
     height: 30px;
     width: 30px;
   }
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .maintenanceHide{
    display:block;
  }
}
@media only screen and (min-width: 100px) and (max-width: 768px) {
  .payeeImage{
    margin-top: 14px;
  }
  .maintenanceHeadTextDark1{
   
    text-align: center;
    padding-top: 9px;
  }
}

@media only screen and (min-width: 100px) and (max-width: 425px) {
     .maintenanceHeadText1{
          line-height: 25px;
        }
        .maintenanceHeadText3{
           line-height: 20px;
         }
         .maintenanceHeadText4{
           line-height: 20px;
         }
}