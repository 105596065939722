
.show {
  display: block;
}
.hide {
  display: none;
}
.modalStyle {
  border-radius: 25px;
}
.modalContent {
  font-size: 19px;
  font-weight: 100;
  margin-top: -40px;
  margin-bottom: -20px;
}

.alertContain {
  position: fixed;
  right: 15px;
  z-index: 1000000 !important;
}

.NotificationWindow {
  z-index: 1000000000000000 !important;
  padding: 10px;
  min-width: 300px;
  max-width: 600px;
  margin-bottom: 5px;
  border-radius: 10px;
  /* animation: AlertRight 1s linear ; */
  opacity: 1;
}
.topMargin10 {
  top: 10px !important;
}
.topMargin40 {
  top: 100px !important;
}
.successAlert {
  /* background-color: rgba(49, 165, 76, 0.81); */
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(45, 43, 43, 0.721) 0px 3px 8px;
  color: rgb(30, 103, 23);
}
.successAlert .fa {
  color: rgb(61, 190, 61);
}

.successAlert .bar {
  background: rgb(48, 237, 48);
}
.warningAlert {
  /* background-color: rgba(252, 255, 85, 0.842); */
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(45, 43, 43, 0.721) 0px 3px 8px;
  color: rgb(142, 143, 51);
}
.warningAlert .fa {
  color: rgb(227, 213, 68);
}

.warningAlert .bar {
  background: rgb(219, 232, 42);
}

.infoAlert {
  /* background-color: rgba(96, 155, 218, 0.842); */
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(45, 43, 43, 0.721) 0px 3px 8px;
  color: rgba(22, 59, 99, 0.842);
}
.infoAlert .fa {
  color: rgba(86, 168, 255, 0.842);
}

.infoAlert .bar {
  background: rgba(105, 177, 255, 0.842);
}
.lightAlert .bar {
  background: rgba(171, 171, 171, 0.842);
}

.dangerAlert {
  /* background-color: rgba(245, 76, 84, 0.822); */
  background-color: rgb(255, 255, 255);

  box-shadow: rgba(45, 43, 43, 0.721) 0px 3px 8px;
  color: rgba(97, 7, 13, 0.822);
}
.dangerAlert .fa {
  color: rgb(255, 71, 71);
}

.dangerAlert .bar {
  background: rgba(255, 65, 84, 0.842);
}

.lightAlert {
  background-color: rgba(238, 238, 238, 0.904);
  box-shadow: rgba(255, 255, 255, 0.982) 0px 3px 8px;
}
@keyframes AlertRight {
  0% {
    opacity: 0.2;

    /* display:block; */
  }

  50% {
    opacity: 1;
    display: block;
    transform: translate(1px, 1px) rotate(0deg);
  }
  55% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  65% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  70% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  75% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  80% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  85% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  90% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  95% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.notificationText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
}
.notificationClose {
  margin-top: 10px;
  margin-right: 100px;
  z-index: 501;
  position: absolute;
  color: rgb(159, 159, 159) !important;
}
.notificationClose:hover {
  color: rgb(108, 108, 108) !important;
}
.notificationText {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
}

@media only screen and (min-width: 425px) and (max-width: 600px) {
  .notificationText {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 425px) {
  .notificationText {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
  }
}
