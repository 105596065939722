.Bar{
    width: 100%;
    height: 260px;
    position: relative;
    
    /* margin: 20px; */
}

.occupied{
    font-family: "Montserrat", sans-serif;
    /* font-family: 'Kanit', sans-serif; */
    /* font-family: 'Titillium Web', sans-serif; */
    /* font-family: 'Ubuntu', sans-serif; */
    font-size:40px;
    padding-left:30px;
}
.colorLine{
    border-bottom: 3px solid #E74C3C;
    opacity: 1;
   
    width:100%;   
    z-index: 6;
    position: absolute;
}
.colorBox{
    width: 100%;
    height: 100%;            
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    z-index: 1;
    border-bottom-left-radius: 15px;
    background: linear-gradient(to bottom, #cab4a9 0%, #E74C3C 100%);
    animation: upColor 1s;
}
@keyframes upColor{
	from{transform: scaleY(0.5);opacity: 0.1;}
	to{transform: scaleY(1);opacity: 1;}
}
@keyframes up{
	from{transform: scaleY(0.5);}
	to{transform: scaleY(1);}
}
.colorChanger{
    z-index: 5;
    height: 50%;
    bottom:0px;
    border-bottom-left-radius: 0px;
    border-bottom: 3px solid rgb(221, 55, 55);
    background: white;
}
.textSize{
    font-size: 10px;
    height:4%;
    line-height: 16px;
}
.imageBox{
    width: 100%;
    height: 265px;
    border-bottom-left-radius: 15px;
    animation: up 1s;
}.Box{
    z-index: 2;
    opacity: 1;
    border-radius: 25;
}
.height00{
    height: 98%;
}
.height05{
    height: 94%;
}
.height10{
    height: 90%;
}
.height15{
    height: 87%;
}
.height20{
    height: 84%;
}
.height25{
    height: 80.5%;
}
.height30{
    height: 77%;
}
.height35{
    height: 73.5%;
}
.height40{
    height: 70%;
}
.height45{
    height: 66.5%;
}
.height50{
    height: 63%;
}
.height55{
    height: 59%;
}
.height60{
    height:56%;
}
.height65{
    height: 51%;
}
.height70{
    height: 48%;
}
.height75{
    height: 44%;
}
.height80{
    height: 40%;
}
.height85{
    height: 36%;
}
.height90{
    height: 32%;
}
.height95{
    height: 28%;
}
.height100{
    height: 24%;
}
@media only screen and (min-width: 491px) and (max-width: 991px) {
    .occ{
        margin-top: 30px;
    }
    .occupied{
        font-size:40px;
    }
}
@media only screen and (min-width: 100px) and (max-width: 491px) {
    .occ{
        margin-top: 30px;
    }
    .occupied{
        font-size:30px;
    }
}