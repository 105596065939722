.card_unit__image {
    /* height: 160px;
    width: 160px; */
    width:100%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    /* border: 1px solid #eb6658; */
    /* box-shadow: 0 3px 15px #dd6e62; */
    max-height:200px;
    max-width:200px;
  }
  .detailsCard{
    border-radius: 15px;
    width:500px;
    /* background: linear-gradient(to bottom right, #feffff 0%, #aabdcf 100%); */
    background-color: #fff;
  } 
  .detailsCard1{
    border-radius: 15px;
    /* width:250px; */
    /* background: linear-gradient(to bottom right, #feffff 0%, #aabdcf 100%); */
    background-color: #fff;
  } 
  .detailsCard2{
   
    box-shadow: 0 3px 15px #dd6e62;
    border: 1px solid #eb6658;
    
  } 
  .detailsText{
    white-space: nowrap;
    color:#26445f;
    font-weight: 1500;
  }
  .textPri {
    color: #ff4b2b;
  }
  .availableTableHead2{
    font-family: "Montserrat", sans-serif;
    text-decoration: underline;
    color:#000;
    font-size: 12px;
  }

  @media only screen and (min-width: 225px) and (max-width: 445px) {
    .detailsCard2{
      max-width: 280px;
    }
    .availableTableHead2{
      font-size: 12px;
    }
    .detailsText2{
      font-size: 12px;
    }
  }
 