.container-contact100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #ffffff;
  
}

.wrap-contact100 {
  width: 1120px;
  background: #fff;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row-reverse;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/*==================================================================
[ Text ]*/
.txt1 {
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
  line-height: 1.2;
  color: #fff;
}

.txt2 {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  line-height: 1.6;
  color: #d2d2d2;
}

.txt3 {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  color: #fecec5;
}

/*==================================================================
[ Size ]*/
.size1 {
  width: 355px;
  max-width: 100%;
  margin-bottom: 7%;
}

.size2 {
  width: calc(100% - 43px);
}

textarea:focus,
input:focus {
  outline: none;
}
/*==================================================================
   [ Contact more ]*/
.contact100-more {
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-content: center;
  position: relative;
  z-index: 1;
  padding: 30px 15px 0px 15px;
  padding-top: 15%;
  padding-left: 10%;
  
}

.contact100-more::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(146, 34, 22, 0.822);
}

/*==================================================================
   [ Form ]*/

.contact100-form {
  width: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  padding: 56px 55px 63px 55px;
}
hr.rdLine {
  width: 115px;
  border: 2px solid #ff4b2b;
  margin-bottom: 40px;
}
hr.lightLine {
  width: 100%;
  border: 1px dashed #edededb6;
  margin-bottom: 10px;
  margin-top: 5px;
}
.contact100-form-title {
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 33px;
}

/*------------------------------------------------------------------
   [ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border: 1px solid #e6e6e6;
}

.rs1-wrap-input100,
.rs2-wrap-input100 {
  width: 50%;
}

.rs2-wrap-input100 {
  border-left: none;
}

.label-input100 {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #373737;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 1px;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 55px;
  border: 1px solid #e6e6e6;
  border-bottom: none;
  padding: 10px 25px;
  margin-top: 15px;
  margin-bottom: 0;
}
.input100::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(198, 198, 198);
  opacity: 1; /* Firefox */
}
.input100 {
  display: block;
  width: 100%;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;

  line-height: 1.2;
  padding: 0 25px;
}

input.input100 {
  height: 55px;
}

textarea.input100 {
  min-height: 139px;
  padding-top: 19px;
  padding-bottom: 15px;
  border: 1px solid #f4f4f4;
}

/*---------------------------------------------*/

.focus-input100 {
  color: #c7c7c7;

  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #bdbdbd;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}

.input100:focus + .focus-input100 {
  visibility: visible;
  opacity: 1;
  color: #c7c7c7;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/*------------------------------------------------------------------
   [ Button ]*/
.container-contact100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 23px;
}

.contact100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 200px;
  height: 50px;
  border-radius: 2px;
  background: #ff4b2b;

  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.contact100-form-btn:hover {
  background: #333333;
}

/*------------------------------------------------------------------
   [ Responsive ]*/

@media (max-width: 992px) {
  .contact100-form {
    width: 60%;
    padding: 56px 30px 63px 30px;
  }

  .contact100-more {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .contact100-form {
    width: 100%;
  }

  .contact100-more {
    width: 100%;
  }
}
@media (max-width: 356px) {
  .txt1 {
    font-size: 20px;
    line-height: 1.2;
  }

  .txt2 {
    font-size: 14px;
    line-height: 1.2;
  }

  .txt3 {
    font-size: 13px;
    line-height: 1.2;
  }
}
@media (max-width: 576px) {
  .contact100-form {
    padding: 56px 15px 63px 15px;
  }

  .rs1-wrap-input100,
  .rs2-wrap-input100 {
    width: 100%;
  }

  .rs2-wrap-input100 {
    border-left: 1px solid #e6e6e6;
    border-top: none;
  }
}

/*------------------------------------------------------------------
   [ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #ff4b2b;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 2px;
  pointer-events: none;

  font-family: "Montserrat", sans-serif;
  color: #ff4b2b;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\2757";
  font-family: "Montserrat", sans-serif;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}
