
.fullNotificationWindow{
     width:100%;
     border:1px solid #ff4949;
     background-color: #FFD6BF;
     display: flex;
     margin:10px 0px;
}
.fullNotificationIcon{
     flex:2;
     color:#ff4949;
     align-self: center;
     text-align: center;
}
.fullNotificationText{
     flex:35;
     align-self: center;
     text-align: left;
     margin-bottom: 13px;
     margin-top:13px;
     font-family: "Montserrat", sans-serif;  
     font-size: 14px;
     line-height: 21px;
     color:#000000;
}
.textNotificationRed{
     color:#ff5628;
     font-weight: 600 !important;
     font-size: 14px;
     
}
.fullNotificationClose{
     color:#0000009c;
     font-size: 18px;
     flex:1.5;
     align-self: center;
     text-align: center;
     font-weight: 300 !important;
     cursor: pointer;
}

@media only screen and (min-width: 750px) and (max-width:1000px) {
     .fullNotificationIcon{
          flex:3;
     }
     .fullNotificationClose{

          flex:3;
          
     }

}
@media only screen and (min-width: 500px) and (max-width:750px) {
     .fullNotificationIcon{
          flex:5;
     }
     .fullNotificationClose{

          flex:5;
          
     }

}
@media only screen and (min-width: 00px) and (max-width:500px) {
     .fullNotificationIcon{
          flex:7;
          align-self: flex-start;
          padding-top: 4%;
          text-align: center;
     }
     .fullNotificationClose{
          align-self: flex-start;
          padding-top: 4%;
          text-align: center;
          flex:7;
          
     }

}
@media only screen and (min-width: 00px) and (max-width:400px) {
     .fullNotificationIcon{
          flex:8;
          align-self: flex-start;
          padding-top: 6%;
          text-align: center;
     }
     .fullNotificationClose{
          align-self: flex-start;
          padding-top: 4%;
          text-align: center;
          flex:8;
          
     }
     .fullNotificationText{
          font-size: 11px;
     }
     .textNotificationRed{
          font-size: 11px;  
     }

}